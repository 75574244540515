import { decodeTime } from 'ulid';
import dayjs from 'dayjs';
import cloneDeep from 'lodash-es/cloneDeep';
// import { AuthorizedHandler } from '@org/client-graphql';

// Item imports

export let noteFields = [
  {
    type: 'text',
    name: 'itemName',
    label: 'Item Name',
    initial: "",
  },
  {
    type: 'textarea',
    name: 'description',
    label: 'Description',
    initial: "",
  },
  {
    type: 'amount',
    name: 'originalBalance',
    label: 'Original Balance',
    initial: 1.0,
  },
  {
    type: 'factor',
    name: 'factor',
    label: 'Factor',
    initial: 1.0,
  },
  {
    type: 'amount',
    name: 'currentBalance',
    label: 'Current Balance',
    initial: 1.0,
  },
  {
    type: 'percent',
    name: 'coupon',
    label: 'Coupon',
    initial: 0.0,
  },
];

// Manage imports

export const columns = [
  // { field: 'id', headerName: 'ID', width: 10 },
  { field: 'itemName', headerName: 'Name', width: 150 },
  { field: 'coupon', headerName: 'Coupon', width: 130, type: 'number' },
  { field: 'originalBalance', headerName: 'Ori Balance', width: 150, type: 'number' },
  { field: 'creationDate', headerName: 'Creation Date', width: 170, type: 'date' },
  { field: 'launchDate', headerName: 'Launch Date', width: 170, type: 'date' },
];

export async function getRows(props) {
  let { authorizedHandler } = props;

  let notes = await authorizedHandler.listNotes();

  notes = cloneDeep(notes);

  notes.forEach(note => {
    note.creationDate = dayjs(decodeTime(note.id)).format('YY-MM-DD hh:mm a');
    note.launchDate = dayjs(note.launchDate).format('YYYY-MM-DD');
  });

  return notes;
}

export async function refreshRows(props) {
  let { authorizedHandler } = props;

  let notes = await authorizedHandler.refreshNotes();

  notes = cloneDeep(notes);

  notes.forEach(note => {
    note.creationDate = dayjs(decodeTime(note.id)).format('YY-MM-DD hh:mm a');
    note.launchDate = dayjs(note.launchDate).format('YYYY-MM-DD');
  });

  return notes;
}

export async function createItem(props) {
  console.log('Notes', 'createItem', props);
  let { authorizedHandler, input } = props;

  return authorizedHandler.createNote(input);
}

export async function updateItem(props) {
  console.log('Notes', 'updateItem', props);
  let { authorizedHandler, input } = props;

  return authorizedHandler.updateNote(input);
}

export async function deleteRows(props) {
  let { authorizedHandler, selections } = props;

  if (selections.length > 1) {
    // batchDelete
  } else { // just 1 selection
    let selection = selections[0];
    let input = { id: selection.id };
    return authorizedHandler.deleteNote(input);
  }
}

export async function handleRowClick(event, props) {
  // alert(JSON.stringify(event.row, null, ' '));
  console.log('Notes', 'handleRowClick', event, props);
  // alert(JSON.stringify(event.row, null, ' '));
  let data = event.row;
  props.history.push({ pathname: '/admin/create-note', state: { data } });
}
