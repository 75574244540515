import React from 'react';
import Typography from '@material-ui/core/Typography';

export function PublicNoteCards() {
  return (
    <div id='public-note-cards' >
      <Typography variant='h4' component='h2' style={{ display: 'flex', flexDirection: 'column', justityContent: 'flex-start', alignItems: 'center', flexGrow: 1, width: '100%' }} >
        {/* {'Publicly available investment information is coming soon. Please Sign In to see detailed investment information.'} */}
        {'Publicly available investment information coming soon...'}
      </Typography>
    </div>
  );
}
