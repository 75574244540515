import dayjs from 'dayjs';
import { RecordClass } from './Record';

export function calculate(input) {
  // console.log('calculate', input);
  // input.investedAmount = input.amount * input.factor * input.price / 100;
  let investedAmount = input.amount * input.factor * input.price / 100;
  // console.log('TradeClass', 'calculate', input);
  return { ...input, investedAmount };
}

export class TradeClass extends RecordClass {
  constructor(input) {
    super(input);
    console.info(this.constructor.name, 'Class constructor', input);

    // this.table = DataTable;

    // this.schema = tradeRecordSchema;

    this.keys.itemType = 'TRADE';

    let now = dayjs();

    this.defaultValues = {
      referenceType: "",
      referenceId: "",
      userId: "",
      eventDate: now.format('YYYY-MM-DD'),
      tradeType: 'BUY',
      amount: 0.0,
      factor: 1.0,
      price: 100.0,
      investedAmount: 0.0,
      itemStatus: "",
    };

    this.requiredValues = {
      referenceType: true,
      referenceId: true,
      userId: true,
      eventDate: true,
      amount: true,
      investedAmount: true,
    };

    this.tradeTypes = [ { label: 'Buy', value: 'BUY' }, { label: 'Sell', value: 'SELL' } ];

    this.update(input);
  }

  calculateValues(input) {
    console.info(`${this.constructor.name} calculateValues input: ${JSON.stringify(input, null, ' ')}`);

    input = { ...this.attributes, ...input };

    input = calculate(input);

    console.log('Trade', input);

    this.attributes = { ...this.attributes, ...input };
    console.log('calculateValues', this.attributes);
    return this.attributes;
  }
}
