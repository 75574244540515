import React from 'react';
// import PropTypes from "prop-types";
// import Typography from '@material-ui/core/Typography';
// import { makeStyles } from '@material-ui/core/styles';
// import { styles } from '@org/client-libs-styles';
import { ExLink } from '@org/client-components-core/links/ExLink';
import { Page, Heading } from '@org/client-components-custom';

// Borrowed from: https://www.blackstone.com/cookie-policy-EU

const { REACT_APP_COMPANY_NAME } = process.env;

// const localStyles = {
//   ...styles,
//   smallLink: {
//     display: 'inline',
//   },
// };

// const useStyles = makeStyles(localStyles);

export function Cookies() {
  // let classes = useStyles();

  return(
    <>
    {/* <div className={classes.pageRoot} > */}
    <Page id='cookie-policy' >
      {/* <Typography variant={localStyles.pageTitleVariant} component='h1' className={classes.pageTitle} >
        {'Cookie Policy'}
      </Typography> */}
      <Heading>{'Cookie Policy'}</Heading>
      <p>Updated: November 17, 2020</p>

      <div style={{width: 'auto', margin: '0', padding: '1rem 0 0 0'}} >

        <section>
          <p className='legal effective' style={{marginBottom: '2rem', fontStyle: 'italic', fontWeight: '500'}}>This notice is intended to address recent user protection legislations such as the <ExLink to='https://en.wikipedia.org/wiki/General_Data_Protection_Regulation' title='European Union’s General Data Protection Regulation (“GDPR“)' />, the <ExLink to='https://en.wikipedia.org/wiki/California_Consumer_Privacy_Act' title='California Consumer Privacy Act of 2018 (“CCPA”)' />, and other current, and expected future, legislation and regulations.</p>
        </section>

        <section>
          <h4><strong>What are Cookies?</strong></h4>
          <p>An <ExLink to='https://en.wikipedia.org/wiki/HTTP_cookie' title='HTTP cookie' /> (also called web cookie, Internet cookie, browser cookie, or simply cookie) is a small piece of data stored on a user’s computer by the web browser application while the user is browsing a website. Cookies were originally designed to be a reliable mechanism for websites to remember stateful information (such as items added in the shopping cart in an online store) or to record the user’s browsing activity (including clicking particular buttons, logging in, or recording which pages were visited in the past). Cookies perform essential functions in the modern web. Perhaps most importantly, authentication cookies are the most common method used by web servers to know whether the user is logged in or not, and which account they are logged in with. Without such a mechanism, the site would not know whether to send a page containing sensitive information, or require the user to authenticate themselves by logging in.</p>
        </section>

        <section>
          <h4><strong>Do we use Cookie’s on our Site?</strong></h4>
          <p style={{marginBottom: '1rem'}} >Cookies generally fall into two categories: essential and functional. Essential cookies are those without which the site will not operate as intended. Functional cookies are those used primarily to improve the user experience but are not strictly necessary for the site to operate as intended.</p>
          <p style={{marginBottom: '1rem'}} >{REACT_APP_COMPANY_NAME} currently uses one essential cookie, a “session“ cookie to manage user authentication and authorization and may use additional cookies in the future. This session cookie is essential to ensuring our users’ data security and the general security and operations of the site cannot be maintained without it. If you do not agree to our usage of cookies, please do not use this site.</p>
          <p style={{marginBottom: '1rem'}} >{REACT_APP_COMPANY_NAME} does not currently use any functional cookies although we may use additional essential and functional cookies in the future. If we choose to use functional cookies in the future, we will also provide users with the ability to manage their usage under their user settings.</p>
        </section>

        <section>
          <h4><strong>What is Web Storage?</strong></h4>
          <p><ExLink to='https://en.wikipedia.org/wiki/Web_storage' title='Web Storage' />, sometimes known as DOM storage (Document Object Model storage), provides web apps with methods and protocols for storing client-side data. Web storage supports persistent data storage, similar to cookies but with a greatly enhanced capacity and no information stored in the HTTP request header. There are two main web storage types: local storage and session storage, behaving similarly to persistent cookies and session cookies respectively. Web Storage is standardized by the <ExLink to='https://en.wikipedia.org/wiki/World_Wide_Web_Consortium' title='World Wide Web Consortium (W3C)' /> and <ExLink to='https://en.wikipedia.org/wiki/WHATWG' title='WHATWG' />.</p>
        </section>

      <section>
        <h4><strong>Do we use Web Storage on our Site?</strong></h4>
        <p>{REACT_APP_COMPANY_NAME} does not currently use Web Storage but may do so in the future.</p>
      </section>

        <section>
          <h4><strong>Why do we use Cookie’s rather than Web Storage?</strong></h4>
          <p>As per the HTML 5 specification, both cookies and local storage are only accessible by the site domain which created them. However, this includes any third-party software used by the website and delivered by the domain name. In addition, all Web Storage is accessible to client-side javascript from any source and all browser extensions enabled by the user. Only Cookies allow access to be restricted a specific domain AND securely hidden from javascript [and browser extensions].</p>
        </section>

        {/* <section>
          <h4><strong>How do I disable or remove local storage?</strong></h4>
          <p>You can restrict, or otherwise change, the use of local storage on the Site(s) by visiting User Settings. [A link to User Settings will be provided at the bottom of all pages on the Site(s).]</p>
        </section> */}
    </div>
    </Page>
    {/* </div> */}
    </>
  );
}

// Cookies.propTypes = {
//   system: PropTypes.shape(),
// };
