import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import round from 'lodash-es/round';
import { makeStyles } from '@material-ui/core/styles';
// import { useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
// import { LineChart, Line, XAxis, YAxis, Label, ResponsiveContainer } from 'recharts';
import { LineChart } from 'recharts/es6/chart/LineChart';
import { Line } from 'recharts/es6/cartesian/Line';
import { XAxis } from 'recharts/es6/cartesian/XAxis';
import { YAxis } from 'recharts/es6/cartesian/YAxis';
import { Label } from 'recharts/es6/component/Label';
import { ResponsiveContainer } from 'recharts/es6/component/ResponsiveContainer';
import theme from '@org/client-libs-themes';

// https://github.com/mui-org/material-ui/tree/master/docs/src/pages/getting-started/templates/dashboard

// // Recharts quick fix
// // https://github.com/FormidableLabs/victory-native/issues/653
// // /server/api/node_modules/d3-array/src/cumsum.js
// // export default function cumsum(values, valueof) {
// //   var sum = 0, index = 0;
// //   return Float64Array.from(values, valueof === undefined
// //     ? v => (sum += +v || 0)
// //     : v => (sum += +valueof(v, index++, values) || 0));
// // }
// // https://github.com/FormidableLabs/victory-native/issues/653
// export default function cumsum(values, valueof) {
//   let fn = (v) => (sum += +valueof(v, index++, values) || 0);
//   if (valueof === undefined)
//     fn = (v) => (sum += +v || 0);
//   var sum = 0, index = 0;
//   return Float64Array.from(values, fn);
// }

const localStyles = {
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    // overflow: 'auto',
    flexDirection: 'column',
    width: '100%',
    boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
  },
  fixedHeight: {
    height: 400,
  },
  yAxisLabel: { whiteSpace: 'normal', textAnchor: 'middle', fill: theme.palette.text.primary },
};

const useStyles = makeStyles(localStyles);

// Generate Sales Data
function createData(date, amount) {
  return { date, amount };
}

const balance = [
  createData('Dec \'19', 100),
  createData('Jan \'20', 101),
  createData('Feb \'20', 101.75),
  createData('Mar \'20', 103),
  createData('Apr \'20', 103.5),
  createData('May \'20', 104.5),
  createData('Jun \'20', 105.25),
  createData('Jul \'20', 106),
  createData('Aug \'20', 107.5),
  createData('Sep \'20', 108.5),
  createData('Oct \'20', 109.75),
  createData('Nov \'20', 110.5),
  // createData('Dec \'20', null),
  // createData('15:00', 2000),
  // createData('18:00', 2400),
  // createData('21:00', 2400),
  // createData('24:00', undefined),
];

let init = 100;
let prev = init;
function createReturn(curr) {
  let r = ((curr / prev) - 1) * 100;
  console.log(prev, curr, round(r, 2));
  prev = curr;
  return r;
}

// let balance = 100;
// let note1 = {
//   netRate: 9,
//   originalTerm: 12,
// };
// let transaction1 = {
//   amount: 25000,
//   firstPaymentDate: 
// };
let netRate1 = 9 / 12;
let netRate2 = 12 / 12;
let netRate3 = 10 / 12;
let netRate4 = 11 / 12;
// let cashflow1 = [
//   { eventDate: }
// ];

// let amount1 = 25000;
// let amount2 = 25000;
// let amount3 = 25000;

const returns = [
  // createData('Dec \'19', createReturn(prev + netRate1)),
  // createData('Jan \'20', createReturn(prev + netRate1)),
  // createData('Feb \'20', createReturn(prev + netRate1)),
  // createData('Mar \'20', createReturn(prev + netRate1)),
  // createData('Apr \'20', createReturn(prev + netRate1)),
  // createData('May \'20', createReturn(prev + netRate1)),
  // createData('Jun \'20', createReturn(prev + (netRate1 + netRate2) / 2)),
  // createData('Jul \'20', createReturn(prev + (netRate1 + netRate2) / 2)),
  // createData('Aug \'20', createReturn(prev + (netRate1 + netRate2) / 2)),
  // createData('Sep \'20', createReturn(prev + (netRate1 + netRate2) / 2)),
  // createData('Oct \'20', createReturn(prev + (netRate1 + netRate2) / 2)),
  // createData('Nov \'20', createReturn(prev + (netRate1 + netRate2) / 2)),
  // createData('Dec \'20', createReturn(prev + (netRate2 + netRate3) / 2)),
  // createData('Jan \'21', createReturn(prev + (netRate2 + netRate3) / 2)),
  // createData('Feb \'21', createReturn(prev + (netRate2 + netRate3) / 2)),
  // createData('Mar \'21', createReturn(prev + (netRate2 + netRate3) / 2)),
  // createData('Apr \'21', createReturn(prev + (netRate2 + netRate3 + netRate4) / 3)),
  // createData('May \'21', createReturn(prev + (netRate2 + netRate3 + netRate4) / 3)),
  // createData('Jun \'21', createReturn(prev + (netRate2 + netRate3 + netRate4) / 3)),
  // createData('Jul \'21', createReturn(prev + (netRate2 + netRate3 + netRate4) / 3)),
  createData('Dec \'19', netRate1),
  createData('Jan \'20', netRate1),
  createData('Feb \'20', netRate1),
  createData('Mar \'20', netRate1),
  createData('Apr \'20', netRate1),
  createData('May \'20', netRate1),
  createData('Jun \'20', (netRate1 + netRate2) / 2),
  createData('Jul \'20', (netRate1 + netRate2) / 2),
  createData('Aug \'20', (netRate1 + netRate2) / 2),
  createData('Sep \'20', (netRate1 + netRate2) / 2),
  createData('Oct \'20', (netRate1 + netRate2) / 2),
  createData('Nov \'20', (netRate1 + netRate2) / 2),
  createData('Dec \'20', (netRate2 + netRate3) / 2),
  createData('Jan \'21', (netRate2 + netRate3) / 2),
  createData('Feb \'21', (netRate2 + netRate3) / 2),
  createData('Mar \'21', (netRate2 + netRate3) / 2),
  createData('Apr \'21', (netRate2 + netRate3 + netRate4) / 3),
  createData('May \'21', (netRate2 + netRate3 + netRate4) / 3),
  createData('Jun \'21', (netRate2 + netRate3 + netRate4) / 3),
  createData('Jul \'21', (netRate2 + netRate3 + netRate4) / 3),
  // createData('Aug \'21', createReturn(107.5)),
  // createData('Sep \'21', createReturn(108.5)),
  // createData('Oct \'21', createReturn(109.75)),
  // createData('Nov \'21', createReturn(110.5)),
  // createData('Dec \'21', createReturn(110.5)),
];

const data = [
  { date: 'Dec \'19', payments: netRate1, balance: 25 },
  { date: 'Jan \'20', payments: netRate1, balance: 25 },
  { date: 'Feb \'20', payments: netRate1, balance: 25 },
  { date: 'Mar \'20', payments: netRate1, balance: 25 },
  { date: 'Apr \'20', payments: netRate1, balance: 25 },
  { date: 'May \'20', payments: netRate1, balance: 25 },
  { date: 'Jun \'20', payments: (netRate1 + netRate2) / 2, balance: 50 },
  { date: 'Jul \'20', payments: (netRate1 + netRate2) / 2, balance: 50 },
  { date: 'Aug \'20', payments: (netRate1 + netRate2) / 2, balance: 50 },
  { date: 'Sep \'20', payments: (netRate1 + netRate2) / 2, balance: 50 },
  { date: 'Oct \'20', payments: (netRate1 + netRate2) / 2, balance: 50 },
  { date: 'Nov \'20', payments: (netRate1 + netRate2) / 2, balance: 50 },
  { date: 'Dec \'20', payments: (netRate2 + netRate3) / 2, balance: 55 },
  { date: 'Jan \'21', payments: (netRate2 + netRate3) / 2, balance: 55 },
  { date: 'Feb \'21', payments: (netRate2 + netRate3) / 2, balance: 55 },
  { date: 'Mar \'21', payments: (netRate2 + netRate3) / 2, balance: 55 },
  { date: 'Apr \'21', payments: (netRate2 + netRate3 + netRate4) / 3, balance: 30 },
  { date: 'May \'21', payments: (netRate2 + netRate3 + netRate4) / 3, balance: 30 },
  { date: 'Jun \'21', payments: (netRate2 + netRate3 + netRate4) / 3, balance: 30 },
  { date: 'Jul \'21', payments: (netRate2 + netRate3 + netRate4) / 3, balance: 30 },
];

function Title(props) {
  return (
    <Typography component="h2" variant="h6" color="primary" gutterBottom style={{ textAlign: 'center' }} >
      {props.children}
    </Typography>
  );
}

Title.propTypes = {
  children: PropTypes.node,
};

export function Chart(_props) {
  let classes = useStyles();
  // const theme = useTheme();
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  return (
    <div className={fixedHeightPaper} >
      {/* <Title>{'Year-to-Date Performance (Coming Soon)'}</Title> */}
      <Title>{'Monthly Performance (Coming Soon)'}</Title>
      <ResponsiveContainer>
        <LineChart
          // height='100%' width='100%'
          data={data}
          margin={{ top: 16, right: 16, bottom: 16, left: 16 }}
        >
          {/* XAxis didn't work below YAxis */}
          {/* <XAxis dataKey='date' stroke={theme.palette.text.secondary} >
            <Label position='bottom' offset={0} >{'Date'}</Label>
          </XAxis> */}
          <XAxis dataKey='date' stroke={theme.palette.text.secondary} />
          {/* <YAxis stroke={theme.palette.text.secondary} type="number" domain={[100, 110]} > */}
          <YAxis yAxisId='left' stroke={theme.palette.text.secondary} type="number" domain={[0.5, 1.5]} >
          {/* <YAxis yAxisId='left' stroke={theme.palette.text.secondary} type="number" domain={[0.5, 1.5]} > */}
            <Label position='left' offset={0} angle={270} className={classes.yAxisLabel} >
              {'Monthly Return (%)'}
            </Label>
          </YAxis>
          {/* <YAxis yAxisId='right' stroke={theme.palette.text.secondary} type="number" domain={[0.5, 1.5]} orientation='right' > */}
          <YAxis yAxisId='right' stroke={theme.palette.text.secondary} type="number" domain={[0, 75]} orientation='right' >
            <Label position='right' offset={0} angle={90} className={classes.yAxisLabel} >
              {'Account Balance ($1,000s)'}
            </Label>
          {/* <YAxis yAxisId="right" orientation="right" /> */}
          </YAxis>
          {/* <Line type="linear" dataKey="amount" dot={{stroke: theme.palette.primary.main, r: 2}} data={props.data} /> */}
          {/* <Line type="linear" dataKey="amount" dot={{stroke: theme.palette.primary.main, r: 2}} data={returns.slice(-12)} /> */}
          {/* <Line  yAxisId='right' type="linear" dataKey="amount" dot={{stroke: theme.palette.primary.main, r: 2}} data={balance.slice(-12)} /> */}
          <Line yAxisId='left' dataKey='payments' type='linear' dot={{stroke: theme.palette.primary.main, r: 2}} />
          <Line yAxisId='right' dataKey='balance' type='linear' dot={{stroke: theme.palette.primary.main, r: 2}} />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}
