import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import cloneDeep from 'lodash-es/cloneDeep'; // nested objects passed by ref when using spread operator
// import { makeStyles } from '@material-ui/core/styles';
import { deepDifference, isDefined } from '@org/common-tools';
// import { Spacer } from '@org/client-components-core/layout/Spacer';

// https://github.com/formium/formik/blob/master/packages/formik/src/FormikContext.tsx
// https://vasanthk.gitbooks.io/react-bits/content/patterns/27.passing-function-to-setState.html

// const useStyles = makeStyles(theme => ({
//   root: {
//     ...theme.custom.form.root,
//     [theme.breakpoints.up('sm')]: {
//       // minHeight: '80vh',
//       // minHeight: `calc(100vh - ${theme.custom.APP_BAR_HEIGHT + 120}px)`,
//       minHeight: `calc(100vh - ${theme.custom.FORM_HEIGHT_OFFSET}px)`,
//       padding: theme.spacing(4, 0, 4, 0),
//     },
//     [theme.breakpoints.only('xs')]: {
//       minHeight: '60vh',
//       padding: theme.spacing(2, 0, 2, 0),
//     },
//   },
//   container: theme.custom.form.container,
// }));

// let defaultState;

// // TODO add useCallback?
// function getDefaultState(props) {
//   defaultState = {
//     ...defaultState,
//     initial: props.initialValues,
//     values: props.initialValues,
//     required: props.requiredValues ? props.requiredValues : {},
//     touched: props.touchedValues ? props.touchedValues : {},
//     validated: props.validatedValues ? props.validatedValues : {},
//   };
//   console.log('getDefaultState', defaultState);
//   return defaultState;
// }

let calculate = null; // function passed through props, run in useEffect [ values ]

let validate = null; // function passed through props, run in useEffect [ validated ]

// function calculateValues(values) {
//   let newValues = calculate(values);
//   let diffValues = 
// };

export function useForm(props) {
  // console.log('useForm', props);
  // console.log(props.calculate ? props.calculate(props.initialValues) : props.initialValues);
  // console.log(props.calculate);
  const [ initialState ] = useState({ // preserve an initial copy since props passes objects by ref
    // initial: props.calculate ? props.calculate(props.initialValues) : props.initialValues,
    // values: props.calculate ? props.calculate(props.initialValues) : props.initialValues,
    initial: { ...props.initialValues }, // calculate moved to useEffect [ initialValues ]
    values: { ...props.initialValues },
    touched: {},
    changed: {},
    validated: {},
    required: { ...props.requiredValues }, // optional
    errors: {},
    isValidated: false,
  });
  // console.log(initialState);
  const [ state, setState ] = useState(cloneDeep(initialState)); // for new input values
  // console.log(state);
  const isMounted = useRef(false);

  // console.log('useForm', props, initialState, state);

  // let { initial, touched, changed, errors, required } = state;
  let { touched, changed, errors, required } = state;

  // useEffect doesn't trigger off of nested state changes (e.g. [ state.values ])
  let values = { ...state.values };
  let validated = { ...state.validated };

  // componentDidMount
  useEffect(() => {
    // console.log('useForm', 'Mounted', state);

    isMounted.current = true;

    if (!props.initialValues)
      throw new Error(`useForm: 'initialValues' is required in props`);

    if (!props.validate)
      throw new Error(`useForm: 'validate' is required in props`);

    validate = props.validate;

    if (props.calculate) { // form has calculated values?
      calculate = props.calculate; // set the function
      let updatedValues = calculate(values); // perform initial calculation
      let diff = deepDifference(updatedValues, values); // has changes?
      // console.log('useForm', 'diff', diff);
      setValues(diff, { shouldValidate: false }); // save changes
    } else
      calculate = null;

    return () => { isMounted.current = false; };
  }, []);

  // update state when props change
  // this should only happen if the form values and schema have changed
  // e.g. changing a form from Loan to Note
  // this will cause useEffect [ values ] to fire
  // useEffect(() => {
  //   console.log('useForm', 'useEffect [ props ]', 'props', props);

  //   let initialValues = props.initialValues; // required

  //   validate = props.validate; // required

  //   if (props.required) // optional
  //     required = props.required;

  //   if (props.calculate) // optional
  //     calculate = props.calculate;

  //   // setValues doesn't work because it checks if the values already exist
  //   if (isMounted.current) {
  //     let newState = { ...initialState, initial: { ...initialValues }, values: { ...initialValues } };
  //     setInitialState(newState);

  //     let clonedState = cloneDeep(newState);
  //     setState(clonedState);
  //   }

  // }, [ props.initialValues ]); // for some reason just using `props` causes infinite loop

  // update calculated values after any value changes (if necessary)
  // N.B. this will cause itself (useEffect [ values ]) to be called again but
  // there should be no changes because the calculated values will be the same
  // useEffect(() => {
  //   console.log('useForm', 'useEffect', '[ values ]', values);
  //   console.log('useForm', 'useEffect', '[ values ]', calculate);

  //   // this doesn't work, causes a rerender which moves cursor to the end!
  //   if (calculate) {
  //     console.log('calculatedValues', 'values', values);
  //     // let calculatedValues = calculate ? calculate(values) : values;
  //     let calculatedValues = calculate(cloneDeep(values));
  //     console.log('calculatedValues', calculatedValues);

  //     if (calculatedValues) { // in calculate returns null
  //       let diff = deepDifference(calculatedValues, values);
  //       console.log('diff', values, calculatedValues, diff);

  //       // if (!shallowEqual(values, calculatedValues)) {
  //       if (diff) {

  //         if (isMounted.current)
  //           setValues(diff);
  //       }
  //     }
  //   }

  // }, [ state.values ]);

  // validate the form whenever any validation element changes
  useEffect(() => {
    // console.log('useForm', 'useEffect', '[ validated ]', state);
    (async () => { // async for yup TODO is superstruct async?
      await validateForm();
    })();

  }, [ validated ]);

  function isTouched() {
    return Object.keys(touched).length > 0;
  }

  function isChanged() {
    return Object.keys(changed).length > 0;
  }

  function validateForm() {
    // console.log('useForm', 'validateForm', 'state', state, isChanged());

    // if (isChanged()) {
    if (isTouched()) {
      try {
        // all required inputs must have been validated
        let keys = Object.keys(required);
        for (let i in keys) {
          let name = keys[i];
          if (!validated[name]) // don't set an error until touched
            throw new Error(`Required value '${name}' not validated!`);
        }

        // no inputs (required or not) can have validation errors
        if (Object.keys(errors).length)
          throw new Error(`form errors found`);

        if (!state.isValidated && isMounted.current)
          setState(prevState => ({ ...prevState, isValidated: true }));

      } catch (error) {
        // console.error('validateForm', error.message);
        if (state.isValidated && isMounted.current)
          setState(prevState => ({ ...prevState, isValidated: false }));
      }
    }
  }

  async function setRequired(required) {
    if (isMounted.current)
      setState({ ...state, required});
  }

  // async function setError(name, value, error) {
  //   // console.log('useForm', 'setError', `'${name}'`, `'${value}'`, error.message);

  //   if (isMounted.current) {
  //     setState(prevState => {
  //       let newState = {
  //         ...prevState,
  //         values: { ...prevState.values, [name]: value}, // so we can fix bad input values!
  //         touched: { ...prevState.touched, [name]: true},
  //         validated: { ...prevState.validated, [name]: false},
  //         errors: { ...prevState.errors, [name]: error.message},
  //       };

  //       if (newState.values[name] !== newState.initial[name])
  //         newState.changed[name] = value;

  //       return newState;
  //     });
  //   }
  // }

  // called in handleChange, setValidated, and setValues
  // e.g. individual NumberField keystrokes
  function setValue(prevState, name, value, error, options) {
    // console.log('useForm', 'setValue', prevState, `'${name}'`, `'${value}'`, error, `${JSON.stringify(options)}`);

    let newState = {
      ...prevState,
      isValidated: false, // not working, even when change makes the input invalid
      values: { ...prevState.values, [name]: value },
      // validated: { ...prevState.validated, [name]: false }, // clear input adornment
      validated: { ...prevState.validated },
      // errors: { ...prevState.errors, error }, // why is this here? errors are deleted directly in setValidated
    };

    if (!error)
      delete newState.errors[name];
    else
      newState.errors = { ...prevState.errors, [name]: error.message };

    // Default options settings for handleChange (the most common case)
    let isInitial = isDefined(options?.isInitial) ? options.isInitial : false;
    let isTouched = isDefined(options?.isTouched) ? options.isTouched : true;
    let isValidated = isDefined(options?.isValidated) ? options.isValidated : false;

    if (isInitial)
      newState.initial[name] = value;

    if (newState.values[name] !== newState.initial[name])
      newState.changed[name] = value;
    else
      delete newState.changed[name];

    if (isTouched)
      newState.touched[name] = true;
    else
      delete newState.touched[name];

    if (isValidated)
      newState.validated[name] = true;

    // if (calculate)
    //   calculate(prevState.values, newState.values);

    // console.log('setValue', 'newState', newState);

    return newState;
  }

  async function setValidated(name, value, options) {
    console.log('setValidated', name, value, options);

    let validationError = null;
    try {
      let isValidated = await validate(name, value);
      // console.log('setValidated', name, value, isValidated, errors[name]);
      // delete errors[name];
      options = isDefined(options) ? { ...options, isValidated } : { isValidated };
      // console.log('setValidated', name, value, isValidated, errors[name]);
    } catch (error) {
      // console.log('setValidated', 'error', error);
      // setError(name, value, error);
      validationError = error;
      // validationError = new Error(error.message);
    }
    delete options?.shouldValidate; // shouldValidate is not set in handleChange
    // console.log('setValidated', validationError, options);

    // let error = !!errors[name] ? errors[name] : null;

    if (isMounted.current)
      setState(prevState => setValue(prevState, name, value, validationError, options));
  }

  // setValues is primarily used for data retrieved from outside sources and
  // therefore it is not validated by default
  function setValues(newValues, options) {
    // console.log('useForm', 'setValues', values, newValues, options);

    options = options ? options : {};
    // default settings for fetched values
    options.isInitial = (options.isInitial != null) ? options.isInitial : true;
    options.isTouched = (options.isTouched != null) ? options.isTouched : false;
    let shouldValidate = (options.shouldValidate != null) ? options.shouldValidate : false;
    // console.log('useForm', 'setValues', shouldValidate, options);

    // console.log('setValues', newValues);

    let keys = Object.keys(newValues);
    // console.log('setValues', keys);
    for (let i in keys) {
      let name = keys[i];
      let value = newValues[name];

      if (!isDefined(values[name]))
        throw new Error(`Unknown value '${name}' passed to setValues. useForm is a controlled component and all values must be initialized.`);

      if (shouldValidate) {
        setValidated(name, value, options);
      } else {
        if (isMounted.current)
          setState(prevState => setValue(prevState, name, value, null, options));
      }
    }
  }

  // parseEvent is shared by handleChange and handleBlur
  // Different MUI inputs pass different event objects and some of the objects
  // do not pass the value event.target.name so I pass that as the second argument
  // TextField(event.target: { type: 'text', name, value })
  // NumberField(event.target: { floatValue, name }) // typeof value === 'string'
  // SelectTextField(event.target: { name, value })
  // DatePicker(Date object, name)
  // Checkbox(event.target: { type: 'checkbox', name, checked }) // value === ""
  function parseEvent(event, name) {
    // console.log('parseEvent', event, name);
    // console.log('parseEvent', defaultState);

    let value;

    if (typeof event?.toDate === 'function' && event?.toDate() instanceof Date) {
      // DatePicker (dayjs formatted object)
      value = dayjs(event).format('YYYY-MM-DD');
      // console.info('parseEvent', 'DatePicker', name, value);
    } else if (event?.target?.type === 'checkbox') {
      name = event.target.name;
      value = event.target.checked;
      // console.info('parseEvent', 'Checkbox', name, value);
    } else if (typeof event?.target?.floatValue === 'number') {
      name = event.target.name;
      value = event.target.floatValue;
      // console.info('parseEvent', 'Number TextField', name, value);
    } else {
      name = event.target.name;
      value = event.target.value;
      // console.info('parseEvent', 'Select/TextField', `'${name}'`, `'${value}'`);

      if (name == null || value == null)
        throw new Error(`parseEvent error: name '${name}' and/or value '${value}' undefined`);
    }

    // console.log('parseEvent', defaultState);

    return { name, value };
  }

  async function handleBlur(event, arg) {
    // event.persist();
    // console.log('useForm', 'handleBlur', `'${arg}'`, event);
    let { name, value } = parseEvent(event, arg);
    // console.log('');
    // console.log('useForm', 'handleBlur', `'${name}'  '${value}'`);

    let options = { isInitial: false, isTouched: true };

    // Moved here from useEffect [ values ] because it triggered a render and
    // caused cursor to jump to end of input
    if (calculate) {
      // console.log('calculatedValues', 'values', values);
      // let calculatedValues = calculate ? calculate(values) : values;
      let calculatedValues = calculate(cloneDeep(values));
      // console.log('useForm', 'calculatedValues', calculatedValues);
      // console.log('useForm', 'state.changed', state.changed);

      if (!calculatedValues)
        throw new Error(`useForm error: 'calculate' method must return calculated values`);

      // // this doesn't work if there are no changes due to calculatedValues
      let diff = deepDifference(calculatedValues, state.values);
      // // let diff = deepDifference(state.values, calculatedValues);
      // console.log('handleBlur', 'diff', diff);

      // calculatedValues returns only the calculated changes because it takes
      // the original change as input so we need to add the original change back
      // let changed = { ...state.changed, ...diff };
      let changed = { ...state.changed, ...diff, [name]: value }; // blurred value touched, even if unchanged

      // console.log('calculatedValues', calculatedValues);
      // setValues(calculatedValues, { isInitial: false, shouldValidate: true });
      // setValues(diff, { isInitial: false, shouldValidate: true });
      // setValues(state.changed, { isInitial: false, isTouched: true, shouldValidate: true });
      setValues(changed, { isInitial: false, isTouched: true, shouldValidate: true });
    } else
      // await setValidated(name, value, options);
      setValidated(name, value, options);
  }

  async function handleChange(event, arg) {
    // event.persist();
    // console.log('handleChange', defaultState);
    // console.log('useForm', 'handleChange', arg, event);
    let { name, value } = parseEvent(event, arg);
    // console.log('useForm', 'handleChange', name, value);

    if (value == null || name == null)
      console.info('useForm', 'handleChangeEvent', 'Unknown input type',  `'${name}'`, `'${value}'`, event);

    let shouldValidate = false;

    if (typeof value === 'boolean') // always validate checkbox
      shouldValidate = true;

    // console.log('handleChange', defaultState);

    if (shouldValidate) {
      await setValidated(name, value);
    } else {
      if (isMounted.current)
        setState(prevState => setValue(prevState, name, value, null, null));
    }

    // handleBlur(event, arg);

    // console.log('handleChange', defaultState);
  }

  // Used in JsonTextField handleInputBlur
  // N.B. the 'values' field doesn't actually exist in the form state, instead
  // changes to state.values is stringified for use in JsonTextField and then
  // parsed back to an object to update state.values
  async function handleValuesBlur(event) {
    // console.log('handleValuesBlur', event.target, event.target.name, event.target.value);

    let { value } = event.target;

    try {
      // parse the values object
      // this will throw an error if there is a syntax error in the JSON
      let blurValues = JSON.parse(value);
      // if (props.calculate)
      //   values = props.calculate(values);

      // Moved here from useEffect [ values ] because it triggered a render and
      // caused cursor to jump to end of input
      if (calculate) {
        // console.log('handleValuesBlur', calculate, blurValues);
        // let calculatedValues = calculate ? calculate(values) : values;
        let calculatedValues = calculate(cloneDeep(blurValues));

        if (!calculatedValues)
          throw new Error(`useForm error: 'calculate' method must return calculated values`);

        // console.log('handleValuesBlur', calculatedValues);
        setValues(calculatedValues, { isInitial: false, shouldValidate: true });
      } else
        setValues(blurValues, { isInitial: false, shouldValidate: true });

    } catch (error) {
      console.error(error.message);

      // not working. how to show an error in 'values' for JsonTextField???
      // if (isMounted.current)
      //   setValue(state, name, value, error, {});
    }

    // let updateValues = {};
    // let options = { isTouched: true };

    // console.log('handleValuesBlur', state.values, values);
    // let keys = Object.keys(values);
    // for (let i in keys) {
    //   let name = keys[i];
    //   console.log(i, name, values[name], state.values[name]);
    //   if (values[name] !== state.values[name])
    //     updateValues[name] = values[name];
    // }
    // console.log('handleValuesBlur', updateValues);

    // if (!!Object.keys(updateValues).length)
    //   setValues(updateValues, { touched: true, shouldValidate: true });
    // setValues(blurValues, { isInitial: false, shouldValidate: true });
  }

  // Treat all values like one text field
  // This is used in JsonTextField handleInputChange
  async function handleValuesChange(event) {
    // console.log('handleValuesChange', event.target, event.target.name, event.target.value);
    // console.log('handleValuesChange', event.target);
    // console.log('handleValuesChange', event.target.name);
    // console.log('handleValuesChange', event.target.value);

    // setFieldsState({
    //   ...fieldsState,
    //   values: { ...fieldsState.values, [event.target.name]: event.target.value },
    // });
    // setValues(JSON.parse(event.target.value), { touched: true });

    // parse the values object
    // this will throw an error if there is a syntax error in the JSON
    try {
      let eventValues = JSON.parse(event.target.value);
      // console.log('handleValuesChange', eventValues);

      let updateValues = {};

      let keys = Object.keys(eventValues);
      for (let i in keys) {
        let name = keys[i];
        // console.log('handleValuesChange', name, eventValues[name], state.values[name], eventValues[name] !== state.values[name]);
        if (eventValues[name] !== state.values[name])
          updateValues[name] = eventValues[name];
      }

      if (!!Object.keys(updateValues).length)
        setValues(updateValues, { touched: true });
    } catch (error) {
      console.error(error.message);
    }

    // console.log(!!0, !!null, !!undefined);
    // console.log(Object.keys(updateValues).length, !!Object.keys(updateValues).length);
    // console.log(updateValues);

    // if (!!Object.keys(updateValues).length)
    //   setValues(updateValues, { touched: true });
  }

  // Resets form state to initialValues
  // Use this to `cancel` changes
  function resetValues() {
    // nested objects are passed by ref when using spread operator so we need to clone
    let clonedState = cloneDeep(initialState);

    if (isMounted.current)
      // setState({ ...clonedState, initial: { ...initial }, values: { ...initial } });
      setState(clonedState);
  }

  // Resets `initial` and `values` to `values` and resets all other form state
  // Use this if you want to keep saving and updating a form and be able to
  // make and see incremental changes
  function resetForm() {
    // nested objects are passed by ref when using spread operator so we need to clone
    let clonedState = cloneDeep(initialState);

    if (isMounted.current)
      setState({ ...clonedState, initial: { ...values }, values: { ...values } });
  }

  // resets `initial` and `values` to an new set of values
  // Use this when sharing a form between different data types (e.g. from LOAN to NOTE)
  function resetInitial(newInitialValues) {
    let clonedState = cloneDeep(initialState);

    if (isMounted.current)
      setState({ ...clonedState, initial: { ...newInitialValues }, values: { ...newInitialValues } });
  }

  // // because the form state is a controlled component, use this to completely change `initial` and `values`
  // function initializeForm(props) {
  //   console.log('initializeForm', props);
    
  //   if (!props.initialValues)
  //     throw new Error(`useForm: 'initialValues' is required in initializeForm props`);

  //   let initialValues = props.initialValues;

  //   if (props.calculate) {
  //     calculate = props.calculate;
  //     initialValues = calculate(initialValues);
  //   }
  //   console.log(initialValues);

  //   // if (!props.validate)
  //   //   throw new Error(`useForm: 'validate' is required in initializeForm props`);

  //   // validate = props.validate;

  //   if (isMounted.current) {
  //     // get all the useForm state objects from initialState
  //     // only override the values in `initial` and `values`
  //     let clonedState = cloneDeep(initialState);
  //     setInitialState({ ...clonedState, initial: { ...initialValues }, values: { ...initialValues } });
  //     resetValues();
  //   }
  //   console.log(state);
  // }

  let fieldProps = { // Props passed through to inputs? (Rename to inputProps?)
    state: { values, validated, errors, required, touched, changed },
    handleChange, handleBlur, setValues // handleValuesChange, handleValuesBlur,
  };

  let jsonInputProps = {
    state: { values, validated, errors, required },
    handleValuesChange, handleValuesBlur,
  };

  return {
    // formState: { ...state, isTouched: isTouched(), isChanged: isChanged(), defaultState },
    formState: { ...state, isTouched: isTouched(), isChanged: isChanged(), initialState },
    handleChange,
    handleBlur,
    handleValuesChange,
    handleValuesBlur,
    setValues,
    resetValues,
    resetForm,
    resetInitial,
    // initializeForm,
    setRequired,
    fieldProps,
    jsonInputProps,
    validateForm,
  };
}

// HooksForm needs to be outside the useForm function body or it triggers an
// additional re-render on each change
// https://github.com/mui-org/material-ui/issues/783
// https://codesandbox.io/s/material-demo-msbxl?fontsize=14&hidenavigation=1&theme=dark&file=/Good.js
export function HooksForm(props) {
  let { formState } = props;
  return (
    <>
    <form id={props.id} noValidate autoComplete='off' style={{ width: '100%', maxWidth: '100% !important' }} >
      {props.children({ ...formState })}
    </form>
    </>
  );
}

HooksForm.propTypes = {
  id: PropTypes.string,
  children: PropTypes.any,
  formState: PropTypes.shape(),
};
