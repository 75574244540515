import React from 'react';
import PropTypes from 'prop-types';
// import { makeStyles } from '@material-ui/core/styles';
import { default as MuiLink } from '@material-ui/core/Link';
// import { Link as RouterLink } from 'react-router-dom';
// import {useHistory} from "react-router-dom";

// const localStyles = {
//   link: {
//     display: 'inline',
//     whiteSpace: 'nowrap',
//   },
// };
// const useStyles = makeStyles(localStyles);

// const useStyles = makeStyles(theme => ({
//   link: theme.custom.link,
// }));

export function ButtonLink(props) {
  // console.log('ButtonLink', props);
  let { title, children, ...rest } = props;

  // let classes = useStyles();

  // This doesn't work because we can only catch the event here
  // `event.preventDefault()` needs to be called locally
  // function handleClick(event, args) {
  //   event.preventDefault();
  //   props.onClick(event, args);
  // }

  // if (!props.onClick)
  //   throw new Error(`ButtonLink Error: props must include 'onClick' function`);

  if (!title && !children)
    throw new Error(`ButtonLink Error: props must include 'title' or 'children'`);

  title = (children) ? children : title;

  return (
    <MuiLink
      component='button'
      // className={classes.link}
      variant={'body1'}
      onClick={props.onClick}
      {...rest}
    >{title}</MuiLink>
  );
}

ButtonLink.propTypes = {
  title: PropTypes.string,
  // variant: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  children: PropTypes.any,
};
