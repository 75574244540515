import React from 'react';
// import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
// import { default as MuiTextField } from '@material-ui/core/TextField';
import { TextField } from '@org/client-components-core/inputs/TextField';
// import black from '@material-ui/core/colors/black';
// import { styles } from '@org/client-libs-styles';

// const useStyles = makeStyles(styles);
const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiFormLabel-root': {
      paddingLeft: theme.spacing(1), // 14 in outlined input
    },
    // '& .MuiFormLabel-root': {
    //   '&.Mui-disabled': { // no space between &.
    //     color: 'black',
    //   },
    // },
    '& .MuiInput-underline:before': {
      borderBottom: '1px solid !important',
    },
    '& .MuiInput-underline.Mui-disabled:before': {
      // borderBottom: '1px solid black',
      borderBottom: '1px solid !important',
      // borderBottomColor: '#black',
    },
    // '& .MuiInputBase-input:before': { // doesn't work
    //   paddingLeft: theme.spacing(1.75), // 14 in outlined input
    // },
    '& .MuiInputBase-input': {
      color: 'black',
      // padding: theme.spacing(0, 1, 0, 1), // 14 in outlined input
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  }
}));

export function StandardTextField(props) {
  // console.log('StandardTextField', props);

  const classes = useStyles();

  return(
    // <TextField id="standard-basic" label="Standard" />
    <TextField
      variant='standard'
      // className={classes.inputField}
      // value={value}
      disabled={true}
      // {...rest}
      // style={{ borderBottomStyle: 'solid' }}
      className={classes.root}
      {...props}
    />
  );
}
