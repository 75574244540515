import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

export function CustomRoute(props) {
  // console.log('CustomRoute', props);
  let { component: Component, test, to, ...rest } = props;

  return (
    <Route
      render={props => (
        test // e.g. isAuthenticated
        ? <Component {...props} {...rest} />
        : <Redirect to={to} />
      )}
    />
  );
}

CustomRoute.propTypes = {
  component: PropTypes.oneOfType([ PropTypes.func, PropTypes.shape() ]).isRequired,
  test: PropTypes.bool.isRequired,
  to: PropTypes.string.isRequired,
};
