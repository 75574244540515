import React from 'react';
import { Link } from '@org/client-components-core/links/Link';

/**
 * A React-Router Link to an internal address.
 */
export function InLink(props) {
  // console.log('InLink', props);
  return(<Link {...props} />);
}

export default InLink;

export function InLinkSmall(props) {
  return(<InLink variant='body2' {...props} />);
}
