import React from 'react';
import PropTypes from 'prop-types';
import { FadeInSection } from '@org/client-components-core/transitions/FadeInSection';
import { NoteCard } from '@org/client-components-custom/cards/NoteCard';

export function AuthenticatedNoteCards(props) {
  // console.log('AuthenticatedNoteCards', props);

  return (
    <div id='authenticatedHandler-note-cards' style={{display: 'flex', flexDirection: 'column', justityContent: 'flex-start', alignItems: 'center', flexGrow: 1, width: '100%'}} >
      {props?.notes.map((note, key) => {
        let index = props.loans.findIndex(loan => loan.id === note.referenceId);
        let referenceItem = props.loans[index];
        return (
          <div key={key} >
            <FadeInSection key={key} id={key} style={{display: 'flex', flexDirection: 'column'}} >
              <NoteCard note={note} referenceItem={referenceItem} {...props} />
            </FadeInSection>
          </div>
        );
      })}
    </div>
  );
}

AuthenticatedNoteCards.propTypes = {
  notes: PropTypes.array,
  loans: PropTypes.array,
};
