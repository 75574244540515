import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { default as MuiTextField } from '@material-ui/core/TextField';
import { CheckAdornment } from '@org/client-components-custom';
// import { styles } from '@org/client-libs-styles';
// import { useStyles } from './styles';

// const useStyles = makeStyles(styles);
// let useStyles = makeStyles((theme) => ({
//   input: {
//     ...theme.custom.input,
//   }
// }));
const useStyles = makeStyles((theme) => ({
  standard: {
    '& .MuiFormLabel-root': {
      paddingLeft: theme.spacing(1), // 14 in outlined input
    },
    // '& .MuiFormLabel-root': {
    //   '&.Mui-disabled': { // no space between &.
    //     color: 'black',
    //   },
    // },
    '& .MuiInput-underline:before': {
      borderBottom: '1px solid !important',
    },
    '& .MuiInput-underline.Mui-disabled:before': {
      // borderBottom: '1px solid black',
      borderBottom: '1px solid !important',
      // borderBottomColor: '#black',
    },
    // '& .MuiInputBase-input:before': { // doesn't work
    //   paddingLeft: theme.spacing(1.75), // 14 in outlined input
    // },
    '& .MuiInputBase-input': {
      color: 'black',
      // padding: theme.spacing(0, 1, 0, 1), // 14 in outlined input
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  }
}));

export function TextField(props) {
  // console.log('TextField', 'props', props);

  let classes = useStyles();

  let {
    name, label, state, InputProps, endAdornment, showValidated,
    handleChange, handleBlur,
    ...rest
  } = props;

  // console.log('TextField', props);
  // moved to state because `required` is a Mui input of type `string`
  let { values, validated, required, errors } = state;

  if (!label && (!props.children && typeof props.children !== 'string'))
    console.warn(`TextField must receive 'label' prop or child of type 'string'`);

  // if (!name)
  //   console.error(`TextField must receive \`name\` prop`);

  // if (!props.handleChange)
  //   console.warn('TextField must receive `handleChange` prop', name);

  // if (!props.handleBlur)
  //   console.warn('TextField must receive `handleBlur` prop', name);

  // if (!state)
  //   console.warn(`TextField must receive \`state\` props from form`, name);

  if (showValidated) {
    let isValidated = !props.disabled && (props.validated ? props.validated : validated[name]);
    let defaultEndAdornment = <CheckAdornment position="end" show={isValidated} />;
    endAdornment = endAdornment ? endAdornment : defaultEndAdornment;
    InputProps = { endAdornment, ...InputProps }; // SelectTextField passes an additional InputProp
  }

  let value = null, isError = null, errorMessage, isRequired = null;

  // console.log('TextField', 'value', `'${props.value}'`, props.value != null);
  value = props.value != null ? props.value : values[name]; // one of these required
  // if (props.value)
  // console.log('TextField', 'value', `'${value}'`);

  if (props.error) {
    isError = props.error;
    errorMessage = props.error[name];
  } else if (errors) {
    isError = errors[name] ? true : false;
    errorMessage = errors[name];
  }

  if (props.isRequired)
    isRequired = props.isRequired;
  else if (required)
    isRequired = required[name] ? true : false;

  return(
    <MuiTextField
      // className={classes.input}
      className={props.variant ? classes[props.variant] : null}
      variant={props.variant ? props.variant : 'outlined'}
      fullWidth
      id={props.id ? props.id : name}
      name={name}
      label={label}
      // value={values[name]}
      value={value}
      onChange={(event) => handleChange(event, name)}
      onBlur={(event) => handleBlur(event, name)}
      // error={errors[name] ? true : false}
      error={isError}
      // helperText={errors[name]}
      helperText={errorMessage}
      // helperText={error.message}
      // required={required[name] ? true : false}
      required={isRequired}
      InputProps={InputProps}
      size={'small'}
      {...rest} // overrides
    />
  );
}

export default TextField;

TextField.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  state: PropTypes.shape().isRequired,
  validated: PropTypes.shape(),
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  endAdornment: PropTypes.shape(),
  InputProps: PropTypes.shape(),
  children: PropTypes.oneOfType([ PropTypes.string, PropTypes.array ]),
  showValidated: PropTypes.bool,
};
