// import { useLocation, useHistory } from 'react-router-dom';
// import packageJson from '../../app/package.json';
// import meta from '../../app/public/meta.json';
// import { publicHandler, authorizedHandler, AuthError } from '@org/client-graphql';
import { AuthError } from '@org/client-graphql';

/**
 * !!! Apollo returns very different responses and errors depending on the error policy !!!
 * 
 * n.b. This only applies to errors thrown in resolver functions (i.e. AFTER the server handler
 * has been created). Errors thrown during the creation of the server handler, e.g. COOKIE_NOT_FOUND,
 * will behave as errorPolicy: 'none' no matter what is set in client defaultOptions.
 * 
 * errorPolicy: 'none':
 * response = undefined; // no data will be returned even if there was partial data
 * error = { 'stack', 'graphQLErrors', 'networkError', 'message', 'extraInfo' };
 * 
 * errorPolicy: 'all':
 * response = {
 *  data, // partial data or null
 *  errors // an array of all errors, not differentitiated as in 'none'
 * };
 * error = ? // No error will be thrown by apollo-server
 * 
 */

function handleResponse(response) {
  console.info('handleResponse', 'response', response);
  // errorPolicy: 'all'
  // response = { data, errors }

  // console.warn('Handler', global.appVersion, packageJson.version, meta.version);
  // console.warn('Handler', 'global.appVersion', global.appVersion);
  // // console.warn('Handler', 'packageJson.appVersion', packageJson.appVersion);
  // console.warn('Handler', 'packageJson.version', packageJson.version);
  // // let meta = await fetch('/meta.json');
  // // console.warn('Handler', 'meta.appVersion', meta.appVersion);
  // console.warn('Handler', 'meta.appVersion', meta.version);

  // if (!response) // errorPolicy: 'none'
  //   return null;

  if (response?.errors?.length) {
    console.error('***handleResponse***', `${response.errors.length} error(s) found in response.errors`);
    response.errors.forEach(error => {
      console.error(error?.extensions?.name, error?.extensions?.code, `'${error.message}'`);
      throw error;
    });
    // throw response.errors[0];
  }

  let resolverName;
  if (response.data)
    resolverName = Object.keys(response.data)[0];

  let resolver = response.data[resolverName];
  console.log('handleResponse', resolverName, resolver);

  // let data = response.data[resolver];

  if (!resolver) // null
    return resolver;

  // Do I still need this?
  // if (resolver?.__typename)
  //   delete resolver.__typename;

  // if (Array.isArray(resolver)) {
  //   for (let index in resolver) {
  //     // console.log(resolver[index], resolver[index].__typename);
  //     if (resolver[index].__typename !== undefined)
  //       delete resolver[index].__typename;
  //   }
  // }

  // console.log('Handler', 'handleResponse', 'data', data);

  // legacy { success, data, error } response check
  if (typeof resolver === 'object') {
    let keys = Object.keys(resolver);
    // console.warn(keys);
    if (keys.includes('error')) {
      console.warn('Handler', `Legacy response found in resolver '${resolverName}'. Fix me!`, keys);
      if (resolver.data?.items)
        return resolver.data.items;
      else
        return resolver.data;
    }
  }

  // all other data types (objects, string, boolean, etc)
  return resolver;
}

function handleError(error, location, history) {
  // console.error('handleError', error);
  // console.error('handleError', Object.getOwnPropertyNames(error));
  // console.error('handleError', location, history, error?.extensions?.name, error?.extensions?.code, error.message);

  if (error?.extensions?.code === 'INTERNAL_SERVER_ERROR')
    console.warn('handleError:', 'INTERNAL_SERVER_ERROR', `Generic Error thrown in server. If this was thrown intentionally, consider using a more descriptive ApolloError or custom error.`);

  // if ('String cannot represent a non string value')
  //   console.warn(`If you think your schema looks correct, make sure you're calling the corrent resolver!`);

  // console.warn('graphQLErrors', error.graphQLErrors);
  // console.warn('networkError', error.networkError);

  if (error.networkError?.result?.errors) { // errors thrown in server createHandler
    let { errors } = error.networkError.result;
    // console.warn(`${errors.length} network error(s) found`);
    // console.warn('handleError:', 'networkError', `Either your errorPolicy is 'none' or this was thrown in server.createHandler`);
    // console.log(errors);

    for (let index in errors) {
      let error = errors[index];
      console.log(error);
      // console.error(Object.getOwnPropertyNames(error));
      // console.error(error?.extensions?.name, error?.extensions?.code, `'${error.message}'`);
      // console.error('handleError', error?.extensions?.code, error.message);

      if (error?.extensions?.name === 'CustomAuthError') {
        let code = error.extensions.code;
        let message = error.message;
        // console.error('handleError', code, message);

        if (code === 'COOKIE_NOT_FOUND') {
          // only thrown by requests which require a cookie
          console.warn('handleError:', code, 'User will be signed out');
          history.push({ pathname: '/sign-out', state: { error } });
        } else if (code === 'SESSION_NOT_FOUND') {
          // should only be thrown in offline development
          // URGENT log SESSION_NOT_FOUND in prod as a security risk
          console.warn('handleError:', code, 'User will be signed out');
          history.push({ pathname: '/sign-out', state: { error } });
        } else if (code === 'SESSION_EXPIRED') {
          console.warn('handleError:', code, 'User will be signed out');
          history.push({ pathname: '/sign-out', state: { error } });
        } else if (code === 'REQUEST_IP_ADDRESS_MISMATCH') {
          console.warn('handleError:', code, 'User will be signed out');
          history.push({ pathname: '/sign-out', state: { error } });
        } else if (message.includes('Context creation failed: ')) {
          // errors duing 
          console.warn('handleError:', code, message);
          message = message.replace('Context creation failed: ', '');
          throw new Error(message);
        } else
          console.warn('handleError:', code, message);
          // throw new CustomAuthError(message, { code });
          throw error; // This doesn't work for Jest toThrow()
        // Below is fixed now. I think a lot of this stuff was from before I
        // fully understood errorPolicy: 'all'
        // We have to throw an error or the handler will return undefined and
        // AppComponent componentDidMount/componentDidUpdate will continue to run
        //   throw new AuthError(message, code);
      } else { // errors thrown in server resolver functions (non-CustomAuthErrors)
        console.warn('handleError:', 'Unhandled networkError', error);
        throw error; // This doesn't work for Jest toThrow()
        // throw new Error(error.message);
      }
    }
  } else {
    console.warn('handleError', 'Unhandled error', error);
    throw error;
    // throw new Error(error);
    // throw new AuthError(error.message, error.extensions.code);
  }
}

// async function offlineCleanup() {
//   if (process.env.REACT_APP_IS_OFFLINE) {
//     console.warn('requestHandler', 'IS_OFFLINE', 'closeConnections');
//     try {
//       await authorizedHandler.closeConnections();
//     } catch (error) {
//       console.error(error);
//     }
//   }
// }

export class Handler {
  constructor(props) {
    this.client = props.client;
    this.location = props.location;
    this.history = props.history;
    // console.info('Handler', props, this);
  }

  handleError(error) {
    // console.error('Handler', 'handleError', error.message, Object.getOwnPropertyNames(error));
    let { location, history } = this;
    handleError(error, location, history);
  }

  async query(params) {
    // console.warn('Handler', 'query', params);
    // console.warn(this.location.pathname, params?.query?.definitions[0]?.name);
  //  console.warn(this.location.pathname, params?.query?.definitions[0]?.selectionSet?.selections[0]?.name);

    // if (meta.appVersion !== global.appVersion && !process.env.REACT_APP_IS_OFFLINE) {
    //   console.warn('Version mismatch!!!', )
    //   // if (caches) {
    //   //   // Service worker cache should be cleared with caches.delete()
    //   //   caches.keys().then(function(names) {
    //   //     for (let name of names) caches.delete(name);
    //   //   });
    //   // }
    //   // delete browser cache and hard reload
    //   window.location.reload(true);
    // }

    try {
      // console.log('Handler', 'query', 'params', params);x
      let response = await this.client.query(params);
      // return await this.client.query(params);
      // console.log('Handler', 'query', 'response', response);
      // !!! Apollo returns 'undefined' when there is an erro and `errorPolicy: 'all'` !!!
      return handleResponse(response);
    } catch (error) {
      // !!! This code is not reached by errors thrown in apollo-server when `errorPolicy: 'all'` !!!
      // console.error('Handler', 'query', 'error in Handler code', error);
      this.handleError(error);
    }
  }

  async mutate(params) {
    // console.log('Handler', 'mutate', params);
    try {
      let response = await this.client.mutate(params);
      // console.log('Handler', 'mutate', response);
      return handleResponse(response);
    } catch (error) {
      // This code is not reached by errors thrown in apollo-server when `errorPolicy: 'all'`
      // console.error('Handler',Object.getOwnPropertyNames(error));
      // console.error('Handler', 'mutate', 'error in Handler code', error);
      this.handleError(error);
    }
  }

  // https://www.apollographql.com/docs/react/caching/cache-interaction/

  async readFragment(params) {
    try {
      console.log('Handler', 'readFragment', params);
      let response = await this.client.readFragment(params);
      console.log('Handler', 'readFragment', response);
      // return handleResponse(response);
      return response;
    } catch (error) {
      this.handleError(error);
    }
  }

  async writeFragment(params) {
    try {
      console.log('Handler', 'writeFragment', params);
      let response = await this.client.writeFragment(params);
      console.log('Handler', 'writeFragment', response);
      // return handleResponse(response);
      return response;
    } catch (error) {
      this.handleError(error);
    }
  }

  // Query must be identical to the original query
  async readQuery(params) {
    try {
      console.log('Handler', 'readQuery', params);
      let response = await this.client.readQuery(params);
      // response = { queryName } 
      console.log('Handler', 'readQuery', response);
      // return handleResponse(response);
      return response[Object.keys(response)[0]];
    } catch (error) {
      this.handleError(error);
    }
  }

  // https://www.apollographql.com/docs/react/caching/cache-interaction/#writequery
  // https://www.apollographql.com/docs/react/caching/cache-interaction/#combining-reads-and-writes
  // Query must be identical to the original query
  async writeQuery(params) {
    try {
      console.log('Handler', 'writeQuery', params);
      let response = await this.client.writeQuery(params);
      // response = { queryName } 
      console.log('Handler', 'writeQuery', response);
      // return handleResponse(response);
      // return response[Object.keys(response)[0]];
    } catch (error) {
      this.handleError(error);
    }
  }

  async watchQuery(params) {
    console.log('Handler', 'watchQuery', params);
    try {
      let response = await this.client.watchQuery(params);
      // return handleResponse(response);
      return response; // an Observable is returned, not { data, error }
    } catch (error) {
      // This code is not reached by errors thrown in apollo-server when `errorPolicy: 'all'`
      // console.error('Handler', 'watchQuery', 'error in Handler code', error);
      this.handleError(error);
    }
  }
}
