import React from 'react';
import PropTypes from 'prop-types';
// import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
// import { styles } from '@org/client-libs-styles';
import theme from '@org/client-libs-themes';

let localStyles = {
  section: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    minWidth: 'fit-content',
    marginBottom: theme.spacing(2),
  },
  sectionHeading: {
    // alignItems: 'flex-start',
    display: 'flex',
    width: '100%',
    marginBottom: theme.spacing(1),
    textTransform: 'uppercase',
  },
  sectionBody: {
    width: '100%',
    marginBottom: theme.spacing(2),
    lineHeight: 1.4,
    // whiteSpace: 'pre-wrap',
    // display: 'inline',
    // workBreak: 'break-all',
  },
};

const useStyles = makeStyles(localStyles);

export function Section(props) {
  let classes = useStyles();
  return(
    <section className={classes.section} >
      {props.children}
    </section>
  );
}

Section.propTypes = {
  children: PropTypes.node,
};

export function SectionHeading(props) {
  let classes = useStyles();

  if (props.title)
    return(
      <Typography {...props} variant="h5" component="h2" className={classes.sectionHeading} >{props.title}</Typography>
    );
  if (props.children)
    return(
      <Typography variant="h5" component="h2" className={classes.sectionHeading} >{props.children}</Typography>
    );
}

SectionHeading.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
};

export function SectionBody(props) {
  // console.log('SectionBody', props);
  let classes = useStyles();

  let variant = props.variant ? props.variant : 'body1';

  return (
    // <Typography variant='h6' component='p' className={classes.sectionBody} >
    <Typography variant={variant} component='p' className={classes.sectionBody} >
      {props.children}
    </Typography>
  );
}

SectionBody.propTypes = {
  variant: PropTypes.string,
  children: PropTypes.oneOfType([ PropTypes.node, PropTypes.arrayOf(PropTypes.node) ]),
};

export function DevSection(props) {
  return (
    <section style={{display: 'flex', flexDirection: 'column', justifyContent: '', alignItems: 'center', margin: theme.spacing(1), padding: theme.spacing(1), border: '1px solid gray', borderRadius: '4px', width: 'auto', minWidth: 'fit-content'}}>
      {props.children}
    </section>
  );
}

DevSection.propTypes = {
  children: PropTypes.node,
};
