import * as yup from 'yup/es';

export const checkboxShape = yup.boolean();

export const agreeShape = checkboxShape
  .oneOf([true], "You must check that you agree to all");

export const accreditedShape = checkboxShape
  .oneOf([true], "You must be an accredited investor in order to access investment details");

export const amountShape = yup.number();

export const confirmCodeShape = yup.string()
  .matches(/^[0-9]{6,6}$/, "A 6 digit numeric code was expected");
// .required("A 6 digit numeric code is required"),

export const currencyShape = yup.number();

// date: 33179492975978
export const dateShape = yup.date()
  .transform(function (castValue, originalValue) {
    // return this.isType(castValue) ? castValue : new Date(originalValue);
    return Number.isNaN(originalValue) ? castValue : new Date(originalValue);
  });

// export const timeStampShape
export const timeStampShape = yup.number();

// dateTimeString: 3021-06-01T21:49:35.978Z
export const dateTimeStringShape = yup.string();

// dateString: '3021-06-01'
export const dateStringShape = yup.string();

// dateInteger: 30210601
export const dateIntegerShape = yup.number();

export const emailShape = yup.string().trim()
  .email(`Please check the email address, it doesn't appear to be a valid format`)
  .required(`A valid email is required`);

export const factorShape = yup.number()
  .min(0, 'Factor must be non-negative number');

export const hashShape = yup.string().trim(); // URGENT How to validate hashShape???

// https://github.com/jquense/yup/issues/628
export const ipAddressShape =  yup.string()
  .matches(/(^(\d{1,3}\.){3}(\d{1,3})$)/, {
    message: 'Invalid IP address',
    excludeEmptyString: true
  }).test('ip', 'Invalid IP address', value => {
    return value === undefined || value.trim() === ''
      ? true
      : value.split('.').find(i => parseInt(i, 10) > 255) === undefined;
  });

export const itemTypeShape = yup.string().trim();
  // .required(`itemType is required'`);

export const nameShape = yup.string().trim()
  .min(1, 'Names cannot be empty')
  .max(24)
  .matches(/^[a-z '-]+$/i, `Only letters and the characters ' and - are allowed`);

// https://stackoverflow.com/questions/2385701/regular-expression-for-first-and-last-name

export const firstNameShape = nameShape
  .required("First name is required");

export const lastNameShape = nameShape
  .required("Last name is required");

export const passwordShape = yup.string().trim()
  // .notOneOf( [ yup.ref('newPassword') ], "You cannot use the same password")
  .min(8, `Password must be at least 8 characters`)
  .max(16, `Password must be no more than 16 characters`)
  .required(`A valid password is required`)
  .matches(/(?=.*[A-Z])(?=.*[0-9])(?=.*[!?@#$%^&_=+-])/,
    `Password must contain at least: 1 capital, 1 number, and 1 of these symbols: !?@#$%^&_=+-`);

export const rateShape = yup.string();

export const ulidShape = yup.string().trim()
  .length(26)
  .matches(/^[0-9A-Z]{26,26}$/, `Invalid ULID`);
  // .required(`userId is required`); // add locally to properly name id field
