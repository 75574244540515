import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { PrimaryButton } from '@org/client-components-core/buttons/Buttons';
// import { authenticatedHandler } from '@org/client-graphql';

let useStyles = makeStyles((theme) => ({
  buttonDiv: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 0,
    margin: theme.spacing(0, 0, 0, 0.5),
    width: 'auto',
    minWidth: 'fit-content',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
      alignItems: 'center',
      padding: theme.spacing(0, 0, 2, 0),
    },
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'flex-start',
      alignItems: 'flex-end',
      padding: theme.spacing(0, 0, 0, 0),
    },
  },
}));

export function DetailsButton(props) {
  console.log('DetailsButton', props);

  const classes = useStyles();

  let { note, referenceItem } = props;

  // Disable the button while loading 
  function handleDetail() {
    console.log('NoteCard', 'handleDetail', props);

    // props.authenticatedHandler.listCashflows({ itemType: note.itemType, id: note.id });
    // props.authenticatedHandler.listCashflows({ itemType: referenceItem.itemType, id: referenceItem.id });

    // props.handleDetail(props);
    localStorage.setItem('noteId', note.id);
    // localStorage.setItem('referenceType', props.note.referenceType);
    // localStorage.setItem('referenceId', props.note.referenceId);
    props.history.push({
      pathname: '/invest/detail',
      state: {
        note,
        referenceItem,
      },
    });
  }

  function handleMouseOver() {
    props.authenticatedHandler.listCashflows({ itemType: note.itemType, id: note.id });
    props.authenticatedHandler.listCashflows({ itemType: referenceItem.itemType, id: referenceItem.id });
  }

  return(
    <div className={classes.buttonDiv} >
      <PrimaryButton
        title='Details'
        onMouseOver={handleMouseOver}
        onClick={handleDetail}
        style={{width: 'auto', minWidth: 'fit-content'}}
      />
    </div>
  );
}
