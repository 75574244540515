import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

// const useStyles = makeStyles((theme) => ({
const useStyles = makeStyles((theme) => ({
  message: {
    width: 'auto',
    // overflowWrap: 'break-word',
    whiteSpace: 'pre-wrap',
    // margin: theme.spacing(4, 0, 3, 0),
    margin: theme.spacing(0, 0, 3, 0),
    textAlign: 'center',
    // color: 'green',
  },
  error: {
    // margin: theme.spacing(4, 0, 3, 0),
    // margin: theme.spacing(0, 0, 3, 0),
    margin: theme.spacing(0, 0, 2, 0),
    textAlign: 'center',
    color: 'red',
  },
}));
// }));

export function FormMessage(props) {
  const classes = useStyles();

  return(
    <Typography component="h3" variant="h5" className={classes.message} >
      {props.message}
    </Typography>
  );
}

FormMessage.propTypes = {
  message: PropTypes.string,
};

export function FormError(props) {
  const classes = useStyles();

  return(
    <Typography component="h3" variant="body1" className={classes.error} >
      {props.message}
    </Typography>
  );
}

FormError.propTypes = {
  message: PropTypes.string,
};
