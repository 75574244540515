import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { default as MuiLink } from '@material-ui/core/Link';

const useStyles = makeStyles(theme => ({
  link: theme.custom.link,
}));

/**
 * A Material-UI Link to an external address.
 * Use this to wrap around other components.
 */

export function ExLink(props) {
  // console.log('ExLink', 'props', props);
  let { to, title, rel } = props;
  let classes = useStyles();

  if (rel && rel === 'same')
    return(<MuiLink className={classes.link} href={to} {...props} >{title}</MuiLink>);

  if (rel && rel === 'window')
    return (<MuiLink className={classes.link} href={to} target='popup' onClick={() => { window.open(to, 'popup','width=400', 'height=600'); return false; }} {...props} >{title}</MuiLink>);

    console.log('here');
  return(<MuiLink className={classes.link} href={to} target='_blank' rel='noopener noreferrer' {...props} >{title}</MuiLink>);
}

ExLink.propTypes = {
  /** The link address */
  to: PropTypes.string,
  /** The link title */
  title: PropTypes.string,
  /** Where to open the link. Default: `tab` */
  rel: PropTypes.oneOf(['same', 'tab', 'window']),
};
