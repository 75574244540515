// import React, { useEffect, useState, useRef } from 'react';

// useValidate is designed to work with useForm in HooksForm
// useValidate must receive a validation method and returns a
// validate function which is then passed to useForm
// validate must return `true` or throw an error to work
// properly with useForm

let schema; // cache this and allow it to be overwritten synchronously

export function useValidate(validationSchema) {
  // console.log('useValidate', 'validationSchema', validationSchema);

  if (!validationSchema)
    throw new Error(`useValidate: must be passed a single prop 'validationSchema'`);

  if (!schema)
    schema = validationSchema;

  // useEffect(() => {
  //   isMounted.current = true;
  //   window.scrollTo(0, 0);

  //   if (isMounted.current)
  //     setState({ ...state, isLoading: false});

  //   return () => { isMounted.current = false; };
  // }, []);

  async function validate(name, value) {
    // console.log('useValidate', 'validate', name, value, schema);
    value = (typeof value === 'string') ? value.trim() : value;
    await schema.validateAt(name, { [name]: value });
    return true;
  }

  // function setValidate(validationSchema) {
  function setValidationSchema(validationSchema) {
    console.log('useValidate', 'setValidationSchema', validationSchema);
    schema = validationSchema;
  }

  // return [ validate, setValidate ];
  return [ validate, setValidationSchema ];
}
