import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@org/client-components-core/inputs/TextField';

export function EmailField(props) {
  // console.log('EmailField', props);
  let { name, label, ...rest } = props;
  return (
    <TextField
      // type='email' // causes spaces to be ignored?
      name={name ? name : 'email'}
      label={label ? label : 'Email'}
      {...rest}
    />
  );
}

EmailField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
};
