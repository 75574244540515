import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import * as yup from 'yup/es';
import Grid from '@material-ui/core/Grid';
import { useForm, HooksForm } from '@org/client-components-core/form/useForm';
import { FormLayout, FormSpacer } from '@org/client-components-custom/layout/Form';
import { ButtonBar } from '@org/client-components-core/buttons/ButtonBar';
import { TextField } from '@org/client-components-core/inputs/TextField';
import { EmailField } from '@org/client-components-core/inputs/EmailField';
import { PrimaryButton } from '@org/client-components-core/buttons/Buttons';
import { ButtonLink } from '@org/client-components-core/links/ButtonLink';
import { BackdropLoader } from '@org/client-components-core/feedback/Loaders';
import { Heading, FormMessage, FormError, FlexRowCentered } from '@org/client-components-custom';
import { DisplayState } from '@org/client-components-utils/DisplayState';
import { useValidate, emailShape, confirmCodeShape } from '@org/common-yup';

let initialValues = {
  email: "",
  confirmCode: "",
};

let requiredValues = {
  email: true,
  confirmCode: true,
};

let validationShape = {
  email: emailShape,
  confirmCode: confirmCodeShape,
};
const validationSchema = yup.object().shape(validationShape);

let codeCount = 0;

export function ConfirmAddEmail(props) {
  // console.log('ConfirmAddEmail', props);
  const [ state, setState ] = useState({ isLoading: true, isSubmitting: false, isCompleted: false, formError: null, formMessage: 'Please check your email and enter the code here' });
  const isMounted = useRef(false);
  const [ validate ] = useValidate(validationSchema);
  const {
    formState, setValues, fieldProps
  } = useForm({ initialValues, requiredValues, validate });
  let { authenticatedHandler } = props;

  // let classes = useStyles();

  useEffect(() => {
    isMounted.current = true;
    window.scrollTo(0, 0);

    if (props?.location?.state)
      setValues(props.location.state, { isInitial: true, shouldValidate: true });

    if (isMounted.current)
      setState({ ...state, isLoading: false });

    return () => { isMounted.current = false; };
  }, []);

  async function handleResendCode(formProps) {
    event.preventDefault();
    // console.log('ConfirmAddEmail', 'handleResendCode', formProps);
    let { email } = formProps.values;

    try {
      if (!formProps.validated['email'])
        throw new Error('Please enter a valid email address');

      if (codeCount > 2)
        throw new Error('You have reached the maximum number of resends');

      await authenticatedHandler.resendAddEmail({ email });

      let formMessage;
      if (codeCount === 0)
        formMessage = 'New code sent';
      else if (codeCount === 1)
        formMessage = 'New code sent. Don’t forget to check your spam folder.';
      else
        formMessage = 'New code sent. Hopefully the third time’s the charm.';

      if (isMounted.current)
        setState({ ...state, formMessage, formError: null });

      codeCount++;
    } catch (error) {
      console.error('ConfirmAddEmail', 'handleResendCode', error);
      if (isMounted.current)
        setState({ ...state, formError: error });
    }
  }

  async function handleSubmit(formProps) {
    setState({ ...state, isSubmitting: true });

    try {
      await authenticatedHandler.confirmAddEmail(formProps.values);

      if (isMounted.current)
        setState({ ...state, isSubmitting: false, isCompleted: true,
          formMessage: 'Your new email has been successfully added', formError: null});
    } catch (error) {
      if (isMounted.current)
        setState({ ...state, isSubmitting: false, formMessage: null, formError: error });
    }
  }

  return (
    <>
    {state.isLoading &&
    <BackdropLoader />}

    <HooksForm
      id='user-personal-form'
      formState={formState}
    >
      {formProps => {
      let fieldEnabled = !state.isLoading && !state.isSubmitting;
      let submitEnabled = fieldEnabled && formProps.isValidated;
      return (
        <>
        <FormLayout>

          <Heading>{'Confirm Add Email'}</Heading>

          <Grid id='confirm-add-email' style={{width: '100%'}} >

            {state.formMessage &&
            <Grid item >
              <FormMessage message={state.formMessage} />
            </Grid>}

            {state.formError &&
            <Grid item >
              <FormError message={state.formError.message} />
            </Grid>}

            {/* show email field if it was not passed in from props.location.state */}
            {!state.isCompleted && !props.location?.state?.email &&
            <Grid item >
              <EmailField {...fieldProps} name='email' required={true} />
            </Grid>}

            {!state.isCompleted &&
            <Grid item >
              <TextField
                {...fieldProps}
                name='confirmCode'
                label='Confirm Code'
                required={true}
              />
            </Grid>}

          </Grid>

          <FormSpacer />

          {!state.isCompleted &&
          <ButtonBar>
            <PrimaryButton
              aria-label='submit button'
              name='submit-button'
              title='Submit'
              disabled={!submitEnabled}
              onClick={() => handleSubmit(formProps)}
            />
          </ButtonBar>}

          <FormSpacer />

          {!state.isCompleted &&
          <FlexRowCentered>
            <ButtonLink variant='body2' component='button' onClick={() => handleResendCode(formProps)} >
              {'Resend confirmation code'}
            </ButtonLink>
          </FlexRowCentered>}

        </FormLayout>
        </>
      );}}
    </HooksForm>

    <DisplayState title='state' state={{ state, formState }} open={true} />
    </>
  );
}

export default ConfirmAddEmail;

ConfirmAddEmail.propTypes = {
  location: PropTypes.shape(),
  authenticatedHandler: PropTypes.shape(),
};
