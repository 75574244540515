import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import * as yup from 'yup/es';
import { useTheme } from '@material-ui/core/styles';
import { useForm, HooksForm } from '@org/client-components-core/form/useForm';
import { FormLayout, FormStack, FormItem, FormSpacer } from '@org/client-components-custom/layout/Form';
import { Spacer } from '@org/client-components-core/layout/Spacer';
import { ButtonBar } from '@org/client-components-core/buttons/ButtonBar';
import { SelectTextField } from '@org/client-components-core/inputs/SelectTextField';
import { InLink } from '@org/client-components-core/links/InLink';
import { PrimaryButton, SecondaryButton } from '@org/client-components-core/buttons/Buttons';
import { BackdropLoader } from '@org/client-components-core/feedback/Loaders';
// import { Spacer } from '@org/client-components-core/layout/Spacer';
import {
  // FormLayout,
  Heading, FormMessage, FormError,
} from '@org/client-components-custom';
import { DisplayState } from '@org/client-components-utils/DisplayState';
// import theme from '@org/client-libs-themes';
// import { styles } from '@org/client-libs-styles';
import { useValidate, emailShape } from '@org/common-yup';

// const useStyles = makeStyles(styles);

let initialValues = {
  emails: [""],
  email: "",
  // backupEmail: "",
  // createdAt: "", ipAddress: "", viewerType: "", viewerOs: "", viewerBrowser: ""
  session: {},
};

let validationShape = {
  emails: yup.array(),
  email: emailShape,
  // backupEmail: emailShape,
  // createdAt: "", ipAddress: "", viewerType: "", viewerOs: "", viewerBrowser: ""
  session: yup.object(),
};
const validationSchema = yup.object().shape(validationShape);

export function Security(props) {
  // console.log('Security', props);
  const [ state, setState ] = useState({
    isLoading: true, isUpdated: false, isSubmitting: false, formMessage: null, formError: null,
  });
  const isMounted = useRef(false);
  const theme = useTheme();
  const [ validate ] = useValidate(validationSchema);
  const { formState, setValues, resetValues, resetForm, fieldProps } = useForm({ initialValues, validate });
  const { authenticatedHandler } = props;

  // let classes = useStyles();

  useEffect(() => {
    isMounted.current = true;
    window.scrollTo(0, 0);

    (async () => {
      try {
        let response = await Promise.all([
          authenticatedHandler.getMe(),
          authenticatedHandler.getSession(),
          authenticatedHandler.listEmails(),
        ]);
        // console.log('Security', response);
        let [ me, session, emails ] = response;

        if (isMounted.current) {
          let values = { email: me.email, emails, session };
          setValues(values);
          setState({ ...state, isLoading: false, formError: null });
        }

      } catch (error) {
        if (isMounted.current)
          setState({ ...state, isLoading: false, formError: error });
      }
    })();

    return () => { isMounted.current = false; };
  }, []);

  async function handleSubmit(formProps) {
    // console.log('handleSubmit', formProps);

    if (isMounted.current)
        setState({ ...state, isSubmitting: true, formMessage: null, formError: null });

    try {
      let input = { email: formProps.values.email };
      // console.log('handleSubmit', input);

      await authenticatedHandler.changeEmail(input);

      resetForm();

      if (isMounted.current)
        setState({ ...state, isSubmitting: false, formMessage: 'Update successful', formError: null });

    } catch (error) {
      if (isMounted.current)
        setState({ ...state, isSubmitting: false, formMessage: null, formError: error });
    }

  }

  let emailProps = { name: 'email', label: 'Primary Email', required: true };
  // let backupEmailProps = { name: 'backupEmail', label: 'Backup Email', required: true, disabled: !emailSelectEnabled };

  return (
    <>
    {state.isLoading &&
    <BackdropLoader />}

    <HooksForm
      id='user-security-form'
      formState={formState}
    >
      {formProps => {
      let fieldEnabled = !state.isLoading && !state.isSubmitting;
      let submitEnabled = fieldEnabled && formProps.changed.email;
      let cancelEnabled = formProps.isChanged;
      let emailSelectEnabled = fieldEnabled && formState?.values?.emails?.length > 1 ? true : false;
      let emails = formProps.values.emails.map(elem => { return { label: elem, value: elem }; });
      return (
        <>
        <FormLayout>

          <Heading>{'Security'}</Heading>

          {state.formMessage &&
          <FormMessage message={state.formMessage} />}

          {state.formError &&
          <FormError message={state.formError.message} />}

          <FormStack>

            <FormItem>
              <InLink title='Change Password' to='/user/change-password' />
            </FormItem>

            <FormItem>
              <InLink title='Add Email' to='/user/add-email' />
            </FormItem>

            <Spacer spacing={1} />

            <FormItem>
              <SelectTextField {...emailProps} {...fieldProps} items={emails} disabled={!emailSelectEnabled} />
            </FormItem>

            {/* {emailSelectEnabled &&
            <Grid item >
              <SelectTextField {...backupEmailProps} {...props.fieldProps} items={emails} disabled={!emailSelectEnabled} />
            </Grid>} */}

          </FormStack>

          <FormSpacer />

          <ButtonBar>

            <PrimaryButton
              name='submit-button'
              aria-label='submit button'
              title='Submit'
              disabled={!submitEnabled}
              onClick={() => handleSubmit(formProps)}
            />
            <SecondaryButton
              name='cancel-button'
              aria-label='cancel button'
              title='Cancel'
              disabled={!cancelEnabled}
              // onClick={handleCancel}
              onClick={resetValues}
            />

          </ButtonBar>

        </FormLayout>

        {/* <FormLayout>

          <Grid style={{width: '100%'}} >

            <Grid item >
              <TextField {...sessionInitiatedProps} {...props.fieldProps} />
            </Grid>

            <Grid item >
              <TextField {...ipAddressProps} {...props.fieldProps} />
            </Grid>

            <Grid item >
              <TextField {...viewerTypeProps} {...props.fieldProps} />
            </Grid>

            <Grid item >
              <TextField {...viewerOsProps} {...props.fieldProps} />
            </Grid>

            <Grid item >
              <TextField {...viewerBrowserProps} {...props.fieldProps} />
            </Grid>

          </Grid>

        </FormLayout> */}
        </>
      );}}
    </HooksForm>

<DisplayState title='state' state={{ state, formState }} open={true} />
    </>
  );
}

Security.propTypes = {
  location: PropTypes.shape(),
  history: PropTypes.shape(),
  isValidated: PropTypes.bool,
  fieldProps: PropTypes.shape(),
  submitProps: PropTypes.shape(),
  stateProps: PropTypes.shape(),
  authenticatedHandler: PropTypes.shape(),
};
