import { Handler } from '@org/client-libs-classes/Handler';
import { authenticatedClient } from './client';
import { authenticatedSchema } from './schema';

export class AuthenticatedHandler extends Handler {
  constructor(props) {
    super({ ...props, client: authenticatedClient });
    this.schema = authenticatedSchema;
  }

  async ping() {
    let params = {
      query: this.schema.PING_AUTHENTICATED,
      fetchPolicy: "no-cache",
    };
    return this.query(params);
  }

  async testSecurity(input) {
    let params = {
      query: this.schema.TEST_SECURITY,
      variables: { input: input },
      fetchPolicy: "no-cache",
    };
    return this.query(params);
  }

  async changeEmail(input) {
    let params = {
      mutation: this.schema.CHANGE_EMAIL,
      variables: { input: input },
      fetchPolicy: "no-cache",
    };
    return this.mutate(params);
  }

  async changePassword(input) {
    let params = {
      mutation: this.schema.CHANGE_PASSWORD,
      variables: { input: input },
      fetchPolicy: "no-cache",
    };
    return this.mutate(params);
  }

  async confirmAddEmail(input) {
    let params = {
      mutation: this.schema.CONFIRM_ADD_EMAIL,
      variables: { input: input },
      fetchPolicy: "no-cache",
    };
    return this.mutate(params);
  }

  async createDeposit(input) {
    let params = {
      mutation: this.schema.CREATE_DEPOSIT,
      variables: { input: input },
      fetchPolicy: "no-cache",
    };
    return this.mutate(params);
  }

  async createTrade(input) {
    let params = {
      mutation: this.schema.CREATE_TRADE,
      variables: { input: input },
      fetchPolicy: "no-cache",
    };
    return this.mutate(params);
  }

  async createWithdrawal(input) {
    let params = {
      mutation: this.schema.CREATE_WITHDRAWAL,
      variables: { input: input },
      fetchPolicy: "no-cache",
    };
    return this.mutate(params);
  }

  async downloadFile(input) {
    let params = {
      query: this.schema.DOWNLOAD_FILE,
      variables: { input: input },
      fetchPolicy: "no-cache",
    };
    return this.query(params);
  }

  async getAuth(input) {
    let params = {
      query: this.schema.GET_AUTH,
      variables: { input: input },
      fetchPolicy: "no-cache",
    };
    return this.query(params);
  }

  async getBalance() {
    let params = {
      query: this.schema.GET_BALANCE,
      // variables: { input: input },
      // fetchPolicy: 'no-cache',
      fetchPolicy: 'network-only',
    };
    return this.query(params);
  }

  async getLoan(input) {
    let params = {
      query: this.schema.GET_LOAN,
      variables: { input: input },
      fetchPolicy: "no-cache",
    };
    return this.query(params);
  }

  async getMe(input) {
    let params = {
      query: this.schema.GET_ME,
      variables: { input: input },
      fetchPolicy: "no-cache",
    };
    return this.query(params);
  }

  async getNote(input) {
    let params = {
      query: this.schema.GET_NOTE,
      variables: { input: input },
      fetchPolicy: "no-cache",
    };
    return this.query(params);
  }

  async getSession(input) {
    let params = {
      query: this.schema.GET_SESSION,
      variables: { input: input },
      fetchPolicy: "no-cache",
    };
    return this.query(params);
  }

  async getTrade(input) {
    let params = {
      query: this.schema.GET_TRADE,
      variables: { input: input },
      fetchPolicy: "no-cache",
    };
    return this.query(params);
  }

  async initiateAddEmail(input) {
    let params = {
      query: this.schema.INITIATE_ADD_EMAIL,
      variables: { input: input },
      fetchPolicy: "no-cache",
    };
    return this.query(params);
  }

  async listCashflows(input) {
    let params = {
      query: this.schema.LIST_CASHFLOWS,
      variables: { input: input },
      fetchPolicy: 'cache-first',
    };
    return this.query(params);
  }

  async listEmails(input) {
    let params = {
      query: this.schema.LIST_EMAILS,
      variables: { input: input },
      fetchPolicy: "no-cache",
    };
    return this.query(params);
  }

  async listFiles(input) {
    let params = {
      query: this.schema.LIST_FILES,
      variables: { input: input },
      fetchPolicy: "no-cache",
    };
    return this.query(params);
  }

  async listLoans(input) {
    let params = {
      query: this.schema.LIST_LOANS,
      variables: { input: input },
      // fetchPolicy: 'no-cache',
      fetchPolicy: 'cache-first',
      // fetchPolicy: 'network-only',   // Used for first execution
      // nextFetchPolicy: 'cache-first' // Used for subsequent executions
    };
    return this.query(params);
  }

  // Should the base case get fresh or cached data?
  async listNotes(input) {
    let params = {
      query: this.schema.LIST_NOTES,
      variables: { input: input },
      // fetchPolicy: 'no-cache',
      fetchPolicy: 'cache-first',
      // fetchPolicy: 'network-only',   // Used for first execution
      // nextFetchPolicy: 'cache-first' // Used for subsequent executions
    };
    return this.query(params);
  }

  async listNoteBalances(input) {
    let params = {
      query: this.schema.LIST_NOTE_BALANCES,
      variables: { input: input },
      fetchPolicy: 'network-only',
    };
    return this.query(params);
  }

  // This will always get fresh data
  async refreshListNotes(input) {
    let params = {
      query: this.schema.LIST_NOTES,
      variables: { input: input },
      fetchPolicy: 'network-only',
    };
    return this.query(params);
  }

  // This will always get cached data
  async listNotesCache(_input) {
    let params = {
      query: this.schema.LIST_NOTES,
      // variables: { input: input },
    };
    return this.readQuery(params);
  }

  // URGENT This requires that the caller has an updated response from listNotes..
  // Is this how this should work? What is the variables example for?
  async updateNotesCache(input) {
    let params = {
      query: this.schema.LIST_NOTES,
      data: { 
        listNotes: [ ...input ]
      },
      // variables: { // ???
      //   id: 5
      // }
    };
    return this.writeQuery(params);
  }

  async readLoanFragment(input) {
    let { id } = input;
    let params = {
      id: 'Loan:' + id,
      fragment: this.schema.GET_LOAN,
    };
    return this.readFragment(params);
  }

  async readNoteFragment(input) {
    let { id } = input;
    let params = {
      id: 'Note:' + id,
      fragment: this.schema.GET_NOTE,
    };
    return this.readFragment(params);
  }

  async writeNoteFragment(id, data) {
    let params = {
      id: 'Note:' + id,
      fragment: this.schema.GET_NOTE,
      data,
    };
    // console.log('noteFragment', params);
    // let response = await this.readFragment(params);
    // console.log('noteFragment', response);
    return this.writeFragment(params);
  }

  async watchNotes() {
    let params = {
      query: this.schema.LIST_NOTES,
      fetchPolicy: 'cache-and-network',
      // fetchPolicy: 'cache-only',
      notifyOnNetworkStatusChange: true,
      // pollInterval: 1000,
      refetchWritePolicy: 'overwrite',
    };
    return this.watchQuery(params);
  }

  async listTrades(input) {
    let params = {
      query: this.schema.LIST_TRADES,
      variables: { input: input },
      fetchPolicy: "no-cache",
    };
    return this.query(params);
  }

  async listTradeTotals(input) {
    let params = {
      query: this.schema.LIST_TRADE_TOTALS,
      variables: { input: input },
      fetchPolicy: 'network-only',
    };
    return this.query(params);
  }

  async listTransactions(input) {
    let params = {
      query: this.schema.LIST_TRANSACTIONS,
      variables: { input: input },
      fetchPolicy: "no-cache",
    };
    return this.query(params);
  }

  async resendAddEmail(input) {
    let params = {
      query: this.schema.RESEND_ADD_EMAIL,
      variables: { input: input },
      fetchPolicy: "no-cache",
    };
    return this.query(params);
  }

  async signOut(input) {
    let params = {
      mutation: this.schema.SIGN_OUT,
      variables: { input: input },
      fetchPolicy: "no-cache",
    };
    return this.mutate(params);
  }

  async updateMe(input) {
    let params = {
      mutation: this.schema.UPDATE_ME,
      variables: { input: input },
      fetchPolicy: "no-cache",
    };
    return this.mutate(params);
  }

  async uploadFile(input) { // Do not copy, data response is not typical
    let params = {
      mutation: this.schema.UPLOAD_FILE,
      variables: { input: input },
      fetchPolicy: "no-cache",
    };
    return this.mutate(params);
  }

  async watchListNotes() {
    let params = {
      query: this.schema.LIST_NOTES,
      fetchPolicy: "cache-and-network",
      notifyOnNetworkStatusChange: true,
    };
    return this.watchQuery(params);
  }
}

// for e2e offline tests
export const authenticatedHandler = new AuthenticatedHandler({ location: {}, history: {} });
