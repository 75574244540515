import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
// import clsx from 'clsx';
import dayjs from 'dayjs';
import * as yup from 'yup/es';
// import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
// import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useForm, HooksForm } from '@org/client-components-core/form/useForm';
import { FormLayout, FormItem, FormSpacer } from '@org/client-components-custom/layout/Form';
// import { Stack, Item } from '@org/client-components-core/layout/Stack';
// import { TextField } from '@org/client-components-core/inputs/TextField';
// import { StandardTextField } from '@org/client-components-core/inputs/StandardTextField';
// import { SelectTextField } from '@org/client-components-core/inputs/SelectTextField';
// import { AmountField } from '@org/client-components-core/inputs/AmountField';
// import { DatePicker } from '@org/client-components-core/inputs/DatePicker';
import { ButtonBar } from '@org/client-components-core/buttons/ButtonBar';
import { PrimaryButton, SecondaryButton } from '@org/client-components-core/buttons/Buttons';
import { BackdropLoader } from '@org/client-components-core/feedback/Loaders';
import { Modal } from '@org/client-components-core/utils/Modal';
import { Page } from '@org/client-components-custom/page/Page';
import { Heading } from '@org/client-components-custom/page/Heading';
import { FormMessage, FormError } from '@org/client-components-custom/page/FormMessage';
import { DisplayState } from '@org/client-components-utils/DisplayState';
import { styles } from '@org/client-libs-styles';
import { useValidate, itemTypeShape, ulidShape } from '@org/common-yup';
import { ConfirmTradeDialog } from './ConfirmTradeDialog';
import { TradeClass, calculate } from '@org/common-classes/Trade';
// import { TradeClass } from '@org/common-classes/Trade';
import { dollarAmount, amountFormat } from '@org/common-formatters';
import { TradeItem } from './enter-trade/TradeItem';
import { AmountRow } from './enter-trade/AmountRow';
import { TradeRow } from './enter-trade/TradeRow';

const useStyles = makeStyles((theme) => ({
  ...styles,
  main: {
    width: '100%',
    maxWidth: theme.spacing(55),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  itemRow: {
    flexDirection: 'row',
    width: 'auto',
    margin: theme.spacing(0, 0, 2, 0),
  },
  gridColumn: {
    flexDirection: 'column',
    maxWidth: '50%',
    // padding: theme.spacing(0, 1, 0, 1),
    padding: theme.spacing(0, 0, 0, 0),
  },
  gridItem: {
    padding: theme.spacing(0, 0, 0, 0),
  },
  itemName: {
    margin: theme.spacing(1),
  },
}));

let initialValues = {
  accountBalance: 0.0,
  itemType: "",
  itemId: "",
  itemName: "",
  tradeType: "BUY",
  eventDate: dayjs().format('YYYY-MM-DD'),
  originalBalance: 0.0,
  availableBalance: 0.0,
  factor: 1.0,
  currentBalance: 0.0,
  minimumAmount: 0.0,
  amount: 0.0,
  price: 100.0,
  investedAmount: 0.0,
};

let requiredValues = {
  amount: true,
};

let tradeAmountShape = yup.number().required('Trade Amount is required');

let validationShape = {
  // accountBalance: yup.number().positive(),
  // accountBalance: yup.lazy((value) => {
  //   return yup.number().positive().required('Trade Amount is required');
  // }),
  itemType: itemTypeShape,
  itemId: ulidShape,
    // .required('Item ID is required'),
  itemName:  yup.string(),
  eventDate: yup.string(),
  // amount: yup.number().positive().required('Trade Amount is required'),
  // amount: yup.number().positive('Trade Amount must be positive').required('Trade Amount is required'),
  amount: tradeAmountShape,
  referenceType: yup.string(),
  referenceId: yup.string(),
  tradeType: yup.string(),
  price: yup.number(),
  factor: yup.number(),
  investedAmount: yup.number().positive(), // .required('Invested Amount is required'),
};
// yup.lazy((value) => validationShape)
let validationSchema = yup.object().shape(validationShape);

let isXs;

function InsufficientFunds() {
  return (
    <div style={{height: 'auto', width: 'auto', maxWidth: '300px', padding: '10px', whiteSpace: 'pre-wrap', backgroundColor: 'white'}}>
      {'You had insufficient funds to make this transactions so we have made a deposit to your account for this demo. Ordinarily you would be required to have the funds in your account prior to entering a trade.'}
    </div>
  ); 
}

InsufficientFunds.propTypes = {
  fieldProps: PropTypes.shape(),
};

// let tradeTypeProps = { id: 'tradeType', name: 'tradeType', label: 'Type' };

let tradeClass;
if (!tradeClass)
  tradeClass = new TradeClass();

// let tradeTypeItems = [ { label: 'Buy', value: 'BUY' }, { label: 'Sell', value: 'SELL' } ];
let tradeTypeItems = tradeClass.tradeTypes;

let accountBalance = 0;

// Replace the imported calculate using tradeClass.calculateValues?
// function calculate(input) {
//   console.log('EnterTrade', 'calculate', input);
//   if (tradeClass && tradeClass.calculateValues)
//     // currentClass.update(input)??? !Which also triggers calculateValues()!
//     return tradeClass.calculateValues(input);
//   else
//     return input;
// }

export function EnterTrade3(props) {
  // console.log('EnterTrade', 'props', props);
  const [ state, setState ] = useState({
    isLoading: true, isSubmitting: false, isCompleted: false, formMessage: null, formError: null
  });
  const isMounted = useRef(false);
  const [ validate ] = useValidate(validationSchema);
  // const { setValues, formState, fieldProps } = useForm({ initialValues, requiredValues, calculate, validate });
  const { formState, fieldProps, validateForm } = useForm({
    // initialValues: calculate({ ...initialValues, ...props.location?.state?.note }),
    initialValues: {
      ...initialValues,
      // ...props.location?.state?.values,
      ...props.location?.state?.values,
    },
    requiredValues, calculate, validate,
  });
  const [ open, setOpen ] = useState(false);
  const [ confirmOpen, setConfirmOpen ] = useState(false);
  let theme = useTheme();
  isXs = useMediaQuery(theme.breakpoints.down("xs"));
  let { authenticatedHandler, authorizedHandler } = props;
  // const { loading, error, data } = useQuery(authenticatedSchema.GET_BALANCE);

  let classes = useStyles();

  // let { accountBalance } = formState.values;

  if (state.isLoading) {
    // let tradeAmoutError = `Minimum is ${amountFormat(formState.values.minimumAmount)}`;
    tradeAmountShape.min(formState.values.minimumAmount);
    // tradeAmountShape.min(formState.values.minimumAmount, tradeAmoutError);
    validationShape.amount = tradeAmountShape.min(formState.values.minimumAmount);
    validationSchema = yup.object().shape(validationShape);
  }

  useEffect(() => {
    isMounted.current = true;
    window.scrollTo(0, 0);
  
    (async () => {
      // if (!note) {
      //   let noteId = localStorage.getItem('noteId');
      //   note = await authenticatedHandler.getNote({ id: noteId });
      //   referenceItem = await authenticatedHandler.getLoan({ id: note.referenceId });
      // }
      // setState({ ...state, isLoading: false });
      // accountBalance = 1000000;

      accountBalance = await authenticatedHandler.getBalance();
      accountBalance = 0.0;

      // if (accountBalance == null)
      //   props.history.push('/invest/');

      // tradeClass = new TradeClass(props.location?.state?.values);
      validateForm();

      if (!accountBalance && !open) {
        let depositAmount = 100000.0;
        let input = { eventDate: dayjs().format('YYYY-MM-DD'), amount: depositAmount };
        try {
          // await authorizedHandler.createDeposit(input);
          await authenticatedHandler.createDeposit(input);
          accountBalance += depositAmount;
          // console.log('useEffect', accountBalance);

          if (isMounted.current) {
            setOpen(true);
            setState({ ...state, isLoading: false });
          }
        } catch (error) {
          console.error('EnterTrade', 'useEffect', 'createDeposit', error);
          if (isMounted.current)
            setState({ ...state, isLoading: false, formError: error });
        }
      } else
        setState({ ...state, isLoading: false });
    })();

    // if (state.isLoading)
    //   // if (props.location?.state)
    //   //   props.location.state = {};
    //   props.history.replace({ state: {} });

    return () => { isMounted.current = false; };
  }, []);

  async function handleCreateDeposit(_event) {
    if (isMounted.current)
      setState({ ...state, isSubmitting: true, formError: null });

    let depositAmount = 100000.0;
    let input = { eventDate: dayjs().format('YYYY-MM-DD'), amount: depositAmount };
    await authenticatedHandler.createDeposit(input);
    // accountBalance += depositAmount;
    accountBalance = await authenticatedHandler.getBalance();
    console.log('handleCreateDeposit', accountBalance);

    if (isMounted.current)
      setState({ ...state, isSubmitting: false, formError: null });
  }
  console.log('handleCreateDeposit1', accountBalance);

  async function handleSubmit() {
    // console.log('EnterTrade', 'handleSubmit', formProps);
    window.scrollTo(0, 0);
    if (isMounted.current)
      setState({ ...state, isSubmitting: true, formError: null });

    let { values } = formState;

    let inputs = {
      referenceType: values.itemType,
      referenceId: values.itemId,
      eventDate: values.eventDate,
      tradeType: 'BUY',
      amount: values.amount,
      price: values.price,
      investedAmount: values.investedAmount,
    };

    try {
      await authenticatedHandler.createTrade(inputs);
      await authenticatedHandler.listNotes();

      if (isMounted.current)
        setState({ ...state, isSubmitting: false, isCompleted: true, formMessage: 'Transaction successfully created', formError: null });
    } catch (error) {
      console.error('EnterTransaction', error);
      if (isMounted.current)
        setState({ ...state, isSubmitting: false, formMessage: null, formError: error });
    }
  }

  function handleConfirm() {
    // console.log('handleConfirm', formProps);
    handleSubmit();
    if (isMounted.current)
      setConfirmOpen(false);
  }

  // open/close the dialog
  function handleShowConfirm() {
    // console.log('handleConfirm', formProps);
    if (isMounted.current)
      setConfirmOpen(true);
  }

  function handleCancel() {
    // if (props.location.state?.values)
    //   props.history.push({
    //     pathname: '/invest/detail',
    //     state: { note: props.location.state?.values },
    //   });
    // else // in case of page refresh
      props.history.push('/invest');
  }

  function handleClose() {
    setOpen(false);
  }

  let confirmDialogProps = { open: confirmOpen, setConfirmOpen, handleConfirm };

  if (state.isCompleted) {
    return (
      <FormLayout>

        <Heading>{'Enter Trade'}</Heading>

        <FormMessage message={state.formMessage} />

      </FormLayout>
    );
  }

  // console.log('about to render', accountBalance);
  let maxWidth = isXs ? 'xs' : 'md';
  // console.log('EnterTrade', 'maxWidth', maxWidth);

  return(
    <>
    {state.isLoading &&
    <BackdropLoader />}

    {/* {!state.isLoading && */}
    <HooksForm
      id='enter-trade-form'
      formState={formState}
    >
      {formProps => {
      {/* console.log('formProps', formProps); */}

      let { minimumAmount, availableBalance, investedAmount } = formProps.values;
      console.log('formProps', accountBalance, minimumAmount, availableBalance, investedAmount);
      let tradeError;
      if (investedAmount < minimumAmount)
      /* if (investedAmount > 0 && investedAmount < minimumAmount) */
        tradeError = `The minimum investment is ${dollarAmount.format(minimumAmount)}`;
      else if (investedAmount > accountBalance)
        tradeError = `You're account accountBalance is ${dollarAmount.format(accountBalance)}. Please reduce the Trade Amount or deposit additional funds.`;
      else if (investedAmount > availableBalance)
        tradeError = `There is only ${dollarAmount.format(availableBalance)} available.`;

      let fieldEnabled = !state.isLoading && !state.isSubmitting;
      let submitEnabled = fieldEnabled && formProps.isValidated && !tradeError;
      return (
        <>
        <Page>

          <Heading>{'Enter Trade'}</Heading>

          {state.formMessage &&
          <FormMessage message={state.formMessage} />}

          <FormMessage message={`Account Balance: ${dollarAmount.format(accountBalance)}`} />

          {state.formError &&
          <FormError message={state.formError.message} />}

          {!state.isCompleted &&
          <>
          <Grid container spacing={0} direction='column' className={classes.main} >

            <TradeItem fieldProps={fieldProps} />

            <AmountRow fieldProps={fieldProps} />

            <TradeRow fieldProps={fieldProps} tradeTypeItems={tradeTypeItems} />

            {!tradeError &&
            <FormSpacer />}

            {tradeError &&
            <FormError message={tradeError} />}

            <ButtonBar>
              <PrimaryButton title='Submit' disabled={!submitEnabled}
                onClick={handleShowConfirm} />
              <SecondaryButton title='Cancel' onClick={handleCancel} />
              {accountBalance < 25000 &&
              <PrimaryButton title='Make Deposit' onClick={handleCreateDeposit} />}
            </ButtonBar>

          </Grid>
          </>}

        </Page>

        <Modal id='insufficient-funds-modal' open={open} handleClose={handleClose} className={classes.presentationModal} >
          <InsufficientFunds />
        </Modal>

        <ConfirmTradeDialog {...confirmDialogProps} values={formProps.values} />
        </>
      );}}

    </HooksForm>

    <DisplayState title='state' state={{ state, formState, location: props.location, history: props.history }} open={true} />
    </>
  );
}

EnterTrade3.propTypes = {
  location: PropTypes.shape(),
  history: PropTypes.shape(),
  fieldProps: PropTypes.shape(),
  authenticatedHandler: PropTypes.shape(),
  authorizedHandler: PropTypes.shape(),
};
