import React, { PureComponent, Suspense } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import { BackdropLoader } from '@org/client-components-core/feedback/Loaders';
import { AppBar } from '@org/client-components-custom/app-bar/AppBar';
import { Footer } from '@org/client-components-custom/Footer';
// import { Page } from '@org/client-components-custom/page/Page';
import { Routes } from './Routes';
import theme from '@org/client-libs-themes';
// import {
//   // publicClient,
//   // PublicHandler,
//   // authenticatedClient, AuthenticatedHandler,
//   authorizedClient, AuthorizedHandler
// } from '@org/client-graphql';
import { PublicHandler } from '@org/client-graphql/public/handler';
import { publicClient } from '@org/client-graphql/public/client';
import { AuthenticatedHandler } from '@org/client-graphql/authenticated/handler';
import { authenticatedClient } from '@org/client-graphql/authenticated/client';
import { AuthorizedHandler } from '@org/client-graphql/authorized/handler';
import { authorizedClient } from '@org/client-graphql/authorized/client';

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexGrow: '1 1 auto',
    height: '100%',
    minHeight: '100vh',
    width: '100%',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flex: '1 1 auto',
    padding: theme.spacing(0),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 0,
    width: '100%',
    // height: `calc(100% - ${appBarHeight}px)`,
    height: `calc(100% - ${theme.custom.APP_BAR_HEIGHT}px)`,
  },
  contentShift: {
    flex: '1 1 auto',
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    // marginLeft: drawerWidth,
    // width: `calc(100% - ${drawerWidth}px)`,
    // marginLeft: theme.custom.DRAWER_WIDTH,
    // width: `calc(100% - ${theme.custom.DRAWER_WIDTH}px)`,
  },
  contentShiftRight: {
    flex: '1 1 auto',
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    // marginRight: drawerWidth,
    // width: `calc(100% - ${drawerWidth}px)`,
    // marginRight: theme.custom.DRAWER_WIDTH,
    // width: `calc(100% - ${theme.custom.DRAWER_WIDTH}px)`,
  },
};

let publicHandler;
let authenticatedHandler;
let authorizedHandler;

// We don't need this any more. Just let Routes handle it.
// const publicRoutes = [
//   '/', '/learn', '/partner',
//   '/sign-in', '/invitation', '/sign-up', '/confirm-sign-up',
//   '/reset-password','/confirm-reset-password',
//   '/unsubscribe',
//   // '/borrow/application',
//   '/user-not-authorized',
// ];

class AppComponent extends PureComponent {
  constructor(props) {
    super(props);

    this.handleOpenMainDrawer = this.handleOpenMainDrawer.bind(this);
    this.handleCloseMainDrawer = this.handleCloseMainDrawer.bind(this);
    this.handleOpenUserDrawer = this.handleOpenUserDrawer.bind(this);
    this.handleCloseUserDrawer = this.handleCloseUserDrawer.bind(this);

    this.state = {
      isLoading: true,
      mainDrawerOpen: false,
      userDrawerOpen: false,
    };
  }

  async componentDidMount() {
    console.warn('AppComponent', 'componentDidMount', 'props.location', this.props.location);

    publicHandler = new PublicHandler(this.props);
    authenticatedHandler = new AuthenticatedHandler(this.props);
    authorizedHandler = new AuthorizedHandler(this.props);

    let authentication;

    let { pathname, search } = this.props.location;

    if (search) { // Search string provided! (for email links, etc)
      let fullpath = pathname + search;
      console.info('AppComponent', 'componentDidMount', fullpath);
      this.props.history.push(fullpath);
    // } else if (pathname === '/cubit-brand.png') {
    // added when brand image stopped working in email but google image caching was the problem
    }

    // Is the user authenticated?
    try {
      authentication = await authenticatedHandler.getAuth();
      console.info('AppComponent', 'componentDidMount', 'getAuth', authentication);

      await publicHandler.handleAuthentication(authentication);

      // Prime the cache
      authenticatedHandler.listNotes();
      authenticatedHandler.listLoans();
      authenticatedHandler.getBalance();

      this.props.history.push(pathname);
    } catch (error) {
      console.warn(error.message);
      // Authentication errors are handled before this in Handler
      // Other errors are rethrown and could catch here

      if (pathname === '/sign-out')
        this.props.history.push('/sign-in');
      else
        this.props.history.push(pathname);
    }

    // if (authentication)
    //   this.props.history.push(pathname);
    // else if (pathname === '/sign-out')
    //   this.props.history.push('/sign-in');

    // } else if (pathname === '/sign-out') {
    //   // this will happen on refresh or if this route is manually entered (which triggers a refresh)
    //   this.props.history.push('/sign-in');
    // } else {
    //   try {
    //     // Check if user is authenticated (on refresh since we can't see http-only session cookie)
    //     response = await authenticatedHandler.getAuth();
    //     // console.info('AppComponent', 'componentDidMount', 'getAuth', response);

    //     if (response) { // The user is authenticated
    //       // console.log('Here');
    //       await publicHandler.handleAuthentication(response);
    //       authenticatedHandler.listNotes();
    //       authenticatedHandler.listLoans();
    //     }
    //     this.props.history.push(pathname);
    //   } catch (error) {
    //     // // The user is NOT authenticated, make sure the local state is reset
    //     // // console.error('AppComponent', 'componentDidMount', error?.code, error?.message);
    //     // await publicHandler.handleClearAuthentication();
    //     // This should not happen
    //   }
    // }

    this.setState({ ...this.state, isLoading: false });

  }

  async componentDidUpdate(props) {
    console.info('AppComponent', 'componentDidUpdate', props);

    // what is this for? do I need it?
    // URGENT Confirm this is to not show disclaimers on the home page...
    this.setState({ pathname: props.history.location.pathname });

  }

  handleOpenMainDrawer() {
    this.setState({ mainDrawerOpen: true });
  }

  handleCloseMainDrawer() {
    this.setState({ mainDrawerOpen: false });
  }

  handleOpenUserDrawer() {
    this.setState({ userDrawerOpen: true });
  }

  handleCloseUserDrawer() {
    this.setState({ userDrawerOpen: false });
  }

  render() {
    const { classes } = this.props;
    let { mainDrawerOpen, userDrawerOpen } = this.state;
    let mainDrawerProps = { open: mainDrawerOpen, handleCloseDrawer: this.handleCloseMainDrawer };
    let userDrawerProps = { open: userDrawerOpen, handleCloseDrawer: this.handleCloseUserDrawer };
    let appBarProps = { mainDrawerOpen, handleOpenMainDrawer: this.handleOpenMainDrawer, userDrawerOpen, handleOpenUserDrawer: this.handleOpenUserDrawer, mainDrawerProps, userDrawerProps, history: this.props.history, location: this.props.location, pathname: this.props.location.pathname };

    let routeProps = { publicHandler, authenticatedHandler, authorizedHandler };
    // let { isLoading } = this.state;
    // let routeProps = { isLoading, publicHandler, authenticatedHandler, authorizedHandler };

    // if (this.state.isLoading)
    //   return (<BackdropLoader />);

    return (
      <>
      {this.state.isLoading &&
      <BackdropLoader />}

      {!this.state.isLoading &&
      <div className={classes.root} >
        <ApolloProvider client={authorizedClient} >
          <ApolloProvider client={authenticatedClient} >
            <ApolloProvider client={publicClient} >

              {/* <ErrorBoundary> */}
                {/* <Suspense fallback={<BackdropLoader />}> */}

                <AppBar {...appBarProps} />

                <Suspense fallback={<div style={{ height: '1000px' }} ></div>}>
                {/* <Suspense fallback={<Page />}> */} {/* doesn't work for signIn / signUp */}

                  <main id='content' className={clsx(classes.content, { [classes.contentShift]: mainDrawerOpen, }, { [classes.contentShiftRight]: userDrawerOpen, })} >

                    <div id="back-to-top-anchor" />

                    <Routes {...routeProps} />

                    {this.state.pathname !== '/' &&
                    <Footer pathname={this.state.pathname} />}

                  </main>

                </Suspense>
              {/* </ErrorBoundary> */}

            </ApolloProvider>
          </ApolloProvider>
        </ApolloProvider>
      </div>}
      </>
    );
  }
}

export default withStyles(styles)(withRouter(AppComponent));

export const App = withStyles(styles)(withRouter(AppComponent));

AppComponent.propTypes = {
  classes: PropTypes.any,
  history: PropTypes.shape(),
  location: PropTypes.shape(),
};
