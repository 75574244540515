import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import * as yup from 'yup/es';
import Grid from '@material-ui/core/Grid';
import { useForm, HooksForm } from '@org/client-components-core/form/useForm';
import { FormLayout, FormSpacer } from '@org/client-components-custom/layout/Form';
import { ButtonBar } from '@org/client-components-core/buttons/ButtonBar';
import { TextField } from '@org/client-components-core/inputs/TextField';
import { SelectTextField } from '@org/client-components-core/inputs/SelectTextField';
import { AmountField } from '@org/client-components-core/inputs/AmountField';
import { DatePicker } from '@org/client-components-core/inputs/DatePicker';
import { PrimaryButton } from '@org/client-components-core/buttons/Buttons';
import { InLink } from '@org/client-components-core/links/InLink';
import { FlexRow } from '@org/client-components-custom/layout/Flex';
import { FormMessage, FormError } from '@org/client-components-custom/page/FormMessage';
import { Heading } from '@org/client-components-custom/page/Heading';
import { DisplayState } from '@org/client-components-utils/DisplayState';
import { useValidate, amountShape } from '@org/common-yup';
// import { defaultLoanValues } from './inputs';
import { referenceItemTypes } from '@org/common-data-types';

let initialValues = {
  referenceItemType: "NOTE",
  referenceItemId: "",
  eventDate: dayjs().format('YYYY-MM-DD'),
  amount: 0.00,
};

let validationShape = {
  referenceItemType: yup.string(),
  referenceItemId: yup.string(),
  eventDate: yup.string(),
  amount: amountShape,
};
const validationSchema = yup.object().shape(validationShape);

export function CreatePayment(props) {
  const [ state, setState ] = useState({
    isLoading: true, isSubmitting: false, isCompleted: false, values: {}, formMessage: null, formError: null,
  });
  const isMounted = useRef(false);
  const [ validate ] = useValidate(validationSchema);
  const {
    formState, // fieldProps, setValues, resetValues, resetForm,
  } = useForm({ initialValues, validate });
  let { authorizedHandler } = props;

  useEffect(()=>{
    window.scrollTo(0, 0);
    console.log('CreatePayment', props.location.state);

    // (async () => {
    //   try {
    //     if (props.location.state) {
    //       let values = props.location.state;
    //       stateValues = {
    //         referenceItemType: values.itemType,
    //         referenceItemId: values.id,
    //         eventDate: dayjs().format('YYYY-MM-DD'),
    //       };
    
    //       // let trades = await authorizedResolvers.listTrades({ itemType: values.itemType, id: values.id });
    //       let trades = await authorizedHandler.listTrades({ itemType: values.itemType, id: values.id });
    //       console.log('trades', trades);
    //     }
    //     if (isMounted.current)
    //       setState({ ...state, isLoading: false});
    //   } catch (error) {
    //     if (isMounted.current)
    //       setState({ ...state, isLoading: false, formError: error });
    //   }
    // })();

    if (isMounted.current)
      setState({ ...state, isLoading: false});
    
    return () => { isMounted.current = false; };
  },[]);

  async function handleSubmit(event, form) {
    event.preventDefault();
    let { values } = form;
    setState({ ...state, isSubmitting: true });

    let input = {
      referenceType: values.referenceItemType,
      referenceId: values.referenceItemId,
      eventDate: values.eventDate,
      amount: values.amount,
    };
    console.log('CreatePayment', input);
    try {
      // let response = await authorizedResolvers.createPayment(input);
      let response = await authorizedHandler.createPayment(input);

      if (response?.success)
        setState({ ...state, isSubmitting: false, isCompleted: true, formMessage: `Payment successfully created` });

      if (response?.error)
        throw response.error;
    } catch (error) {
      console.error('handleSubmit', error);
      setState({ ...state, isSubmitting: false, formError: error });
    }
  }

  return (
    <>
    <HooksForm
      id='admin-create-payment-form'
      formState={formState}
    >
      {formProps => {
      let fieldEnabled = !state.isLoading && !state.isSubmitting;
      let submitEnabled = fieldEnabled && formProps.isValidated;
      {/* let cancelEnabled = formProps.isChanged; */}
      return (
        <>
        <FormLayout >

          <FlexRow>
            <InLink to='/admin/note-detail' title='Back' />
          </FlexRow>

          <Heading>{'Create Payment'}</Heading>

          <Grid style={{width: '100%'}} >

            {state.formMessage &&
            <Grid item >
              <FormMessage message={state.formMessage} />
            </Grid>}

            {state.formError &&
            <Grid item >
              <FormError message={state.formError.message} />
            </Grid>}

            <Grid item >
              <SelectTextField {...props.fieldProps} name={'referenceItemType'} label={'Item Type'} items={referenceItemTypes} required={true} />
            </Grid>

            <Grid item >
              <TextField {...props.fieldProps} name='referenceItemId' label='Item ID' required={true} />
            </Grid>

            <Grid item >
              <DatePicker {...props.fieldProps} name='eventDate' label='Payment Date' required={true} />
            </Grid>

            <Grid item >
              <AmountField {...props.fieldProps} name='amount' label='Amount' required={true} />
            </Grid>

            {/* <Grid item >
              <AmountField {...props.fieldProps} name='principalAmount' label='Principal' required={true} />
            </Grid> */}

            {/* <Grid item >
              <AmountField {...props.fieldProps} name='paymentAmount' label='Payment' required={true} />
            </Grid> */}

          </Grid>

          <FormSpacer />

          <ButtonBar>
            <PrimaryButton disabled={!submitEnabled}
            onClick={(event) => handleSubmit(event, props)} />
          </ButtonBar>

        </FormLayout>

        <DisplayState title='state' state={{ state, formState }} open={true} />
        </>
      );}}

    </HooksForm>
    </>
  );
}

export default CreatePayment;

CreatePayment.propTypes = {
  location: PropTypes.shape(),
  // isValidated: PropTypes.bool,
  // values: PropTypes.shape(),
  fieldProps: PropTypes.shape(),
  // buttonProps: PropTypes.shape(),
  // stateProps: PropTypes.shape(),
  // amount: PropTypes.number(),
  authorizedHandler: PropTypes.shape(),

};
