import { decodeTime } from 'ulid';
import dayjs from 'dayjs';
import cloneDeep from 'lodash-es/cloneDeep';
// import { AuthorizedHandler } from '@org/client-graphql';
// import { isDefined } from '@org/common-tools';

// Item

export let fields = [
  {
    type: 'text',
    name: 'referenceType',
    label: 'Reference Type',
    initial: "",
  },
  {
    type: 'text',
    name: 'referenceId',
    label: 'Reference Id',
    initial: "",
  },
  {
    type: 'text',
    name: 'eventDate',
    label: 'Event Date',
    initial: "",
  },
  {
    type: 'amount',
    name: 'amount',
    label: 'Amount',
    initial: 0.00,
  },
];

// Manage

export const columns = [
  { field: 'referenceType', headerName: 'Reference Type', width: 180 },
  { field: 'referenceId', headerName: 'Reference ID', width: 260 },
  { field: 'eventDate', headerName: 'Date', width: 110, type: 'date' },
  { field: 'amount', headerName: 'Amount', width: 150, type: 'number' },
];

export async function getRows(props) {
  // let authorizedHandler = new AuthorizedHandler({ location: props.location, history: props.history });

  let { authorizedHandler } = props; 

  try {
    let transactions = await authorizedHandler.listTransactions();

    transactions = cloneDeep(transactions);

    transactions.forEach(loan => {
      if (loan.creationDate?.id)
        loan.creationDate = dayjs(decodeTime(loan.id)).format('YY-MM-DD hh:mm a');
      else
        loan.creationDate = 'YY-MM-DD hh:mm a';
    });

    return transactions;
  } catch (error) {
    console.error(error.message);
    return [];
  }
}

export async function refreshRows(props) {
  let { authorizedHandler } = props;

  let transactions = await authorizedHandler.refreshTransactions();

  transactions = cloneDeep(transactions);

  transactions.forEach(transaction => {
    transaction.creationDate = dayjs(decodeTime(transaction.id)).format('YY-MM-DD hh:mm a');
  });

  return transactions;
}

export async function deleteRows(props) {
  let { authorizedHandler, selections } = props;

  if (selections.length > 1) {
    // batchDelete
    let input = selections.map(item => item.id);
    console.log('input', input);
    return authorizedHandler.batchDeleteTransactions(input);
  } else { // just 1 selection
    let selection = selections[0];
    let input = {
      itemType: selection.itemType,
      id: selection.id,
    };
    // let response = await authorizedHandler.deleteLoan(input);
    return authorizedHandler.deleteTransaction(input);
  }
}

export async function createItem(props) {
  // console.log('Loans', 'createItem', props);
  let { authorizedHandler, input } = props;

  return authorizedHandler.createTransaction(input);
}

export async function updateItem(props) {
  let { authorizedHandler, input } = props;

  return authorizedHandler.updateTransaction(input);
}

export async function handleRowClick(event) {
  alert(JSON.stringify(event.row, null, ' '));
}
