import { decodeTime } from 'ulid';
import dayjs from 'dayjs';
import { AuthorizedHandler } from '@org/client-graphql/authorized/handler';

export const columns = [
  // { field: 'id', headerName: 'ID', width: 10 },
  { field: 'email', headerName: 'Email', width: 250 },
  { field: 'creationDate', headerName: 'Creation Date', width: 180, type: 'date' },
  { field: 'expirationDate', headerName: 'Expiration Date', width: 180, type: 'date' },
  { field: 'isActive', headerName: 'Active', width: 120, type: 'boolean' },
  { field: 'fetchCount', headerName: '# Fetches', width: 140, type: 'number' },
  // { field: 'userId', headerName: 'User ID', width: 260 },
];

export async function getRows(props) {

  let authorizedHandler = new AuthorizedHandler({ location: props.location, history: props.history });

  let sessions = await authorizedHandler.listSessions({ limit: 20 });

  let rows = [], row;

  sessions.forEach(session => {

    let creationDate = dayjs(decodeTime(session.id)).format('YY-MM-DD hh:mm a');
    let expirationDate = dayjs(session.expirationDate).format('YY-MM-DD hh:mm a');

    let now = new Date().getTime();
    // let exp = (new Date(expirationDate)).getTime();
    let isActive = now < session.expirationDate ? true : false;
    // console.log(expirationDate, session.expirationDate, now, now < session.expirationDate, isActive);

    row = {
      id: session.id,
      email: session.email,
      creationDate,
      expirationDate,
      fetchCount: session.fetchCount,
      isActive,
      userId: session.userId,
    };

    rows.push(row);

  });

  return rows;
}

export function handleRowClick(event) {
  alert(JSON.stringify(event.row, null, ' '));
}
