import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { Button } from '@org/client-components-core/buttons/Buttons';

export function LinkButton(props) {
  let { to, ...rest } = props;

  return (
    <Button
      component={RouterLink}
      to={to}
      {...rest}
    />
  );
}

LinkButton.propTypes = {
  name: PropTypes.string,
  to: PropTypes.string.isRequired,
};

export function SmallLinkButton(props) {
  return (
    <LinkButton size='small' {...props} />
  );
}
