import React, { useState } from 'react';
import PropTypes from 'prop-types';
// import { Link as RouterLink } from 'react-router-dom';
// import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
// import Link from '@material-ui/core/Link';
// import { SmallStateLinkButton } from '@org/client-components-core/buttons/StateLinkButtons';
import { CheckAdornment } from '@org/client-components-custom';
// import { styles } from '@org/client-libs-styles';
// import { useStyles } from './styles';

// const useStyles = makeStyles(styles);
// let useStyles = makeStyles((theme) => ({
//   root: {
//     margin: theme.spacing(0, 0, 2, 0),
//   }
// }));

function PasswordAdornment(props) {
  let { showPassword, handleShowPassword } = props;

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return(
    <InputAdornment position='end' >
      <IconButton
        name='showPassword'
        aria-label='toggle password visibility'
        edge='end'
        onClick={() => handleShowPassword(!showPassword)}
        onMouseDown={handleMouseDownPassword}
      >
        {showPassword ? <VisibilityOff />: <Visibility />}
      </IconButton>
    </InputAdornment>
  );
}

PasswordAdornment.propTypes = {
  showPassword: PropTypes.bool,
  handleShowPassword: PropTypes.func,
};

export function PasswordField(props) {
  // console.log('PasswordField', 'props', props);
  let [ showPassword, setShowPassword ] = useState(false);
  // const classes = useStyles();

  let {
    name, label, state,
    handleChange, handleBlur,
    noMargin,
    // style,
    ...rest
  } = props;

  let { values, validated, required, errors } = state;

  if (props.required)
    throw new Error(`PasswordField Error: 'required' must be passed in 'requiredValues' object`);

  name = name ? name : 'password';

  label = label ? label : 'Password'; // we need this to add the '*' if input is required

  let errorStyle = errors[name] ? { color: '#f44336' } : {};

  let isValid = validated && validated[name] && !props.disabled;

  function handleShowPassword(show) {
    // console.log('handleShowPassword', show);
    setShowPassword(show);
  }

  let error = errors[name] ? true : false;

  noMargin = noMargin ? { margin: 0 } : {};

  let adornmentProps = { showPassword, handleShowPassword };
  let adornment;
  if (isValid)
    adornment = <><PasswordAdornment {...adornmentProps} /><CheckAdornment show={isValid} /></>;
  else
    adornment = <PasswordAdornment {...adornmentProps} />;
  // console.log('adornment', adornment);

  return(
    <>
    <FormControl
      // className={classes.input}
      // variant='outlined' fullWidth margin='normal' required={required} >
      // variant='outlined' fullWidth margin='dense' required={required} >
      variant='outlined'
      fullWidth
      required={required[name] ? true : false}
      size={'small'}
      style={noMargin}
    >
      {/* <InputLabel htmlFor={name} style={errorStyle} >{text}</InputLabel> */}
      {/* <InputLabel htmlFor={name} >{text}</InputLabel> */}
      <InputLabel htmlFor={name} style={errorStyle} >{label}</InputLabel>
      <OutlinedInput
        id={name}
        name={name}
        // label={props.label? props.label : 'Password'}
        type={showPassword ? 'text' : 'password'}
        // type={type}
        // value={value}
        value={values[name]}
        // placeholder={'Password'}
        // label={required ? text + ' *' : text} // this is only used to calculate the label length, InputLabel handles the style
        label={required[name] ? label + ' *' : label} // this is only used to calculate the label length, InputLabel handles the style
        // onChange={(event) => handleChange(event, type)}
        onChange={(event) => handleChange(event, name)}
        onBlur={(event) => handleBlur(event, name)}
        // error={error}
        error={error}
        // endAdornment={<PasswordAdornment {...adornmentProps} />}
        endAdornment={adornment}
        disabled={props.disabled}
        // required={required ? required : true}
        required={required[name] ? true : false}
        {...rest}
      />
      {/* {error && <FormHelperText error={true} >{errors[name]}</FormHelperText>} */}
      {error && <FormHelperText error={true} >{errors[name]}</FormHelperText>}
    </FormControl>
    </>
  );
}

export default PasswordField;

PasswordField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  text: PropTypes.string,
  state: PropTypes.shape(),
  values: PropTypes.shape(),
  errors: PropTypes.shape(),
  validated: PropTypes.shape(),
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  showPassword: PropTypes.bool,
  handleShowPassword: PropTypes.func,
  handleMouseDownPassword: PropTypes.func,
  noMargin: PropTypes.bool,
  children: PropTypes.any,
};

// export function ForgotPassword(props) {
//   let theme = useTheme();
//   return(
//     <div style={{ marginTop: theme.spacing(1) }} >
//       <Link variant='body2' component={RouterLink} to='/sign-up' >
//         {"Don't have an account? Sign Up"}
//       </Link>
//       <SmallStateLinkButton title='Forgot Password?' to='/reset-password' {...props} />
//     </div>
//   );
// }
