import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { ulid } from 'ulid';
import dayjs from 'dayjs';
import round from 'lodash-es/round';
import * as yup from 'yup/es';
import cloneDeep from 'lodash-es/cloneDeep';
import { DataGrid as MuiDataGrid } from '@material-ui/data-grid';
import { useForm } from '@org/client-components-core/form/useForm';
import { Stack } from '@org/client-components-core/layout/Stack';
import { SelectTextField } from '@org/client-components-core/inputs/SelectTextField';
import { PrimaryButton } from '@org/client-components-core/buttons/Buttons';
// import { BackdropLoader } from '@org/client-components-core/feedback/Loaders';
import { Page, Heading, FlexRow, FormError } from '@org/client-components-custom';
import { useValidate } from '@org/common-yup';
import { LoanClass } from '@org/common-classes/Loan';
import { NoteClass } from '@org/common-classes/Note';
import { TransactionClass } from '@org/common-classes/Transaction';
import { DisplayState } from '@org/client-components-utils/DisplayState';
import theme from '@org/client-libs-themes';

import {
  columns as alertColumns, getRows as getAlerts, refreshRows as refreshAlerts, // handleRowClick as handleAlert,
} from './manage/Alerts';

import {
  columns as cashflowColumns, getRows as getCashflows, refreshRows as refreshCashflows, deleteRows as deleteCashflows,
} from './manage/Cashflows';

import {
  columns as emailColumns, getRows as getEmails, // handleRowClick as handleEmail,
} from './manage/Emails';

import {
  columns as invitationColumns, getRows as getInvitations, // handleRowClick as handleInvitation,
} from './manage/Invitations';

import {
  columns as loanColumns, getRows as getLoans, refreshRows as refreshLoans, deleteRows as deleteLoans,
} from './manage/Loans';

import {
  columns as noteColumns, getRows as getNotes, refreshRows as refreshNotes, deleteRows as deleteNotes, // handleRowClick as handleNote,
} from './manage/Notes';

import {
  columns as paymentColumns, getRows as getPayments, refreshRows as refreshPayments, deleteRows as deletePayments, // handleRowClick as handleNote,
} from './manage/Payments';

import {
  columns as sessionColumns, getRows as getSessions, // handleRowClick as handleSession,
} from './manage/Sessions';

import {
  columns as tradeColumns, getRows as getTrades, refreshRows as refreshTrades, deleteRows as deleteTrades,
} from './manage/Trades';

import {
  columns as transactionColumns, getRows as getTransactions, refreshRows as refreshTransactions, deleteRows as deleteTransactions,
} from './manage/Transactions';

import {
  columns as userColumns, getRows as getUsers, // handleRowClick as handleUser,
} from './manage/Users';
import { authorizedHandler } from '@org/client-graphql/authorized/handler';

let rows = [], columns = [], getRows, refreshRows, deleteRows;
// let handleRowClick;
// let selections = [];

const PADDING_WIDTH = 2 * 16;
const SORT_WIDTH = 26;
const NUM_ROWS = 10;
// const ROW_HEIGHT = 52;
const ROW_HEIGHT = 36;
const SCROLL_HEIGHT = 15;
const BODY_HEIGHT = NUM_ROWS * ROW_HEIGHT; // 5 * 36 = 180
// border + header + body + footer
// const TABLE_HEIGHT = 2 + 56 + BODY_HEIGHT + SCROLL_HEIGHT + 52;

const HEADER_HEIGHT = 39;
const FOOTER_HEIGHT = 72;
const TABLE_HEIGHT = HEADER_HEIGHT + BODY_HEIGHT + SCROLL_HEIGHT + FOOTER_HEIGHT + 2;

const typeSelectItems = [
  { label: 'Alerts', value: 'ALERT' },
  { label: 'Cashflows', value: 'CASHFLOW' },
  { label: 'Emails', value: 'EMAIL' },
  { label: 'Invitations', value: 'INVITATION' },
  { label: 'Loans', value: 'LOAN' },
  { label: 'Notes', value: 'NOTE' },
  { label: 'Payments', value: 'PAYMENT' },
  { label: 'Sessions', value: 'SESSION' },
  { label: 'Trades', value: 'TRADE' },
  { label: 'Transactions', value: 'TRANSACTION' },
  { label: 'Users', value: 'USER' },
];

// let initialValues = {
//   actionSelect: "",
//   typeSelect: "",
// };

// let validationShape = {
//   actionSelect: yup.string(),
//   typeSelect: yup.string(),
// };
// const validationSchema = yup.object().shape(validationShape);

function DataGrid(props) {
  return (
    <>
    <div style={{ height: TABLE_HEIGHT, width: '100%' }} >
    {/* <div style={{ width: '100%' }} > */}
      <MuiDataGrid
        // rows={props.rows}
        // columns={props.columns}
        pageSize={NUM_ROWS}
        // onRowClick={(event) => props.handleRowClick(event)}
        {...props}
      />
    </div>
    </>
  );
}

DataGrid.propTypes = {
  rows: PropTypes.array,
  columns: PropTypes.array,
  handleRowClick: PropTypes.func,
};

function getCellWidth(length) {
  let width = (length * 8) + PADDING_WIDTH + SORT_WIDTH;
  // console.log('getCellWidth', length, width);
  return width;
}

function getColumnWidths() {

  let row, col;
 
  // only including the fields shown (in columns)
  for (let c in Object.values(columns)) {
    col = Object.values(columns)[c];

    // start with the headers
    col.width = getCellWidth(col.headerName.length);

    // then go throuch each row
    for (let r in rows) {
      row = rows[r];
      // console.log('row', row);

      let value = row[col.field];
      // console.log('value', col.field, value);

      let length = 0;

      if (!value) // null
        value = '';

      if (col.type === 'date')
        // length = (dayjs(value).format('MM/DD/YY hh:mm a')).length;
        length = 16;
      else if (col.type === 'boolean')
        // length = (value.toString()).length;
        length = 3;
      else if (col.type === 'number')
        length = (value.toString()).length;
      else
        length = value.length;

      let width = getCellWidth(length);

      col.width = Math.max(col.width, width);
      col.minWidth = Math.max(col.width, width);
      // console.log(r, c, length, col.width);
    }

  }
}

export function Manage(props) {
  const [ state, setState ] = useState({
    isLoading: true,
    // values: { type: "", action: "" },
    formMessage: null,
    formError: null,
  });
  // const [ actionEnabled, setActionEnabled ] = useState(false);
  const [ selections, setSelections ] = useState([]);
  const isMounted = useRef(false);
  // const [ validate ] = useValidate(validationSchema);
  // const { fieldProps, setValues } = useForm({ initialValues, validate });
  const [ typeSelectValue, setTypeSelectValue ] = useState("");
  // console.log('Item', 'typeSelectValue', `'${typeSelectValue}'`);

  // let { authorizedHandler } = props;
  let { authenticatedHandler } = props;

  // let { values } = fieldProps.state;
  // let { authorizedHandler } = props;
  // const [ selectValue, setSelectValue ] = useState("");

  // if (typeSelectValue === 'LOAN') {
  //   getRows = getLoans;
  //   refreshRows = refreshLoans;
  //   columns = loanColumns;
  // }

  useEffect(() => {
    isMounted.current = true;
    window.scrollTo(0, 0);
    rows = [];
    columns = [];

    if (isMounted.current)
      setState({ ...state, isLoading: false});

    return () => {
      // setValues(cloneDeep(initialValues));
      setTypeSelectValue("");
      rows = [];
      columns = [];
      getRows = null;
      refreshRows = null;
      isMounted.current = false;
    };
  }, []);

  async function handleTypeSelectChange(event) {
    console.info('handleTypeSelectChange', event.target);
    // let name = event.target.name;
    let value = event.target.value;
    // setState({ ...state, isLoading: true, values: { [name]: value } });
    if (isMounted.current) {
      setState({ ...state, isLoading: true });
      setSelections([]);
      // setValues({ [name]: value, actionSelect: "" });
      setTypeSelectValue(value);
      rows = [];
    }
      // setState({ ...state, isLoading: true, values: { ...state.values, type: value } });

    try {
      if (value === 'ALERT') {
        // rows = await getEmails(props);
        getRows = getAlerts;
        columns = alertColumns;
        refreshRows = refreshAlerts;
        deleteRows = null;
        // handleRowClick = handleEmail;
      } else if (value === 'CASHFLOW') {
        // rows = await getEmails(props);
        getRows = getCashflows;
        columns = cashflowColumns;
        refreshRows = refreshCashflows;
        deleteRows = deleteCashflows;
      } else if (value === 'EMAIL') {
        // rows = await getEmails(props);
        getRows = getEmails;
        columns = emailColumns;
        refreshRows = null;
        deleteRows = null;
        // handleRowClick = handleEmail;
      } else if (value === 'INVITATION') {
        // rows = await getInvitations(props);
        columns = invitationColumns;
        getRows = getInvitations;
        refreshRows = null;
        deleteRows = null;
      } else if (value === 'LOAN') {
        // rows = await getLoans(props);
        // actionTypes = loanActionTypes;
        columns = loanColumns;
        getRows = getLoans;
        refreshRows = refreshLoans;
        deleteRows = deleteLoans;
        // handleRowClick = handleLoan;
      } else if (value === 'NOTE') {
        // rows = await getNotes(props);
        columns = noteColumns;
        getRows = getNotes;
        refreshRows = refreshNotes;
        deleteRows = deleteNotes;
      } else if (value === 'PAYMENT') {
        // rows = await getNotes(props);
        columns = paymentColumns;
        getRows = getPayments;
        refreshRows = refreshPayments;
        deleteRows = deletePayments;
      } else if (value === 'SESSION') {
        getRows = getSessions;
        columns = sessionColumns;
        refreshRows = null;
        deleteRows = null;
      } else if (value === 'TRADE') {
        getRows = getTrades;
        columns = tradeColumns;
        refreshRows = refreshTrades;
        deleteRows = deleteTrades;
      } else if (value === 'TRANSACTION') {
        getRows = getTransactions;
        columns = transactionColumns;
        refreshRows = refreshTransactions;
        deleteRows = deleteTransactions;
      } else if (value === 'USER') {
        columns = userColumns;
        getRows = getUsers;
        refreshRows = null;
        deleteRows = null;
      }

      rows = await getRows(props);

      // await getColumnWidths(columns, rows);
      // getColumnWidths();
      // console.log(columns, rows);

      // setState(prevState => ({ ...prevState, isLoading: false, formError: null }));
      setState({ ...state, isLoading: false, formError: null });
    } catch (error) {
      console.error(error);
      setState({ ...state, isLoading: false, formError: error });
    }
  }

  async function handleTypeSelectBlur(event) {
    console.log(event);
  }

  function handleView() {
    // console.log(event);
    // let index = rows.findIndex(row => selections[0] === row.id);
    // let row = rows[index];
    // alert(JSON.stringify(row, null, ' '));
    alert(JSON.stringify(selections[0], null, ' '));
  }

  // async function handleAction(event) {
  //   console.info('handleSelect', event.target);

  //   if (isMounted.current) {
  //     // setState({ ...state, values: { ...state.values, action: event.target.value }});
  //     setValues({ [event.target.name]: event.target.value });
  //   }
  // }

  // async function handleSubmit(event) {
  //   console.info('handleSubmit', event.target);
  // }

  async function handleRefresh() {
    if (getRows) {
      if (isMounted.current)
        setState({ ...state, isLoading: true });

      // rows = await getRows(props);
      rows = await refreshRows(props);

      // await getColumnWidths(columns, rows);
      // getColumnWidths();
      // console.log(columns, rows);

      if (isMounted.current)
        setState(prevState => ({ ...prevState, isLoading: false, formError: null }));
    } else
      if (isMounted.current)
        setTypeSelectValue("");
  }

  function handleUpdate(event) {
    console.log('handleUpdate', event);
    console.log('handleUpdate', selections);
    props.history.push({ pathname: '/admin/item', state: { ...selections[0] } });
  }

  async function handleDelete(event) {
    console.log('handleDelete', event.target);
    console.log('handleDelete', selections);

    setState({ ...state, isLoading: true });

    try {
      await deleteRows({ ...props, selections });

      rows = await refreshRows(props);

      setState({ ...state, isSubmitting: false, isCompleted: true, formMessage: 'Item successfully created', formError: null });
    } catch (error) {
      console.error('Item', 'handleSubmit', error);
      setState({ ...state, isSubmitting: false, formMessage: null, formError: error });
    }

    setState({ ...state, isLoading: false });
  }

  async function handleRowClick(event) {
    console.log('handleRowClick', event);
    // setActionEnabled(true);
    // authenticatedHandler.listNoteBalances();
    authenticatedHandler.listTradeTotals();
  }

  // Does not work when onSelectionModelChange is enabled
  async function handleRowSelected(event) {
    console.log('handleRowSelected', event);
    // setActionEnabled(true);
  }

  function handleCreateLoanCashflow(_event) {
    // console.log('handleCreateNoteCashflow', event.target);
    // console.log('handleCreateNoteCashflow', selections);

    let referenceItem = selections[0];
    props.history.push({ pathname: '/admin/item', state: { action: 'createLoanCashflow', referenceItem } });
  }

  async function handleCreateLoanCashflows(_event) {
    // console.log('handleCreateNoteCashflow', event.target);
    // console.log('handleCreateNoteCashflow', selections);

    setState({ ...state, isLoading: true });

    let referenceItem = selections[0];

    let loanClass = new LoanClass(referenceItem);
    // authorizedHandler.createLoanCashflows({ id: referenceItem.id });
    let input;
    while (loanClass.attributes.currentTerm > 0) { 
      input = loanClass.getNextCashflow();
      console.log('Manage', 'handleCreateLoanCashflows', 'input', input);
      await authorizedHandler.createCashflow(input);
      loanClass.applyNextCashflow(input);
    }

    authorizedHandler.refreshCashflows();

    setState({ ...state, isLoading: false });
  }

  // Create a Loan payment from a Loan cashflow
  async function handleCreateLoanPayment(_event) {
    // console.log('handleCreateNoteCashflow', event.target);
    console.log('handleCreateNoteCashflow', selections);

    setState({ ...state, isLoading: true });

    // let input = { ...selections[0] };
    let selection = { ...selections[0] };

    // // URGENT Replace with class type
    // delete input.__typename;
    // delete input.creationDate;
    // delete input.balance;

    let { eventDate } = selection;

    let transactionId = ulid();
    let accountType = selection.referenceType;
    let accountId = selection.referenceId;
    let debit = 0.0;
    let credit = selection.paymentAmount;
    let amount = selection.paymentAmount;

    let input = {
      transactionId,
      eventDate,
      accountType,
      accountId,
      categoryType: 'ASSET',
      categoryName: 'CASH',
      // description: referenceType + ' ' + itemType,
      // description: accountType + ' ' + 'PAYMENT',
      debit,
      credit,
      amount,
      // status,
      referenceType: selection.itemType,
      referenceId: selection.id,
    };
    console.log('handleCreateLoanPayment', input);

    // authorizedHandler.createLoanPayment({ id: referenceItem.id });
    await authorizedHandler.createPayment(input);

    setState({ ...state, isLoading: false });
  }

  // Create a Note payment from a Loan payment
  async function handleCreateNotePayment(_event) {
    // console.log('handleCreateNoteCashflow', event.target);
    // console.log('handleCreateNoteCashflow', selections);

    setState({ ...state, isLoading: true }); 

    let selection = { ...selections[0] };

    // let { accountId } = selection;
    console.log('Manage', 'handleCreateNotePayment', 'selection', selection);

    let loan = await authorizedHandler.getLoan(selection.accountId);
    console.log('Manage', 'handleCreateNotePayment', 'loan', loan);

    let transactionId = ulid();

    // Get all Notes referencing the selected Loan Loan
    // let notes = await authorizedHandler.listNotes({ filter: { referenceType, referenceId }});
    let notes = await authorizedHandler.listNotes();
    console.log('Manage', 'handleCreateNotePayment', 'notes', notes);

    // let notesThatReference = [];
    for (let index in notes) {
      let note = notes[index];
      let ratio = note.originalBalance / loan.originalBalance;
      if (note.referenceId === selection.accountId) { // URGENT Add filters to listItemsInput
        // notesThatReference.push(note);
        let eventDate = dayjs(selection.eventDate);
        console.log(selection.eventDate, eventDate.date(), note.paymentDelay, selection.principalAmount, ratio, 0 * ratio);
        eventDate = eventDate.date(eventDate.date() + note.paymentDelay);
        eventDate = eventDate.format('YYYY-MM-DD');
        let payment = selection.amount * ratio,

        // Debit funds from the loan account
        input = {
          transactionId,
          eventDate,
          accountType: 'LOAN',
          accountId: loan.id,
          categoryType: 'ASSET',
          categoryName: 'CASH',
          debit: payment,
          credit: 0.0,
          amount: payment,
          referenceType: 'PAYMENT', // Item used to create new item, e.g. 
          referenceId: selection.id,
        };
        // await authorizedHandler.createPayment(input);
        let response = authorizedHandler.createPayment(input);
        console.log('handleCreateNoteCashflow', response);

        // And credit the note accounts which reference the loan (can be more than one)
        input = {
          transactionId,
          eventDate,
          accountType: 'NOTE',
          accountId: note.id,
          categoryType: 'ASSET',
          categoryName: 'CASH',
          debit: 0.0,
          credit: payment,
          amount: payment,
          referenceType: 'PAYMENT', // Same as item above
          referenceId: selection.id,
        };
        console.log('Manage', 'handleCreateNotePayment', 'input', input);
        // await authorizedHandler.createPayment(input);
        authorizedHandler.createPayment(input);
      }
    }

    // // URGENT Replace with class type
    // delete input.__typename;
    // delete input.creationDate;

    // // authorizedHandler.createLoanPayment({ id: referenceItem.id });
    // await authorizedHandler.createPayment(input);

    await authorizedHandler.refreshPayments();

    setState({ ...state, isLoading: false });
  }

  // Allocate Note payment to platform fees and User payments
  async function handleAllocateNotePayment(_event) {

    let selection = selections[0];
    console.log('Manage', 'handleAllocateNotePayment', 'selection', selection);
    let { referenceType, referenceId, eventDate }= selection;

    // Get the Note
    let note = await authorizedHandler.getNote({ id: selection.accountId });
    console.log('Manage', 'handleAllocateNotePayment', 'note', note);

    let transactionId = ulid();

    // Allocate platform fees
    let { currentBalance, managementRate } = note;
    // URGENT Add managementFee calculation to Note class
    let managementFee = round(currentBalance * managementRate / 1200, 2);
    console.log('Manage', 'handleAllocateNotePayment', 'managementFee', managementFee);
    let input = {
      // userId: 'PLATFORM',
      // // accountId: trade.userId,
      // referenceType,
      // referenceId,
      // eventDate,
      // interestAmount: managementFee,
      // principalAmount: 0,
      // paymentAmount: managementFee,
      transactionId,
      eventDate,
      accountType: 'PLATFORM',
      accountId: "FEES",
      categoryType: 'ASSET',
      categoryName: 'CASH',
      debit: 0.0,
      credit: managementFee,
      amount: managementFee,
      referenceType: 'PAYMENT', // Same as item above
      referenceId: selection.id,
    };
    await authorizedHandler.createPayment(input);

    let totalPayments = managementFee;

    let paymentRatio = (selection.paymentAmount - managementFee) / selection.paymentAmount;

    // Allocate net payment pro-rata to Note holders
    let trades = await authorizedHandler.listTrades();
    console.log('Manage', 'handleAllocateNotePayment', 'trades', trades);

    let amountsPaid = 0;
    for (let index in trades) {
      let trade = trades[index];
      console.log('Manage', 'handleAllocateNotePayment', 'trade', trade);
      let ratio = trade.amount / note.originalBalance * paymentRatio;
      let payment = selection.amount * ratio;
      totalPayments += payment;
      let input = {
        // userId: trade.userId,
        // // accountId: trade.userId,
        // referenceType,
        // referenceId,
        // eventDate,
        // interestAmount: round(selection.interestAmount * ratio, 2),
        // principalAmount: round(selection.principalAmount * ratio, 2),
        // paymentAmount: round(selection.paymentAmount * ratio, 2),
        // fromAccountType: referenceType,
        // fromAccountId: referenceId,
        // toAccountType: 'USER',
        // toAccountId: trade.userId,
        transactionId,
        eventDate,
        accountType: 'PAYMENT',
        accountId: "PLATFORM",
        categoryType: 'ASSET',
        categoryName: 'CASH',
        debit: 0.0,
        credit: payment,
        amount: payment,
        referenceType: 'PAYMENT', // Same as item above
        referenceId: selection.id,
      };
      console.log('Manage', 'handleAllocateNotePayment', 'input', input);
      await authorizedHandler.createPayment(input);
    }

    // Debit the Note account for the total payments
    input = {
      transactionId,
      eventDate,
      accountType: 'PAYMENT',
      accountId: selection.id,
      categoryType: 'ASSET',
      categoryName: 'CASH',
      debit: totalPayments,
      credit: 0.0,
      amount: totalPayments,
      referenceType: 'PAYMENT', // Same as item above
      referenceId: selection.id,
    };
    await authorizedHandler.createPayment(input);

    // Allocate the balance to the Fund?
  }

  function handleCreateNote(_event) {
    // console.log('handleCreateNote', event.target);
    // console.log('handleCreateNote', selections);

    let referenceItem = selections[0];
    props.history.push({ pathname: '/admin/item', state: { action: 'createNote', referenceItem } });
  }

  async function handleCreateNoteCashflows(_event) {
    // console.log('handleCreateNoteCashflow', event.target);
    console.log('handleCreateNoteCashflow', selections);

    let note = selections[0];

    let noteClass = new NoteClass(note);

    console.log('Manage', 'handleCreateNote', noteClass.values());

    let loanCashflows = await authorizedHandler.refreshCashflows();

    let input;
    loanCashflows.forEach(async (loanCashflow) => {
      if (loanCashflow.referenceType === note.referenceType && loanCashflow.referenceId === note.referenceId) {
        input = noteClass.getCashflow(loanCashflow);
        console.log('Manage', 'handleCreateNoteCashflows', 'input', input);
        await authorizedHandler.createCashflow(input);
        noteClass.applyCashflow(input);
      }
    });

    authorizedHandler.refreshCashflows();
  }

  let typeSelectProps = {
    name: 'typeSelect',
    label: 'Item Type',
    handleChange: handleTypeSelectChange,
    handleBlur: handleTypeSelectBlur,
    state: {},
    items: typeSelectItems,
    value: typeSelectValue,
    // noForm: true,
  };

  // let typeSelected = values.typeSelect !== '' ? true : false;
  // let refreshEnabled = typeSelected && !state.isLoading;
  let refreshEnabled = !state.isLoading && typeSelectValue !== "";
  // let actionEnabled = typeSelected && !state.isLoading;
  // let actionSelected = values.actionSelect !== '' ? true : false;
  // let submitEnabled = actionSelected && !state.isLoading;

  let viewEnabled = !state.isLoading && selections.length === 1;
  let updateEnabled = !state.isLoading && selections.length === 1;
  let deleteEnabled = !state.isLoading && selections.length;
  // console.log(viewEnabled);

  return(
    <>
    {/* {state.isLoading &&
    <BackdropLoader />} */}

    <Page id='admin-manage-records' >

      <Heading>{'Manage Records'}</Heading>

      <Stack>

        <SelectTextField { ... typeSelectProps } style={{ width: theme.spacing(24) }} />
        <PrimaryButton title='Refresh' onClick={handleRefresh} disabled={!refreshEnabled} />

        {/* <SelectTextField
          {...fieldProps}
          name={'actionSelect'}
          label={'Select Action'}
          items={actionTypes}
          handleChange={handleAction}
          disabled={!actionEnabled}
          style={{ width: theme.spacing(24) }}
        />
        <PrimaryButton title='Submit' onClick={handleSubmit} disabled={!submitEnabled} /> */}

        <PrimaryButton title='View' onClick={handleView} disabled={!viewEnabled} />
        <PrimaryButton title='Update' onClick={handleUpdate} disabled={!updateEnabled} />
        <PrimaryButton title='Delete' onClick={handleDelete} disabled={!deleteEnabled} />

      </Stack>
      <Stack>

        {typeSelectValue === 'LOAN' &&
        <>
        <PrimaryButton title='Create Note' onClick={handleCreateNote} disabled={!updateEnabled} />
        <PrimaryButton title='Create Cashflows' onClick={handleCreateLoanCashflows} disabled={!updateEnabled} />
        <PrimaryButton title='Create Payment' onClick={handleCreateLoanPayment} disabled={!updateEnabled} />
        </>}

        {(typeSelectValue === 'NOTE' && selections.length === 1) &&
        <>
        <PrimaryButton title='Create Cashflows' onClick={handleCreateNoteCashflows} />
        {/* <PrimaryButton title='Create Payment' onClick={handleCreateNotePayment} /> */}
        </>}

        {(typeSelectValue === 'CASHFLOW'  && selections.length === 1 && selections[0].referenceType === 'LOAN') &&
        <>
        <PrimaryButton title='Create Loan Payment' onClick={handleCreateLoanPayment} disabled={!updateEnabled} />
        </>}

        {(typeSelectValue === 'PAYMENT' && selections.length === 1 && selections[0].accountType === 'LOAN') &&
        <>
        <PrimaryButton title='Create Note Payment' onClick={handleCreateNotePayment} />
        </>}

        {(typeSelectValue === 'PAYMENT' && selections.length === 1 && selections[0].accountType === 'NOTE') &&
        <>
        <PrimaryButton title='Alocate Note Payment' onClick={handleAllocateNotePayment} />
        </>}

      </Stack>

      {state.formError &&
      <FlexRow style={{ justifyContent: 'center' }} >
        <FormError message={state.formError.message} />
      </FlexRow>}

      <div style={{ height: TABLE_HEIGHT, width: '100%' }}>
        <MuiDataGrid
          loading={state.isLoading}
          rows={rows}
          columns={columns}
          pageSize={NUM_ROWS}
          rowsPerPageOptions={[NUM_ROWS]}
          editMode='row'
          onRowSelected={handleRowSelected}
          // onSelectionChange={e => console.log('onSelectionChange', e)}
          checkboxSelection
          disableSelectionOnClick={true}
          // selectionModel={selectionModel}
          // onSelectionModelChange={(e) => console.log(e)}
          onSelectionModelChange={(ids) => {
            // This fires no matter which part of row was clicked
            console.log('onSelectionModelChange', ids, rows);
            // selections = event;

            // setSelections([]);
            let updatedSelections = [];
            for (let id of ids) {
              // let id = ids[i];
              // console.log('onSelectionModelChange', i, id);
              let index = rows.findIndex(row => row.id === id);
              let row = rows[index];
              console.log('onSelectionModelChange', index, row);
              updatedSelections.push(row);
            }
            console.log('onSelectionModelChange', updatedSelections);
            setSelections(updatedSelections);
            // const selectedIDs = new Set(event.selectionModel);
            // const selectedRowData = rows.filter((r) =>
            //   selectedIDs.has(r.id.toString())
            // );
            // console.log(selectedRowData);
            // return selectedRowData;
            // return [ 1 ];
          }}
          // onRowClick={(event) => handleRowClick(event, props)}
          onRowClick={handleRowClick} // does not fire when checkbox clived
          density='compact'
          // disableSelectionOnClick={false}
        />
      </div>

      <DisplayState title='state' state={{ state, typeSelectValue, selections, columns, rows }} open={true} />

    </Page>
    </>
  );
}

export default Manage;

Manage.propTypes = {
  location: PropTypes.shape(),
  history: PropTypes.shape(),
  authorizedHandler: PropTypes.shape(),
};
