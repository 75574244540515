import React from 'react';
import PropTypes from 'prop-types';
// import { useTheme } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import { TextField } from '@org/client-components-core/inputs/TextField';

export function SelectTextField(props) {
  // console.log('SelectTextField', props);

  // let length = Math.max(props.title.label.length, props.state.values)

  // let theme = useTheme();
  // let { name, items, ...rest } = props;
  let { items, setValues, ...rest } = props;
  // let { items, handleBlur, ...rest } = props;
  // // let { name, state, items, ...rest } = props;
  // let { values, validated, required, errors } = state;

  // let id = (!props.id && name) ? name : throw new Error(`SelectTextField must receive 'name' prop`);
  // name = (!name && props.id) ? name : () => { throw new Error(`SelectTextField must receive 'id' prop`); };

  // let item = items.find(item => { return values[name] === item.value; });
  // let item = items[0];

  // let value = item?.value ? item?.value : ''; // in case items array is not available on initial render
  // console.log(values[name], item.value);

  // document.activeElement.blur();

  // this is designed to work with useForm and to trigger a blur and resulting
  function changeHandler(event, arg) {
    console.log('SelectTextField', 'changeHandler', event, arg);
    let values = { [arg]: event.target.value };
    let options = { isInitial: false, touched: true, shouldValidate: true };
    // console.log(values);
    setValues(values, options);
  }
  // rest = { ...rest, handleChange };

  let handleChange;

  if (!props.setValues)
    handleChange = props.handleChange;
  else
    handleChange = changeHandler;

  // console.log('SelectTextField', handleChange);

  return(
    // <TextField select endAdornment={<></>} id={id} name={name} value={value} values={values} >
    // <TextField select endAdornment={<></>} id={id} name={name} value={value} values={values} >
    <TextField
      select
      endAdornment={<></>} // don't show TextField endAdornment
      // handleBlur={(event) => { // blur passes the item object w/o this
      //   // event.target = { ...event.target, name: props.name, floatValue: value };
      //   console.log('NumberField', 'handleBlur', event.target, `'${props.name}'`, `'${value}'`);
      //   handleBlur(event, props.name);
      // }}
      // fullWidth
      // style={{ width: '100%', minWidth: 'fit-content', maxWidth: theme.spacing(40) }}
      {...rest}
      handleChange={handleChange} // override handleChange in TextField
    >
      {items.map(elem => (
        <MenuItem key={elem.value} value={elem.value} style={{ whiteSpace: 'nowrap' }} >
          {elem.label}
        </MenuItem>
      ))}
    </TextField>
  );
}

SelectTextField.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  values: PropTypes.shape(),
  items: PropTypes.array,
  setValues: PropTypes.func,
  handleChange: PropTypes.func,
};
