import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { default as MuiGrid } from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

export function Stack(props) {
  // console.log('Stack', props);
  let { direction, spacing, margin, children, ...rest } = props;

  spacing = spacing ? spacing : 1;
  // console.log(spacing);

  margin = margin != null ? margin : 2;

  const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      // flexDirection: direction ? direction : 'row',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      margin: theme.spacing(0, 0, margin, 0),
    },
    row: {
      flexDirection: 'row',
      '& > *': {
        margin: theme.spacing(0, spacing, 0, 0),
        '&:last-child': {
          marginRight: theme.spacing(0),
        },
      },
      // '&:last-child': {
      //   marginRight: theme.spacing(0),
      // },
    },
    column: {
      flexDirection: 'column',
      '& > *': {
        margin: theme.spacing(0, 0, spacing, 0),
        '&:last-child': {
          marginBottom: theme.spacing(0),
        },
      },
      // '&:last-child': {
      //   marginBottom: theme.spacing(0),
      // },
    },
  }));
  const classes = useStyles();

  let className = (direction === 'column')
    ? clsx(classes.root, classes.column)
    : clsx(classes.root, classes.row);

  // let childrenWithProps = React.cloneElement(children, { direction });
  const childrenWithProps = React.Children.map(
    children,
    (child, index) => {
      // console.log(child, index, typeof child);
      // when conditional rendering condition is false
      // props passes a child with value `false` in children
      if (!child)
        return null;

      // makes selected props available in child components
      return React.cloneElement(child, { index, direction });
    }
  );

  return(
    <MuiGrid className={className} {...rest} >
      {/* {children} */}
      {/* {children({ direction })} */}
      {childrenWithProps}
    </MuiGrid>
  );
}

Stack.propTypes = {
  direction: PropTypes.string,
  margin: PropTypes.number,
  spacing: PropTypes.number,
  children: PropTypes.any, // node,
};

export function Item(props) {
  // console.log('Item', props);
  let { children, ...rest } = props;

  // const useStyles = makeStyles((theme) => ({
  //   item: {
  //     margin: theme.spacing(0),
  //   }
  // }));
  // const classes = useStyles();

  return(
    <MuiGrid item {...rest} >
      {children}
    </MuiGrid>
  );
}

Item.propTypes = {
  children: PropTypes.any, // node,
};
