import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { default as MuiLink } from '@material-ui/core/Link';
import { Page, Heading, Section, SectionBody, FlexRow, FlexColumn } from '@org/client-components-custom';

export function Contact() {
  const [ state, setState ] = useState({ isLoading: true });

  useEffect(() => {
    let isMounted = true;
    window.scrollTo(0, 0);

    if (isMounted)
      setState({ ...state, isLoading: false });
    
    return () => { isMounted = false; };
  }, []);

  return (
    <Page id='company-contact' >

      <Heading>{'Contact'}</Heading>

      <Section>

        <SectionBody>

          <FlexRow style={{ justifyContent: 'center' }} >

            <FlexColumn style={{ alignItems: 'flex-Start', width: 'auto' }} >

              <Typography variant='body1' style={{ }} >{'Cubit Investments, LLC'}</Typography>

              <MuiLink href="http://maps.google.com/maps?q=710+Heather+Wood+Drive,+Grapevine,+TX+76051" target='_blank' rel="noopener noreferrer" style={{ display: 'flex', width: 'auto', minWidth: 'fit-content' }} >
                <div>
                  <Typography variant='body1' style={{ whiteSpace: 'nowrap' }} >{'710 Heather Wood Drive'}</Typography>
                  <Typography variant='body1' >{'Grapevine, TX 76051'}</Typography>
                </div>
              </MuiLink>

              <MuiLink href="tel:817-527-6743" variant='body1' >
                {'+1 (817) 527-6743'}
              </MuiLink>

            </FlexColumn>

          </FlexRow>

        </SectionBody>

        {/* <iframe title='address' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3348.0137045409306!2d-97.07189638481353!3d32.95064758091989!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x864c2b6a40c97c55%3A0x5eda4e9b1316df6!2s710%20Heatherwood%20Dr%2C%20Grapevine%2C%20TX%2076051!5e0!3m2!1sen!2sus!4v1628625322925!5m2!1sen!2sus" width="400" height="300" style="border:0;" allowfullscreen="" loading="lazy"></iframe> */}
        {/* <iframe title='address' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3348.0137045409306!2d-97.07189638481353!3d32.95064758091989!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x864c2b6a40c97c55%3A0x5eda4e9b1316df6!2s710%20Heatherwood%20Dr%2C%20Grapevine%2C%20TX%2076051!5e0!3m2!1sen!2sus!4v1628625322925!5m2!1sen!2sus" width="400" height="300" style={{ border: 0 }} sandbox='' allowFullScreen="" loading="lazy" ></iframe> */}

      </Section>

    </Page>
  );
}

Contact.propTypes = {
  system: PropTypes.shape(),
};
