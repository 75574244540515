import React from 'react';
// import PropTypes from 'prop-types';
import { ButtonBar } from '@org/client-components-core/buttons/ButtonBar';
import { LinkButton } from '@org/client-components-core/buttons/LinkButtons';
import { Spacer } from '@org/client-components-core/layout/Spacer';
import {
  Page, Heading, Section, SectionBody, Bullet, // FlexRow,
} from '@org/client-components-custom';
import { FlexRow } from '@org/client-components-custom/layout/Flex';
import theme from '@org/client-libs-themes';

export function Partner() {
  return(
    <Page id='invest' >

      <Heading>{'Partner'}</Heading>

      <Section style={{alignItems: 'flex-start'}} >

        <SectionBody variant='h6' >
          {`${process.env.REACT_APP_COMPANY_NAME} is currently looking to partner with a limited number of specific lenders, brokers, and dealers based on the following criteria:`}
        </SectionBody>

        {/* <SectionBody variant='h6' > */}
        {/* <SectionBody >
          {'We partner with lenders and brokers on a limited basis:'}
        </SectionBody> */}

        {/* <SectionBody >
          {'Lenders:'}
        </SectionBody> */}

        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', margin: 0, padding: theme.spacing(0.5, 0, 0, 1), width: '100%' }} >

          <SectionBody>
            <Bullet>{'Loan purchases on an la carte and/or forward flow basis'}</Bullet>

          {/* <SectionBody>
            <Bullet>{'We lend...'}</Bullet>
          </SectionBody> */}

            <Bullet>{'We currently specialize in investment-purpose, real estate bridge loans on both residential and commercial real estate'}</Bullet>

            <Bullet>{'We are also interested in underserved and esoteric asset classes backed by hard assets or other payment assurances'}</Bullet>

            <Bullet>{'We do not purchase or lend on any consumer loans or non-investment purpose real estate-backed products'}</Bullet>
          </SectionBody>

          <SectionBody variant='h6' >
            {`In addition, thanks to our technology infrastructure, we are able to work with our partners to take applications via form, batch file upload, or through an API`}
          </SectionBody>

          {/* <ButtonBar>
            <LinkButton name='apply-now-button' title='Apply Now' to='/borrow/application' color='default' style={{ whiteSpace: 'nowrap', }} />
            <LinkButton disabled={true} name='apply-now-button' title='Download Template' to='/borrow/application' color='default' style={{ whiteSpace: 'nowrap', }} />
          </ButtonBar> */}

          {/* <Spacer spacing={2} /> */}

          {/* <FlexRow style={{ justifyContent: 'center', margin: theme.spacing(0, 0, 2, 0) }} >
            <BasicDropZone />
          </FlexRow> */}

          <SectionBody variant='h6' >
            {'Please contact us to learn more'}
            {/* URGENT Add link to Contact section and add expand button to Contact section */}
          </SectionBody>

        </div>

      </Section>

    </Page>
  );
}

export default Partner;
