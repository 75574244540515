// import gql from 'graphql-tag';
import { gql } from '@apollo/client';

// Queries

const PING_AUTHORIZED = gql`
  query pingAuthorized {
    pingAuthorized
  }
`;

const DOWNLOAD_USER_FILE = gql`
  query downloadFile($input: GetItemInput) {
    downloadFile(input: $input) {
      # id
      # body
      # size
      # type
      # encoding
      data
    }
  }
`;

const GET_EMAIL = gql`
  query getEmail($input: GetItemInput!) {
    getEmail(input: $input) {
      id
      userId
    }
  }
`;

export const GET_INVITATION = gql`
  query getInvitation($input: GetItemInput!) {
    getInvitation(input: $input) {
      id
      invitedBy
      email
      firstName
      lastName
      message
      expirationDate
      viewDates
      confirmationDate
      userId
    }
  }
`;

const GET_LOAN = gql`
  query getLoan($input: ID!) {
    getLoan(input: $input) {
      itemType
      id
      creationDate
      createdBy
      borrowerName
      propertyAddress
      assetType
      assetSubType
      originalBalance
      currentBalance
      currentTerm
      amortizationTerm
      paymentFrequency
      interestRate
      servicingRate
      netRate
      interestType
      paymentAmount
      originationDate
      firstPaymentDate
      nextPaymentDate
      # perDiemPayment
      # perDiemDays
      # perDiemAmount
    }
  }
`;

const GET_NOTE = gql`
  query getNote($input: GetItemInput!) {
    getNote(input: $input) {
      itemType
      id
      itemName
      description
      originalBalance
      currentBalance
      managementRate
      coupon
      price
      launchDate
      availableBalance
      minimumAmount
      paymentDelay
      referenceType
      referenceId
      referenceAssetType
      referenceAssetSubType
      referenceOriginalBalance
      referenceNetRate
      referenceOriginationDate
    }
  }
`;

const GET_SESSION = gql`
  query getSession($input: GetItemInput!) {
    getSession(input: $input) {
      id
      # expiresAt
      expirationDate
      userId
      email
      groups
      ipAddress
      fetchCount
      viewerAgent
      viewerType
      viewerOs
      viewerBrowser
    }
  }
`;

const GET_TRANSACTION = gql`
  query getTransaction($input: GetItemInput!) {
    getTransaction(input: $input) {
      success
      data {
        id
        itemType
        userId
        # itemId
        # xType
        # xAmount
        # xPrice
        # xStatus
        referenceType
        referenceId
        eventDate
        # tradeType
        # amount
        # factor
        # price
        # investedAmount
        # status
      }
      error
    }
  }
`;

const GET_USER = gql`
  query getUser($input: GetItemInput!) {
    getUser(input: $input) {
      success
      data {
        id
        # itemType # doesn't work
        # # createdAt
        # # updatedAt
        # firstName
        # lastName
        # email
        # investorStatus
        # sessionId
        # doNotContact
      }
      error
    }
  }
`;

const GET_RDS_VERSION = gql`
  query getRdsVersion {
    getRdsVersion
  }
`;

export const LIST_ACCOUNT_BALANCES = gql`
  query listAccountBalances($input: ListItemsInput) {
    listAccountBalances(input: $input) {
      id
      accountType
      accountId
      debit
      credit
      balance
    }
  }
`;

export const LIST_ALERTS = gql`
  query listAlerts($input: ListItemsInput) {
    listAlerts(input: $input) {
      id
      userId
    }
  }
`;

export const LIST_CASHFLOWS = gql`
  query listCashflows($input: ListItemsInput) {
    listCashflows(input: $input) {
      itemType
      id
      # createdBy
      referenceType
      referenceId
      eventDate
      interestAmount
      principalAmount
      paymentAmount
      balance
    }
  }
`;

export const LIST_DEPOSITS = gql`
  query listDeposits($input: ListItemsInput) {
    listDeposits(input: $input) {
      id
      eventDate
      amount
    }
  }
`;

const LIST_EMAILS = gql`
  query listEmails($input: ListItemsInput) {
    listEmails(input: $input) {
      itemType
      id
      # creationDate # delete in migration
      confirmationDate
      username
      domainName
      userId
    }
  }
`;

// export const LIST_EMAIL_FILES = gql`
//   query listEmailFiles($input: ListItemsInput) {
//     listEmailFiles(input: $input) {
//       id
//       key
//       lastModified
//       eTag
//       size
//       storageClass
//     }
//   }
// `;

const LIST_INVITATIONS = gql`
  query listInvitations($input: ListItemsInput) {
    listInvitations(input: $input) {
      itemType
      id
      invitedBy
      email
      firstName
      lastName
      message
      expirationDate
      # viewedDates
      viewDates
      confirmationDate
      userId
    }
  }
`;

const LIST_LOANS = gql`
  query listLoans($input: ListItemsInput) {
    listLoans(input: $input) {
      itemType
      id
      createdBy
      # creationDate
      borrowerName
      propertyAddress
      assetType
      assetSubType
      originalBalance
      currentBalance
      originalTerm
      currentTerm
      amortizationTerm
      paymentFrequency
      interestRate
      servicingRate
      netRate
      interestType
      paymentAmount
      originationDate
      firstPaymentDate
      nextPaymentDate
    }
  }
`;

const LIST_NOTES = gql`
  query listNotes($input: ListItemsInput) {
    listNotes(input: $input) {
      itemType
      id
      itemName
      description
      originalBalance
      currentBalance
      managementRate
      coupon
      price
      launchDate
      availableBalance
      minimumAmount
      paymentDelay
      referenceType
      referenceId
      referenceAssetType
      referenceAssetSubType
      referenceOriginalBalance
      referenceNetRate
      referenceOriginationDate
    }
  }
`;

export const LIST_NOTES_REFERENCE = gql`
  query listNotesReference($input: ListItemsInput) {
    listNotesReference(input: $input) {
      success
      data {
        count
        scannedCount
        items {
          id
          itemType
          itemName
          description
          coupon
          originalBalance
          referenceItemType
          referenceItem {
            loanItem {
              id
              assetType
              assetSubType
              originalBalance
              interestRate
              servicingRate
              originalTerm
            }
          }
        }
      }
      error
    }
  }
`;

export const LIST_PAYMENTS = gql`
  query listPayments($input: ListItemsInput) {
    listPayments(input: $input) {
      id
      itemType
      transactionId
      eventDate
      accountType
      accountId
      categoryType
      categoryName
      debit
      credit
      amount
      referenceType
      referenceId
    }
  }
`;

export const LIST_SESSIONS = gql`
  query listSessions($input: ListItemsInput) {
    listSessions(input: $input) {
      itemType
      id
      expirationDate
      userId
      email
      groups
      ipAddress
      fetchCount
      viewerAgent
      viewerType
      viewerOs
      viewerBrowser
    }
  }
`;

export const LIST_TRADES = gql`
  query listTrades($input: ListItemsInput) {
    listTrades(input: $input) {
      itemType
      id
      userId
      referenceType
      referenceId
      eventDate
      tradeType
      amount
      factor
      price
      investedAmount
      # status
    }
  }
`;

export const LIST_TRANSACTIONS = gql`
  query listTransactions($input: ListItemsInput) {
    listTransactions(input: $input) {
      id
      itemType
      transactionId
      eventDate
      accountType
      accountId
      categoryType
      categoryName
      debit
      credit
      amount
      referenceType
      referenceId
    }
  }
`;

export const LIST_USERS = gql`
  query listUsers($input: ListItemsInput) {
    listUsers(input: $input) {
      itemType
      id
      email
      firstName
      lastName
      investorStatus
      doNotContact
    }
  }
`;

export const LIST_USER_EMAILS = gql`
  query listUserEmails($input: ListItemsInput) {
    listUserEmails(input: $input) {
      id
      key
      lastModified
      eTag
      size
      storageClass
    }
  }
`;

export const LIST_USER_FILES = gql`
  query listUserFiles($input: ListItemsInput) {
    listUserFiles(input: $input) {
      id
      key
      lastModified
      eTag
      size
      storageClass
    }
  }
`;

export const DESCRIBE_RDS_TABLE = gql`
  query describeTable($input: String) {
    describeTable(input: $input) {
      success
      # data {
      #   items {
      #     datid
      #     datname
      #     pid
      #     state
      #   }
      # }
      error
    }
  }
`;

export const CLOSE_CONNECTIONS = gql`
  query closeConnections {
    closeConnections {
      success
      # error
    }
  }
`;

export const LIST_RDS_CONNECTIONS = gql`
  query listConnections {
    listConnections {
      success
      data {
        items {
          datid
          datname
          pid
          state
        }
      }
      error
    }
  }
`;

export const LIST_RDS_TABLES = gql`
  query listTables {
    listTables {
      success
      data
      error
    }
  }
`;

// export const LIST_ACTIVITY = gql`
//   query listActivity($input: ListItemsInput) {
//     listActivity(input: $input) {
//       id
//       referenceItemType
//       referenceItemId
//       eventDateString
//       amount
//       debit
//       credit
//     }
//   }
// `;

export const TEST_EMAIL = gql`
  query testEmail($input: String) {
    testEmail(input: $input) {
      success
      error
    }
  }
`;

export const TEST_ERROR = gql`
  query testError($input: String) {
    testError(input: $input) {
      success
      error
    }
  }
`;

// export const TEST_MX = gql`
//   query testMx($input: String) {
//     testMx(input: $input)
//   }
// `;
export const TEST_MX = gql`
  query testMx {
    testMx
  }
`;

// Mutations

export const CREATE_CASHFLOW = gql`
  mutation createCashflow($input: CashflowInput!) {
    createCashflow(input: $input) {
      id
    }
  }
`;

export const CREATE_DEPOSIT = gql`
  mutation createDeposit($input: TransactionInput) {
    createDeposit(input: $input) {
      id
    }
  }
`;

export const CREATE_FUND = gql`
  mutation createFund($input: FundInput) {
    createFund(input: $input) {
      id
    }
  }
`;

// TODO Remove createExtension / CREATE_EXTENSION
// export const CREATE_EXTENSION = gql`
//   mutation createExtension($input: ManageRdsInput) {
//     createExtension(input: $input) {
//       success
//       error
//     }
//   }
// `;

export const PG_CREATE_EXTENSION = gql`
  mutation createExtension($input: ManageRdsInput) {
    createExtension(input: $input) {
      success
      error
    }
  }
`;

// export const CREATE_INVITATION = gql`
//   mutation createInvitation($input: InvitationInput!) {
//     createInvitation(input: $input)
//     # createInvitation(input: $input) {
//     #   success
//     #   error
//     # }
//   }
// `;

export const CREATE_LOAN = gql`
  mutation createLoan($input: LoanInput!) {
    createLoan(input: $input) {
      id
      # itemType
      # createdAt
      # # updatedAt
      # assetType
      # assetSubType
      # interestRate
      # servicingRate
      # netRate
      # originalBalance
      # originalTerm
      # firstPaymentOn
    }
  }
`;

export const CREATE_NOTE = gql`
  mutation createNote($input: NoteInput!) {
    createNote(input: $input) {
      id
      # itemType
      # createdAt
      # # updatedAt
      # launchedAt
      # itemName
      # description
      # coupon
      # managementRate
      originalBalance
      # availableBalance
      # minimumAmount
      # referenceItemId
      # referenceItemType
      # referenceItemAssetType
      # referenceItemAssetSubType
    }
  }
`;

export const CREATE_PAYMENT = gql`
  mutation createPayment($input: PaymentInput) {
    createPayment(input: $input) {
      itemType
      id
      referenceType
      referenceId
    }
  }
`;

export const CREATE_PAYMENTS = gql`
  mutation createPayments($input: PaymentsInput) {
    createPayments(input: $input)
  }
`;

export const CREATE_TRADE = gql`
  # mutation createTrade($input: TradeInput) {
  mutation createTrade($input: TransactionInput) {
    createTrade(input: $input) {
      transactionId
    }
  }
`;

// export const CREATE_TRANSACTION = gql`
//   mutation createTransaction($input: TransactionInput) {
//     createTransaction(input: $input) {
//       success
//       data {
//         itemType
//         itemId
//         xType
//         xDate
//         xAmount
//         xPrice
//         xStatus
//       }
//       error
//     }
//   }
// `;

export const CREATE_USER = gql`
  mutation createUser($input: UserInput!) {
    createUser(input: $input) {
      itemType
      id
      firstName
      lastName
      email
    }
  }
`;

export const CREATE_WITHDRAWAL = gql`
  mutation createWithdrawal($input: TransactionInput) {
    createWithdrawal(input: $input) {
      id
    }
  }
`;

// export const DELETE_ALL_TRANSACTIONS = gql`
export const BATCH_DELETE_TRANSACTIONS = gql`
  mutation batchDeleteTransactions($input: [ ID ]) {
    batchDeleteTransactions(input: $input)
  # mutation batchDeleteTransactions {
  #   batchDeleteTransactions {
  #     success
  #     error
  #   }
  }
`;

export const DELETE_CASHFLOW = gql`
  mutation deleteCashflow($input: GetItemInput!) {
    deleteCashflow(input: $input) {
      id
    }
  }
`;

export const DELETE_DEPOSIT = gql`
  mutation deleteDeposit($input: GetItemInput!) {
    deleteDeposit(input: $input) {
      id
    }
  }
`;

export const DELETE_EMAIL = gql`
  mutation deleteEmail($input: GetItemInput!) {
    deleteEmail(input: $input) {
      id
    }
  }
`;

export const DELETE_USER_FILE = gql`
  mutation deleteFile($input: GetItemInput!) {
    deleteFile(input: $input) {
      id
    }
  }
`;

export const DELETE_LOAN = gql`
  mutation deleteLoan($input: GetItemInput!) {
    deleteLoan(input: $input) {
      id
    }
  }
`;

export const DELETE_NOTE = gql`
  mutation deleteNote($input: GetItemInput!) {
    deleteNote(input: $input) {
      id
    }
  }
`;

export const DELETE_PAYMENT = gql`
  mutation deletePayment($input: ID!) {
    deletePayment(input: $input) {
      id
    }
  }
`;

export const DELETE_TRADE = gql`
  mutation deleteTrade($input: GetItemInput!) {
    deleteTrade(input: $input) {
      id
    }
  }
`;

export const DELETE_TRANSACTION = gql`
  mutation deleteTransaction($input: GetItemInput!) {
    deleteTransaction(input: $input) {
      id
    }
  }
`;

export const DELETE_USER = gql`
  mutation deleteUser($input: GetItemInput!) {
    deleteUser(input: $input) {
      id
    }
  }
`;

export const INITIATE_INVITATION = gql`
  mutation initiateInvitation($input: AuthInput!) {
    initiateInvitation(input: $input)
    # initiateInvitation(input: $input) {
    #   success
    #   data
    #   error
    # }
  }
`;

export const MIGRATE_ACTIVITY_TABLE = gql`
  mutation migrateActivityTable {
    migrateActivityTable {
      success
      error
    }
  }
`;

export const MIGRATE_CASHFLOWS_TABLE = gql`
  mutation migrateCashflowsTable {
    migrateCashflowsTable {
      success
      error
    }
  }
`;

export const MIGRATE_INVITATIONS = gql`
  mutation migrateInvitations {
    migrateInvitations
  }
`;

export const MIGRATE_SESSIONS = gql`
  mutation migrateSessions {
    migrateSessions
  }
`;

export const MIGRATE_USERS = gql`
  mutation migrateUsers {
    migrateUsers
  }
`;

export const MIGRATE_TRANSACTIONS_TABLE = gql`
  mutation migrateTransactionsTable {
    migrateTransactionsTable {
      success
      error
    }
  }
`;

export const MIGRATE_RECORDS = gql`
  mutation migrateRecords($input: ListItemsInput!) {
    migrateRecords(input: $input)
  }
`;

// export const SET_USER_PASSWORD = gql`
//   mutation setUserPassword($input: SignInInput!) {
//     setUserPassword(input: $input) {
//       success
//       error
//     }
//   }
// `;

export const UPDATE_LOAN = gql`
  mutation updateLoan($input: LoanInput!) {
    updateLoan(input: $input) {
      id
      originalBalance
    }
  }
`;

export const UPDATE_NOTE = gql`
  mutation updateNote($input: NoteInput!) {
    updateNote(input: $input) {
      id
      originalBalance
    }
  }
`;

export const UPDATE_USER = gql`
  mutation updateUser($input: UserInput!) {
    updateUser(input: $input) {
      success
      # data {
      #   id
      # }
      error
    }
  }
`;

export const UPLOAD_FILE = gql`
  mutation uploadFile($input: FileInput!) {
    uploadFile(input: $input) {
      # id
      # body
      name
      data
    }
  }
`;

export const authorizedSchema = {
//// Queries
  PING_AUTHORIZED,
  DOWNLOAD_USER_FILE,
  GET_EMAIL,
  GET_INVITATION,
  GET_LOAN,
  GET_NOTE,
  GET_SESSION,
  GET_TRANSACTION,
  GET_USER,
  GET_RDS_VERSION,
  LIST_ACCOUNT_BALANCES,
  LIST_ALERTS,
  LIST_DEPOSITS,
  LIST_EMAILS,
  LIST_LOANS,
  LIST_INVITATIONS,
  LIST_NOTES,
  // LIST_NOTES_REFERENCE,
  LIST_PAYMENTS,
  LIST_SESSIONS,
  LIST_TRADES,
  LIST_USERS,
  LIST_USER_EMAILS,
  LIST_USER_FILES,
  TEST_EMAIL,
  TEST_ERROR,
  TEST_MX,
//// Mutations
  BATCH_DELETE_TRANSACTIONS,
  CREATE_CASHFLOW,
  CREATE_DEPOSIT,
  // CREATE_EXTENSION,
  // CREATE_INVITATION,
  CREATE_FUND,
  CREATE_LOAN,
  CREATE_NOTE,
  CREATE_PAYMENT,
  CREATE_PAYMENTS, // CREATE_NOTE_PAYMENTS
  CREATE_TRADE,
  // CREATE_TRANSACTION,
  CREATE_USER,
  CREATE_WITHDRAWAL,
  // DELETE_ALL_TRANSACTIONS,
  DELETE_CASHFLOW,
  DELETE_DEPOSIT,
  DELETE_EMAIL,
  DELETE_USER_FILE,
  DELETE_LOAN,
  DELETE_NOTE,
  DELETE_PAYMENT,
  DELETE_TRADE,
  DELETE_TRANSACTION,
  DELETE_USER,
  INITIATE_INVITATION,
  MIGRATE_ACTIVITY_TABLE,
  MIGRATE_CASHFLOWS_TABLE,
  MIGRATE_INVITATIONS,
  MIGRATE_SESSIONS,
  MIGRATE_USERS,
  MIGRATE_TRANSACTIONS_TABLE,
  MIGRATE_RECORDS,
  // SET_USER_PASSWORD,
  UPDATE_LOAN,
  UPDATE_NOTE,
  // UPDATE_USER,
  UPLOAD_FILE,
  PG_CREATE_EXTENSION,
  DESCRIBE_RDS_TABLE,
  CLOSE_CONNECTIONS,
  LIST_RDS_CONNECTIONS,
  LIST_RDS_TABLES,
  // LIST_ACTIVITY,
  LIST_CASHFLOWS,
  LIST_TRANSACTIONS,
};
