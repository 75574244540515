import React from 'react';
import PropTypes from 'prop-types';
// import clsx from 'clsx';
import { IconButton } from '@org/client-components-core/buttons/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, useTheme } from '@material-ui/core/styles';
// import { appBarStyles } from './appBarStyles';

const styles = {
  menuButtonHidden: { // shared by main and user buttons
    // display: 'none', // not working in this project, TODO fix and remove !important
    display: 'none !important',
  },
}

// const useStyles = makeStyles(appBarStyles);
const useStyles = makeStyles(styles);

export function MainIconButton(props) {
  const classes = useStyles();
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down("sm")); // not only!!!
  const isXs = useMediaQuery(theme.breakpoints.only("xs"));
  let { open, handleOpenDrawer } = props;

  let size = 'medium';
  let fontSize = 'large';
  if (isXs)
    size = 'small';
    // fontSize = 'default';
  // } else if (isSm) {
  //   size = 'medium';
  //   fontSize = 'large';
  // }
  // console.log('MainIconButton', size, fontSize);

  return (
    <IconButton
      name='main-icon-button'
      aria-label="main drawer"
      // edge="start"
      // color="inherit"
      size={size}
      onClick={handleOpenDrawer}
      // className={clsx(classes.mainButton, (open || !isSmall) && classes.menuButtonHidden)}
      className={(open || !isSm) ? classes.menuButtonHidden : null}
      icon={<MenuIcon fontSize={fontSize} />}
    />
  );
}

export default MainIconButton;

MainIconButton.propTypes = {
  open: PropTypes.bool,
  handleOpenDrawer: PropTypes.func,
};
