import { decodeTime } from 'ulid';
import dayjs from 'dayjs';
import cloneDeep from 'lodash-es/cloneDeep';
// import { AuthorizedHandler } from '@org/client-graphql';

export let fields = [
  {
    type: 'text',
    name: 'firstName',
    label: 'First Name',
    initial: "",
  },
];

export const columns = [
  { field: 'firstName', headerName: 'First Name', width: 160 },
  { field: 'lastName', headerName: 'Last Name', width: 160 },
  { field: 'email', headerName: 'Email', width: 270 },
  { field: 'creationDate', headerName: 'Creation Date', width: 170, type: 'date' },
];

export async function getRows(props) {
  // let authorizedHandler = new AuthorizedHandler({ location: props.location, history: props.history });
  let { authorizedHandler } = props;

  let users = await authorizedHandler.listUsers();

  users.forEach(user => {
    user.creationDate = dayjs(decodeTime(user.id)).format('YY-MM-DD hh:mm a');
  });

  return users;
}

export async function refreshRows(props) {
  let { authorizedHandler } = props;

  let users = await authorizedHandler.refreshUsers();

  users = cloneDeep(users);

  users.forEach(user => {
    user.creationDate = dayjs(decodeTime(user.id)).format('YY-MM-DD hh:mm a');
  });

  return users;
}

export async function createItem(props) {
  console.log('Users', 'createItem', props);
  let { authorizedHandler, input } = props;

  return authorizedHandler.createUser(input);
}

export async function updateItem(props) {
  console.log('Users', 'updateItem', props);
  let { authorizedHandler, input } = props;

  return authorizedHandler.updateUser(input);
}

export async function deleteRows(props) {
  let { authorizedHandler, selections } = props;

  if (selections.length > 1) {
    // batchDelete
  } else { // just 1 selection
    let selection = selections[0];
    let input = { id: selection.id };
    return authorizedHandler.deleteUser(input);
  }
}

export async function handleRowClick(event) {
  alert(JSON.stringify(event.row, null, ' '));
}
