import { gql } from '@apollo/client';

const PING_AUTHENTICATED = gql`
  query pingAuthenticated {
    pingAuthenticated
  }
`;

const TEST_SECURITY = gql`
  query testSecurity($input: TestSecurityInput) {
    testSecurity(input: $input)
  }
`;

const DOWNLOAD_FILE = gql`
  query downloadFile($input: GetItemInput) {
    downloadFile(input: $input) {
      # id
      # body
      # size
      # type
      # encoding
      data
    }
  }
`;

export const GET_AUTH = gql`
  query getAuth {
    getAuth {
      id
      email
      # backupEmail
      groups
    }
  }
`;

export const GET_BALANCE = gql`
  query getBalance {
    getBalance
  }
`;

export const GET_ME = gql`
  query getMe {
    getMe {
      id
      firstName
      lastName
      email
      investorStatus
      doNotContact
    }
  }
`;

export const GET_LOAN = gql`
  query getLoan($input: GetItemInput!) {
    getLoan(input: $input) {
      itemType
      id
      assetType
      assetSubType
      originalBalance
      currentBalance
      originalTerm
      currentTerm
      amortizationTerm
      paymentFrequency
      interestRate
      servicingRate
      netRate
      interestType
      paymentAmount
      originationDate
      firstPaymentDate
      nextPaymentDate
    }
  }
`;

export const GET_NOTE = gql`
  query getNote($input: GetItemInput!) {
    getNote(input: $input) {
      itemType
      id
      launchDate
      itemName
      description
      originalBalance
      currentBalance
      factor
      managementRate
      coupon
      price
      launchDate
      firstPaymentDate
      nextPaymentDate
      availableBalance
      minimumAmount
      referenceType
      referenceId
    }
  }
`;

export const GET_SESSION = gql`
  query getSession {
    getSession {
      id
      expirationDate
      userId
      email
      fetchCount
      groups
      ipAddress
      viewerType
      viewerOs
      viewerBrowser
      viewerAgent
    }
  }
`;

export const GET_TRADE = gql`
  query getTrade($input: GetItemInput!) {
    getTrade(input: $input) {
      id
      userId
      referenceType
      referenceId
      eventDate
      tradeType
      amount
      factor
      price
      investedAmount
      # status
    }
  }
`;

const GET_TRANSACTION = gql`
  query getTransaction($input: GetItemInput!) {
    getTransaction(input: $input) {
      id
      # itemType
      # createdAt
      # itemId
      # xType
      # xAmount
      # xPrice
      # xStatus
    }
  }
`;

export const LIST_CASHFLOWS = gql`
  query listCashflows($input: ListItemsInput) {
    listCashflows(input: $input) {
      id
      itemType
      eventDate
      principalAmount
      interestAmount
      paymentAmount
      balance
    }
  }
`;

export const LIST_EMAILS = gql`
  query listEmails($input: ListItemsInput) {
    listEmails(input: $input)
  }
`;

const LIST_LOANS = gql`
  query listLoans($input: ListItemsInput) {
    listLoans(input: $input) {
      itemType
      id
      createdBy
      assetType
      assetSubType
      originalBalance
      factor
      currentBalance
      originalTerm
      currentTerm
      amortizationTerm
      paymentFrequency
      interestRate
      servicingRate
      netRate
      interestType
      paymentAmount
      originationDate
      firstPaymentDate
      nextPaymentDate
    }
  }
`;

export const LIST_NOTES = gql`
  query listNotes($input: ListItemsInput) {
    listNotes(input: $input) {
      itemType
      id
      itemName
      description
      launchDate
      firstPaymentDate
      nextPaymentDate
      originalBalance
      factor
      currentBalance
      managementRate
      coupon
      price
      availableBalance
      minimumAmount
      referenceType
      referenceId
    }
  }
`;

// for readQuery
export const READ_NOTE = gql`
  query readNote($id: ID!) {
    note(id: $id) {
      id
      itemType
      itemName
    }
  }
`;

// readFragment / writeQuery
export const NOTE_FRAGMENT = gql`
  fragment noteFragment on Note {
    id
    itemType
    itemName
  }
`;

export const LIST_NOTE_BALANCES = gql`
  query listNoteBalances($input: ListItemsInput) {
    listNoteBalances(input: $input) {
      # itemType
      id
      # availableBalance
      referenceType
      referenceId
      # tradeBalance
      # availableBalance
    }
  }
`;

export const LIST_TRADES = gql`
  query listTrades($input: ListItemsInput) {
    listTrades(input: $input) {
      itemType
      id
      userId
      referenceType
      referenceId
      eventDate
      tradeType
      amount
      factor
      price
      investedAmount
      # status
    }
  }
`;

export const LIST_TRADE_TOTALS = gql`
  query listTradeTotals($input: ListItemsInput) {
    listTradeTotals(input: $input) {
      # itemType
      # id
      # availableBalance
      referenceType
      referenceId
      total
      # availableBalance
    }
  }
`;

export const LIST_TRANSACTIONS = gql`
  query listTransactions($input: ListItemsInput) {
    listTransactions(input: $input) {
      id
      itemType
      transactionId
      eventDate
      accountType
      accountId
      categoryType
      categoryName
      debit
      credit
      amount
      referenceType
      referenceId
    }
  }
`;

export const LIST_FILES = gql`
  query listFiles($input: ListItemsInput) {
    listFiles(input: $input) {
      id
      key
      lastModified
      size
    }
  }
`;

// Mutations

export const INITIATE_ADD_EMAIL = gql`
  mutation initiateAddEmail($input: AuthInput!) {
    initiateAddEmail(input: $input) {
      success
      confirmCode
    }
  }
`;

export const CHANGE_EMAIL = gql`
  mutation changeEmail($input: ChangeEmailInput!) {
    changeEmail(input: $input) {
      email
    }
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation changePassword($input: ChangePasswordInput!) {
    changePassword(input: $input) {
      success
      # error
    }
  }
`;

export const CONFIRM_ADD_EMAIL = gql`
  mutation confirmAddEmail($input: AuthInput!) {
    # confirmAddEmail(input: $input)
    confirmAddEmail(input: $input) {
      success
    #   error
    }
  }
`;

export const CREATE_DEPOSIT = gql`
  mutation createDeposit($input: TransactionInput) {
    createDeposit(input: $input) {
      id
    }
  }
`;

export const CREATE_TRADE = gql`
  mutation createTrade($input: TradeInput) {
  # mutation createTrade($input: TransactionInput) {
    createTrade(input: $input) {
      id
      # itemType
      # itemId
      # xType
      # xDate
      # xAmount
      # xPrice
      # xStatus
    }
  }
`;

export const CREATE_WITHDRAWAL = gql`
  mutation createWithdrawal($input: TransactionInput) {
    createWithdrawal(input: $input) {
      id
    }
  }
`;

export const RESEND_ADD_EMAIL = gql`
  mutation resendAddEmail($input: AuthInput!) {
    resendAddEmail(input: $input) {
      success
      confirmCode
    }
  }
`;

export const SIGN_OUT = gql`
  mutation signOut {
    # signOut
    signOut {
      success
    }
  }
`;

export const UPDATE_ME = gql`
  mutation updateMe($input: UserInput!) {
    updateMe(input: $input) {
      id
      firstName
      lastName
      email
      investorStatus
      doNotContact
    }
  }
`;

export const UPLOAD_FILE = gql`
  mutation uploadFile($input: FileInput!) {
    uploadFile(input: $input) {
      # id
      # body
      name
      data
    }
  }
`;

export const authenticatedSchema = {
  PING_AUTHENTICATED,
  TEST_SECURITY,
  DOWNLOAD_FILE,
  GET_AUTH,
  GET_BALANCE,
  GET_ME,
  GET_LOAN,
  GET_NOTE,
  GET_SESSION,
  GET_TRADE,
  GET_TRANSACTION,
  LIST_CASHFLOWS,
  LIST_EMAILS,
  LIST_FILES,
  LIST_LOANS,
  LIST_NOTES,
  READ_NOTE,
  NOTE_FRAGMENT,
  LIST_NOTE_BALANCES,
  LIST_TRADES,
  LIST_TRADE_TOTALS,
  LIST_TRANSACTIONS,
  // Mutations
  CHANGE_EMAIL,
  CHANGE_PASSWORD,
  CONFIRM_ADD_EMAIL,
  CREATE_DEPOSIT,
  CREATE_TRADE,
  CREATE_WITHDRAWAL,
  INITIATE_ADD_EMAIL,
  RESEND_ADD_EMAIL,
  SIGN_OUT,
  UPDATE_ME,
  UPLOAD_FILE,
};
