import dayjs from 'dayjs';
import round from 'lodash-es/round';
import * as yup from 'yup/es';
import { RecordClass } from '@org/common-classes/Record';
import { shallowEqual, deepDifference, isDefined } from '@org/common-tools';
import {
  currencyShape, dateStringShape, factorShape, itemTypeShape, rateShape, ulidShape,
} from '@org/common-yup/shapes';

// export function calculate(input) {
//   console.log(input);
// }

let validationShape = {
  itemType: itemTypeShape,
  id: ulidShape,
  createdBy: ulidShape,
  itemName: yup.string(),
  description: yup.string(),
  originalBalance: currencyShape,
  factor: factorShape,
  currentBalance: currencyShape,
  managementRate: rateShape,
  coupon: rateShape,
  minimumAmount: currencyShape,
  price: yup.number(),
  launchDate: dateStringShape,
  availableBalance: currencyShape,
  paymentDelay: yup.number(),
  firstPaymentDate: dateStringShape,
  nextPaymentDate: dateStringShape,
  referenceType: itemTypeShape,
  referenceId: ulidShape,
  referenceAssetType: yup.string(),
  referenceAssetSubType: yup.string(),
  referenceOriginalBalance: currencyShape,
  referenceNetRate: rateShape,
  referenceFirstPaymentDate: dateStringShape,
  referenceNextPaymentDate: dateStringShape,
};
export const validationSchema = yup.object().shape(validationShape);

export class NoteClass extends RecordClass {
  constructor(input) {
    super(input);

    // Only create a Note from a specific reference item
    // if (!input)
    //   throw new Error(`${this.constructor.name} constructor need input from the referenceItem! Received: '${JSON.stringify(input)}'`);

    // this.table = UserTable;

    this.validationSchema = validationSchema;

    this.keys.itemType = 'NOTE';

    // let { referenceItem } = input;

    // let launchDate = dayjs(input.referenceOriginationDate).add(1, 'month').date(1);
    let launchDate = dayjs();
    // console.log('launchDate', launchDate.format('YYYY-MM-DD'));
    let firstPaymentDate = (launchDate.add(1, 'month')).date(1 + 19);
    // console.log('firstPaymentDate', firstPaymentDate.format('YYYY-MM-DD'));
    let nextPaymentDate = firstPaymentDate;
    // console.log('nextPaymentDate', nextPaymentDate.format('YYYY-MM-DD'));

    this.defaultValues = {
      createdBy: "",
      itemName: "",
      description: "",
      originalBalance: 0.0,
      factor: 1.0,
      currentBalance: 0.0,
      managementRate: 1.0,
      coupon: 0.0,
      minimumAmount: 5000.0,
      price: 100.0,
      launchDate: launchDate.format('YYYY-MM-DD'),
      availableBalance: 0.0,
      paymentDelay: 19,
      firstPaymentDate: firstPaymentDate.format('YYYY-MM-DD'),
      nextPaymentDate: nextPaymentDate.format('YYYY-MM-DD'),
      referenceType: "",
      referenceId: "",
      referenceAssetType: "",
      referenceAssetSubType: "",
      // referenceOriginalBalance: 0.0,
      // referenceNetRate: 0.0,
      // referenceOriginationDate: "",
    };

    this.requiredValues = {};
    // this.requiredValues = {
    //   itemName: true,
    //   description: true,
    // };

    // this.attributes = {
    //   ...this.defaultValues,
    // };

    // this.calculateValues(input);

    // if (input)
    //   this.update(input);
    // if (input)
    //   this.initialize(input);
    this.update(input);
  }

  calculateValues(input) {
    let diff = deepDifference(input, this.values());
    this.attributes = { ...this.attributes, ...diff };
    // console.log('Note', 'calculateValues', 'diff', diff);

    if (diff.originalBalance)
      this.attributes.currentBalance = this.attributes.originalBalance * this.attributes.factor;

    if (diff.factor)
      this.attributes.currentBalance = this.attributes.originalBalance * this.attributes.factor;

    if (diff.currentBalance) {
      if (this.attributes.currentBalance > this.attributes.originalBalance)
        this.attributes.originalBalance = this.attributes.currentBalance / this.attributes.factor;
      else
        this.attributes.factor = this.attributes.currentBalance / this.attributes.originalBalance;
    }

    // we can't have zero coupon
    // if (!values.coupon && !this.attributes.coupon)
    //   values.coupon = this.attributes.referenceNetRate - this.attributes.managementRate;
    // if (!values.coupon && !this.attributes.coupon)
    //   values.coupon = this.attributes.referenceNetRate - this.attributes.managementRate;

    if (diff.coupon)
      this.attributes.coupon = diff.coupon;

    if (diff.servicingRate)
      this.attributes.servicingRate = diff.servicingRate;

    //   this.attributes.netRate = diff.interestRate - this.attributes.servicingRate;
    // }

    // let originalBalance = values.originalBalance ? values.originalBalance : this.attributes.originalBalance;
    // let factor = values.factor ? values.factor : this.attributes.factor;
    // // let ratio = values.originalBalance / values.referenceBalance;
    // // ratio *= values.coupon / values.referenceNetRate;

    // if (originalBalance !== this.attributes.originalBalance)
    //   values.currentBalance = originalBalance * factor;

    // if (factor !== this.attributes.factor)
    //   values.currentBalance = originalBalance * factor;

    // // if (ratio !== this.attributes.ratio)
    // //   values.ratio = ratio;

    // let referenceNetRate = values.referenceNetRate ? values.referenceNetRate : this.attributes.referenceNetRate;
    // let managementRate = values.managementRate ? values.managementRate : this.attributes.managementRate;

    // console.log(referenceNetRate, this.attributes.referenceNetRate, managementRate);

    // if (referenceNetRate !== this.attributes.referenceNetRate)
    //   values.coupon = referenceNetRate - managementRate;

    // if (managementRate !== this.attributes.managementRate)
    //   values.coupon = referenceNetRate - managementRate;

    // if (this.referenceItem) {
    //   let nextPaymeDate = dayjs(this.referenceItem.nextPaymentDate);
    //   values.nextPaymentDate = nextPaymeDate.add(this.attributes.paymentDelay, 'day');
    // }

    // console.log(values);

    // this.attributes = { ...this.attributes, ...values };

    // console.log(this.attributes);

    // console.log('Note', 'calculateValues', this.attributes);
    return this.attributes;
  }

  setReferenceItem(item) {
    console.log('Note', 'setReferenceItem', item);

    let nextPaymentDate = dayjs(item.nextPaymentDate);
    // let firstPaymentDate = (nextPaymentDate.add(1, 'month')).date(1 + 19);
    let firstPaymentDate = nextPaymentDate.date(1 + this.attributes.paymentDelay);

    let input = {
      originalBalance: item.originalBalance, // URGENT change to unreferenced amount
      factor: item.factor,
      currentBalance: item.originalBalance * item.factor,
      coupon: item.netRate - this.attributes.managementRate,
      // launchDate: launchDate.format('YYYY-MM-DD'), // item.firstPaymentDate???
      availableBalance: item.originalBalance, // URGENT change to unreferenced amount
      // paymentDelay: 19,
      firstPaymentDate: firstPaymentDate.format('YYYY-MM-DD'), // item.firstPaymentDate + this.attributes.paymentDelay???
      nextPaymentDate: firstPaymentDate.format('YYYY-MM-DD'), // item.firstPaymentDate + this.attributes.paymentDelay???
      referenceType: item.itemType,
      referenceId: item.id,
      referenceAssetType: item.assetType,
      referenceAssetSubType: item.assetSubType,
      referenceOriginalBalance: item.originalBalance,
      referenceNetRate: item.netRate,
      referenceFirstPaymentDate: item.firstPaymentDate,
      referenceNextPaymentDate: item.nextPaymentDate,
    };
    // Need to calculateValues before update or the call to calculateValues in
    // update will not see any diff
    input = this.calculateValues(input);
    this.update(input);
  }

  getCashflow(input) {
    let ratio = this.attributes.originalBalance / this.attributes.referenceOriginalBalance;
    // ratio = this.attributes.coupon / this.attributes.referenceNetRate;
    // let couponRatio = this.attributes.coupon / (this.)
    console.log('Note', 'getCashflow', 'this', this);
    console.log('Note', 'getCashflow', 'ratio', ratio);

    let paymentDate = dayjs(input.eventDate);
    paymentDate = paymentDate.add(this.attributes.paymentDelay, 'day');
    // console.log('getNextCashflow', paymentDate);

    // Calc interest amounts off Note current balance so we don't need ratio
    let servicingAmount = round(this.attributes.managementRate / 1200 * this.attributes.currentBalance, 2);
    let netInterestAmount = input.interestAmount - servicingAmount;
    let interestAmount = netInterestAmount + servicingAmount;

    // let interestAmount = round(input.interestAmount * ratio, 2);
    // let interestAmount = round(this.referenceNetRate )
    // let servicingAmount = round(this.attributes.managementRate / this.attributes.coupon * interestAmount, 2);
    // let netInterestAmount = round(interestAmount - servicingAmount, 2);
    let principalAmount = round(input.principalAmount * ratio, 2);
    let paymentAmount = round(netInterestAmount + principalAmount, 2);
    let balance = this.attributes.currentBalance - principalAmount;

    return {
      referenceType: this.keys.itemType,
      referenceId: this.keys.id,
      eventDate: paymentDate.format('YYYY-MM-DD'),
      interestAmount,
      servicingAmount,
      netInterestAmount,
      principalAmount,
      paymentAmount,
      balance,
    };
  }

  // input = reference cashflow
  applyCashflow(input) {
    // console.log('Note', 'applyCashflow', cashflow);

    let ratio = this.attributes.originalBalance / this.attributes.referenceBalance;
    ratio = this.attributes.coupon / this.attributes.referenceNetRate;

    let paymentDate = dayjs(input.eventDate);
    paymentDate = paymentDate.add(this.attributes.paymentDelay, 'day');
    // console.log('getNextCashflow', paymentDate);

    let principalAmount = round(input.principalAmount * ratio, 2);
    let interestAmount = round(input.interestAmount * ratio, 2);
    let paymentAmount = round(interestAmount + principalAmount, 2);

    this.attributes.currentBalance -= principalAmount;
    this.attributes.factor = round(this.attributes.currentBalance / this.attributes.originalBalance, 12);

    return {
      referenceType: this.keys.itemType,
      referenceId: this.keys.id,
      eventDate: paymentDate.format('YYYY-MM-DD'),
      interestAmount,
      principalAmount,
      paymentAmount,
      balance: this.attributes.currentBalance,
    };
  }

  // for saving updated values to DB
  getCashflowUpdates() {
    return {
      id: this.keys.id,
      currentBalance: this.attributes.currentBalance,
      factor: this.attributes.factor,
      currentTerm: this.attributes.currentTerm,
      nextPaymentDate: this.attributes.nextPaymentDate,
    };
  }
}
