import { decodeTime } from 'ulid';
import dayjs from 'dayjs';
import cloneDeep from 'lodash-es/cloneDeep';

// Item

export let transactionFields = [
  {
    type: 'text',
    name: 'referenceType',
    label: 'Reference Type',
    initial: "",
  },
  {
    type: 'text',
    name: 'referenceId',
    label: 'Reference Id',
    initial: "",
  },
  {
    type: 'text',
    name: 'eventDate',
    label: 'Event Date',
    initial: "",
  },
  {
    type: 'amount',
    name: 'amount',
    label: 'Amount',
    initial: 0.00,
  },
];

// Manage

export const columns = [
  { field: 'referenceType', headerName: 'Reference Type', width: 180 },
  { field: 'referenceId', headerName: 'Reference ID', width: 260 },
  { field: 'eventDate', headerName: 'Date', width: 110, type: 'date' },
  { field: 'amount', headerName: 'Amount', width: 150, type: 'number' },
];

export async function getRows(props) {
  let { authorizedHandler } = props; 

  try {
    let trades = await authorizedHandler.listLoans();

    trades = cloneDeep(trades);

    trades.forEach(loan => {
      loan.creationDate = dayjs(decodeTime(loan.id)).format('YY-MM-DD hh:mm a');
    });

    return trades;
  } catch (error) {
    console.error(error.message);
    return [];
  }
}

export async function refreshRows(props) {
  let { authorizedHandler } = props;

  let trades = await authorizedHandler.refreshTrades();

  trades = cloneDeep(trades);

  trades.forEach(trade => {
    trade.creationDate = dayjs(decodeTime(trade.id)).format('YY-MM-DD hh:mm a');
  });

  return trades;
}

export async function deleteRows(props) {
  let { authorizedHandler, selections } = props;

  if (selections.length > 1) {
    // batchDelete
  } else { // just 1 selection
    let selection = selections[0];
    let input = {
      itemType: selection.itemType,
      id: selection.id,
    };

    return authorizedHandler.deleteTrade(input);
  }
}

export async function createItem(props) {
  let { authorizedHandler, input } = props;

  return authorizedHandler.createTrade(input);
}

export async function updateItem(props) {
  let { authorizedHandler, input } = props;

  return authorizedHandler.updateTrade(input);
}

export async function handleRowClick(event) {
  alert(JSON.stringify(event.row, null, ' '));
}
