import dayjs from 'dayjs';
import round from 'lodash-es/round';
import * as yup from 'yup/es';
import { RecordClass } from '@org/common-classes/Record';
// import { CashflowClass } from '@org/common-classes/Cashflow';
// import { UserTable } from '@org/server-libs-data';
// import { round } from '@org/common-formatters';
// import { isDefined } from '@org/common-tools';
import { shallowEqual, deepDifference, isDefined } from '@org/common-tools';
import {
  currencyShape, itemTypeShape, ulidShape,
} from '@org/common-yup/shapes';

const assetTypes = [ 'REAL_ESTATE', 'ART' ];
const assetSubTypes = [ 'RESIDENTIAL', 'COMMERCIAL', 'MULTIFAMILY' ];
const paymentFrequencyTypes = [ 'MONTHLY', 'QUARTERLY', 'SEMI', 'ANNUAL' ];
const interestTypes = [ '30_360' ];

let validationShape = {
  itemType: itemTypeShape,
  id: ulidShape,
  createdBy: ulidShape,
  borrowerName: yup.string(),
  propertyAddress: yup.string(),
  assetType: yup.string().oneOf(assetTypes).required(),
  assetSubType: yup.string().required(),
  originalBalance: currencyShape.required('Original Balance is required'),
  factor: yup.number().required('Factor is required'),
  currentBalance: currencyShape.required('Current Balance is required'),
  originalTerm: yup.number().required('Original Term is required'),
  currentTerm: yup.number(),
  amortizationTerm: yup.number().required('Original Term is required'),
  paymentFrequency:  yup.string().oneOf(paymentFrequencyTypes).required('Payment Frequency is required'), // DAILY, MONTHLY, QUARTERLY, etc
  interestRate: yup.number().required('Interest Rate is required'),
  servicingRate: yup.number().required('Servicing Rate is required'),
  netRate: yup.number(),
  interestType: yup.string().oneOf(interestTypes).required('Interest Type is required'),
  paymentAmount: yup.number().required('Payment Amount is required'),
  appraisalAmount: yup.number(),
  originationDate: yup.date().required('Origination Date is required'),
  firstPaymentDate: yup.date().required('First Payment Date is required'),
  nextPaymentDate: yup.date(),
  perDiemPayment: yup.number(),
  perDiemDays: yup.number(),
  perDiemAmount: yup.number(),
};
export const validationSchema = yup.object().shape(validationShape);

function calcFirstPaymentDate(originationDate) {
  let dayOfMonth = originationDate.get('date');

  if (dayOfMonth === 1)
    return originationDate.add(1, 'month');
  else {
    let firstPaymentDate = originationDate.add(2, 'month');
    // console.log(firstPaymentDate.format('YYYY-MM-DD'));
    firstPaymentDate = firstPaymentDate.set('date', 1);
    // console.log(firstPaymentDate.format('YYYY-MM-DD'));

    return firstPaymentDate;
  }
}

// // Does this work outside loan class?
// export function calculate(values) {
//   console.log('calculate', values);
//   // input.currentBalance = input.originalBalance * input.factor;
//   // input.netRate = input.interestRate - input.servicingRate;
//   // console.log('TradeClass', 'calculate', input);

//   let updates = {};

//   if (updates.originalBalance !== values.originalBalance)
//     values.currentBalance = updates.originalBalance * updates.factor;

//   if (updates.factor !== values.factor)
//     values.currentBalance = updates.originalBalance * updates.factor;

//   if (updates.interestRate !== values.interestRate)
//     values.netRate = updates.interestRate - updates.servicingRate;
  
//   return values;
// }

export class LoanClass extends RecordClass {
  constructor(input) {
    super(input);

    // if (input.createdBy) {
    //   console.warn(`${this.constructor.name}: 'createdBy' found in input. Please remove!`);
    //   delete input.createdBy;
    // }

    // this.table = UserTable;

    this.keys.itemType = 'LOAN';

    let originationDate = dayjs();
    let firstPaymentDate = calcFirstPaymentDate(originationDate);

    // used for create form
    this.defaultValues = {
      // Original details
      createdBy: "",
      // borrower
      borrowerName: "",
      propertyAddress: "",
      // asset
      assetType: "", // "REAL_ESTATE",
      assetSubType: "", // "RESIDENTIAL",
      appraisalAmount: 0.0,
      // originalLtv: 0.0, 
      // loan original
      originalBalance: 0.0,
      originalTerm: 12,
      amortizationTerm: 0,
      paymentFrequency: "MONTHLY",
      // couponType: "FIXED",
      interestType: "30_360",
      // closing details
      originationDate: originationDate.format('YYYY-MM-DD'),
      firstPaymentDate: firstPaymentDate.format('YYYY-MM-DD'),
      perDiemAmount: 0.0,
      perDiemDays: 0,
      perDiemPayment: 0.0,
      // Current details
      // loan current (including period 0)
      // save all values in LOAN
      // only save values below in PAYMENT?
      factor: 1.0,
      currentBalance: 0.0,
      currentTerm: 12,
      interestRate: 0.0,
      servicingRate: 0.5,
      netRate: 0.0,
      paymentAmount: 0.0,
      nextPaymentDate: firstPaymentDate.format('YYYY-MM-DD'),
    };

    this.validationSchema = validationSchema;

    this.requiredValues = {};

    this.update(input);
  }

  // !!! must take input so new/updated values can be passed from useForm !!!
  calculateValues(input) {
    // console.log('Loan', 'calculateValues', input, this.values());
    let diff = deepDifference(input, this.values());
    // console.log('calculateValues', 'diff', diff);
    this.attributes = { ...this.attributes, ...diff };
    // console.log('calculateValues', 'this.attributes', this.attributes);

    if (diff.assetType && !diff.assetSubType)
      this.attributes.assetSubType = "";

    if (diff.originationDate && !diff.firstPaymentDate) {
      let originationDate = dayjs(diff.originationDate);
      let firstPaymentDate = calcFirstPaymentDate(originationDate);
      this.attributes.firstPaymentDate = firstPaymentDate.format('YYYY-MM-DD');
      this.attributes.nextPaymentDate = this.attributes.firstPaymentDate;
    }

    if (diff.originalBalance)
      this.attributes.currentBalance = this.attributes.originalBalance * this.attributes.factor;

    if (diff.factor)
      this.attributes.currentBalance = this.attributes.originalBalance * this.attributes.factor;

    if (diff.currentBalance) {
      if (this.attributes.currentBalance > this.attributes.originalBalance)
        this.attributes.originalBalance = this.attributes.currentBalance / this.attributes.factor;
      else
        this.attributes.factor = this.attributes.currentBalance / this.attributes.originalBalance;
    }

    // if (factor !== this.attributes.factor) {
    //   this.attributes.currentBalance = this.attributes.originalBalance * factor;
    //   currentBalance = this.attributes.currentBalance;
    // }

    // if (currentBalance !== this.attributes.currentBalance)
    //   this.attributes.factor = currentBalance / this.attributes.originalBalance;

    // we need to know if both values have changed to correctly calculate netRate
    // let interestRate = values.interestRate ? values.interestRate : this.attributes.interestRate;
    // let servicingRate = values.servicingRate ? values.servicingRate : this.attributes.servicingRate;
    // let netRate = values.netRate ? values.netRate : this.attributes.netRate;

    if (diff.interestRate || diff.servicingRate)
      this.attributes.netRate = this.attributes.interestRate - this.attributes.servicingRate;

    if (diff.netRate)
      this.attributes.interestRate = this.attributes.netRate + this.attributes.servicingRate;

    // let paymentAmount = this.calcPayment(values);
    let paymentAmount = this.calcPayment();
    if (paymentAmount !== this.attributes.paymentAmount)
      this.attributes.paymentAmount = paymentAmount;

    // let perDiemAmount = this.calcPerDiemAmount(values);
    let perDiemAmount = this.calcPerDiemAmount();
    if (perDiemAmount !== this.attributes.perDiemAmount)
      this.attributes.perDiemAmount = perDiemAmount;

    // let perDiemDays = this.calcPerDiemDays(values);
    let perDiemDays = this.calcPerDiemDays();
    if (perDiemDays !== this.attributes.perDiemDays)
      this.attributes.perDiemDays = perDiemDays;

    let perDiemPayment = round(perDiemDays * perDiemAmount, 2);
    if (perDiemPayment !== this.attributes.perDiemPayment)
      this.attributes.perDiemPayment = perDiemPayment;

    // this.attributes = { ...this.attributes, ...values };

    // // for Forms
    // return values;
    // console.log('calculateValues', this.attributes);
    return this.attributes;
  }

  // URGENT Do I need this?
  noteInput() {
    return ({
      referenceType: this.keys.itemType,
      referenceId: this.keys.id,
      referenceAssetType: this.attributes.assetType,
      referenceAssetSubType: this.attributes.assetSubType,
      referenceBalance: this.attributes.originalBalance,
      referenceNetRate: this.attributes.netRate,
      referenceOriginationDate: this.attributes.originationDate,
    });
  }

  getNextPaymentDate() {
    if (this.attributes.paymentFrequency === "MONTHLY") {
      let nextPaymentDate = (dayjs(this.attributes.nextPaymentDate).add(1, 'month')).format('YYYY-MM-DD');
      return nextPaymentDate;
    } else
      throw new Error('Loan', 'getNextPaymentDate', `Unknown 'paymentFrequency'`);
  }

  calcInterest() {
    let interestAmount = this.attributes.interestRate / 1200 * this.attributes.currentBalance;
    return round(interestAmount, 2);
  }

  calcPayment() {
    let {
      originalBalance, interestRate, amortizationTerm, currentBalance, currentTerm,
    } = this.attributes;
    let i = interestRate / 1200;
    let n = amortizationTerm;
    let paymentAmount = 0;

    if (amortizationTerm === 0 && currentTerm > 1)
      paymentAmount = originalBalance * interestRate / 1200;
    else if (amortizationTerm === 0 && currentTerm === 1)
      paymentAmount = originalBalance * interestRate / 1200 + currentBalance;
    else if (interestRate && originalBalance)
      paymentAmount = originalBalance * i * (Math.pow(1 + i, n)) / (Math.pow(1 + i, n) - 1);
    else
      paymentAmount = 0; // ?

    paymentAmount = round(paymentAmount, 2);

    return paymentAmount;
  }

  // Generates the payment for a loan at a specific remaining term
  __getCashflow(remainingTerm) {
    console.log('Loan', '__getCashflow', remainingTerm);
    // In the future changing payment amounts will be calculated here...
    // let paymentAmount = this.calcPayment(remainingTerm);
    let paymentAmount = this.calcPayment();
    // let interestAmount = this.calcInterest(remainingTerm);
    let interestAmount = this.calcInterest();
    console.log('Loan','__getCashflow', this, paymentAmount, interestAmount);
    let servicingAmount = round(this.attributes.servicingRate / this.attributes.interestRate * interestAmount, 2);
    let netInterestAmount = interestAmount - servicingAmount;
    let principalAmount = paymentAmount - interestAmount;
    let balance = this.attributes.currentBalance - principalAmount; // this won't work if generated out of order
    console.log('Loan','__getCashflow', servicingAmount, netInterestAmount, principalAmount, balance);
    return {
      eventDate: this.attributes.nextPaymentDate,
      principalAmount,
      interestAmount,
      servicingAmount,
      netInterestAmount,
      paymentAmount,
      balance,
    };
  }

  // __getNextPayment() {
  //   return this.__getPayment(this.attributes.remainingTerm);
  // }

  // Generates a Loan Cashflow record to save in DB
  getNextCashflow() {
    let cashflow = this.__getCashflow(this.attributes.remainingTerm);
    return {
      referenceType: this.keys.itemType,
      referenceId: this.keys.id,
      // eventDate: this.attributes.nextPaymentDate,
      // interestAmount: payment.interestAmount,
      // principalAmount: payment.principalAmount,
      // paymentAmount: payment.paymentAmount,
      // balance: this.attributes.currentBalance - payment.principalAmount,
      ...cashflow,
    };
  }

  // input = getNextCashflow() response
  // Update the LoanClass attributes to project the next cashflow (if any)
  // Generates changes to a Loan record to save in DB
  applyNextCashflow(input) {
    if (this.attributes.currentTerm > 0) {
      this.attributes.currentBalance -= input.principalAmount;
      this.attributes.factor = this.attributes.currentBalance / this.attributes.originalBalance;
      this.attributes.currentTerm -= 1;
      this.attributes.nextPaymentDate = this.getNextPaymentDate();

      return {
        id: this.keys.id,
        currentBalance: this.attributes.currentBalance,
        factor: this.attributes.factor,
        currentTerm: this.attributes.currentTerm,
        nextPaymentDate: this.attributes.nextPaymentDate,
      };
    }
  }

  // for saving updated values to DB
  getCashflowUpdates() {
    return {
      id: this.keys.id,
      currentBalance: this.attributes.currentBalance,
      factor: this.attributes.factor,
      currentTerm: this.attributes.currentTerm,
      nextPaymentDate: this.attributes.nextPaymentDate,
    };
  }

  // calcPerDiemAmount(input) {
  calcPerDiemAmount() {
    // console.log('calcPerDiemAmount', 'input', input);
    // let { originalBalance, interestRate } = input;
    let { originalBalance, interestRate } = this.attributes;

    // console.log(isDefined(originalBalance), isDefined(interestRate));

    // [ 'originalBalance', 'interestRate' ].forEach(elem => {
    //   if (!isDefined(input[elem])) {
    //     console.error('calcPerDiemAmount', elem, 'missing in input');
    //     return 0;
    //   } else if (!input[elem])
    //     return 0;
    // });

    // if (!isDefined(originalBalance) || !isDefined(interestRate)) {
    //   console.error(`Missing input element in 'calcPerDiemAmount:`, JSON.stringify(input));
    //   return 0;
    // }

    // if (!originalBalance || !interestRate)
    //   return 0;

    let payment = interestRate / 100 * originalBalance / 360;
    payment = round(payment, 2);
    return payment;
  }
  
  // calcPerDiemDays(input) {
  calcPerDiemDays() {
    // console.log('Loan Class', 'calcPerDiemDays', 'input', input);
    // let { originationDate } = input;
    let { originationDate } = this.attributes;

    // if (!isDefined(originationDate)) {
    //   // console.log(isDefined(originationDate), originationDate);
    //   console.error(`Missing input elements in 'calcPerDiemDays:`, JSON.stringify(input));
    //   return 0;
    // }

    let date = dayjs(originationDate);
    let nextDate = date.add(1, 'month');
    nextDate = nextDate.set('date', 1);
    let days = nextDate.diff(date, 'days');
    // console.log('calcPerDiemDays', days);
    return days;
  }

  getClosingCashflow() {
    // this.attributes.perDiemAmount = this.calcPerDiemAmount(this.attributes);
    // this.attributes.perDiemDays = this.calcPerDiemDays(this.attributes);
    // this.attributes.perDiemPayment = this.attributes.perDiemAmount * this.attributes.perDiemDays;
    // return this.attributes.perDiemPayment;

    // console.log('LoanClass', 'getClosingCashflow', this.values());

    // let input = {
    return {
      referenceType: this.keys.itemType,
      referenceId: this.keys.id,
      eventDate: this.attributes.originationDate,
      interestAmount: this.attributes.perDiemPayment,
      principalAmount: 0.0,
      paymentAmount: this.attributes.perDiemPayment,
      balance: this.attributes.originalBalance,
    };

    // let cashflowClass = new CashflowClass(input);

    // return cashflowClass.values();
  }
}
