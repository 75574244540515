import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import * as yup from 'yup/es';
import Grid from '@material-ui/core/Grid';
import { useForm, HooksForm } from '@org/client-components-core/form/useForm';
import { FormLayout, FormSpacer } from '@org/client-components-custom/layout/Form';
import { AmountField } from '@org/client-components-core/inputs/AmountField';
import { ButtonBar } from '@org/client-components-core/buttons/ButtonBar';
import { PrimaryButton } from '@org/client-components-core/buttons/Buttons';
import { DatePicker } from '@org/client-components-core/inputs/DatePicker';
import { TextField } from '@org/client-components-core/inputs/TextField';
import { SelectTextField } from '@org/client-components-core/inputs/SelectTextField';
import { InLink } from '@org/client-components-core/links/InLink';
import { FlexRow } from '@org/client-components-custom/layout/Flex';
import { Heading } from '@org/client-components-custom/page/Heading';
import { FormMessage, FormError } from '@org/client-components-custom/page/FormMessage';
import { DisplayState } from '@org/client-components-utils/DisplayState';
import { referenceItemTypes } from '@org/common-data-types';
// import { LoanClass } from '@org/common-classes/Loan';
import { CashflowClass } from '@org/common-classes/Cashflow';
import {
  useValidate, itemTypeShape, ulidShape, dateShape, currencyShape,
} from '@org/common-yup';

let cashflowClass;

let initialValues = {};

// let requiredValues = {};

let validationShape = {
  referenceType: itemTypeShape,
  referenceId: ulidShape,
  eventDate: dateShape,
  interestAmount: currencyShape,
  principalAmount: currencyShape,
  paymentAmount: currencyShape,
};
const validationSchema = yup.object().shape(validationShape);

let title;

export function CreateCashflow(props) {
  const [ state, setState ] = useState({
    isLoading: true, isSubmitting: false, isCompleted: false, formMessage: null, formError: null,
  });
  const isMounted = useRef(false);
  const [ validate ] = useValidate(validationSchema);
  const {
    formState, fieldProps, // , resetValues, resetForm
  } = useForm({ initialValues, validate });
  let { authorizedHandler } = props;

  if (state.isLoading) {
    console.log('CreateCashflow', props.location?.state);
    if (props.location?.state?.data) {
      // let { loanClass } = props.location.state.data;
      // if (!loanClass.getNextCashflow)
      //  loanClass = new LoanClass({ ...loanClass.attributes, ...loanClass.keys });
      // let loanClass = new LoanClass(props.location.state.data);
      // let cashflowInput = loanClass.getNextCashflow();
      // console.log('CreateCashflow', loanClass, cashflowInput);
      // cashflowClass = new CashflowClass(cashflowInput);
      cashflowClass = new CashflowClass(props.location?.state?.data);
      // console.log('CreateCashflow', 'cashflowClass', cashflowClass);
      if (cashflowClass.referenceType === 'LOAN')
        title = 'Create Loan Cashflow';
      else if (cashflowClass.referenceType === 'NOTE')
        title = 'Create Note Cashflow';
      initialValues = cashflowClass.attributes;
      // requiredValues = cashflowClass.requiredValues;
    } else
      props.history.push('/admin/manage');
  }

  useEffect(()=>{
    isMounted.class = true;
    window.scrollTo(0, 0);

    if (isMounted.class)
      setState({ ...state, isLoading: false});

    console.log('CreateCashflow', 'componentDidMount');
    
    return () => { isMounted.calss = false; };
  },[]);

  async function handleSubmit(event, form) {
    event.preventDefault();
    let { values } = form;
    setState({ ...state, isSubmitting: true });

    let input = {
      referenceItemType: values.referenceItemType,
      referenceItemId: values.referenceItemId,
      eventDate: values.eventDate,
      interestAmount: values.interestAmount,
      principalAmount: values.principalAmount,
      paymentAmount: values.paymentAmount,
    };
    console.log('CreateCashflow', input);
    try {
      // URGENT Write responses to the cache?
      // let response = await authorizedResolvers.createCashflow(input);
      await authorizedHandler.createCashflow(input);

      if (isMounted.class)
        setState({ ...state, isSubmitting: false, isCompleted: true, formMessage: `Cashflow successfully created` });

    } catch (error) {
      console.error('handleSubmit', error);
      setState({ ...state, isSubmitting: false, formError: error });
    }
  }

  console.log('CreateCashflow', 'initialValues', initialValues);

  return (
    <>

    <HooksForm
      id='user-personal-form'
      formState={formState}
    >
      {formProps => {
      let fieldEnabled = !state.isLoading && !state.isSubmitting;
      let submitEnabled = fieldEnabled && formProps.isValidated;
      {/* let cancelEnabled = formProps.isChanged; */}
      return (
        <>
        <FormLayout id='create-cashflow' >

          <FlexRow>
            <InLink to='/admin/create-loan' title='Back' />
          </FlexRow>

          <Heading>{title}</Heading>

          <Grid style={{width: '100%'}} >

            {state.formMessage &&
            <Grid item >
              <FormMessage message={state.formMessage} />
            </Grid>}

            {state.formError &&
            <Grid item >
              <FormError message={state.formError.message} />
            </Grid>}

            <Grid item >
              <SelectTextField {...fieldProps} name={'referenceType'} label={'Reference Type'} items={referenceItemTypes} required={true} />
            </Grid>

            <Grid item >
              <TextField {...fieldProps} name='referenceId' label='Reference ID' required={true} />
            </Grid>

            <Grid item >
              <DatePicker {...fieldProps} name='eventDate' label='Cashflow Date' required={true} />
            </Grid>

            <Grid item >
              <AmountField {...fieldProps} name='interestAmount' label='Interest' required={true} />
            </Grid>

            <Grid item >
              <AmountField {...fieldProps} name='principalAmount' label='Principal' required={true} />
            </Grid>

            <Grid item >
              <AmountField {...fieldProps} name='paymentAmount' label='Payment' required={true} />
            </Grid>

          </Grid>

          <FormSpacer />

          <ButtonBar>
            <PrimaryButton {...formProps.submitProps} title='Submit' disabled={!submitEnabled}
            onClick={(event) => handleSubmit(event, formProps)} />
          </ButtonBar>

        </FormLayout>

        <DisplayState title='state' state={{ state, formState, router: props.location.state }} open={true} />
        </>
      );}}

    </HooksForm>
    </>
  );
}

export default CreateCashflow;

CreateCashflow.propTypes = {
  location: PropTypes.shape(),
  history: PropTypes.shape(),
  isValidated: PropTypes.bool,
  // values: PropTypes.shape(),
  fieldProps: PropTypes.shape(),
  buttonProps: PropTypes.shape(),
  stateProps: PropTypes.shape(),
  // amount: PropTypes.number(),
  authorizedHandler: PropTypes.shape(),
};
