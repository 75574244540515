import React from 'react';
import Typography from '@material-ui/core/Typography';
import { Page } from '@org/client-components-custom/page/Page';
import { FlexColumnCentered } from '@org/client-components-custom/layout/Flex';

export function UserNotAuthorized() {
  return(
    <Page>
      <FlexColumnCentered>
        <Typography variant='h4' style={{ height: '100%', textAlign: 'center', whiteSpace: 'pre-line' }} >
          {`User Not Authorized`}
        </Typography>
        <Typography variant='body1' style={{ height: '100%', textAlign: 'center', whiteSpace: 'pre-line' }} >
          {/* {`This can also happen when an authenticated user attempts to access a page for uauthenticated users only`} */}
          {`Either you are not authenticated (signed in) or you are trying to access a page that is not accessible to authenticated users`}
        </Typography>
      </FlexColumnCentered>
    </Page>
  );
}

export default UserNotAuthorized;
