import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import * as yup from 'yup/es';
import { useForm, HooksForm } from '@org/client-components-core/form/useForm';
import { FormLayout, FormStack, FormItem, FormSpacer } from '@org/client-components-custom/layout/Form';
import { ButtonBar } from '@org/client-components-core/buttons/ButtonBar';
import { TextField } from '@org/client-components-core/inputs/TextField';
import { SelectTextField } from '@org/client-components-core/inputs/SelectTextField';
import { Checkbox } from '@org/client-components-core/inputs/Checkbox';
import { PrimaryButton, SecondaryButton } from '@org/client-components-core/buttons/Buttons';
import { BackdropLoader } from '@org/client-components-core/feedback/Loaders';
import { Heading } from '@org/client-components-custom/page/Heading';
import { FormMessage, FormError } from '@org/client-components-custom/page/FormMessage';
import { DisplayState } from '@org/client-components-utils/DisplayState';
import { useValidate, firstNameShape, lastNameShape } from '@org/common-yup';

let investorStatusTypes = [
  { value: 'NOT_ACCREDITED', label: 'Non-Accredited Investor' },
  { value: 'ACCREDITED', label: 'Accredited Investor' },
];

let initialValues = {
  firstName: "",
  lastName: "",
  investorStatus: "",
  doNotContact: false,
};

let validationShape = {
  firstName: firstNameShape,
  lastName: lastNameShape,
  investorStatus: yup.string(),
  doNotContact: yup.boolean(),
};

const validationSchema = yup.object().shape(validationShape);

export function Personal(props) {
  const [ state, setState ] = useState({
    isLoading: true, isSubmitting: false, formMessage: null, formError: null,
  });
  const isMounted = useRef(false);
  const [ validate ] = useValidate(validationSchema);
  const {
    formState, fieldProps, setValues, resetValues, resetForm
  } = useForm({ initialValues, validate });
  let { authenticatedHandler } = props;

  // let classes = useStyles();

  useEffect(() => {
    isMounted.current = true;
    window.scrollTo(0, 0);

    (async () => {
      try {
        let response = await authenticatedHandler.getMe();

        let { firstName, lastName, investorStatus, doNotContact } = response;
        let values = { firstName, lastName, investorStatus, doNotContact };

        setValues(values, { isInitial: true });

        if (isMounted.current)
          setState({ ...state, isLoading: false });
      } catch (error) {
        if (isMounted.current)
          setState({ ...state, isLoading: false, formError: error });
      }

    })();

    return () => { isMounted.current = false; };
  }, []);

  async function handleSubmit(formProps) {
    if (isMounted.current)
      setState({ ...state, isSubmitting: true });

    try {

      await authenticatedHandler.updateMe(formProps.changed);

      if (isMounted.current) {
        resetForm();
        setState({
          ...state,
          isSubmitting: false,
          formMessage: 'Update successful',
          formError: null,
        });
      }

    } catch (error) {
      if (isMounted.current)
        setState({ ...state, isSubmitting: false, formError: error, formMessage: null });
    }
  }

  let firstNameProps = { name: 'firstName', label: 'First Name', type: 'string', required: true };
  let lastNameProps = { name: 'lastName', label: 'Last Name', type: 'string', required: true };
  let investorStatusProps = { name: 'investorStatus', label: 'Investor Status', required: true };
  let doNotContactProps = { name: 'doNotContact', label: 'Please do not contact me with marketing messages or product updates' };

  return (
    <>
    {state.isLoading &&
    <BackdropLoader />}

    <HooksForm
      id='user-personal-form'
      formState={formState}
    >
      {formProps => {
      let fieldEnabled = !state.isLoading && !state.isSubmitting;
      let submitEnabled = fieldEnabled && formProps.isValidated;
      let cancelEnabled = formProps.isChanged;
      return (
        <>
        <FormLayout>

          <Heading>{'Personal'}</Heading>

          {state.formMessage &&
          <FormMessage message={state.formMessage} />}

          {state.formError &&
          <FormError message={state.formError.message} />}

          <FormStack>

            <FormItem>
              <TextField {...fieldProps} {...firstNameProps} />
            </FormItem>

            <FormItem>
              <TextField {...fieldProps} {...lastNameProps} />
            </FormItem>

            <FormItem>
              <SelectTextField {...fieldProps} {...investorStatusProps} items={investorStatusTypes} />
            </FormItem>

            <FormItem>
              <Checkbox {...fieldProps} {...doNotContactProps} />
            </FormItem>

          </FormStack>

          <FormSpacer />

          <ButtonBar>
            <PrimaryButton
              aria-label='submit button'
              id='submit-button'
              title='Submit'
              disabled={!submitEnabled}
              onClick={() => handleSubmit(formProps)}
            />
            <SecondaryButton
              id='reset-button'
              aria-label='reset button'
              title='Reset'
              disabled={!cancelEnabled}
              onClick={resetValues}
            />
          </ButtonBar>

        </FormLayout>
        </>
      );}}
    </HooksForm>

    <DisplayState title='state' state={{ state, formState }} open={true} />
    </>
  );
}

Personal.propTypes = {
  location: PropTypes.shape(),
  history: PropTypes.shape(),
  checkboxProps: PropTypes.shape(),
  isValidated: PropTypes.bool,
  fieldProps: PropTypes.shape(),
  authenticatedHandler: PropTypes.shape(),
};
