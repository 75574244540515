import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { publicSchema } from '@org/client-graphql';
import { CustomRoute } from '@org/client-components-routes/CustomRoutes';

export function AuthorizedRoute(props) {
  // console.log('AuthenticatedRoute', props);
  let { allowed, ...rest } = props;

  let isAuthorized = false;
  const { data } = useQuery(publicSchema.GET_GROUPS);

  if (data?.groups)
    isAuthorized = data.groups.some(elem => allowed.includes(elem));

  return (<CustomRoute {...rest} test={isAuthorized} to='/user-not-authorized' />);
}

export default AuthorizedRoute;

AuthorizedRoute.propTypes = {
  allowed: PropTypes.array.isRequired,
};
