import { createTheme, responsiveFontSizes } from '@material-ui/core/styles';
import { grey, green } from '@material-ui/core/colors';

// import theme from '@org/client-libs-themes';

// https://material-ui.com/customization/default-theme/
// https://material-ui.com/customization/palette/#default-values
// https://material-ui.com/customization/breakpoints/
// https://material-ui.com/components/use-media-query/

// xs, extra-small: 0-599px
// sm, small: 600-959px
// md, medium: 960-1279px
// lg, large: 1280-1919px
// xl, extra-large: 1920+px

// const theme = React.useMemo(() => {
//   const nextTheme = createTheme(

const defaultTheme = createTheme();

// console.log(baseTheme);

export const APP_BAR_HEIGHT = defaultTheme.spacing(9); // 72
export const DRAWER_WIDTH = defaultTheme.spacing(30); // 240
export const FORM_WIDTH =  defaultTheme.spacing(45); // 360
export const MAX_INPUT_WIDTH = 36; // defaultTheme.spacing(42); // 336
export const FORM_FOOTER_OFFSET = defaultTheme.spacing(20); // 160
export const FORM_HEIGHT_OFFSET = APP_BAR_HEIGHT + FORM_FOOTER_OFFSET;

let theme = createTheme({
  // props: {}, //
  custom: {
    APP_BAR_HEIGHT,
    DRAWER_WIDTH,
    FORM_WIDTH,
    MAX_INPUT_WIDTH,
    FORM_HEIGHT_OFFSET,
    link: {
      // whiteSpace: 'nowrap', // prevents line breaks w/in link title
      whiteSpace: 'pre-wrap', // allows link to wrap on overflow
      display: 'inline', // prevents  default line breaks when link is embedded in text
      // wordWrap: 'break-word',
      // workBreak: 'break-all',
    },
    form: {
      root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        // padding: defaultTheme.spacing(0, 1, 0, 1),
        // width: FORM_WIDTH,
        width: '100%',
      },
      container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        // width: INPUT_WIDTH,
        // width: 'auto',
        // maxWidth: 'fit-content',
        width: FORM_WIDTH,
        maxWidth: FORM_WIDTH,
        padding: defaultTheme.spacing(0, 1, 0, 1),
      },
    },
    // input: {
    //   margin: defaultTheme.spacing(0.5, 0, 1.5, 0),
    // }
    // inputs: {
    //   width: INPUT_WIDTH,
    // },
  },
  //[theme.breakpoints.down('sm')]: {
  //  //backgroundColor: theme.palette.secondary.main,
  //  backgroundColor: red,
  //},
  breakpoints: {
    values: {
      // ...defaultTheme.breakpoints.values,
      // defaults
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
      // custom
      presentation: 900,
      card: 780,
    },
  },
  palette: {
    primary: {
      main: '#1976d2',
      // main: '#2196f3',
    },
    secondary: {
    //   // main: red[500],
      main: '#dc004e', // 'rgb(220, 0, 78)',
    //   // main: red.A400,
    //   //main: '#19857b',
    },
    error: {
      main: '#f44336',
    //   main: red.A400,
    },
    warning: {
      main: '#ffb74d',
    //   // main: '#19857b',
    //   main: yellow[500],
    },
    success: {
      main: '#4caf50',
      // main: '#19857b',
      // contrastText: '#fff',
    },
    // gray: '#6c757d',
    // gray: 'rgba(0, 0, 0, 0.54)',
    grey: {
      main: grey[500],
    },
    green: {
      main: green,
    },
  },
  typography: {
    fontSize: 14,
    htmlFontSize: 10,
    h1: {
      fontSize: '6rem',
      fontWeight: '400',
      // marginBottom: '2rem',
    },
    h2: {
      fontSize: '5rem',
      fontWeight: '400',
    },
    h3: {
      fontSize: '4rem',
      fontWeight: '400',
    },
    h4: {
      fontSize: '3rem',
      fontWeight: '400',
    },
    h5: {
      fontSize: '2.4rem',
      fontWeight: '400',
    },
    h6: {
      fontSize: '2.0rem',
      fontWeight: '400',
    },
    subtitle1: {
      fontSize: '1.6rem',
      fontWeight: '500',
    },
    subtitle2: {
      fontSize: '1.4rem',
      fontWeight: '500',
    },
    body1: {
      fontSize: '1.6rem',
    },
    body2: {
      fontSize: '1.4rem',
    },
    button: {
      fontSize: '1.4rem',
      fontWeight: '500',
    },
    caption: {
      fontSize: '1.2rem',
      fontWeight: '400',
    },
    overline: {
      fontSize: '1.2rem',
      fontWeight: '400',
    },
  },
  overrides: {
    // https://stackoverflow.com/questions/54236623/cant-remove-padding-bottom-from-card-content-in-material-ui/54239043
    MuiButton: {
      // fontSize: '1rem', // style does not exist???
      root: {
        textTransform: 'none', // 'turns off' auto-uppercase
        whiteSpace: 'nowrap', // stops text from wrapping
      },
    },
    MuiCardContent: {
      root: {
        padding: defaultTheme.spacing(2), // 16,
        '&:last-child': {
          paddingBottom: defaultTheme.spacing(2), // 16, // default: 24
        },
      },
    },
    MuiFormHelperText: {
      marginDense: {
        marginTop: defaultTheme.spacing(0),
      },
    },
    // MuiFormLabel: {
    //   root: {
    //     whiteSpace: 'nowrap',
    //     // width: '100%',
    //   },
    // },
    // MuiGrid: {
    //   // root: {
    //   //   // width: '100%',
    //   //   // display: 'flex',
    //   //   // flexDirection: 'column',
    //   //   // margin: 'auto',
    //   //   '&:last-child': {
    //   //     marginBottom: defaultTheme.spacing(0), // 0,
    //   //   },
    //   // },
    //   // item: {
    //   //   // margin: 'auto',
    //   //   marginBottom: defaultTheme.spacing(2),
    //   // },
    // },
    // MuiInputBase: {
    // //   root: {
    // //     width: INPUT_WIDTH,
    // //   },
    //   root: {
    //     whiteSpace: 'nowrap',
    //     // width: '100%',
    //   },
    // },
    MuiLink: {
      root: {
        // fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif;`,
        whiteSpace: 'nowrap',
      },
    },
    // MuiList: {
    //   root: {
    //     display: 'flex',
    //     flexDirection: 'column',
    //   },
    // },
    MuiOutlinedInput: {
      multiline: {
        paddingRight: defaultTheme.spacing(0), // space next to scroll bar in multiline TextField
      },
      // adornedEnd: {
      //   paddingRight: defaultTheme.spacing(1),
      // },
    },
    // PrivateNotchedOutline: {
    //   root: {
    //     width: '100%',
    //   },
    // },
    // MuiTextField: {
    //   root: {
    //     size: 'small',
    //   },
    // },
    // MuiSelect: {
    //   root: {
    //     width: '100%',
    //   },
    //   nativeInput: {
    //     width: '100%',
    //     minWidth: 'fit-content'
    //   }
    // },
    MuiToolbar: {
      root: {
        height: APP_BAR_HEIGHT,
      },
    },
  },
});

export const responsiveTheme = responsiveFontSizes(theme);

export default responsiveTheme;
