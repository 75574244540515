import React from 'react';
import PropTypes from 'prop-types';
// import { makeStyles } from '@material-ui/core/styles';
import { default as MuiTextareaAutosize } from '@material-ui/core/TextField';
// import { default as MuiTextareaAutosize } from '@material-ui/core/TextareaAutosize';
// import { green } from '@material-ui/core/colors';
// import theme from '@org/client-libs-themes';
import { CheckAdornment } from '@org/client-components-custom';
// import { styles } from '@org/client-libs-styles';
// import { useStyles } from './styles';

// const useStyles = makeStyles((theme) => ({
// // const localStyles = {
//   root: {
//     margin: theme.spacing(0, 0, 2, 0),
//     '& input:valid + fieldset': {
//       // borderColor: 'green',
//       borderColor: theme.palette.success.main,
//       borderWidth: 2,
//     },
//     '& input:invalid + fieldset': {
//       margin: theme.spacing(0, 0, 0, 0), // TODO Fix invalid spacing
//     },
//     // '& input:invalid + fieldset': {
//     //   borderColor: 'red',
//     //   borderWidth: 2,
//     // },
//     // '& input:valid:focus + fieldset': {
//     //   borderLeftWidth: 6,
//     //   padding: '4px !important', // override inline-style
//     // },
//     // '&:focus': {
//     //   boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
//     // },
//   },
// }));

// const theme = createTheme({
//   palette: {
//     primary: green,
//   },
// });

// const useStyles = makeStyles((theme) => (localStyles));

// const useStyles = makeStyles(styles);

export function TextAreaField(props) {
  // console.log('TextAreaField', props);

  // let classes = useStyles();

  let {
    // name, label, values, errors, validated,
    // handleChange, handleBlur, required, autoFocus,
    name, label, state, InputProps, endAdornment, showValidated,
    handleChange, handleBlur,
    ...rest
  } = props;
  let { values, validated, required, errors } = state;
  // let value = (values && name && values[name] != null ? values[name] : "");
  // let error = (errors && name && errors[name] ? true : false);
  // let isValid = validated[name] && !props.disabled;

  if (!name)
  console.error(`TextAreaField must receive \`name\` prop`);

  if (!label && (!props.children && typeof props.children !== 'string'))
    console.warn(`TextAreaField must receive 'label' prop or child of type 'string'`);

  if (!props.handleChange)
    console.warn('TextAreaField must receive `handleChange` prop', name);

  if (!props.handleBlur)
    console.warn('TextAreaField must receive `handleBlur` prop', name);

  if (!state)
    console.warn(`TextAreaField must receive \`state\` props from form`, name);

  if (showValidated) {
    let isValidated = !props.disabled && (props.validated ? props.validated : validated[name]);
    let defaultEndAdornment = <CheckAdornment position="end" show={isValidated} />;
    // console.log('defaultEndAdornment', typeof defaultEndAdornment);
    endAdornment = endAdornment ? endAdornment : defaultEndAdornment;
    InputProps = { endAdornment, ...InputProps }; // So we can pass an additional adornment
  }

  // const classes = useStyles();
  // let error = errors[name] ? true : false;

  // let style = { margin:  };
  // if (error)
  //   style = { margin: 0 };

  return(
    <MuiTextareaAutosize
      // {...props} // Warning: React does not recognize the `handleChange` prop on a DOM element.
      // {...rest}
      // className={classes.input}
      variant='outlined'
      // margin="normal"
      fullWidth
      id={name}
      name={name}
      label={label}
      value={values[name]}
      onChange={(event) => handleChange(event, name)}
      onBlur={(event) => handleBlur(event, name)}
      error={errors[name] ? true : false}
      helperText={errors[name]}
      required={required[name] ? true : false}
      InputProps={InputProps}
      // // type={type ? type : 'text'} // A valid HTML5 input type
      // // value={value}
      // initialValue={value} // fixes jumping cursor bug on edits
      // defaultValue={value} // fixes missing initial value when using initialValue
      // onChange={handleChange}
      // onBlur={(event) => handleBlur(event, name)}
      // error={error}
      // helperText={error && errors[name]}
      // required={required ? required : false}
      // disabled={props.disabled}
      // // autoFocus={autoFocus ? autoFocus : false}
      // // InputProps={{
      // //   endAdornment: <CheckAdornment position="end" show={isValid} />,
      // // }}
      multiline={true}
      rows={3}
      // rowsMin={3} // works
      // maxRows={5} // works
      size={'small'}
      {...rest}
    />
  );
}

export default TextAreaField;

TextAreaField.propTypes = {
  name: PropTypes.string,
  // type: PropTypes.string,
  label: PropTypes.string,
  state: PropTypes.shape(),
  // values: PropTypes.shape(),
  // errors: PropTypes.shape(),
  validated: PropTypes.shape(),
  handleChange: PropTypes.func,
  // handleChangeEvent: PropTypes.func,
  handleBlur: PropTypes.func,
  disabled: PropTypes.bool,
  // required: PropTypes.bool,
  // autoFocus: PropTypes.bool,
  endAdornment: PropTypes.shape(),
  InputProps: PropTypes.shape(),
  children: PropTypes.oneOfType([ PropTypes.string, PropTypes.array ]),
};
