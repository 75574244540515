import React from 'react';
import PropTypes from 'prop-types';
// import { makeStyles } from '@material-ui/core/styles';
import { default as MuiFab } from '@material-ui/core/Fab';
// import AddIcon from '@material-ui/icons/Add';
// import EditIcon from '@material-ui/icons/Edit';

// const useStyles = makeStyles((theme) => ({
//   button: {
//     // margin: theme.spacing(1),
//     textTransform: 'none', // 'turns off' auto-uppercase
//   },
//   // linkButton: {
//   //   margin:
//   // }
//   successButton: {
//     // margin: theme.spacing(1),
//     backgroundColor: theme.palette.success.main,
//     borderColor: theme.palette.success.main,
//   },
// }));

export function FabButton(props) {
  // const classes = useStyles();

  return (
    <MuiFab {...props} >
      {props.icon}
    </MuiFab>
  );
}

FabButton.propTypes = {
  icon: PropTypes.shape(),
};
