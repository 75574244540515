import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

const { NODE_ENV, REACT_APP_STAGE } = process.env;

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '90%',
  },
  details: {
    width: 'auto',
    marginTop: '1rem', 
    marginBottom: '1rem', 
    padding: '1rem',
    borderRadius: '3px',
    boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
  },
});

export function DisplayState(props) {
  // console.log('DisplayState', props);
  const classes = useStyles();
  let open = props.open ? props.open : false;
  return(
    <>
      {(NODE_ENV === 'development' && REACT_APP_STAGE === 'dev') &&
      <Box className={classes.root} >
        <details open={open} className={classes.details} >
          <summary style={{ cursor: 'pointer'}} >{props.title}</summary>
          <pre>{JSON.stringify(props.state, null, 2)}</pre>
        </details>
      </Box>}
    </>
  );
}

DisplayState.propTypes = {
  title: PropTypes.string,
  open: PropTypes.bool,
  state: PropTypes.shape({}),
};
