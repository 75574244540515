// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date
// https://stackoverflow.com/questions/40561484/what-data-type-should-be-use-for-timestamp-in-dynamodb
// let date = new Date(); // UTC time as Object
// let date = new Date().toISOString(); // UTC time as String with format 'YYYY-MM-DDTHH:mm:ss.sssZ'
// let date = new Date().toUTCString(); // UTC time as String with format 'Wed, 14 Jun 2017 07:00:00 GMT'
// let date = Date.now(); // UTC time as Number of milliseconds since epoch
// let date = new Date().getTime(); // UTC time as Number of milliseconds since epoch
// let expiresDate = Date.now() + 1000 * 60 * 60 * 24; // milliseconds * seconds * minutes * hours = 1 day
// let expiresAt = new Date(expiresDate).toUTCString(); // 'Wed, 14 Jun 2017 07:00:00 GMT'
// let expiresAt = timestamp(expiresDate); // 'YYYY-MM-DDTHH:mm:ss.sssZ'

export const MS_TO_1D_CODE_EXPIRATION = 1000 * 60 * 60 * 24; // 24 hours
export const MS_TO_7D_CODE_EXPIRATION = 1000 * 60 * 60 * 24 * 7; // 7 days
export const MS_IN_1D = 1000 * 60 * 60 * 24; // 24 hours
export const MS_IN_7D = MS_IN_1D * 7;

export function timestamp(date) {
  // console.log('timestamp', date, typeof date);
  if (!date)
    date = new Date();
  else if (typeof date === 'number')
    date = new Date(date); // convert to Object
  // console.log('timestamp', date.toISOString());
  if (typeof date === 'object')
    return date.toISOString();
  else
    throw new Error('Unexpected date type in timestamp...');
}

export function expiration(timeToExpire) {
  // in milliseconds
  // 1 min = 1000 * 60 = 60,000
  // 1 hour = * 60 = 360,000
  // 1 day = * 24 = 86,400,000
  // 1 week = * 7 = 604,800,000
  let nowDate = new Date();
  // let timeToExpire = 1000 * 60 * 60 * 24; // 1 hour * 24; // 1 day // TODO Set this in DB or ENV?
  let expiresEpoch = nowDate.getTime() + timeToExpire;
  let expiresDate = new Date(expiresEpoch);
  let expiresAt = expiresDate.toISOString();
  // console.log('expiration', 'expiresEpoch', expiresEpoch, 'expiresDate', expiresDate, 'expiresAt', expiresAt);
  return expiresAt;
}

// NumericDate is the number of seconds (not milliseconds) since the Epoch (1970-01-01T00:00:00Z)
export function epochToDate(epoch) {
  return new Date(epoch * 1000);
}

export function dateToEpoch(date) {
  if (typeof date === 'number')
    return Math.floor( date / 1000 );
  else if (typeof date === 'object')
    return Math.floor( date.getTime() / 1000 );
  else if (typeof date === 'string')
    return Math.floor( new Date(date).getTime() / 1000 );
  else
    throw new Error('Unknown date format in dateToEpoch');
}

// [Intl NumberFormat](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat)
// let amount = dollarAmount.format(something); // works on string or number input types
export const dollarAmount = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });

export function amountFormat(number) {
  const dollarAmount = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });
  return dollarAmount.format(number);
}

/**
 * Percentage format
 */
export function percentage({ value, places }) {
  // console.log(value, places);
  let percentage = new Intl.NumberFormat('en-US', { style: 'percent', /* signDisplay: 'exceptZero' */ minimumFractionDigits: places ? places : 1 });
  return percentage.format(value);
}

/**
 * Decimal format
 */
export function decimal({ value, places }) {
  // console.log(value, places);
  let decimal = new Intl.NumberFormat('en-US', { maximumSignificantDigits: places ? places : 1 });
  return decimal.format(value);
}

/**
 * Returns a Number rounded to AT LEAST the number of places. Trailing zeros will also be removed.
 * e.g. round(10.201, 2) = 10.2
 */
export function round(value, places) { // (20783.98765, 2)
  // return Number(value.toFixed(places)); // 20783.99
  return Number(value.toFixed(places)).toLocaleString(); 
}
