import { getSessionCookie } from '@org/client-graphql';

const { REACT_APP_IS_OFFLINE } = process.env;

// For end2end testing
export const customFetch = async (uri, options) => {
  // console.info('customFetch', uri, options);

  // Outside the browser we need to manage headers manually
  // This is only used for e2e testing
  if (REACT_APP_IS_OFFLINE) {
    console.info('customFetch', 'REACT_APP_IS_OFFLINE', 'Setting session cookie...');

    let sessionCookie = getSessionCookie();

    if (sessionCookie?.length) {
      // Add the cookie if the the test has set it
      sessionCookie = sessionCookie.split(';')[0];
      options.headers.Cookie = sessionCookie;
    } else if(options.headers.Cookie) {
      // Otherwise remove it if it exists in the headers
      // Is this necessary? Headers aren't persistent in offline tests, are they?
      console.warn('Deleting session cookie...');
      delete options.headers.Cookie;
    }

    // Add the Origin // Why do we need this?
    // REACT_APP_HTTP_URI: 'http://localhost:4011/dev'
    // options.headers.Origin = process.env.REACT_APP_HTTP_URI;
  }
  // console.log('customFetch', 'options.headers', options.headers);
  // return fetch(uri, options);

  // what was this for? does it even work??
  try {
    return fetch(uri, options);
    // response = await fetch(uri, options);
    // console.log('customFetch', response);
  } catch (error) {
    // this catch block is not catching errors
    // POST http://localhost:4011/dev/authenticated 400 (Bad Request)
    console.error('customFetch', error);
    return { success: false, data: null, error};
  }
};
