import React from 'react';
import PropTypes from 'prop-types';
import { default as MuiButton } from '@material-ui/core/Button';

export function Button(props) {
  // console.log('Button', props);
  let { title, name, ...rest } = props;

  if (!title && props.children)
    title = props.children;
  else if (!title && !props.children)
    title = 'Submit';

  if (!props.onClick && !props.to)
    console.warn(`Buttons must be passed either 'onClick' or 'to' in props '${name}'`);

  return(
    <div>
      <MuiButton
        id={name}
        name={name}
        variant='contained'
        color='default'
        size='medium'
        {...rest}
      >
      {title}
      </MuiButton>
    </div>
  );
}

export default Button;

Button.propTypes = {
  name: PropTypes.string,
  title: PropTypes.string,
  onClick: PropTypes.func,
  to: PropTypes.string,
  children: PropTypes.node,
};

export function SmallButton(props) {
  return(
    <Button size='small' {...props} />
  );
}

export function LargeButton(props) {
  return(
    <Button size='large' {...props} />
  );
}

export function PrimaryButton(props) {
  return(
    <Button color='primary' {...props} />
  );
}

export function SecondaryButton(props) {
  props = { ...props, color: 'secondary' };
  return(
    <Button color='secondary' {...props} />
  );
}

export function TextButton(props) {
  return(<Button variant='text' { ...props } />);
}

/**
 * IntraLinkButton
 * Only for intra-page links to avoid using RouterLink. In general, use `InLink`
 * for internal navigation.
 */
export function IntraLinkButton(props) {
  return(<MuiButton variant='text' href={`#${props.to}`} color='primary' { ...props } />);
}

IntraLinkButton.propTypes = {
  to: PropTypes.string.isRequired,
};
