import { decodeTime } from 'ulid';
import dayjs from 'dayjs';
import cloneDeep from 'lodash-es/cloneDeep';
// import { AuthorizedHandler } from '@org/client-graphql';

export let fields = [
  {
    type: 'text',
    name: 'id',
    label: 'ID',
    initial: "",
  },
  {
    type: 'text',
    name: 'userId',
    label: 'User ID',
    initial: "",
  },
];

export const columns = [
  { field: 'id', headerName: 'ID', width: 170 },
  // { field: 'firstName', headerName: 'First Name', width: 130 },
  // { field: 'lastName', headerName: 'Last Name', width: 130 },
  // { field: 'fullName', headerName: 'Full Name', width: 160,
  //   valueGetter: (params) => `${params.getValue(params.id, 'firstName')} ${params.getValue(params.id, 'lastName')}` },
  // { field: 'confirmationDate', headerName: 'Confirmation Date', width: 170, type: 'date' },
  { field: 'userId', headerName: 'User ID', width: 170 },
];

export async function getRows(props) {
  let { authorizedHandler } = props;

  let alerts = await authorizedHandler.listAlerts();

  alerts = cloneDeep(alerts);
  
  alerts.forEach(alert => {
    alert.creationDate = dayjs(decodeTime(alert.id)).format('YY-MM-DD hh:mm a');
  });

  return alerts;
}

export async function refreshRows(props) {
  let { authorizedHandler } = props;

  let alerts = await authorizedHandler.refreshAlerts();

  alerts = cloneDeep(alerts);

  alerts.forEach(alert => {
    alert.creationDate = dayjs(decodeTime(alert.id)).format('YY-MM-DD hh:mm a');
  });

  return alerts;
}

export async function createItem(props) {
  // console.log('Users', 'createItem', props);
  let { authorizedHandler, input } = props;

  return authorizedHandler.createAlert(input);
}

export async function updateItem(props) {
  // console.log('Users', 'updateItem', props);
  let { authorizedHandler, input } = props;

  return authorizedHandler.updateAlert(input);
}

export async function deleteRows(props) {
  let { authorizedHandler, selections } = props;

  if (selections.length > 1) {
    // batchDelete
  } else { // just 1 selection
    let selection = selections[0];
    let input = { id: selection.id };
    return authorizedHandler.deleteAlert(input);
  }
}

export async function handleRowClick(event) {
  alert(JSON.stringify(event.row, null, ' '));
}
