import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { AuthenticatedHandler } from '@org/client-graphql/authenticated/handler';
import {
  Page, Heading,
} from '@org/client-components-custom';
import { DisplayState } from '@org/client-components-utils/DisplayState';
import { Chart } from './Chart';

let authenticatedHandler;

export function User(props) {
  const [ state, setState ] = useState({ isLoading: true, values: {} });

  if (!authenticatedHandler)
    authenticatedHandler = new AuthenticatedHandler({ location: props.location, history: props.history });

  useEffect(() => {
    let isMounted = true;

    (async () => {
      isMounted = true;
      window.scrollTo(0, 0);

      try {
        let transactions = [], trades = [];
        // let [ transactions, trades ] = await Promise.all([
        //   authenticatedHandler.listTransactions(),
        //   authenticatedHandler.listTrades(),
        // ]);

        let balance = 0;
        let data = [];
        let items = transactions.data.items;
        for (let index in items) {
          let item = items[index];
          // console.log('item', index, item);

          if ([ 'DEPOSIT', 'PAYMENT', 'WITHDRAWAL' ].includes(item.itemType)) {
            let date = dayjs(item.eventDate).format(`MMM 'YY`);
            let exists = data.find(o => o.date === date);
            // console.log('exists', exists);

            if (exists)
              exists.amount += item.amount;
            else {
              balance += item.amount;
              data.push({ date, amount: balance});
            }
          }
        }

        if (isMounted)
          setState({ ...state, isLoading: false, values: { data, transactions, trades } });

      } catch (error) {
        if (isMounted)
          setState({ ...state, isLoading: false, formError: error });
      }
    })();
    
    return () => {
      isMounted = false;
    };
  }, []);

  return(
    <Page id='user-welcome' >

      <Heading>{'User Home'}</Heading>
  
      {/* Coming soon... */}

      <Chart data={state.values.data} />

      <DisplayState title='state' state={{ state }} open={true} />

    </Page>
  );
}

export default User;

User.propTypes = {
  location: PropTypes.shape(),
  history: PropTypes.shape(),
};
