import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import * as yup from 'yup/es';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
// import Link from '@material-ui/core/Link';
import Container from '@material-ui/core/Container';
// import { Link as RouterLink } from 'react-router-dom';
import { useForm, HooksForm } from '@org/client-components-core/form/useForm';
import { TextField } from '@org/client-components-core/inputs/TextField';
import { PasswordField } from '@org/client-components-core/inputs/PasswordField';
import { Button } from '@org/client-components-core/buttons/Buttons';
import { FormHeading, FormError } from '@org/client-components-custom';
import { DisplayState } from '@org/client-components-utils/DisplayState';
import { useValidate, emailShape, passwordShape } from '@org/common-yup';
import { styles } from './styles';
// import { authorizedResolvers } from '@org/client-graphql';

// TODO Don't allow Authenticated User to Sign In or Sign Up again...

// let location = 'pages.admin.ResetUserPassword';


const useStyles = makeStyles(styles);

let initialValues = {
  email: "",
  password: "",
};

let validationShape = {
  email: emailShape,
  password: passwordShape,
};
const validationSchema = yup.object().shape(validationShape);

export function SetUserPassword(props) {
  const [ error, setError ] = useState();
  const [ state, setState ] = useState({
    isLoading: true, isSubmitting: false, formMessage: null, formError: null,
  });
  const isMounted = useRef(false);
  const [ validate ] = useValidate(validationSchema);
  const {
    formState, fieldProps, // setValues, resetValues, resetForm
  } = useForm({ initialValues, validate });
  let { authorizedHandler } = props;

  let classes = useStyles();

  async function handleSubmit(event, form) {
    // form.handleSetSubmitting();
    try {
      let { email, password } = form.values;
      let input = { email, password };

      // let response = await authorizedResolvers.setUserPassword(input);
      let response = await authorizedHandler.setUserPassword(input);
      if (response.success) {
        // await publicResolvers.setAuthentication(response.data.authentication);
        // props.history.push('/user');
        console.log('success');
      }
      if (response.error) {
        console.log('SignIn', response.error);
        setError(response.error);
      }
    } catch (error) {
      console.log(location, 'Unknown error in handleSubmit', error);
      setError('Unknown error in handleSubmit');
    }
  }

  return (
    <>
    <HooksForm
      id='admin-set-user-password-form'
      formState={formState}
    >
      {formProps => {
      let fieldEnabled = !state.isLoading && !state.isSubmitting;
      let submitEnabled = fieldEnabled && formProps.isValidated;
      return (
        <>
        <Container component="main" maxWidth="xs" className={classes.children} >

          <Grid className={classes.paper} >

            <Grid item >
              <FormHeading title='Set User Password' />
            </Grid>
            {/* <Grid item >
              <FormMessage message='Admin Reset Password' />
            </Grid> */}

            {error &&
            <Grid item >
              <FormError message={error.message} />
            </Grid>}

            <Grid item >
              <TextField
                name='email'
                label='Email'
                {...fieldProps}
                required={true}
              />
            </Grid>

            <Grid item >
              <PasswordField name='password' {...fieldProps} required={true} />
            </Grid>

            <Grid item className={clsx(classes.centered, classes.buttonSpan)} >
              <Button title='Submit' disabled={!submitEnabled}
                onClick={() => handleSubmit(props)} />
            </Grid>

          </Grid>

        </Container>
        </>
      );}}
    </HooksForm>

    <DisplayState title='state' state={{ state, formState }} />
    </>
  );
}

SetUserPassword.propTypes = {
  // history: PropTypes.shape({
  //   push: PropTypes.func,
  // }),
  // isValidated: PropTypes.bool,
  // isSubmitting: PropTypes.bool,
  // fieldProps: PropTypes.shape(),
  // passwordProps: PropTypes.shape(),
  // buttonProps: PropTypes.shape(),
  // stateProps: PropTypes.shape(),
  authorizedHandler: PropTypes.shape(),
};
