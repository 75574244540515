import dayjs from 'dayjs';
import * as yup from 'yup/es';
import { RecordClass } from './Record';
import { deepDifference } from '@org/common-tools';
import {
  currencyShape, itemTypeShape, ulidShape,
} from '@org/common-yup';

let validationShape = {
  itemType: itemTypeShape,
  id: ulidShape,
  referenceType: itemTypeShape, // 'NOTE' | 'LOAN'
  referenceId: ulidShape,
  eventDate: dayjs().format('YYYY-MM-DD'),
  interestAmount: currencyShape.required('Original Balance is required'),
  principalAmount: currencyShape.required('Original Balance is required'),
  paymentAmount: currencyShape.required('Original Balance is required'),
  balance: currencyShape.required('Original Balance is required'),
};
export const validationSchema = yup.object().shape(validationShape);

export class CashflowClass extends RecordClass {
  constructor(input) {
    super(input);

    // this.table = UserTable;

    this.validationSchema = validationSchema;

    this.keys.itemType = 'CASHFLOW';
    // this.keys.itemType = `CASHFLOW-${input.referenceId}-${input.referenceId}`;

    this.defaultValues = {
      referenceType: "", // 'NOTE' | 'LOAN'
      referenceId: "",
      eventDate: dayjs().format('YYYY-MM-DD'),
      interestAmount: 0.0,
      principalAmount: 0.0,
      paymentAmount: 0.0,
      balance: 0.0,
    };

    this.requiredValues = {};
    // this.requiredValues = {
    //   referenceType: true,
    //   referenceId: true,
    // };

    // this.attributes = {
    //   ...this.defaultValues,
    // };

    // this.calculateValues(input);

    // if (input)
    //   this.update(input);

    this.update(input);
  }

  calculateValues(input) {
    console.info(`${this.constructor.name} calculateValues input: ${JSON.stringify(input, null, ' ')}`);

    let diff = deepDifference(input, this.values());
    this.attributes = { ...this.attributes, ...diff };

    return this.attributes;
  }

  createLoanCashflow(item) {
    let input = {
      referenceType: item.itemType,
      referenceId: item.id,
      eventDate: item.nextPaymentDate,
      interestAmount: item.paymentAmount,
      principalAmount: 0,
      paymentAmount: item.paymentAmount,
      balance: item.currentBalance,
    };
    this.update(input);
  }

  createNoteCashflow(note, cashflow) {
    let input = {
      referenceType: note.itemType,
      referenceId: note.id,
      eventDate: note.nextPaymentDate,
      interestAmount: cashflow.paymentAmount,
      principalAmount: cashflow.paymentAmount,
      paymentAmount: cashflow.paymentAmount,
      balance: cashflow.currentBalance,
    };
    this.update(input);
  }

}
