import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
// import { authenticatedResolvers } from '@org/client-graphql';
import { AuthenticatedHandler } from '@org/client-graphql/authenticated/handler';
import { BackdropLoader } from '@org/client-components-core/feedback/Loaders';
import { Modal } from '@org/client-components-core/utils/Modal';
import { Page } from '@org/client-components-custom/page/Page';
import { Heading } from '@org/client-components-custom/page/Heading';
import { FlexRow } from '@org/client-components-custom/layout/Flex';
import { DisplayState } from '@org/client-components-utils/DisplayState';
import { dollarAmount } from '@org/common-formatters';
import theme from '@org/client-libs-themes';
import { styles } from '@org/client-libs-styles';

const useStyles = makeStyles(styles);

// let loading;
// let data;
// let error;

// const notessSubscription = authenticatedResolvers.watchNotes()
//   .subscribe({
//     next: ({ loading, data }) => {
//       console.log(loading, data);
      
//     },
//     error: (e) => console.error(e)
//   });

let authenticatedHandler;

let transactions;
// let notes;
let trades;
// let balance = 0;
// let activity;
// let notesSubscription;

// let activeRow;
let detail = {};
let rows = [];

function TransactionDetail() {
  return (
    <div style={{height: 'auto', width: 'auto', padding: '10px', whiteSpace: 'pre-wrap', backgroundColor: 'white'}}>
      {JSON.stringify(detail, null, 4)}
    </div>
  );
}

export function Transactions(props) {
  const [ state, setState ] = useState({ isLoading: true, formMessage: null, formError: null, rows: [] });
  const [ open, setOpen ] = useState(false);
  let classes = useStyles();

  if (!authenticatedHandler)
    authenticatedHandler = new AuthenticatedHandler({ location: props.location, history: props.history });

  useEffect(() => {
    // console.log('*****useEffect*****', state, !state.rows.length && state.isLoading);
    let isMounted = true;
    window.scrollTo(0, 0);

    (async () => {
      // if (!state.rows.length && state.isLoading) {
        try {
          // let [ notes, transactions ] = await Promise.all([
          let response = await Promise.all([
            // authenticatedResolvers.listTransactions(),
            // authenticatedResolvers.listTrades(),
            authenticatedHandler.listTransactions(),
          //  authenticatedHandler.listTrades(),
            // authenticatedResolvers.listNotes(),
          ]);
          // console.log('Transactions', response);

          // transactions = response[0]?.data?.items ? response[0].data.items : [];
          transactions = response[0];
        //  trades = response[1];
          // notes = response[2].data.items;

          // balance = transactions[transactions.length-1]?.balance;
          rows = transactions;

          // let rows = [];
          // transactions.forEach(transaction => {
          //   // console.error('YYYYYYY', rows);
          //   // console.log('transaction', transaction);
          //   let index = notes.findIndex(note => { return note.id === transaction.itemId; });
          //   if (index === -1)
          //     throw new Error(location, 'Item not found:', transaction.itemId);
          //   let note = notes[index];
          //   // console.log('note', note);
          //   rows.push({ ...transaction, referenceItem: { ...note } });
          // });
          // console.error('rowsXXXXX', rows);

          if (isMounted)
            setState({ ...state, isLoading: false, rows: rows });
        } catch (error) {
          // console.log('Transactions', 'formError', error);
          if (isMounted)
            setState({ ...state, isLoading: false, formError: error});
        }

        // https://dev.to/bdbch/automatically-watch-queries-in-apollo-graphql-n45
        // https://codesandbox.io/s/apollo-client-watch-query-6ik5n?from-embed=&file=/src/client.js
        try {
          // let watched = await authenticatedResolvers.watchNotes();
          // console.log('Activity', watched, typeof watched, watched.subscribe);
          // watched.subscribe({
          //   next: ({ loading, data }) => {
          //     console.log(loading, data);
          //   },
          //   error: (e) => console.error(e)
          // });
          // console.log('Activity', watched);

          // This works!!!
          // notesSubscription = await authenticatedResolvers.watchNotes()
          //   .subscribe({
          //     next: ({ loading, data }) => {
          //       console.log('next', loading, data);
          //     },
          //     error: (e) => console.error(e)
          //   });
          // console.log('Activity', notesSubscription);
        } catch (error) {
          console.error('Activity', error);
        }
      // }
    })();

    // console.warn('End useEffect');

    return () => {
      // notesSubscription.unsubscribe();
      isMounted = false;
    };
  // }, [state.rows]);
  }, []);

  function handleClose() {
    setOpen(false);
  }

  async function handleRowClick(event, row) {
    // console.log('handleRowClick', row);
    detail.transaction = row;
    // if (row.itemType === 'TRADE') {
    //   let response = await authenticatedResolvers.getTrade({ id: row.id });
    //   detail.trade = response;
    // }
    setOpen(true);
    // props.history.push({
    //   pathname: '/user/transaction',
    //   state: {
    //     data: row,
    //   },
    // });
  }

  // let balance = 0;

  return(
    <>
    {state.isLoading &&
    <BackdropLoader />}

    <Page id='user-transactions-page' >

      <Heading>{'Activity'}</Heading>

      <FlexRow style={{ justifyContent: 'center', margin: theme.spacing(0, 0, 2, 0) }} >
        <Typography variant={'h6'} style={{ fontStyle: 'italic' }} >
          {'*** Transactions may be deleted periodically in order to improve the demo experience for new users ***'}
        </Typography>
      </FlexRow>

      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>{'Date'}</TableCell>
              {/* <TableCell>{'Transaction ID'}</TableCell> */}
              <TableCell>{'Type'}</TableCell>
              <TableCell>{'Description'}</TableCell>
              {/* <TableCell>{'Item Type'}</TableCell> */}
              {/* <TableCell>{'Item ID'}</TableCell> */}
              {/* <TableCell>{'Item Name'}</TableCell> */}
              {/* <TableCell align="right">{'Amount'}</TableCell> */}
              <TableCell>{'Debit'}</TableCell>
              <TableCell>{'Credit'}</TableCell>
              <TableCell>{'Amount'}</TableCell>
              <TableCell>{'Balance'}</TableCell>
              {/* <TableCell>{'Price'}</TableCell> */}
              {/* <TableCell>{'Invested'}</TableCell> */}
              {/* <TableCell>{'Status'}</TableCell> */}
              {/* <TableCell align="right">Protein&nbsp;(g)</TableCell> */}
            </TableRow>
          </TableHead>

          <TableBody>
            {state.rows.map((row) => {
            {/* console.log(row.eventDate); */}
            return (
              <TableRow key={row.id} onClick={(event) => handleRowClick(event, row)} hover={true} >
                {/* <TableCell component="th" scope="row" >{row.id}</TableCell> */}
                {/* <TableCell component="th" scope="row" >{dt}</TableCell> */}
                {/* <TableCell component="th" scope="row" >{row.createdAt}</TableCell> */}
                {/* <TableCell component="th" scope="row" >{dayjs(row.createdAt).format('MM/DD/YYYY')}</TableCell> */}
                <TableCell component="th" scope="row" >{dayjs(row.eventDate).format('MM/DD/YYYY')}</TableCell>
                {/* <TableCell>{row.id}</TableCell> */}
                {/* <TableCell>{row.xType}</TableCell> */}
                <TableCell>{row.itemType}</TableCell>
                <TableCell>{row.description}</TableCell>
                {/* <TableCell>{row.itemId}</TableCell> */}
                {/* <TableCell>{row.referenceItem.itemName}</TableCell> */}
                {/* <TableCell align="right" >{dollarAmount.format(row.xAmount)}</TableCell> */}
                {/* <TableCell>{dollarAmount.format(row.xPrice)}</TableCell> */}
                <TableCell>{dollarAmount.format(row.debit)}</TableCell>
                <TableCell>{dollarAmount.format(row.credit)}</TableCell>
                <TableCell>{dollarAmount.format(row.amount)}</TableCell>
                <TableCell>{dollarAmount.format(row.balance)}</TableCell>
              </TableRow>
            );})}
          </TableBody>
        </Table>
      </TableContainer>

      <Modal id='transactions-modal' open={open} handleClose={handleClose} className={classes.presentationModal} >
        <TransactionDetail />
      </Modal>

      <DisplayState title='state' state={{ state, rows, trades }} open={true} />

    </Page>
    </>
  );
}

export default Transactions;

Transactions.propTypes = {
  location: PropTypes.shape(),
  history: PropTypes.shape(),
};
