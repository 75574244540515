import React from 'react';
import AddIcon from '@material-ui/icons/Add';
import { FabButton } from '@org/client-components-core/buttons/FabButton';

export function AddFabButton(props) {
  let icon = <AddIcon />;
  return (
    <FabButton color='primary' size='small' aria-label='add button' icon={icon} {...props} />
  );
}
