import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
// import { styles } from '@org/client-libs-styles';
// import theme from '@org/client-libs-themes';

let styles = {
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    // flexGrow: 0,
    // flexGrow: 1, // causes the height to grow unnecessarily
    height: 'auto',
    minHeight: 'fit-content',
    width: '100%',
    // [theme.breakpoints.only('xs')]: { // sm, small: 600px
    //   flexDirection: 'column',
    // },
  },
  rowCentered: {
    justifyContent: 'center',
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    // flexGrow: 1,
    width: '100%',
    // width: 'auto',
    // minWidth: 'fitContent',
  },
};

const useStyles = makeStyles(styles);

export function FlexRow(props) {
  let classes = useStyles();

  // let className = props.className ? clsx(classes.flexRow, props.className) : classes.flexRow;

  return (
    <div className={classes.flexRow} {...props} >
      {props.children}
    </div>
  );
}

FlexRow.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

export function FlexRowCentered(props) {
  let { style, ...rest } = props;
  style = { ...style, justifyContent: 'center' };
  // console.log('FelxRowCenterd', style);
  // return(<FlexRow {...rest} style={style} />);
  return(<FlexRow {...rest} style={style} />);
  // return(<FlexRow style={{ justifyContent: 'center' }} {...props} />);
}

FlexRowCentered.propTypes = {
  style: PropTypes.shape(),
};

export function FlexColumn(props) {
  // console.log('FlexColumn', props);
  let classes = useStyles();

  // This doesn't work. The injection of style tags happens in the same order as
  // the invocation of `makeStyles`.
  // https://material-ui.com/styles/advanced/#makestyles-withstyles-styled
  // let className = props.className ? clsx(props.className, classes.flexColumn) : classes.flexColumn;

  return (
    <div className={classes.flexColumn} {...props} >
      {props.children}
    </div>
  );
}

FlexColumn.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

export function FlexColumnLeft(props) {
  return(<FlexColumn style={{ alignItems: 'flex-start' }} {...props} />);
}

export function FlexColumnCentered(props) {
  return (<FlexColumn style={{ justifyContent: 'center', flexGrow: 1, height: '100%' }} {...props} />);
}
