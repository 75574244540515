import { ApolloClient, InMemoryCache, ApolloLink, HttpLink, gql } from '@apollo/client';
// import { ApolloClient } from 'apollo-client';
// import { ApolloLink } from 'apollo-link';
// import { createHttpLink } from 'apollo-link-http';
// import { RetryLink } from 'apollo-link-retry';
// import { InMemoryCache } from 'apollo-cache-inmemory';
// import { gql } from 'graphql-tag';
// import { retryLink } from '../common/retry';
import { errorLink } from '@org/client-graphql';
// import mocks from './mocks';
// import { offlineLink, getSessionCookie } from '../common/offline';
import { offlineLink } from '../common/offline';
import { customFetch } from '../common/fetch';
import { publicSchema } from './schema';

// const { REACT_APP_HTTP_URI, REACT_APP_IS_OFFLINE } = process.env;
const { REACT_APP_HTTP_URI } = process.env;

// const location = 'client.graphql.public.client';

// // sessionCookie and the getter and setter are for offline end-to-end testing only
// // let cookies = [];
// let sessionCookie = "";
// export const getSessionCookie = () => {
//   return sessionCookie;
// };
// export const setSessionCookie = (value) => {
//   sessionCookie = value;
// };

// mocks = (REACT_APP_STAGE === 'dev') ? mocks : false; // ???g

// const customFetch = (uri, options) => {
//   if (REACT_APP_IS_OFFLINE) {
//     let sessionCookie = getSessionCookie();
//     if (sessionCookie.length) {
//       sessionCookie = sessionCookie.split(';')[0];
//       options.headers.Cookie = sessionCookie;
//       // console.log('customFetch', 'options.headers', options.headers);
//     }
//   }
//   return fetch(uri, options);
// };

// const httpLink = createHttpLink({
const httpLink = new HttpLink({
  uri: REACT_APP_HTTP_URI + '/public',
  credentials: 'include', // This includes cookies in client fetch (here or below???)
  fetch: customFetch,
});

// // `offlineLink` is used handle cookies when running offline end-to-end tests.
// // Because we're using Apollo, we aren't calling fetch directly, so we don't
// // have access to the response headers when fetch is called.
// // Cookies in response headers are set on: headers.set-cookie = [ cookie1, ... ]
// const offlineLink = new ApolloLink((operation, forward) => {
//   // console.log('offlineLink', 'operation', operation);
//   // console.log('offlineLink', 'forward', forward);

//   return forward(operation).map(response => {
//     // console.log('offlineLink', 'response', response);
//     // console.log('testLink', REACT_APP_IS_OFFLINE, REACT_APP_HTTP_URI);
//     if (REACT_APP_IS_OFFLINE) {
//       let context = operation.getContext();
//       // console.log('offlineLink', 'context.response.headers', context.response.headers);
//       // console.log('offlineLink', 'context.response.headers[\'set-cookie\']', context.response.headers['set-cookie']);
//       // if (context.response.headers['set-cookie'])
//       //   sessionCookie = context.response.headers.get('set-cookie');
//       try {
//         let cookies = context.response.headers.get('set-cookie');
//         // console.log('cookies', cookies, typeof cookies);
//         if (typeof cookies == 'string')
//           sessionCookie = cookies;
//       } catch (error) {
//         // console.log(`[ offlineLink Error ]: ${error}`);
//       }
//       // URGENT Does more than one cookie come in an array?
//       // console.log('testLink', 'sessionCookie', sessionCookie, typeof sessionCookie);
//       // let resolver = Object.keys(response.data)[0];
//       // response.data[resolver].cookie = cookie;
//       // console.log('offlineLink', 'sessionCookie', sessionCookie);
//     }
//     return response;
//   }); 

// });

const publicLink = ApolloLink.from([
  // retryLink,
  errorLink,
  offlineLink,
  httpLink,
]);

const typeDefs = gql`
  extend type Query {
    isLoading: Boolean!
    isOnline: Boolean!
    isAuthenticated: Boolean!
    isAdministrator: Boolean!
    isDeveloper: Boolean!
    isSuper: Boolean!
    #groups: [ String ] # URGENT This is not setting an arry but a JSON object...?
  }
  # Can I do this?
  # extend type Mutation {
  #   setGroups: Boolean!
  # }
`;

const resolvers = {
  Query: {
    isLoading: () => true,
    // isOnline: () => false,
    // isOnline: () => navigator.onLine,
    isOnline: () => {
      // console.log('XXXXXXXXXX');
      return navigator.onLine;
    },
    isAuthenticated: () => false,
    isAdministrator: () => false,
    isDeveloper: () => false,
    isSuper: () => false,
    groups: () => { return []; },
  },
  // Mutation: {
  //   set
  // }
};

export const publicCache = new InMemoryCache();

publicCache.writeQuery({
  query: publicSchema.IS_LOADING,
  data: { isLoading: true },
});

// publicCache.writeData({
//   data: {
//     // isOnline: navigator.onLine,
//     isAuthenticated: false,
//     isAdministrator: false,
//     isDeveloper: false,
//     isSuper: false,
//     groups: [],
//   },
// });

publicCache.writeQuery({
  query: publicSchema.IS_AUTHENTICATED,
  data: { isAuthenticated: false },
});

publicCache.writeQuery({
  query: publicSchema.IS_ADMINISTRATOR,
  data: { isAdministrator: false },
});

publicCache.writeQuery({
  query: publicSchema.IS_DEVELOPER,
  data: { isDeveloper: false },
});

publicCache.writeQuery({
  query: publicSchema.IS_SUPER,
  data: { isSuper: false },
});

publicCache.writeQuery({
  query: publicSchema.GET_GROUPS,
  data: { getGroups: [] },
});

const defaultOptions = {
  query: {
    // fetchPolicy: 'no-cache',  // individual resolver settings not working with this set here
    // 'no-cache' stops local resolvers from working!
    errorPolicy: 'all',
  },
  mutate: {
    // fetchPolicy: 'no-cache', stops local resolvers from working!
    errorPolicy: 'all',
  },
  watchQuery: {
    // fetchPolicy: 'cache-and-network', stops local resolvers from working!
    errorPolicy: 'all',
  },
};

export const publicConfig = {
  link: publicLink,
  cache: publicCache,
  defaultOptions,
  typeDefs, // local
  resolvers, // local
  connectToDevTools: process.env.NODE_ENV === 'development' ? true : false,
  // credentials: 'include', // This includes cookies in client fetch (here or above???)
  // mocks,
};

export const publicClient = new ApolloClient(publicConfig);
