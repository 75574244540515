import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import * as yup from 'yup/es';
import Grid from '@material-ui/core/Grid';
import { useForm, HooksForm } from '@org/client-components-core/form/useForm';
import { FormLayout, FormSpacer } from '@org/client-components-custom/layout/Form';
import { TextField } from '@org/client-components-core/inputs/TextField';
import { EmailField } from '@org/client-components-core/inputs/EmailField';
import { TextAreaField } from '@org/client-components-core/inputs/TextAreaField';
import { PrimaryButton } from '@org/client-components-core/buttons/Buttons';
import { SmallLinkButton } from '@org/client-components-core/buttons/LinkButtons';
import { ButtonBar } from '@org/client-components-core/buttons/ButtonBar';
import { AddFabButton } from '@org/client-components-custom/buttons/AddFabButton';
import { FlexRow } from '@org/client-components-custom/layout/Flex';
import { Heading } from '@org/client-components-custom/page/Heading';
import { FormMessage, FormError } from '@org/client-components-custom/page/FormMessage';
import { DisplayState } from '@org/client-components-utils/DisplayState';
import { useValidate, firstNameShape, lastNameShape, emailShape } from '@org/common-yup';
import theme from '@org/client-libs-themes';

let initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  message: "I hope this note finds you well. I wanted to show you the project I’ve been working on for some time, and invite you to be a demo user. I still have some work to do but I'm finally getting close to being ready to launch. And hopefully you will be interested in using the site when we go live!\n\nThanks,\n\nZach",
};

let requiredValues = {
  firstName: true,
  lastName: true,
  email: true,
  message: true,
};

let validationShape = {
  firstName: firstNameShape,
  lastName: lastNameShape,
  email: emailShape,
    // .required("Email is required"),
  message: yup.string(),
};
const validationSchema = yup.object().shape(validationShape);

// function calculate(input) {

// }

export function CreateInvitation(props) {
  // console.log('CreateInvitation', 'props', props);
  const [ state, setState ] = useState({
    isLoading: true, isSubmitting: false, isCompleted: false, formMessage: null, formError: null,
  });
  const isMounted = useRef(false);
  const [ validate ] = useValidate(validationSchema);
  const { formState, fieldProps, resetValues } = useForm({ initialValues, requiredValues, validate });
  let { authorizedHandler } = props;

  useEffect(() => {
    isMounted.current = true;
    window.scrollTo(0, 0);

    if (isMounted.current)
      setState({ ...state, isLoading: false});

    return () => { isMounted.current = false; };
  }, []);

  function handleAddClick() {
    resetValues();
    setState({ ...state, isCompleted: false, formMessage: null, formError: null });
  }

  async function handleSubmit(formProps) {
    // console.log('CreateInvitation', 'handleSubmit', formProps);
    setState({ ...state, isSubmitting: true, formError: null });

    try {
      // let response = await authorizedHandler.initiateInvitation(formProps.values);
      await authorizedHandler.initiateInvitation(formProps.values);

      setState({ ...state, isSubmitting: false, isCompleted: true, formMessage: 'Invitation successfully created', formError: null });
    } catch (error) {
      setState({ ...state, isSubmitting: false, formMessage: null, formError: error });
    }
  }

  return (
    <>
    <HooksForm
      id='admin-create-invitation-form'
      formState={formState}
    >
      {formProps => {
      let fieldEnabled = !state.isLoading && !state.isSubmitting && !state.isCompleted;
      let submitEnabled = fieldEnabled && formProps.isValidated;
      let resetEnabled = formProps.isChanged;
      return (
        <>
        <FormLayout>

          <FlexRow style={{ justifyContent: 'space-between', margin: theme.spacing(0, 0, 1, 0) }} >
            {/* <InLink to='/invest' title='Back' /> */}
            <SmallLinkButton name='back-button' title='Back' to='/admin' />
            <AddFabButton onClick={() => handleAddClick(formProps)} disabled={!resetEnabled} />
          </FlexRow>

          <Heading>{'Create Invitation'}</Heading>

          <Grid style={{width: '100%'}} >

            {state.formMessage &&
            <Grid item >
              <FormMessage message={state.formMessage} />
            </Grid>}

            {state.formError &&
            <Grid item >
              <FormError message={state.formError.message} />
            </Grid>}

            <Grid item >
              <TextField
                { ...fieldProps }
                name='firstName'
                label='First Name'
                disabled={!fieldEnabled}
                showValidated
              />
            </Grid>

            <Grid item >
              <TextField
                { ...fieldProps }
                name='lastName'
                label='Last Name'
                disabled={!fieldEnabled}
                showValidated
              />
            </Grid>

            <Grid item >
              <EmailField {...fieldProps} disabled={!fieldEnabled} showValidated />
            </Grid>

            <Grid item >
              <TextAreaField
                {...fieldProps}
                name='message'
                label='Message'
                multiline={true}
                rows={14}
                disabled={!fieldEnabled}
                style={{ margin: 0 }}
              />
            </Grid>

            <FormSpacer />

            <ButtonBar>
              <PrimaryButton name='submit-button' title='Submit' disabled={!submitEnabled}
                onClick={() => handleSubmit(formProps)} />
              {/* <SecondaryButton title='Reset' disabled={!submitEnabled}
                onClick={resetValues} /> */}
            </ButtonBar>

          </Grid>

        </FormLayout>
        </>
      );}}
    </HooksForm>

    <DisplayState title='state' state={{ state, formState }} open={true} />
    </>
  );
}

export default CreateInvitation;

CreateInvitation.propTypes = {
  location: PropTypes.shape(),
  history: PropTypes.shape(),
  submitProps: PropTypes.shape(),
  resetForm: PropTypes.func,
  authorizedHandler: PropTypes.shape(),

};
