import React, { Suspense, lazy, useEffect } from 'react';
import PropTypes from 'prop-types';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import SlideshowIcon from '@material-ui/icons/Slideshow';
import GetAppIcon from '@material-ui/icons/GetApp';
import { makeStyles } from '@material-ui/core/styles';
import { BackdropLoader } from '@org/client-components-core/feedback/Loaders';
import { Modal } from '@org/client-components-core/utils/Modal';
import { Page } from '@org/client-components-custom/page/Page';
import { Heading } from '@org/client-components-custom/page/Heading';
import { FlexRowCentered } from '@org/client-components-custom/layout/Flex';
import { dollarAmount, percentage } from '@org/common-formatters';
import theme from '@org/client-libs-themes';
import { fundInfo } from './fundInfo';
const ProFormaDownload = lazy(() => import('./download/ProFormaDownload'));
const FundPresentation = lazy(() => import('./presentation/FundPresentation'));
const RequestSubDocs = lazy(() => import('./RequestSubDocs'));

let styles = {
  presentationLink: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    margin: theme.spacing(0),
  },
  presentationModal: {
    flexGrow: 1,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'baseline',
    width: '100%',
    margin: theme.spacing(0, 0, 1, 0),
    [theme.breakpoints.only('xs')]: {
      flexDirection: 'column',
    },
  },
  linkRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'baseline',
    margin: theme.spacing(0, 0, 1, 0),
    width: '100%',
    '& > *': {
      margin: theme.spacing(0, 1, 0, 1),
    },
    [theme.breakpoints.only('xs')]: {
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
  },
  rowLabel: {
    [theme.breakpoints.only('xs')]: {
      textDecoration: 'underline'
    },
    [theme.breakpoints.up('sm')]: {
      minWidth: '20rem',
    },
  },
  fieldVariant: 'body1',
  fieldValue: {
    lineHeight: 1.4,
  },
  variants: {
    [theme.breakpoints.only('xs')]: {
      title: 'h5',
      field: 'body1',
    },
    [theme.breakpoints.up('sm')]: {
      title: 'h3',
      field: 'h6',
    },
  },
};

const useStyles = makeStyles(styles);

function FieldSpan(props) {
  const classes = useStyles();
  return(
    <span className={classes.row} >
      <Typography variant={styles.fieldVariant} className={classes.rowLabel} >
        {props.title}
      </Typography>
      <Typography variant={styles.fieldVariant} className={classes.fieldValue} >
        {props.value}
      </Typography>
    </span>
  );
}

FieldSpan.propTypes = {
  title: PropTypes.string,
  value: PropTypes.string,
};

export function TheFund() {
  // let isMounted = false;
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();

  useEffect(() => {
    // isMounted = true;
    window.scrollTo(0, 0);

    // return () => { isMounted = false; };
  }, []);

  const handleOpen = () => {
    setOpen(true);
  };
  
  const handleClose = () => {
    setOpen(false);
  };

  let presentationProps = { handleClose };

  return (
    <Page id='the-fund' >

      <Heading>{fundInfo.fundName}</Heading>

      <FlexRowCentered style={{ margin: theme.spacing(0, 0, 2, 0) }} >
        <Typography variant={'h6'} style={{ fontStyle: 'italic' }} >
          {'*** ALL TERMS ARE SUBJECT TO CHANGE ***'}
        </Typography>
      </FlexRowCentered>

      <FieldSpan title='Description' value={fundInfo.description} />

      <div className={classes.linkRow} >

          <Link component="button" variant={styles.fieldVariant} onClick={handleOpen} className={classes.presentationLink} >
            <SlideshowIcon fontSize='medium' />
            <span style={{ marginLeft: '0.3rem'}} >Investor Presentation</span>
          </Link>

          <Modal id='the-fund-modal' open={open} handleClose={handleClose} className={classes.presentationModal} >
            <Suspense fallback={<BackdropLoader loading={true} />} >
              <FundPresentation {...presentationProps} />
            </Suspense>
          </Modal>

          <ProFormaDownload underline='none' title={'Pro Forma'} variant='body1' >
            <GetAppIcon fontSize='medium' style={{color: theme.palette.primary.main}} />
          </ProFormaDownload>

          <RequestSubDocs />

      </div>

      <FieldSpan title='Manager' value={fundInfo.manager} />

      <FieldSpan title='Fund Type' value={fundInfo.fundType} />
  
      <FieldSpan title='Minimum Investment' value={dollarAmount.format(fundInfo.minInvestment)} />

      <FieldSpan title='Management Fee' value={fundInfo.managementFee ? percentage({ value: fundInfo.managementFee, places: 2 }) : 'None'} />

      <FieldSpan title='Incentive Fee' value={fundInfo.incentiveFee ? percentage.format({ value: fundInfo.incentiveFee, places: 2 }) : 'None'} />

      {/* <FieldSpan title='Lockup' value={decimal.format(fundInfo.lockup) ? decimal.format(fundInfo.lockup) : 'None'} /> */}
      <FieldSpan title='Lockup' value={fundInfo.lockup ? fundInfo.lockup.toString() : 'None'} />

      {/* <FieldSpan title='Liquidity' value={fundInfo.liquidity} /> */}
      {/* <FieldSpan title='Notice' value={fundInfo.notice} /> */}
      {/* <FieldSpan title='Redemption Type' value={fundInfo.redemptionType} /> */}
      {/* <FieldSpan title='Withdrawals' value={fundInfo.liquidity, fundInfo.notice + ' Days Notice, ' + fundInfo.redemptionType} /> */}
      <FieldSpan title='Withdrawals' value={fundInfo.liquidity + ', ' + fundInfo.noticeDays + ', ' + fundInfo.redemptionType} />

      <FieldSpan title='Administrator' value={fundInfo.administrator} />

      <FieldSpan title='Counsel' value={fundInfo.counsel} />

      <FieldSpan title='Auditor' value={fundInfo.auditor} />

      <FieldSpan title='Custodian' value={fundInfo.custodian} />

      <FieldSpan title='Trustee' value={fundInfo.trustee} />

    </Page>
  );
}

export default TheFund;
