import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
// import SvgIcon from '@material-ui/core/SvgIcon';
import { SvgIcon } from '@org/client-components-core/display/SvgIcon';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > svg': {
      margin: theme.spacing(2),
    },
  },
}));

// <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" /> // NavigateNextIcon
// <path d="M5.59 7.41L10.18 12l-4.59 4.59L7 18l6-6-6-6zM16 6h2v12h-2z" /> // LastPageIcon

// Custom made by me using Material-UI ChevronRightIcon
export function NextPageIcon(props) {
  return (
    <SvgIcon {...props}>
      <title>Next Page Icon</title>
      <desc>Two Chevrons Pointing Right</desc>
      <path d="M 5.59 7.41 l 4.41 4.59 L 5.41 16.59 L 7 18 l 6 -6 l -6 -6 z M 18 12 L 12 18 l -1.59 -1.41 L 15 12 L 10.59 7.41 L 12 6 L 18 12 z" />
    </SvgIcon>
  );
}

// https://material-ui.com/components/icons/#svgicon
export function HomeIcon(props) {
  return (
    <SvgIcon {...props}>
      <title>Home Icon</title>
      <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
    </SvgIcon>
  );
}

// https://feathericons.com/?query=loader
export function LoaderIcon(props) {
  return (
    <SvgIcon {...props} >
      <title>Loader Icon</title>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-loader"><line x1="12" y1="2" x2="12" y2="6"></line><line x1="12" y1="18" x2="12" y2="22"></line><line x1="4.93" y1="4.93" x2="7.76" y2="7.76"></line><line x1="16.24" y1="16.24" x2="19.07" y2="19.07"></line><line x1="2" y1="12" x2="6" y2="12"></line><line x1="18" y1="12" x2="22" y2="12"></line><line x1="4.93" y1="19.07" x2="7.76" y2="16.24"></line><line x1="16.24" y1="7.76" x2="19.07" y2="4.93"></line></svg>
    </SvgIcon>
  );
}

export function SvgIconsSize() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <HomeIcon fontSize="small" />
      <HomeIcon />
      <HomeIcon fontSize="large" />
      <HomeIcon style={{ fontSize: 40 }} />
    </div>
  );
}
