import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
// import Typography from '@material-ui/core/Typography';
// import Box from '@material-ui/core/Box';
// import { Page, PageHeadingWrapper, PageHeadingLeft, PageHeadingCenter, PageHeadingRight, BackIconButton, Loader } from '@org/client-components-custom';
// import { Form } from '@org/client-components-core/form/Form';
import { TextField } from '@org/client-components-core/inputs/TextField';
import { EmailField } from '@org/client-components-core/inputs/EmailField';
import {
  FormLayout, FormMessage, FormError,
  HeadingWrapper, HeadingLeft, HeadingCenter, HeadingRight, HeadingTitle,
  BackIconButton
} from '@org/client-components-custom';
import { DisplayState } from '@org/client-components-utils/DisplayState';
import { authorizedResolvers } from '@org/client-graphql';
import { authSchema } from '@org/common-yup';
// import theme from '@org/client-libs-themes';
import { styles } from '@org/client-libs-styles';
// import { assetTypes, assetSubTypes, referenceAssetTypes } from '@org/common-data-types';

const localStyles = {
  ...styles,
};

const useStyles = makeStyles(localStyles);

let defaultValues = {
  email: "",
};

let stateValues = {};

let isMounted = false;

export function UserDetail(props) {
  const [state, setState] = useState({ isLoading: true, isSubmitting: false, isEditing: false, formMessage: null, formError: null });
  let classes = useStyles();

  useEffect(() => {
    isMounted = true;

    (async () => {
      if (state.isLoading) {
        window.scrollTo(0, 0);

        if (isMounted) {
          if (props.location.state && props.location.state.data)
            stateValues = props.location.state.data;
          else
            stateValues = defaultValues;
          console.log('stateValues', stateValues);

          let input = { id: stateValues.id };
          console.log('input', input);
          let response = await authorizedResolvers.getAuth(input);

          if (response.success && response.data && response.data)
            stateValues = { ...stateValues, ...response.data };

          setState({ ...state, isLoading: false });
        }
      }
    })();

    return () => {
      isMounted = false;
    };
  }, []);

  return(
    <>
    <Form id='user-detail' defaultValues={defaultValues} stateValues={stateValues} validationSchema={authSchema} {...props} >
      {props => {
      {/* let enabled = props.isValidated && !state.isSubmitting; */}
      let firstNameProps = { name: 'firstName', label: 'First Name', required: true, disabled: !state.isEditing };
      let lastNameProps = { name: 'lastName', label: 'Last Name', required: true, disabled: !state.isEditing };
      let emailProps = { name: 'email', label: 'email', required: true, disabled: !state.isEditing };
      return (
        <>
        <FormLayout id='user-detail' >

          <HeadingWrapper>
            <HeadingLeft>
              <BackIconButton history={props.history} />
            </HeadingLeft>
            <HeadingCenter>
              <HeadingTitle>{'User Detail'}</HeadingTitle>
            </HeadingCenter>
            <HeadingRight/>
          </HeadingWrapper>

          <Grid id='sign-in' style={{width: '100%'}} >

            {state.formMessage &&
            <Grid item >
              <FormMessage message={state.formMessage} />
            </Grid>}

            {state.formError &&
            <Grid item >
              <FormError message={state.formError.message} />
            </Grid>}

            <Grid item >
              <TextField {...firstNameProps} {...props.fieldProps} />
            </Grid>

            <Grid item >
              <TextField {...lastNameProps} {...props.fieldProps} />
            </Grid>

          <Grid item >
            <EmailField {...emailProps} {...props.fieldProps} />
          </Grid>

          </Grid>

        </FormLayout>

        {/* <DisplayState title='state' state={{ state, stateValues, form: props.stateProps }} open={true} /> */}
        <DisplayState title='state' state={{ state, form: props.stateProps }} open={true} />
        </>
      );
      }}
    </Form>
    </>
  );
}

UserDetail.propTypes = {
  location: PropTypes.shape(),
  history: PropTypes.shape(),
  isValidated: PropTypes.bool,
  fieldProps: PropTypes.shape(),
  passwordProps: PropTypes.shape(),
  submitProps: PropTypes.shape(),
  stateProps: PropTypes.shape(),
};
