import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
// import { PublicHandler, AuthenticatedHandler } from '@org/client-graphql';
import { BackdropLoader } from '@org/client-components-core/feedback/Loaders';
// import { FadeInSection } from '@org/client-components-core/transitions/FadeInSection';
import { Page, Heading } from '@org/client-components-custom';
import { PublicNoteCards } from '@org/client-components-custom/cards/PublicNoteCards';
import { AuthenticatedNoteCards } from '@org/client-components-custom/cards/AuthenticatedNoteCards';
import { DisplayState } from '@org/client-components-utils/DisplayState';

let isAuthenticated = false;

let notes = [];
let loans = [];

let notesSubscription;

export function Invest(props) {
  const isMounted = useRef(false);
  const [ state, setState ] = useState({
    isLoading: true, isUpdating: false, isSubmitting: false, formError: null
  });
  let { publicHandler, authenticatedHandler } = props;

  useEffect(() => {
    isMounted.current = true;
    window.scrollTo(0, 0);
    // authenticatedHandler = new AuthenticatedHandler({ location: props.location, history: props.history });

    (async () => {
      try {

        isAuthenticated = await publicHandler.isAuthenticated();

        let values = {}; // , notes = [], loans = [];
        if (isAuthenticated) {
          console.log('Here');
          [ notes, loans ] = await Promise.all([
            // authenticatedHandler.listNotes({ sort: false }),
            authenticatedHandler.listNotes(),
            authenticatedHandler.listLoans(),
            // authenticatedHandler.refreshNotes(),
          ]);
          console.warn('Invest', notes, loans);

          // notesSubscription = await authenticatedHandler.watchNotes();
          // console.log('Activity', notesSubscription);
          // notesSubscription.subscribe({
          //   next: ({ loading, data }) => {
          //     // setState({ ...state, isUpdating: true });
          //     console.log('next', loading, data);
          //     notes = data.listNotes;
          //     // setState({ ...state, isUpdating: false });
          //   },
          //   error: (e) => console.error(e)
          // });
          // loans = await authenticatedHandler.listLoans();
  
          // notes.forEach(note => {
          //   let index = loans.findIndex(loan => loan.id === note.referenceId);
          //   console.warn(note, index, loans[index]);
          //   if (index === -1)
          //     console.warn(`note ${note.id} did not find loan referenceId ${note.referenceId}`);
          //   else
          //     note.referenceItem = loans[index];
          // });
          // values = notes;
        } else {
          let response = await publicHandler.listNotes({ sort: false });
          values = response;
        }
        console.log('values', values);

        if (isMounted.current)
          setState({ ...state, isLoading: false, values, notes, loans });

      } catch (error) {
        console.error(error);
        if (isMounted.current)
          setState({ ...state, isLoading: false, formError: error });
      }
    })();
    
    return () => {
      isMounted.current = false;
      // notesSubscription.unsubscribe();
    };
  }, []);

  async function handleDetail(inputs) {
    console.log('Invest', 'handleDetail', inputs);
    let { note, referenceItem } = inputs;
    try {
      localStorage.setItem('noteId', props.note.id);
      // localStorage.setItem('referenceType', props.note.referenceType);
      // localStorage.setItem('referenceId', props.note.referenceId);
      props.history.push({
        pathname: '/invest/detail',
        state: {
          note,
          referenceItem,
        },
      });
    } catch (error) {
      if (isMounted.current)
        setState({ ...state, isSubmitting: true });
      console.error(error);
    }
  }

  // console.log(isAuthenticated, state);

  // let items = state.values ? state.values : [];

  // let { notes, loans } = state;

  let noteCardProps = { ...props, isAuthenticated, notes, loans };

  // console.log(isAuthenticated, state, items);

  return(
    <>
    {state.isLoading &&
    <BackdropLoader />}

    <Page id='invest-page' >

      <Heading>{'Invest'}</Heading>

      {(!state.isLoading && !isAuthenticated) &&
      <PublicNoteCards handleDetail={handleDetail} isAuthenticated={state.isAuthenticated} />}

      {!state.isLoading && !notes.length && 
      <Typography variant='h4' component='h2' >{'There are no investments available at this time'}</Typography>}

      {!state.isLoading && !!notes.length &&
      <AuthenticatedNoteCards handleDetail={handleDetail} { ...noteCardProps } />}

      <DisplayState title='state' state={{ isAuthenticated, state, location: props.location, history: props.history }} open={false} />
    </Page>
    </>
  );
}

Invest.propTypes = {
  location: PropTypes.shape(),
  history: PropTypes.shape(),
  isAuthenticated: PropTypes.bool,
};
