import { ulid } from 'ulid';
import dayjs from 'dayjs';
import * as yup from 'yup/es';
// import { round } from '@org/common-formatters';
// import { toBytes } from '@org/common-tools';
// import { v4 as uuid } from 'uuid';
import round from 'lodash-es/round';
import { RecordClass } from '@org/common-classes/Record';
import {
  currencyShape, dateStringShape, factorShape, itemTypeShape, rateShape, ulidShape,
} from '@org/common-yup';

let validationShape = {
  id: ulidShape,
  itemType: itemTypeShape,
  transactionId: ulidShape,
  eventDate: dateStringShape,
  accountType: yup.string(),
  accountId: ulidShape,
  categoryType: yup.string(),
  categoryName: yup.string(),
  description: yup.string(),
  debit: currencyShape,
  credit: currencyShape,
  amount: currencyShape,
  referenceType: yup.string(),
  referenceId: ulidShape,
  // balance: currencyShape,
  // total: currencyShape,
};
export const validationSchema = yup.object().shape(validationShape);

export class TransactionClass extends RecordClass {
  constructor(input) {
    super(input);
    // console.info('Transaction Class constructor', input);
    // this.name = 'Transactions'; //  eslint-disable-line

    // if (!input)
    //   throw new Error('Transaction Class constructor requires input!');

    if (!input.itemType)
      throw new Error('TransactionClass constructor requires input.itemType!');

    // this.keys.itemType = input.itemType; // Transaction

    // this.values.id = input?.id ? input.id : ulid(); // Same as refId
    // this.values.itemType = input?.itemType ? input.itemType : ""; // Same as refType
    // this.values.userId = input?.userId ? input.userId : "";
    // // this.eventDate = input?.eventDate ? input.eventDate : (new Date()).getTime();
    // this.values.eventDate = input?.eventDate ? (dayjs(input.eventDate)).format('YYYY-MM-DD') : (dayjs()).format('YYYY-MM-DD');
    // this.values.description = input?.description ? input.description : "";
    // this.values.referenceType = input?.referenceType ? input.referenceType : "";
    // this.values.referenceId = input?.referenceId ? input.referenceId : "";
    // // this.referenceId = toBytes(input.referenceId);
    // // this.referenceId = uuid.nice();
    // // this.referenceId = `CAST(${input.referenceId}, BITEA)`;
    // // this.referenceId = `CAST("${input.referenceId}" AS BITEA)`;
    // // this.referenceId = `CAST("01F2H62TXPCJHY82G6XPA6W1H6" AS BITEA)`;
    // // this.referenceId = "01F2H62TXPCJHY82G6XPA6W1H6";
    // // this.referenceId = `encode("referenceId", 'base64')`;
    // this.values = { ...defaultValues };

    // this.table = UserTable;

    this.validationSchema = validationSchema;

    this.defaultValues = {
      // id: "", // UUID PK
      // itemType: "", // DEPOSIT, TRADE, PAYMENT, WITHDRAWAL, etc
      transactionId: "",
      eventDate: (dayjs()).format('YYYY-MM-DD'), // Deprecated, use int(?) value 2021
      // userId: "", // Deprecated, use accountType + accountId
      accountType: "", // USER, LOAN, NOTE, etc
      accountId: "", // userId, loanId, noteId, etc
      categoryType: "", // ASSET
      categoryName: "", // CASH
      description: "",
      debit: 0.0,
      credit: 0.0,
      amount: 0.0,
      referenceType: "", // e.g. note payment used for user payments
      referenceId: "",
      // balance: 0.0,
      // total: 0.0, // Is this for getBalance?
    };

    // if (input)
    //   this.values = { ...this.values, ...input };

    // If I keep this, change values to attributes or values() not longer works!
    // if (input?.debit) {
    //   // console.log(typeof input.debit);
    //   let amount = round(input.debit, 2);
    //   this.values.debit = amount;
    //   this.values.amount = -1 * amount; // URGENT This is only correct for account types that increase with a CREDIT
    //   this.values.credit = 0;
    // } else if (input?.credit) {
    //   // console.log(typeof input.credit);
    //   let amount = round(input.credit, 2);
    //   this.values.credit = amount;
    //   this.values.amount = amount;
    //   this.values.debit = 0;
    // } else {
    //   // throw new Error('Transactions must include either a debit or a credit amount');
    //   this.values.credit = 0;
    //   this.values.debit = 0;
    //   this.values.amount = 0;
    // }

    // Only Transactions overwrite the itemType field
    // let itemType = input.itemType; // Deposit, Trade, Payment, etc
    this.update(input);
    // this.keys.itemType = itemType;
    console.log('Transaction', this.values());
  }

  /**
   * Transactions
   * id: ULID (PK -- same for the itemType entry below in Data table)
   * itemType: DEPOSIT | WITHDRAWAL | TRADE | CASHFLOW | PAYMENT
   * 
   */

  create() {
    // let query = `
    //   CREATE TABLE IF NOT EXISTS "Transactions" (
    //     "id"                 CHAR(26) PRIMARY KEY NOT NULL,
    //     "itemType"           VARCHAR(16) NOT NULL,
    //     "userId"             CHAR(26) NOT NULL,
    //     "eventDate"          DATE NOT NULL,
    //     "description"        VARCHAR(64) NOT NULL,
    //     "amount"             NUMERIC(15,2) NOT NULL,
    //     "debit"              NUMERIC(15,2) NOT NULL,
    //     "credit"             NUMERIC(15,2) NOT NULL,
    //     "referenceType"      VARCHAR(16),
    //     "referenceId"        CHAR(26)
    //   );
    // `;

    let query = `
      CREATE TABLE IF NOT EXISTS "Transactions" (
        "id"                 CHAR(26) PRIMARY KEY NOT NULL,
        "uuid"               BYTEA,
        "itemType"           VARCHAR(32) NOT NULL,
        "transactionId"      CHAR(26),
        "eventDate"          CHAR(10) NOT NULL,
        "userId"             CHAR(26),
        "accountType"        VARCHAR(32) NOT NULL,
        "accountId"          CHAR(26) NOT NULL,
        "categoryType"       VARCHAR(32) NOT NULL,
        "categoryName"       VARCHAR(32),
        "description"        VARCHAR(64),
        "debit"              NUMERIC(15,2) NOT NULL,
        "credit"             NUMERIC(15,2) NOT NULL,
        "amount"             NUMERIC(15,2) NOT NULL,
        "status"             VARCHAR(32),
        "referenceType"      VARCHAR(32),
        "referenceId"        VARCHAR(26)
      );
    `;
    // "referenceId"        BYTEA
    // "uuid"               UUID DEFAULT uuid_generate_v4 ()
    console.info('Transactions', 'create', query);
    return query;
  }

  delete() {
    let { itemType, id } = this.values;
    let query = ``
      + `DELETE FROM "Transactions" `
      + `WHERE "itemType" = '${itemType}' AND "id" = '${id}';`;
    console.info(this.constructor.name, 'delete', query);
    return query;
  }

  insert() {
    // let { itemType, id, userId, eventDate, description, amount, debit, credit, referenceType, referenceId } = this.values;
    let { id, itemType } = this.keys;
    let {
      // id,
      // itemType,
      transactionId,
      eventDate,
      accountType,
      accountId,
      categoryType,
      categoryName,
      description,
      debit,
      credit,
      amount,
      // status,
      referenceType,
      referenceId,
    // } = this.values;
    } = this.attributes;

    // // let items = '"itemType", "id", "userId", "eventDate", "description", "amount", "debit", "credit", "referenceType", "referenceId"';
    // let items = '"itemType", "id", "userId", "eventDate", "description", "amount", "debit", "credit", "referenceType", "referenceId"';
    // let query = 'INSERT INTO "Transactions" (' + items + ') VALUES($1, $2, $3, $4, $5, $6, $7, $8, $9, $10) RETURNING *';
    // // let query = 'INSERT INTO "Transactions" (' + items + ') VALUES($1, $2, $3, $4, $5, $6, $7, $8, $9, CAST($10 AS BYTEA)) RETURNING *';
    // let inputs = [ itemType, id, userId, eventDate, description, amount, debit, credit, referenceType, referenceId ];
  
    let items = '"id", "itemType", "transactionId", "eventDate", "accountType", "accountId", "categoryType", "categoryName", "description", "debit", "credit", "amount", "referenceType", "referenceId"';
    let query = 'INSERT INTO "Transactions" (' + items + ') VALUES($1, $2, $3, $4, $5, $6, $7, $8, $9, $10, $11, $12, $13, $14) RETURNING *';
    let inputs = [ id, itemType, transactionId, eventDate, accountType, accountId, categoryType, categoryName, description, debit, credit, amount, referenceType, referenceId ];

    // console.info('Transaction', 'insert', this.values(), query, inputs);
    console.info('Transaction', 'insert', query, inputs);
    return { query, inputs };
  }

  updateItem() {
    let { itemType, id, userId, eventDate, description, amount, debit, credit, referenceType, referenceId } = this.values;

    // let query = `UPDATE "Transactions" SET "userId" = '${this.userId}', "eventDate" = CAST('${this.eventDate}' AS DATE), "description" = '${this.description}', "amount" = ${this.amount}, "debit" = ${this.debit}, "credit" = ${this.credit}, "referenceType" = '${this.referenceType}', "referenceId" = '${this.referenceId}' WHERE "id" = '${this.id}';`;
    let query = ``
      + `UPDATE "Transactions" SET "userId" = '${userId}', "eventDate" = CAST('${eventDate}' AS DATE), "description" = '${description}', "amount" = ${amount}, "debit" = ${debit}, "credit" = ${credit}, "referenceType" = '${referenceType}', "referenceId" = '${referenceId}' `
      + `WHERE "itemType" = '${itemType} AND "id" = '${id}';`;

    console.info('Transactions', 'update', query);

    return { query };
  }

  // migrate() {
  //   let query = `ALTER TABLE "TRANSACTIONS" `
  //     + `ADD COLUMN accountId CHAR(26) NOT NULL;`;

  //   console.info('Transactions', 'update', query);

  //   return { query };
  // }

  // values() {
  //   // return { ...this.attributeValues, ...this.keys };
  //   // return this.values;
  //   return { ...this.values };
  // }
}
