import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import * as yup from 'yup/es';
import Grid from '@material-ui/core/Grid';
import { useForm, HooksForm } from '@org/client-components-core/form/useForm';
import { FormLayout, FormSpacer } from '@org/client-components-custom/layout/Form';
import { PasswordField } from '@org/client-components-core/inputs/PasswordField';
import { PasswordFieldTooltip } from '@org/client-components-core/inputs/PasswordFieldTooltip';
import { ButtonBar } from '@org/client-components-core/buttons/ButtonBar';
import { PrimaryButton, SecondaryButton } from '@org/client-components-core/buttons/Buttons';
import { BackdropLoader } from '@org/client-components-core/feedback/Loaders';
import {
  Heading, FormMessage, FormError
} from '@org/client-components-custom';
import { DisplayState } from '@org/client-components-utils/DisplayState';
import { useValidate, passwordShape } from '@org/common-yup';

let initialValues = {
  password: "",
  newPassword: "",
};

let requiredValues = {
  password: true,
  newPassword: true,
};

let validationShape = {
  password: passwordShape,
  newPassword: passwordShape,
};
const validationSchema = yup.object().shape(validationShape);

export function ChangePassword(props) {
  const [ state, setState ] = useState({ isLoading: true, isSubmitting: false, isCompleted: false, formMessage: null, formError: null });
  const isMounted = useRef(false);
  const [ validate ] = useValidate(validationSchema);
  const {
    formState, fieldProps,
  } = useForm({ initialValues, requiredValues, validate });
  let { authenticatedHandler } = props;

  useEffect(() => {
    isMounted.current = true;
    window.scrollTo(0, 0);

    if (isMounted.current)
      setState({ ...state, isLoading: false});

    return () => { isMounted.current = false; };
  }, []);

  async function handleBlur(event, formProps) {
    // console.log('ChangePassword', 'handleBlur', event, formProps);
    if (formProps.values.password === formProps.values.newPassword && formProps.validated.password) {
      if (isMounted.current)
        setState({ ...state, formError: { message: 'You cannot use the same password' } });
    } else {
      if (isMounted.current && state.formError)
        setState({ ...state, formError: null });
        fieldProps.handleBlur(event, event.target.name);
    }
  }

  async function handleSubmit(formProps) {
    // console.log('handleSubmit', formProps);
    if (isMounted.current)
      setState({ ...state, isSubmitting: true });

    try {
      let { password, newPassword } = formProps.values;
      let input = { password, newPassword };
      // let response = await authenticatedHandler.changePassword(input);
      await authenticatedHandler.changePassword(input);

      // if (response.success && isMounted.current)
      //   setState({ ...state, isSubmitting: false, isCompleted: true, formMessage: 'Update successful', formError: null });

      // if (response.error)
      //   throw response.error;
      let message = 'Your password has been successfully changed';
      if (isMounted.current)
        setState({ ...state, isSubmitting: false, isCompleted: true, formMessage: message, formError: null });
    } catch (error) {
      if (isMounted.current)
        setState({ ...state, isSubmitting: false, formError: error });
    }

    // try {
    //   let success = {
    //     pathname: '/success',
    //     state: {
    //       successMessage: 'You password has been successfully changed',
    //       next: '/home',
    //     },
    //   };
    //   if (process.env.REACT_APP_TEST_MUI_MODE === 'true') {
    //     props.history.push(success);
    //   } else {
    //     let input = { password, newPassword };
    //     let response = await authenticatedResolvers.changePassword(input);

    //     if (response.success) 
    //       setSuccess(true);
    //     else if (response.error)
    //       setError(response.error);
    //   }
    // } catch (error) {
    //   // console.log('ChangePassword', 'Unknown error in handleSubmit', error);
    //   setError('Unknown error in handleSubmit');
    // }
  }

  // function renderForm() {
  //   return(
  //     <Form id='sign-in' initialValues={initialValues} validationSchema={authSchema} {...props} >
  //       {props => {
  //       let enabled = props.isValidated && !props.isSubmitting;
  //       return (
  //         <>
  //         <Container id='invitation' component="main" maxWidth="xs" className={classes.children} >

  //           <Grid className={classes.paper} >
  //             <form className={classes.form} noValidate >
  //               <Grid item >
  //                 <FormHeading title='Change Password' />
  //               </Grid>

  //               {error &&
  //               <Grid item >
  //                 <FormError message={error.message} />
  //               </Grid>}

  //               <Grid item >
  //                 <PasswordField name='password' {...props.passwordProps} required={true} />
  //               </Grid>
  //               <Grid item >
  //                 <PasswordFieldTooltip name='newPassword' {...props.passwordProps} required={true} text='New Password'
  //                 handleBlur={(event) => handleBlur(event, props)} />
  //               </Grid>
  //               <Grid item className={clsx(classes.centered, classes.buttonSpan)} >
  //                 <PrimaryButton {...props.buttonProps} title='Submit' disabled={!enabled}
  //                   onClick={(event) => handleSubmit(event, props)} />
  //               </Grid>
  //             </form>
  //           </Grid>

  //           {NODE_ENV === 'development' &&
  //           <DisplayState title='state' state={props.stateProps} />}

  //         </Container>
  //         </>
  //       );
  //     }}
  //     </Form>
  //   );
  // }

  // function renderSuccess() {
  //   return(
  //     <Container id='change-email' component="main" maxWidth="xs" className={classes.children} >

  //       <Grid className={classes.paper} >
  //         {/* <form className={classes.form} noValidate > */}
  //           <Grid item >
  //             <FormHeading title='Change Password' />
  //           </Grid>
  //           {/* // TODO Why aren't these margins collapsing */}
  //           <Grid item >
  //             <FormMessage message='Your password has been successfully changed' />
  //           </Grid>
  //       </Grid>

  //       {NODE_ENV === 'development' &&
  //       <DisplayState title='state' state={props.stateProps} />}

  //     </Container>
  //   );
  // }

  function handleCancel() {
    props.history.goBack();
  }

  return(
    <>
    {state.isLoading &&
    <BackdropLoader />}

    <HooksForm
      id='user-change-password-form'
      formState={formState}
    >
      {formProps => {
      let fieldEnabled = !state.isLoading && !state.isSubmitting;
      let submitEnabled = fieldEnabled && formProps.isValidated;
      return (
        <>
        <FormLayout >

          {/* <FlexRow style={{ justifyContent: 'space-between'}} >
            <InLink to='/user/security' title='Back' />
            <div />
          </FlexRow> */}

          <Heading>{'Change Password'}</Heading>

          <Grid style={{width: '100%'}} >

            {state.formMessage &&
            <Grid item >
              <FormMessage message={state.formMessage} />
            </Grid>}

            {state.formError &&
            <Grid item >
              <FormError message={state.formError.message} />
            </Grid>}

            {!state.isCompleted &&
            <>
            <Grid item >
              <PasswordField {...fieldProps} />
            </Grid>

            <Grid item >
              <PasswordFieldTooltip
                {...fieldProps}
                name='newPassword'
                label='New Password'
                handleBlur={() => handleBlur(event, formProps)}
              />
            </Grid>
            </>}

          </Grid>

          <FormSpacer />

          {!state.isCompleted &&
          <ButtonBar>
            <PrimaryButton
              aria-label='submit button'
              title='Submit'
              disabled={!submitEnabled}
              onClick={() => handleSubmit(formProps)}
            />
            <SecondaryButton
              aria-label='cancel button'
              title='Cancel'
              // disabled={!enabled}
              onClick={handleCancel}
            />
          </ButtonBar>}

        </FormLayout>
        </>
      );}}
    </HooksForm>

    <DisplayState title='state' state={{ state, formState }} open={true} />
    </>
  );
}

export default ChangePassword;

ChangePassword.propTypes = {
  location: PropTypes.shape(),
  history: PropTypes.shape(),
  isValidated: PropTypes.bool,
  passwordProps: PropTypes.shape(),
  submitProps: PropTypes.shape(),
  stateProps: PropTypes.shape(),
  handleBlur: PropTypes.func,
  authenticatedHandler: PropTypes.shape(),
};
