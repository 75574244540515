import React from 'react';
import PropTypes from 'prop-types';
import { PasswordField } from '@org/client-components-core/inputs/PasswordField';
import { PasswordTooltip, PasswordTooltipTitle } from '@org/client-components-core/inputs/PasswordTooltip';
// import { makeStyles } from '@material-ui/core/styles';
// import { styles } from '@org/client-libs-styles';

// const useStyles = makeStyles(styles);

// https://material-ui.com/components/click-away-listener/

export function PasswordFieldTooltip(props) {
  // console.log('PasswordFieldTooltip', props);
  let { children, ...rest } = props;

  // const classes = useStyles();

  return(
    <>
    <PasswordTooltip
      title={<PasswordTooltipTitle {...props} />}
      placement="top"
      disableHoverListener // arrow // ?
    >
    <div>
      <PasswordField {...rest} />
      {children}
    </div>
    </PasswordTooltip>
    </>
  );
}

export default PasswordFieldTooltip;

PasswordFieldTooltip.propTypes = {
  children: PropTypes.any,
};
