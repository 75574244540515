import { ApolloClient, InMemoryCache, ApolloLink, HttpLink } from '@apollo/client';
import { setContext } from "@apollo/client/link/context";
// import { ApolloClient, split } from 'apollo-client';
// import { ApolloLink } from 'apollo-link';
// import { ApolloLink, split } from 'apollo-link';
// import { createHttpLink } from 'apollo-link-http';
// import { RetryLink } from 'apollo-link-retry';
// import { retryLink } from '../common/retry';
// import { setContext } from 'apollo-link-context';
// import { InMemoryCache } from 'apollo-cache-inmemory';
// import { getSessionCookie, errorLink } from '@org/client-graphql';
import { errorLink, customFetch } from '@org/client-graphql';

// import { WebSocketLink } from 'apollo-link-ws';
// import { WebSocketLink } from '@apollo/client/link/ws';
// import { getMainDefinition } from 'apollo-utilities';

// const { REACT_APP_HTTP_URI, REACT_APP_IS_OFFLINE } = process.env;
// const { REACT_APP_HTTP_URI, REACT_APP_WS_URI } = process.env;
const { REACT_APP_HTTP_URI } = process.env;

const contextLink = setContext(async (_, { headers }) => {
  return {
    headers: {
      ...headers,
    },
  };
});

// const httpLink = createHttpLink({
const httpLink = new HttpLink({
  uri: REACT_APP_HTTP_URI + '/authenticated',
  credentials: 'include',
  fetch: customFetch,
});

// const wsLink = new WebSocketLink({
//   // uri: `ws://localhost:5000/`,
//   uri: REACT_APP_WS_URI,
//   options: {
//     reconnect: true
//   }
// });

// const splitLink = split(
//   // split based on operation type
//   ({ query }) => {
//     console.warn('splitLink', query);
//     const definition = getMainDefinition(query);
//     console.warn('splitLink', definition);
//     return (
//       definition.kind === 'OperationDefinition' &&
//       definition.operation === 'subscription'
//     );
//   },
//   wsLink,
//   httpLink,
// );

const authenticatedLink = ApolloLink.from([
  contextLink,
  // retryLink,
  errorLink,
  // offlineLink,
  httpLink,
  // splitLink,
]);

export const authenticatedCache = new InMemoryCache();

const defaultOptions = {
  query: {
    // fetchPolicy: 'no-cache', // individual resolver settings not working with this set here
    errorPolicy: 'all',
  },
  mutate: {
    // fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
  watchQuery: {
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
  },
};

const authenticatedConfig = {
  link: authenticatedLink,
  cache: authenticatedCache,
  defaultOptions,
  connectToDevTools: process.env.NODE_ENV === 'development' ? true : false,
};

export const authenticatedClient = new ApolloClient(authenticatedConfig);
