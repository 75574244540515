import React from 'react';
import { useQuery } from '@apollo/client';
import { publicSchema } from '@org/client-graphql';
import { CustomRoute } from '@org/client-components-routes/CustomRoutes';

export function AuthenticatedRoute(props) {
  // console.log('AuthenticatedRoute', props);

  const { loading, data } = useQuery(publicSchema.IS_AUTHENTICATED);

  if (loading)
    return null;
  else
    return (<CustomRoute {...props} test={data.isAuthenticated} to='/user-not-authorized' />);
}

export default AuthenticatedRoute;
