import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
// import { makeStyles } from '@material-ui/core/styles';
// import { default as MuiTextField } from '@material-ui/core/TextField';
import { TextField } from '@org/client-components-core/inputs/TextField';
// import { CheckAdornment } from '@org/client-components-custom';
// import { styles } from '@org/client-libs-styles';

// https://material-ui.com/components/text-fields/#integration-with-3rd-party-input-libraries
// https://www.npmjs.com/package/react-number-format
// https://codepen.io/s-yadav/pen/bpKNMa
// https://jinno.io/app/23/format?source=react-number-format&displayType=input&format=###-####

// const useStyles = makeStyles(styles);

function NumberFieldFormat(props) {
  // console.log('NumberFieldFormat', props);
  const { inputRef, onChange, ...rest } = props;

  return (
    <NumberFormat
      getInputRef={inputRef}
      onValueChange={(values) => { // changes will passed as a string w/o this
        // console.log('NumberFormat', 'onValueChange', props.name, values);
        onChange({
          target: {
            name: props.name,
            value: values.value,
            floatValue: values.floatValue,
          },
        });
      }}
      // thousandSeparator // default: ','
      // decimalScale={2} // passed in ...other
      // allowLeadingZeros={false} // default: false?
      isNumericString={true} // default: false
      // prefix="$" // passed in ...other
      // suffix='%' // passed in ...other
      // label=''
      // placeholder={'xxxx'} // doesn't work
      style={{ textAlign: 'right' }}
      {...rest}
    />
  );
}

NumberFieldFormat.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export function NumberField(props) {
  // console.log('NumberField', props);
  let {
    // name, values, errors, validated, autoFocus, required, handleChange, handleBlur, ...rest
    // name, // label, // endAdornment,
    // state,
  
    // handleChange, handleBlur, // useForm parseEvent needs the input `name` along with the `event`
    handleBlur,

    ...rest
  } = props;
  // let { values, validated, required, errors } = state;

  // const classes = useStyles();

  // console.log('NumberField', 'validated', validated);
  // let isValid = validated[name] && !props.disabled;

  // if (!name)
  //   return(<div>{`Missing 'name' prop`}</div>);

  // if (values == null)
  //   return(<div>{`Missing 'values' prop`}</div>);

  // if (values[name] == null && props.value == null)
  //   return(<div>{`Number field must have ${name} in 'values' prop or a 'value' prop override`}</div>);

  // if (values[name] && typeof values[name] !== 'number')
  //   return(<div>{`values[${name}] must be a number, received type ${typeof values[name]}`}</div>);

  // if (props.value && typeof props.value !== 'number')
  //   return(<div>{`value must be a number, received type ${typeof props.value} for ${name}`}</div>);

  // let error = (errors && errors[name] ? true : false);

  let type = 'float';

  let floatValue = props.value != null ? props.value : props?.state?.values[props.name];
  if (floatValue === '') // initial value to show placeholder
    floatValue = 0;
  // console.log('NumberField', `'${props.name}'`, `'${floatValue}'`);
  // console.log('NumberField', rest);

  return(
    // <MuiTextField
    <TextField
      // {...props} // Warning: React does not recognize the `handleChange` prop on a DOM element.
      // className={classes.inputField}
      // variant="outlined"
      // margin="normal"
      // fullWidth
      // label={props.label}
      // id={props.id ? props.id : name}
      // name={name}
      // type={type ? type : 'text'}
      type={type} // TODO Why do I need this?
      // value={values[name]}
      // onChange={(event) => handleChange(event, name)}
      // onBlur={(event) => handleBlur(event, name)}

      // handleChange={(event) => handleChange(event, props.name)}
      // handleBlur={(event) => handleBlur(event, props.name)}

      // onBlur={(event) => {
      handleBlur={(event) => { // blur values passed as a string w/o this
        // event.target = { ...event.target, name: props.name, floatValue: value };
        event.target = { ...event.target, name: props.name, floatValue: floatValue };
        // console.log('NumberField', 'handleBlur', event.target, `'${props.name}'`, `'${value}'`);
        // console.log('NumberField', 'handleBlur', `'${value}'`, event.target);
        handleBlur(event, props.name);
      }}
      // error={errors[name] ? true : false}
      // helperText={errors[name]}
      // required={required[name] ? true : false}
      // inputProps={props.inputProps} // these are passed to InputProps
      InputProps={{
        inputComponent: NumberFieldFormat,
        // inputComponent: <NumberFieldFormat { ...props.inputProps} />, // doesn't work
        // endAdornment: <CheckAdornment position="end" show={isValid} />,
      }}
      // label={''}
      // placeholder={'(   )    -    '} // not working
      {...rest}
    />
  );
}

export default NumberField;

NumberField.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  // value: PropTypes.number,
  value: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]), // for SelectNumberField
  type: PropTypes.string,
  label: PropTypes.string,
  state: PropTypes.shape(),
  values: PropTypes.shape(),
  errors: PropTypes.shape(),
  validated: PropTypes.shape(),
  inputProps: PropTypes.shape(),
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  autoFocus: PropTypes.bool,
};
