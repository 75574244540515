import React from 'react';
import PropTypes from 'prop-types';
import { default as MuiLink } from '@material-ui/core/Link';
import { Link as RouterLink } from 'react-router-dom';

/**
 * A React-Router Link wrapper to an internal address.
 * Use this to wrap around any component(s).
 */
export function InLinkWrapper(props) {
  let { to, children, ...rest } = props;

  // if (!to)
  //   throw new Error(`InLinkWrapper Error: 'to' address must be passed in props`);

  // if (!children)
  //   throw new Error(`InLinkWrapper Error: 'children' must be passed in props`);

  return(<MuiLink component={RouterLink} to={to} {...rest} >{children}</MuiLink>);
}

InLinkWrapper.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
};
