// import { ulid } from "ulid";
import cloneDeep from 'lodash-es/cloneDeep';

/**
 * RecordClass
 * 
 * This is the basis for all the classes which are used to create DB
 * query inputs.
 */

export class RecordClass {
  constructor(values) {
    console.info(this.constructor.name, 'Class constructor', values);
    let input = cloneDeep(values);

    // this.table = null;

    this.handler = null;

    this.validationSchema = null;

    this.keys = {
      itemType: "",
      id: "",
    };
    if (input && input.id) { // input?.id ???
      this.keys.id = input.id;
      delete input.id;
    }
    if (input && input.itemType) {
      this.keys.itemType = input.itemType;
      delete input.itemType;
    }

    this.attributes = {};

    this.requiredValues = {};

    this.changed = {};
  }

  setHandler(handler) {
    this.handler = handler;
  }

  // What is this used for? The valdationSchema's are set during construction in
  // the extended classes (e.g. Loan, Note, etc)
  setValidationSchema(validationSchema) {
    this.validationSchema = validationSchema;
  }

  values() { // all values
    return { ...this.keys, ...this.attributes };
  }

  // Allow forms to update attribute values only
  // Throws an error if 'name' not in attributes
  // Calls calculateValues if all inputs exist
  update(values) {
    // console.log(`${this.constructor.name} update input: `, input);
    // console.log('Record', 'update', this.attributes);

    let input = cloneDeep(values);

     // we need to check if the attributes have been initialized since the
     // default values are set in extended class constructor (e.g. Loan, Note, etc)
    if (!Object.keys(this.attributes).length)
      this.attributes = { ...this.defaultValues };

    // Allow the id to be updated correctly
    if (input && input.id) {
      this.keys.id = input.id;
      delete input.id;
    }
    // itemType is hard-coded and cannot be updated
    if (input && input.itemType)
      delete input.itemType;

    let changed = {};

    // First use the inputs to derive any calculated values
    // After updating attributes, diff === {}
    if (this.calculateValues) // defined in parent class
      input = this.calculateValues(input);

    if (input) {
      let keys = Object.keys(input);
      // console.log('update', keys);
      for (let i in keys) {
        // console.log('update', i);
        let name = keys[i];
        // console.log('update', name, input[name]);
        if (this.attributes[name] !== undefined) {
          this.attributes[name] = input[name];
          // this.changed[name] = input[name];
          changed[name] = input[name];
        // else
        //   throw new Error(`${this.constructor.name} '${name}' not found in attributes`);
        } else
          console.warn(`${this.constructor.name} '${name}' not found in attributes`);
      }
    }

    return this.values(); // used with useForm so we need to return the updated values
  }

  required(input) { // take input for forms
    let keys = Object.keys(this.requiredValues);
    for (let i in keys) {
      let name = keys[i];
      if (this.requiredValues[name] && input[name] == null)
        throw new Error(`${this.constructor.name} '${name}' is a required input`);
    }
  }

  async validate(input) { // take input for forms
    this.validationSchema.validate(input);
  }

  // Still used in SessionClass, maybe others. Migrate all to update()
  initialize(input) {
    this.required(input);
    this.update(input);
  }
}
