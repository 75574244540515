import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
// import { useTheme } from '@material-ui/core/styles';
import { Stack, Item } from '@org/client-components-core/layout/Stack';
import { AmountField } from '@org/client-components-core/inputs/AmountField';
import { DatePicker } from '@org/client-components-core/inputs/DatePicker';
import { SelectTextField } from '@org/client-components-core/inputs/SelectTextField';

export function TradeRow(props) {
  console.log('TradeRow', props);
  // let theme = useTheme();
  let { fieldProps, tradeTypeItems } = props;
  return(
    <Stack>

      <Item>
        <DatePicker
          {...fieldProps}
          name='eventDate'
          label='Trade Date'
          minDate={dayjs().format('YYYY-MM-DD')}
          disabled={true}
        />
      </Item>

      <Item>
        <SelectTextField
          {...fieldProps}
          name='tradeType'
          label='Type'
          items={tradeTypeItems}
          disabled={true}
        />
      </Item>

      <Item>
        <AmountField
          {...fieldProps}
          name='amount'
          label='Trade Amount'

        />
      </Item>

    </Stack>
  );
}

TradeRow.propTypes = {
  fieldProps: PropTypes.shape(),
};
