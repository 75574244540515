import { useQuery } from '@apollo/client';
// import { useHistory } from "react-router-dom";
import { handleError } from '@org/common-errors';
import {
  AuthError,
  publicCache, publicClient, publicResolvers,
  authenticatedClient,
  authorizedClient, authorizedSchema, // authorizedResolvers,
} from '@org/client-graphql';
// import { createBrowserHistory } from 'history';
// import { ApolloError } from 'apollo-client/errors/ApolloError';
// import { ApolloError } from '@apollo/client/errors/ApolloError';

let location = 'client.graphql.common.handler';

// code: "MISSING_COOKIE"
// date: "2020-11-12T21:59:21.419Z"
// message: "No cookie found in headers"
// name: "AuthError"
// stack: "AuthError: No cookie found in headers at verifyAuthentication 
// function handleServerError({ resolver, success, data, error }) {
//   console.log(location, 'handleServerError', resolver, success, data, error);
//   if (error.name === 'AuthError') { // URGENT get Session should not return an AuthError
//     console.log(error.name);
//     createBrowserHistory().push({
//       pathname: '/sign-out',
//       state: {
//         error
//       },
//     });
//   }
// }

// function printError(error) {
//   console.warn('----- Start printError -----');
//   let keys = Object.keys(error);
//   // console.warn('keys', keys);
//   let properties = Object.getOwnPropertyNames(error);
//   // console.warn('properties', properties);
//   // keys.concat(properties);
//   // let merged = [ ...keys, ...properties ];
//   // var c = a.concat(b.filter((item) => a.indexOf(item) < 0));
//   let merged = keys.concat(properties.filter((item) => keys.indexOf(item) < 0));
//   console.warn('merged', merged);

//   merged.forEach(key => {
//     console.warn(key, error[key]);
//   });
//   console.warn('----- End printError -----');
// }

// error = {
//   message,
//   stack,
//   networkError,
//   graphQLErrors,
//   extraInfo,
// }

// networkError = {
//   name,
//   message,
//   stack,
//   statusCode: '400'
// }

export function handleResponseError(props) {
  console.warn('handleResponseError', props);
  let { error } = props;
  console.warn('handleResponseError', error.message);
  // if (!props.location)
  //   console.
  if (error.code === 'COOKIE_NOT_FOUND') {
    // console.warn('AppComponent', this.props.history, this.props.location);
    // this error is only thrown by requests which require a cookie
    props.history.push('/sign-in');
  } else if (error.code === 'SESSION_NOT_FOUND') {
    props.history.push({ pathname: '/sign-out', state: { error } });
  } else if (error.code === 'REQUEST_IP_ADDRESS_MISMATCH') {
    props.history.push({ pathname: '/sign-out', state: { error } });
  } else if (props.location.pathname === '/sign-out') {
    props.history.push('/sign-in');
  } else {
    console.warn('Unhandled error type');
    props.history.push({ pathname: '/sign-out', state: { error } });
  }
}

export function handleServerError(error) {
  console.info('');
  console.info('');
  console.error('handleServerError(error)');
  console.error('error', error);
  console.error('error', error.name, typeof error);
  // 'Error' 'object'
  console.warn('.getOwnPropertyNames', Object.getOwnPropertyNames(error));
  console.warn(error.networkError, error.graphQLErrors);

  let forward = [ 'COOKIE_NOT_FOUND', 'SESSION_NOT_FOUND', 'REQUEST_IP_ADDRESS_MISMATCH', 'TRADE_ERROR'];

  if (error.networkError) {
    console.error('handleServerError', 'networkError', error.networkError.message);

    if (error.networkError?.result?.errors) {
      let { errors } = error.networkError.result;
      console.info(`${errors.length} network error(s) found`);
      for (let index in errors) {
        console.error('handleServerError', errors[index]?.extensions?.code, errors[index].message);
        if (errors[index]?.extensions?.code && forward.includes(errors[index].extensions.code))
          throw new AuthError(errors[index].message, errors[index].extensions.code);
      }
    }
  }

  if (error.networkError || error.graphQLErrors) {
    console.info('');
    // 'Error: Network error: Response not successful: Received status code 400'
    // console.warn('instanceof ApolloError', error instanceof ApolloError);
    // false
    console.warn('.getOwnPropertyNames', Object.getOwnPropertyNames(error));
    // ["stack", "graphQLErrors", "networkError", "message", "extraInfo"]
    console.warn('.keys', Object.keys(error));
    // ["graphQLErrors", "networkError", "message", "extraInfo"]
    console.warn('.message', error.message);
    // 'Network error: Response not successful: Received status code 400'
    console.warn('.extraInfo', error.extraInfo);
    // undefined
    console.error('.graphQLErrors', error.graphQLErrors);
    // []
    console.warn('.graphQLErrors.getOwnPropertyNames', Object.getOwnPropertyNames(error.graphQLErrors));
    // ["length"]
    console.warn('.graphQLErrors.keys', Object.keys(error.graphQLErrors));
    // []
    let networkError = error.networkError;
    console.error('.networkError', networkError);
    // 'ServerError: Response not successful: Received status code 400'
    console.warn('.networkError.getOwnPropertyNames', Object.getOwnPropertyNames(networkError));
    // ["stack", "message", "name", "response", "statusCode", "result"]
    console.warn('.networkError.keys', Object.keys(networkError));
    // ["name", "response", "statusCode", "result"]
    console.warn('.networkError.statusCode', networkError.statusCode, typeof networkError.statusCode);
    // 400
    console.warn('.networkError.message', networkError.message);
    // 'Response not successful: Received status code 400'
    console.warn('.networkError.name', networkError.name);
    // 'ServerError'
    console.warn('.networkError.response', networkError.response);
    // eslint-disable-next-line
    // {type: "cors", url: "http://localhost:4011/dev/authenticated", redirected: false, status: 400, ok: false, …}
    console.warn('.networkError.result', networkError.result);
    // {errors: Array(1)}

    if (networkError?.result?.errors) {
      let errors = networkError.result.errors;
      console.error('.networkError.result.errors', errors);
      // [{…}]
      console.error('.networkError.result.errors.getOwnPropertyNames', Object.getOwnPropertyNames(errors));
      // ["0", "length"]
      console.error('.networkError.result.errors.keys', Object.keys(errors));
      // ["0"]
      console.warn('.networkError.result.errors[0]', errors[0]);
      // {message: "Context creation failed: Cookie not found", extensions: {…}}
      console.warn('.networkError.result.errors[0].message', errors[0].message);
      // 'Context creation failed: Cookie not found'
      console.warn('.networkError.result.errors[0].extensions', errors[0].extensions);
      // {name: "CookieNotFoundError", code: "COOKIE_NOT_FOUND", exception: {…}}
      let extensions = errors[0].extensions;
      console.warn('.networkError.result.extensions.name', extensions.name);
      // 'CookieNotFoundError'
      console.warn('.networkError.result.extensions.code', extensions.code);
      // 'COOKIE_NOT_FOUND'
      console.warn('.networkError.result.extensions.exception', extensions.exception);
      // {stacktrace: Array(11)}

      // errors.forEach(elem => {
      //   console.log(elem, errors[elem]);
      // });
      console.info('');
      console.info('');

      if (forward.includes(extensions.code)) {
        console.warn('throwing new AuthError', errors[0].message, extensions.code);
        // throw new AuthError(errors[0].message, extensions.code);
      }
      // if (extensions.code === 'COOKIE_NOT_FOUND' || extensions.code === 'SESSION_NOT_FOUND')
      //   throw new AuthError(errors[0].message, extensions.code);
    }
  }



  // console.error('handleServerError', 'graphQLErrors', Object.getOwnPropertyNames(error.networkError));
  // Object.getOwnPropertyNames(error.graphQLErrors).forEach(elem => {
  //   console.log(elem, error.graphQLErrors[elem]);
  // });

  // This is for errors thrown in GraphQL, either network errors or errors thrown by our code
  // These errors are initially caught in the client's errorLink
  // !!! The above is not necessarily true, this will also catch Invariant Violation errors that never make it to Apollo Server (and therefore never get sent back to the Client's errorLink)
  // return handleClientError({ client, type, params, error });
  // console.log(location, 'requestHandler', error.message);

  // Client Errors -- these are not caught by the errorLink
  // let plain = handleError({ error });
  if (error.name === 'Invariant Violation') {
    // console.error(location, 'requestHandler', error.name, error.code, error.message);
    if (error.message === 'mutation option is required. You must specify your GraphQL document in the mutation option.')
      console.warn('This is most likely because the requested mutation was not exported from the schema...');
  }

  // Uncaught TypeError: Cannot set property 'value' of null
  // This means that the element doesn't exist. This often happens when trying to
  // access a non-existent element of an object.

  if (error.networkError) {

    if (error.networkError.result) {
      // printError(error.networkError.result);

      if (error.networkError.result.errors) { // This is where the graphQLErrors in errorLink are found!!!
        error.networkError.result.errors.forEach(elem => {
          // printError(elem);
          console.log('elemX', elem.message);
          console.log('elemX', elem.extensions);
          console.log('history', history);
          if (elem.extensions?.code === 'SESSION_NOT_FOUND' || elem.extensions?.code === 'COOKIE_NOT_FOUND')
            // throw elem;
            console.warn('handleServerError', elem.extensions?.code);
            // history.push('/sign-out');
            // history.push({
            //   pathname: '/sign-out',
            //   state: { code: elem.extensions.code },
            // });
            // setState(() => {
            //   throw elem;
            // });
          else if (elem.extensions?.code === 'INTERNAL_SERVER_ERROR')
            // return { success: false, data: null, error: elem };
            throw elem;
          else
            console.warn('Why am I here?');
        });
      }
    }
    
    // let errors = error.networkError.result.errors;
    // if (errors.length > 1)
    //   console.warn(`GraphQL networkError: !!!${errors.length} errors returned!!!`);
    // let networkError = errors[0];
    // console.error('[networkError]', networkError);
    // return { success: false, data: null, error: error.networkError };
  }

  if (error.graphQLErrors) {

    // error.graphQLErrors.forEach(elem => {
    // // for (let error in error.graphQLErrors) {
    //   console.error('handleServerError', 'graphQLErrors', error);
    //   // if (error.message === 'This email address has been confirmed since you started the sign up process')
    //   //   throw error;
    //   throw error;
    // });

    // let errors = error.graphQLErrors;
    // errors.forEach(elem => {
    //   console.error(`${JSON.stringify(elem.path, null, ' ')} ${elem.extensions.code} ${elem.message}`);
    // });
    // return { success: false, data: null, error: error.graphQLErrors[0] };
  }


  // return { success: false, data: null, error };
  // throw error;
}

export function handleClientError(props) {
  // this is for errors that occured on the client but were properly handled
  console.error(location, 'handleClientError', 'props', props);
  // console.log(typeof props.error, !props.error);
  if (!props.error) {
    // console.log(typeof props.error, !props.error);
    console.error(location, '***** Improper usage of handleClientError: must be passed an error inside props object!!! *****');
    return null;
  }

  let error = handleError({ error: props.error });
  // console.error(location, 'handleClientError1', 'error', error);
  // console.error(location, 'handleClientError2', JSON.stringify(error, null, ' '));
  // console.error(location, 'handleClientError3', JSON.stringify(error.message, null, ' '));

  if (error.message.indexOf('is not a function') !== -1) {
    if (error.message.indexOf('authorizedResolvers') !== -1)
      console.error('Undefined client resolver in authorizedResolvers...', error);
    else if (error.message.indexOf('authenticatedResolvers') !== -1)
      console.error('Undefined client resolver in authenticatedResolvers...', error);
    else if (error.message.indexOf('publicResolvers') !== -1)
      console.error('Undefined client resolver in publicResolvers...', error);
  // } else if (error.client) {
  } else if (error.message === 'Network error: Response not successful: Received status code 400') {
    console.error('This is probably due to an improperly formed request in client schema or resolver. Check that the data sent to the API and matches the API schema and the data requested by the Client is being returned by the API.');
  } else if (error.error) {
    console.log(location, 'This is an Apollo GraphQL error:', error.error);
    // This is an Apollo GraphQL error
    // URGENT Find a better test for GraphQL errors... // && error.client === 'ApolloClient') {
    // error = {
    //   client,
    //   error,
    //   ...
    // }
    error = error.error;
    // Uncaught Invariant Violation: Argument of undefined passed to parser was not a valid GraphQL DocumentNode. You may need to use 'graphql-tag' or another method to convert your operation into a document
    console.log(location, 'handleClientError', 'GraphQL Error:', error.message);
    if (error.message == 'Error: Network error: Response not successful: Received status code 400')
      console.error(location, 'This is probably due to an improperly formed request in client schema or resolver. Check that the data requested by the Client is being returned by the API.');
    else if (error.message === 'Network error: Failed to fetch')
      console.error(location, 'This is probably due to a syntax or other error in the API. Please check the API logs for error messages.');
    else if (error.message === 'query option is required. You must specify your GraphQL document in the query option.')
      console.error(location, 'This may mean that schema definition is missing or is improperly formed. Check if a mutation is being passed query or vice versa...');
    else if (error.message === `Uncaught Invariant Violation: Argument of undefined passed to parser was not a valid GraphQL DocumentNode. You may need to use 'graphql-tag' or another method to convert your operation into a document`)
      console.error(location, 'This may mean that schema definition that was passed to the resolver does not exist.');
    else
      console.error(location, 'The is a new error message. Make sure to add the cause to handleClientError after you figure it out...');
  } else
    console.error(location, 'handleClientError', error, error.client, typeof error.client, typeof error);

  // let response = { success: false, data: null, error: error.error };
  // return response;
  return error;
}

// export const handleAuthentication = async (inputs) => {
export async function handleAuthentication(input) {
  console.log('XXX handleAuthentication', 'input', input);
  if (input?.success) {
    console.warn('handleAuthentication', 'Legacy success response format passed as input. Please update!');
    input = input.data;
  }
  // try {
  //   let success = true;
  //   // let response = await Promise.all([
  //     await publicResolvers.setAuthenticated(success);
  //     await publicResolvers.setGroups(input.groups);
  //   // ]);
  //   // console.log('handleAuthentication', response);
  // } catch (error) {
  //   console.error(error);
  // }
  // let { success, data, error } = inputs;
  // if (success && !error && data.groups) {
  //   await Promise.all([
  //     publicResolvers.setAuthenticated(success),
  //     publicResolvers.setGroups(data.groups),
  //   ]);
  // } else
  //   throw new Error("Error in handleAuthentication... this should not be happening");
}

export function query(schema) {
  // console.warn(location, 'query');
  const { loading, data, error } = useQuery(schema);
  let response;
  if (loading)
    response = 'Loading...';
  if (data) {
    response = data[Object.keys(data)[0]];
  }

  if (error) {
    // response = handleClientError({ error }).message;
    // return handleServerError(error);
  // console.log(location, 'query', response);
    response = error.code + ' ' + error.message;
  }

  return response;
}

// export async function localQueryHandler(params) {
//   return await publicClient.query(params);
// }

// export function localQueryHandler(params) {
//   return publicClient.query(params);
// }

// export async function localQueryHandler(params) {
//   let response = await publicClient.query(params);
//   // return response;
//   let resolver = Object.keys(response.data)[0];
//   // return response.data[resolver];
//   response = response.data[resolver];
//   // console.log('localQueryHandler', response, typeof response);
//   return response;
// }

// export async function localMutationHandler(input) {
//   let params = { data: input };
//   // return await publicCache.writeData(params);
//   // await publicCache.writeData(params);
//   await publicCache.writeQuery(params);
// }

// requestHandlers return response = { data: { requestName: { ... } } }
//   => handleResponse(response)
// hooks return data = { requestName: { ... } }
//   => handleResponse({ data })
export function handleResponse(response) {
  console.log('handleResponse', response);
  // if (response && response.data) { // graphql data, not my data!

  if (response?.data) { // graphql data, not my data!
    let resolver = Object.keys(response.data)[0];
    // console.log('handleResponse', resolver, typeof response.data[resolver], response.data[resolver]);
    response = response.data[resolver];

    if ([
      'createTrade', 'createTransaction',
      'getTrade',
      'listActivity', 'listActivity', 'listCashflows', 'listEmails', 'listTransactions'
    ].includes(resolver)) {
      // console.log('handleResponse', response);
      return response;
    }

    // if (typeof response.data[resolver] === 'string') // ping, getVersion
    if (typeof response === 'string') // ping, getVersion
      return response;    // if (typeof response.data[resolver] === 'string') // ping, getVersion

    response.resolver = resolver;

    // console.log('handleResponse', response);

    if (response.error)
      response.error = JSON.parse(response.error);

    if (response['__typename'])
      delete response['__typename'];

    if (response?.data?.__typename)
      delete response.data['__typename'];

    if (response?.data?.items && response.data.items.length > 0)
      response.data.items.forEach(elem => {
        delete elem['__typename'];
      });

    console.log('handleResponse', response);

    return response;

    // console.log('handleResponse', resolver, response);
//    if (typeof response.data[resolver] !== 'object') // a scalar?
//      return { resolver, success: true, data: response.data[resolver], error: null };

    // let { success, data, error } = response.data[resolver];
    // // console.log('handleResponse', 'resolver', resolver, 'success', success, 'data', data, 'error', error);
    // if (data && Object.keys(data).length && data['__typename'])
    //   delete data['__typename'];
    // if (error) {
    //   error = JSON.parse(error);
    //   error = handleClientError({ error });
    // }
    // console.log('handleResponse', resolver, success, data, error);
    // return { resolver, success, data, error };
  // } else if (response && response.errors && response.errors.length) {
  // } else if (response?.errors?.length) {
  //   console.error('This is a GraphQL server error:', response.errors.length, 'errors returned');
  //   let errors = [];
  //   response.errors.forEach(error => {
  //     // console.error(error);
  //     errors.push(handleClientError({ error }));
  //   });
  //   return { error: errors[0], data: errors };
  } else
    console.error('Error in handleResponse:', 'This is likely because the response was not formatted correctly.', 'Hooks takes graphql data wrapped in object: ({ data })', response);
}

// response = {
//   data: {
//     'resolver': {
//       success: Boolean,
//       data: {},
//       error: String
//     }
//   }
// }
async function requestHandler({ client, type, params }) {
  // console.log('requestHandler', 'client:', client, 'type:', type, 'params:', params);
  // console.log('requestHandler', 'type:', type, 'params:', params);

  // For some reason, Hot Module Reload is not working with `serverless-offline` lambda caching
  // so eventually the offline server crashes due to too many connections being opened.
  // N.b. This may be due to yarn workspaces?
  // if (process.env.REACT_APP_IS_OFFLINE) {
  //   // let isAuthenticated = await publicResolvers.isAuthenticated();
  //   try {
  //     let isDeveloper = await publicResolvers.isDeveloper();
  //     if (isDeveloper) {
  //       console.warn('requestHandler', 'IS_OFFLINE', 'closeConnections');
  //       // await authorizedResolvers.closeConnections();
  //       let params = {
  //         query: authorizedSchema.CLOSE_CONNECTIONS,
  //         fetchPolicy: "no-cache",
  //       };
  //       await authorizedClient.query(params);
  //     }
  //   } catch (error) {
  //     console.warn(error);
  //   }
  // }

  let response = {};
  try {
    if (type === 'query')
      response = await client.query(params);
    else if (type === 'watchQuery')
      response = await client.watchQuery(params);
    else if (type === 'mutation')
      response = await client.mutate(params);
    else
      throw new Error('Unknown type in GraphQL handler');

    console.info('requestHandler', 'response', response);

    if (response.errors)
      for (let index in response.errors) {
        console.error(index, response.errors[index]);
        throw response.errors[index];
      }

    if (response.data)
      return handleResponse(response);
    else
      throw new Error('No data object found in response');
  } catch (error) {
    console.warn('requestHandler', error, Object.getOwnPropertyNames(error));
    // return handleServerError(error);
    let response = await handleServerError(error);
    console.log('requestHandler', response);
    // throw response;

    // let newError = await handleServerError(error);
    // console.warn('requestHandler', 'newError', newError.extensions?.code);
    // throw newError;
    // throw error;

  //   printError(error);

  //   // This is for errors thrown in GraphQL, either network errors or errors thrown by our code
  //   // These errors are initially caught in the client's errorLink
  //   // !!! The above is not necessarily true, this will also catch Invariant Violation errors that never make it to Apollo Server (and therefore never get sent back to the Client's errorLink)
  //   // return handleClientError({ client, type, params, error });
  //   // console.log(location, 'requestHandler', error.message);

  //   // Client Errors -- these are not caught by the errorLink
  //   // let plain = handleError({ error });
  //   if (error.name === 'Invariant Violation') {
  //     // console.error(location, 'requestHandler', error.name, error.code, error.message);
  //     if (error.message === 'mutation option is required. You must specify your GraphQL document in the mutation option.')
  //       console.warn('This is most likely because the requested mutation was not exported from the schema...');
  //   }

  //   if (error.networkError) {
  //     printError(error.networkError);

  //     if (error.networkError.result) {
  //       printError(error.networkError.result);

  //       if (error.networkError.result.errors) {
  //         error.networkError.result.errors.forEach(elem => {
  //           printError(elem);
  //           console.log('elemX', elem);
  //           console.log('elemX', elem.extensions);
  //           console.log('elemX', elem.extensions.code);
  //           if (elem.extensions?.code === 'SESSION_NOT_FOUND')
  //             throw elem;
  //         });
  //       }
  //     }
      
  //     // let errors = error.networkError.result.errors;
  //     // if (errors.length > 1)
  //     //   console.warn(`GraphQL networkError: !!!${errors.length} errors returned!!!`);
  //     // let networkError = errors[0];
  //     // console.error('[networkError]', networkError);
  //     // return { success: false, data: null, error: error.networkError };
  //   }

  //   if (error.graphQLErrors) {
  //     // console.error(location, 'requestHandler', error.graphQLErrors);
  //     // let errors = error.graphQLErrors;
  //     // errors.forEach(elem => {
  //     //   console.error(`${JSON.stringify(elem.path, null, ' ')} ${elem.extensions.code} ${elem.message}`);
  //     // });
  //     // return { success: false, data: null, error: error.graphQLErrors[0] };
  //   }

  //   return { success: false, data: null, error };
  }
}

// export function publicQueryHandler(params) {
//   return requestHandler({ client: publicClient, type: 'query', params });
// }

// export function publicMutationHandler(params) {
//   return requestHandler({ client: publicClient, type: 'mutation', params });
// }

// export function authenticatedQueryHandler(params) {
//   return requestHandler({ client: authenticatedClient, type: 'query', params });
// }

// export function authenticatedWatchQueryHandler(params) {
//   return requestHandler({ client: authenticatedClient, type: 'watchQuery', params });
// }

// export function authenticatedMutationHandler(params) {
//   return requestHandler({ client: authenticatedClient, type: 'mutation', params });
// }

// export function authorizedQueryHandler(params) {
//   return requestHandler({ client: authorizedClient, type: 'query', params });
// }

// export function authorizedMutationHandler(params) {
//   return requestHandler({ client: authorizedClient, type: 'mutation', params });
// }
