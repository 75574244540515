import React from 'react';
// import { Link } from 'react-router-dom';
// import Typography from '@material-ui/core/Typography';
// import { makeStyles } from '@material-ui/core/styles';
// import { styles } from '@org/client-libs-styles';
import { InLink } from '@org/client-components-core/links/InLink';
import { ExLink } from '@org/client-components-core/links/ExLink';
import { Page, Heading } from '@org/client-components-custom';

// Borrowed from: https://aws.amazon.com/terms/

const { REACT_APP_LEGAL_NAME, REACT_APP_COMPANY_NAME, REACT_APP_SHORT_NAME, REACT_APP_DOMAIN_NAME } = process.env;

// const localStyles = {
//   ...styles,
//   smallLink: {
//     display: 'inline',
//   },
// };

// const useStyles = makeStyles(localStyles);

export function Privacy() {
  // let classes = useStyles();
  return(
    <>
    {/* <div className={classes.pageRoot} > */}
    <Page id='privacy-policy' >
      {/* <Typography variant={localStyles.pageTitleVariant} component='h1' className={classes.pageTitle} >
        {'Privacy Policy'}
      </Typography> */}
      <Heading>{'Privacy Policy'}</Heading>
      <p>Updated: November 17, 2020</p>

    {/* <div className={classes.pageRoot} > */}
    <div>
      <section>
        <h4><strong>YOUR PRIVACY MATTERS</strong></h4>
        <p>
          {REACT_APP_COMPANY_NAME}’s mission is to democratize credit investing. An integral part of our ability to do that requires us to collect and store a limited amount of your personal information. Most of this information we are legally required to collect and retain for regulatory, tax, and/or other reasons which are out of our control. However, we may also collect additional information in order to provide you with the best user experience possible and enhance our overall service. This Privacy Notice describes how we collect and use your personal information in relation to {REACT_APP_SHORT_NAME} websites, applications, products, services, events, and experiences that reference this Privacy Notice (together, “{REACT_APP_SHORT_NAME} Offerings”).
        </p>
      </section>
      <section>
        <h4><strong>PERSONAL INFORMATION WE COLLECT</strong></h4>
        <p>We collect your personal information in the course of providing {REACT_APP_SHORT_NAME} Offerings to you.</p>
        <p>Here are the types of information we gather:</p>
        <ul>
          <li><strong>Information You Give Us:</strong> We collect any information you provide in relation to {REACT_APP_SHORT_NAME} Offerings. Click here to see examples of information you give us.</li>
          <li><strong>Automatic Information:</strong> We automatically collect certain types of information when you interact with {REACT_APP_SHORT_NAME} Offerings. [Click here to see examples of information we collect automatically.]</li>
          <li><strong>Information from Other Sources:</strong> We may collect information about you from other sources, including service providers, partners, and publicly available sources. [Click here to see examples of information we collect from other sources.]</li>
        </ul>
      </section>
      <section>
        <h4><strong>HOW WE USE PERSONAL INFORMATION</strong></h4>
        <p>We use your personal information to operate, provide, and improve {REACT_APP_SHORT_NAME} Offerings. Our purposes for using personal information include:</p>
        <ul>
          <li><strong>Provide {REACT_APP_SHORT_NAME} Offerings:</strong> We use your personal information to provide and deliver {REACT_APP_SHORT_NAME} Offerings and process transactions related to {REACT_APP_SHORT_NAME} Offerings, including registrations, subscriptions, purchases, and payments.</li>
          <li><strong>Measure, Support, and Improve {REACT_APP_SHORT_NAME} Offerings:</strong> We use your personal information to measure use of, analyze performance of, fix errors in, provide support for, improve, and develop {REACT_APP_SHORT_NAME} Offerings.</li>
          <li><strong>Recommendations and Personalization:</strong> We use your personal information to recommend {REACT_APP_SHORT_NAME} Offerings that might be of interest to you, identify your preferences, and personalize your experience with {REACT_APP_SHORT_NAME} Offerings.</li>
          <li><strong>Comply with Legal Obligations:</strong> In certain cases, we have a legal obligation to collect, use, or retain your personal information. For example, we collect tax ID numbers for identity verification and income reporting purposes.</li>
          <li><strong>Communicate with You:</strong> We use your personal information to communicate with you in relation to {REACT_APP_SHORT_NAME} Offerings via different channels (e.g., by phone, email, chat) and to respond to your requests.</li>
          <li><strong>Marketing:</strong> We use your personal information to market and promote {REACT_APP_SHORT_NAME} Offerings. We might display interest-based ads for {REACT_APP_SHORT_NAME} Offerings. To learn more, please read our <span><InLink variant='body2' to='/legal/ads' title='Interest-Based Ads notice' /></span>.</li>
          <li><strong>Fraud and Abuse Prevention and Credit Risks:</strong> We use your personal information to prevent and detect fraud and abuse in order to protect the security of our customers, {REACT_APP_SHORT_NAME}, and others. We may also use scoring methods to assess and manage credit risks.</li>
          <li><strong>Purposes for Which We Seek Your Consent:</strong> We may also ask for your consent to use your personal information for a specific purpose that we communicate to you.</li>
        </ul>
      </section>
      <section>
        <h4><strong>COOKIES</strong></h4>
        <p>To enable our systems to recognize your browser or device and to provide {REACT_APP_SHORT_NAME} Offerings to you, we use cookies. For more information about cookies and how we use them, please read our <InLink variant='body2' to='/legal/cookies' title='Cookies Policy' />.</p>
      </section>
      <section>
        <h4><strong>HOW WE SHARE PERSONAL INFORMATION</strong></h4>
        <p>Information about our customers is an important part of our business and we are not in the business of selling our customers’ personal information to others. We share personal information only as described below and with {REACT_APP_LEGAL_NAME} and the subsidiaries that {REACT_APP_LEGAL_NAME} controls that are either subject to this Privacy Notice or follow practices at least as protective as those described in this Privacy Notice.</p>
        <ul>
          <li><strong>Transactions Involving Third Parties:</strong> We may make available to you services, software, and content provided by third parties for use on or through {REACT_APP_SHORT_NAME} Offerings. You can tell when a third party is involved in your transactions, and we share information related to those transactions with that third party. {/* For example, you can order services, software, and content from sellers using the AWS Marketplace and we provide those sellers information to facilitate your subscription, purchases, or support. */}</li>
          <li><strong>Third-Party Service Providers:</strong> We employ other companies and individuals to perform functions on our behalf. Examples include: delivering {REACT_APP_SHORT_NAME} hardware, sending communications, processing payments, assessing credit and compliance risks, analyzing data, providing marketing and sales assistance (including advertising and event management), conducting customer relationship management, and providing training. These third party service providers have access to personal information needed to perform their functions, but may not use it for other purposes. Further, they must process that information in accordance with this Privacy Notice and as permitted by applicable data protection law.</li>
          <li><strong>Business Transfers:</strong> As we continue to develop our business, we might sell or buy businesses or services. In such transactions, personal information generally is one of the transferred business assets but remains subject to the promises made in any pre-existing Privacy Notice (unless, of course, the individual consents otherwise). Also, in the unlikely event that {REACT_APP_SHORT_NAME} or substantially all of its assets are acquired, your information will of course be one of the transferred assets.</li>
          <li><strong>Protection of Us and Others:</strong> We release account and other personal information when we believe release is appropriate to comply with the law, enforce or apply our terms and other agreements, or protect the rights, property, or security of {REACT_APP_SHORT_NAME}, our customers, or others. This includes exchanging information with other companies and organizations for fraud prevention and detection and credit risk reduction.</li>
          <li><strong>At Your Option:</strong> Other than as set out above, you will receive notice when personal information about you might be shared with third parties, and you will have an opportunity to choose not to share the information.</li>
        </ul>
      </section>
      <section>
        <h4 style={{textTransform: 'uppercase'}} ><strong>Location of Personal Information</strong></h4>
        <p>{REACT_APP_COMPANY_NAME} is located in the United States, and our affiliated companies are located throughout the world. Depending on the scope of your interactions with {REACT_APP_SHORT_NAME} Offerings, your personal information may be stored in or accessed from multiple countries, including the United States. Whenever we transfer personal information to other jurisdictions, we will ensure that the information is transferred in accordance with this Privacy Notice and as permitted by applicable data protection laws.</p>
      </section>
      <section>
        <h4 style={{textTransform: 'uppercase'}} ><strong>How We Secure Information</strong></h4>
        <p>At {REACT_APP_SHORT_NAME}, security is our highest priority. We design our systems with your security and privacy in mind.</p>
        <ul>
          <li><strong></strong>We maintain a wide variety of compliance programs that validate our security controls. Click here to learn more about our compliance programs.</li>
          <li><strong></strong>We protect the security of your information during transmission to or from {REACT_APP_SHORT_NAME} websites, applications, products, or services by using encryption protocols and software.</li>
          <li><strong></strong>We follow the <ExLink variant='body2' to='https://www.pcisecuritystandards.org/' title='Payment Card Industry Data Security Standard (PCI DSS)' /> when handling credit card data.</li>
          <li><strong></strong>We maintain physical, electronic, and procedural safeguards in connection with the collection, storage, and disclosure of personal information. Our security procedures mean that we may require proof of identity before we disclose your personal information to you.</li>
        </ul>
      </section>
      <section>
        <h4 style={{textTransform: 'uppercase'}} ><strong>Internet Advertising and Third Parties</strong></h4>
        <p>{REACT_APP_SHORT_NAME} Offerings may include third-party advertising and links to other websites and applications. Third party advertising partners may collect information about you when you interact with their content, advertising, or services. For more information about third-party advertising, including interest-based ads, please read our <InLink variant='body2' to='/legal/ads' title='Interest-Based Ads Notice' />.</p>
      </section>
      <section>
        <h4 style={{textTransform: 'uppercase'}} ><strong>Access and Choice</strong></h4>
        <p>You can view, update, and delete certain information about your account and your interactions with {REACT_APP_SHORT_NAME} Offerings. This information can be found online for authenticated users in <InLink variant='body2' to='/user/profile' title='User Profile' />. If you cannot access or update your information yourself, you can always contact us for assistance.</p>
        <p>You have choices about the collection and use of your personal information. Many {REACT_APP_SHORT_NAME} Offerings include settings that provide you with options as to how your information is being used. You can choose not to provide certain information, but then you might not be able to take advantage of certain {REACT_APP_SHORT_NAME} Offerings.</p>
        <ul>
          <li><strong>Account Information: </strong> If you want to add, update, or delete information related to your account, please go to <InLink variant='body2' to='/user/profile' title='User Profile' />. When you update or delete any information, we usually keep a copy of the prior version for our records and, in certain cases, we are required by law to retain certain information.</li>
          <li><strong>Communications:</strong> If you do not want to receive promotional messages from us, please unsubscribe or adjust your communication preferences in <InLink variant='body2' to='/user/setting' title='User Settings' />. If you do not want to receive in-app notifications from us, please adjust your notification settings in the app or your device.</li>
          <li><strong>Advertising:</strong> If you don’t want to see interest-based ads, please adjust your Advertising Preferences in <InLink variant='body2' to='/user/settings' title='User Settings' />.</li>
          <li><strong>Browser and Devices:</strong> The Help feature on most browsers and devices will tell you how to prevent your browser or device from accepting new cookies, how to have the browser notify you when you receive a new cookie, or how to disable cookies altogether.</li>
          <li><strong>Sellers and Partners:</strong> {REACT_APP_SHORT_NAME} Sellers and Partners can add, update, or delete information in the {REACT_APP_DOMAIN_NAME}, respectively. The scope of information available will be limited to the specific products sold and/or individual partnership terms, respectively.</li>
        </ul>
      </section>
      <section>
        <h4 style={{textTransform: 'uppercase'}} ><strong>Children’s Personal Information</strong></h4>
        <p>We don’t provide {REACT_APP_SHORT_NAME} Offerings for purchase by children. If you are under 18, you may ONLY use the {REACT_APP_SHORT_NAME} Site and participate in {REACT_APP_SHORT_NAME} Offerings with the involvement of a parent or guardian.</p>
      </section>
      <section>
        <h4 style={{textTransform: 'uppercase'}} ><strong>Retention of Personal Information</strong></h4>
        <p>We keep your personal information to enable your continued use of {REACT_APP_SHORT_NAME} Offerings, for as long as it is required in order to fulfill the relevant purposes described in this Privacy Notice, as may be required by law (including for tax and accounting purposes), or as otherwise communicated to you. How long we retain specific personal information varies depending on the purpose for its use, and we will delete your personal information in accordance with applicable law.</p>
      </section>
      <section>
        <h4 style={{textTransform: 'uppercase'}} ><strong>Contacts, Notices, and Revisions</strong></h4>
        <p>If you have any concern about privacy at {REACT_APP_SHORT_NAME}, please contact us with a thorough description, and we will try to resolve it. You may also contact us at Our Address.</p>
        <p>If you interact with {REACT_APP_SHORT_NAME} Offerings on behalf of or through your organization, then your personal information may also be subject to your organization’s privacy practices, and you should direct privacy inquiries to your organization.</p>
        <p>Our business changes constantly, and our Privacy Policy may also change. You should check our website frequently to see recent changes. You can see the date on which the latest version of this Privacy Notice was posted. Unless stated otherwise, our current Privacy Policy applies to all personal information we have about you and your account. We stand behind the promises we make, however, and will never materially change our policies and practices to make them less protective of personal information collected in the past without informing affected customers and giving them a choice.</p>
      </section>
    </div>
    </Page>
    {/* </div> */}
    </>
  );
}
