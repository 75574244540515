import { decodeTime } from 'ulid';
import dayjs from 'dayjs';
import cloneDeep from 'lodash-es/cloneDeep';
// import { AuthorizedHandler } from '@org/client-graphql';
// import { isDefined } from '@org/common-tools';

// Item

export let loanFields = [
  {
    type: 'amount',
    name: 'originalBalance',
    label: 'Original Balance',
    initial: 0.00,
  },
  {
    type: 'factor',
    name: 'factor',
    label: 'Factor',
    initial: 1.0,
  },
  {
    type: 'amount',
    name: 'currentBalance',
    label: 'Current Balance',
    initial: 0.00,
  },
  {
    type: 'percent',
    name: 'interestRate',
    label: 'interestRate',
    initial: 0.00,
  },
  {
    type: 'percent',
    name: 'servicingRate',
    label: 'Servicing Rate',
    initial: 0.00,
  },
  {
    type: 'percent',
    name: 'netRate',
    label: 'Net Rate',
    initial: 0.00,
  },
  {
    type: 'amount',
    name: 'paymentAmount',
    label: 'Payment Amount',
    initial: 0.00,
    disabled: true,
  },
];

// Manage

export const columns = [
  // { field: 'id', headerName: 'ID', width: 10 },
  { field: 'originationDate', headerName: 'Origination Date', width: 170 },
  { field: 'borrowerName', headerName: 'Borrower Name', width: 220 },
  { field: 'originalBalance', headerName: 'Ori Balance', width: 150, type: 'number' },
  { field: 'interestRate', headerName: 'Int Rate', width: 130, type: 'number' },
  { field: 'creationDate', headerName: 'Creation Date', width: 170, type: 'date' },
];

export async function getRows(props) {
  // let authorizedHandler = new AuthorizedHandler({ location: props.location, history: props.history });

  let { authorizedHandler } = props; 

  try {
    let loans = await authorizedHandler.listLoans();

    loans = cloneDeep(loans);

    loans.forEach(loan => {
      loan.creationDate = dayjs(decodeTime(loan.id)).format('YY-MM-DD hh:mm a');
      loan.originationDate = dayjs(loan.originationDate).format('YYYY-MM-DD');
    });

    return loans;
  } catch (error) {
    console.error(error.message);
    return [];
  }
}

export async function refreshRows(props) {
  // let authorizedHandler = new AuthorizedHandler({ location: props.location, history: props.history });
  let { authorizedHandler } = props;

  // try {
    let loans = await authorizedHandler.refreshLoans();

    loans = cloneDeep(loans);

    loans.forEach(loan => {
      loan.creationDate = dayjs(decodeTime(loan.id)).format('YY-MM-DD hh:mm a');
      loan.originationDate = dayjs(loan.originationDate).format('YYYY-MM-DD');
    });

    return loans;
  // } catch (error) {
  //   console.error(error.message);
  //   return [];
  // }
}

export async function deleteRows(props) {
  let { authorizedHandler, selections } = props;

  if (selections.length > 1) {
    // batchDelete
  } else { // just 1 selection
    let selection = selections[0];
    let input = {
      itemType: selection.itemType,
      id: selection.id,
    };

    // let response = await authorizedHandler.deleteLoan(input);
    return authorizedHandler.deleteLoan(input);
  }
}

export async function createItem(props) {
  // console.log('Loans', 'createItem', props);
  let { authorizedHandler, input } = props;

  return authorizedHandler.createLoan(input);
}

export async function updateItem(props) {
  let { authorizedHandler, input } = props;

  return authorizedHandler.updateLoan(input);
}

export async function handleRowClick(event, props) {
  console.log('handleRowClick', event, props);
  // alert(JSON.stringify(event.row, null, ' '));

  // let data = event.row;

  // if (isDefined(data.createdBy)) {
  //   console.warn(`'Loans: 'createdBy' found in row data. Please remove!`);
  //   delete data.createdBy;
  //   console.log('Loans', 'data:', data);
  // }

  // if (isDefined(data.creationDate)) {
  //   console.warn(`'Loans: 'creationDate' found in row data. Please remove!`);
  //   delete data.creationDate;
  //   console.log('Loans', 'data:', data);
  // }

  // props.history.push({ pathname: '/admin/create-loan', state: { data } });
}
