import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
// import { AuthenticatedHandler } from '@org/client-graphql';
import { Page } from '@org/client-components-custom/page/Page';
import { Heading } from '@org/client-components-custom/page/Heading';
import { FlexRow, FlexColumn } from '@org/client-components-custom/layout/Flex';
import { FormSpacer } from '@org/client-components-custom/layout/Form';
import { ButtonBar } from '@org/client-components-core/buttons/ButtonBar';
import { Button, PrimaryButton } from '@org/client-components-core/buttons/Buttons';
import { DisplayState } from '@org/client-components-utils/DisplayState';
import { useDropzone } from 'react-dropzone';
import theme from '@org/client-libs-themes';
import { round } from '@org/common-formatters';

// let authenticatedHandler;

const MAX_SIZE = 3000000; // bytes

const MAX_FILE_SIZE = 1000000; // bytes

const MAX_FILES = 3;

let acceptedSize = 0;

let fileRejectionsHandled = true;

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  // justifyContent: 'center',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  // backgroundColor: '#fafafa',
  backgroundColor: 'white',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
};

const activeStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};

const useStyles = makeStyles((theme) => ({
  listStyle: {
    width: 'auto',
    minWidth: 'fit-content',
    whiteSpace: 'nowrap',
    margin: theme.spacing(1, 0, 1, 0),
    '& > li:not(:first-child)': {
      margin: theme.spacing(1, 0, 0, 0),
    },
  },
}));

let isMounted = false;

let initState = { isLoading: true, accepted: [], files: [], rejected: [] };

export function FileCenter(props) {
  console.log('FileCenter', props);
  const [ state, setState ] = useState(initState);

  let { authenticatedHandler } = props;

  let classes = useStyles();

  // if (!authenticatedHandler)
  //   authenticatedHandler = new AuthenticatedHandler({ location: props.location, history: props.history });

  useEffect(() => {
    isMounted = true;
    window.scrollTo(0, 0);

    if (isMounted)
      setState({ ...state, isLoading: false });

    return () => { isMounted = false; };
  }, []);

  useEffect(() => {
    console.log('state updated...', state);
  // }, []);
  }, [state]);

  // const handleRemove = file => (event) => {
  function handleRemove(file) {
    console.log('handleRemove', file);

    const accepted = [ ...state.accepted ];
    const files = [ ...state.files ];

    let index = accepted.map(elem => {
      console.log('onDrop', elem, elem.path);
      return elem.path;
    }).indexOf(file.path);
    console.log(index);

    accepted.splice(index, 1);
    files.splice(index, 1);

    acceptedSize -= file.size;

    if (isMounted)
      setState({ ...state, accepted, files });
  }

  const handleReset = () => {
    // console.log('handleReset');
    acceptedSize = 0;
    if (isMounted)
      setState({ ...state, accepted: [], files: [], rejected: [] });
  };

  // useEffect(() => {
  //   window.addEventListener('click', handleRemove);
  //   return () => {
  //     window.removeEventListener('click', handleRemove);
  //   };
  // });
  
  function onDrop(acceptedFiles) {
  // const onDrop = useCallback((acceptedFiles) => {
    console.log('');
    // console.log('onDrop', acceptedFiles);
    let accepted = [ ...state.accepted ];
    let files = [ ...state.files ];
    let rejected = [ ...state.rejected ];
    // console.log('onDrop', accepted, files, rejected);
    fileRejectionsHandled = false;

    // acceptedFiles.forEach((file) => {
    for (let index in acceptedFiles) {
      let file = acceptedFiles[index];
      console.log('onDrop', typeof file, file);
      const reader = new FileReader();
      let errors = [];

      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = () => console.log('file reading has failed');
      // reader.onload = () => {
      //   console.log('onDrop', 'reader.onload');
      // };
      reader.onloadend = () => {
        console.log('onDrop', 'reader.onloadend');

        let index = accepted.map(elem => {
          console.log('onDrop', elem, elem.path);
          return elem.path;
        }).indexOf(file.path);
        console.log(index);

        if (file.size > MAX_FILE_SIZE) {
          let error = new Error(`This file exceeds the maximum size allowed (${formatSize(MAX_FILE_SIZE)})`);
          errors.push(error);
          // rejected.push({ file, errors: [ error ] });
        }

        if (acceptedSize + file.size > MAX_SIZE) {
          let error = new Error(`Adding this file will exceed the maximum total size allowed (${formatSize(acceptedSize + file.size)} > ${formatSize(MAX_SIZE)})`);
          errors.push(error);
          // rejected.push({ file, errors: [ error ] });
          // console.log('onDrop', file, error.message);
        }

        if (accepted.length >= MAX_FILES) {
          let error = new Error(`Adding this file will exceed the maximum number of files allowed (${MAX_FILES})`);
          errors.push(error);
          // rejected.push({ file, errors: [ error ] });
        }

        if (index !== -1) {
          let error = new Error('File is already loaded');
          errors.push(error);
          // rejected.push({ file, errors: [ error ] });
          // console.log('onDrop', file, error.message);
        }

        if (errors.length > 0) {
          rejected.push({ file, errors });
        } else {
          acceptedSize += file.size;
          accepted.push(file);
          const binaryStr = reader.result;
          files.push(binaryStr);
          console.log('onDrop', file, binaryStr);
        }

        console.log('onDrop', 'setting state', accepted, files, rejected);
        if (isMounted)
          setState({ ...state, accepted, files, rejected });
      };
      reader.readAsArrayBuffer(file);
    }

    console.log('onDrop', 'ending...', state);
  // }, []);
  }

  const {
    // acceptedFiles, // getRootProps, getInputProps,
    fileRejections,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    // onBlur,
  // } = useDropzone({ onDrop, accept: 'application/pdf', maxSize: MAX_SIZE, maxFiles: MAX_FILES });
  // } = useDropzone({ onDrop, accept: 'application/pdf', maxSize: MAX_SIZE });
  } = useDropzone({ onDrop, accept: 'application/pdf' });

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isDragActive ? activeStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isDragActive,
    isDragReject,
    isDragAccept
  ]);

  async function handleUpload(event) {
    console.log('handleUpload', event);

    try {
      // uploadUserFile();
      if (isMounted)
        setState(initState);
    } catch (error) {
      console.error('FileCenter', 'handleUpload', error);
    }
  }

  function formatSize(size) {
    // console.log(size/1000, round(size/1000, 1), round(size/1000, 2));
    if (size >= 1000000)
      return `${round(size/1000000, 1)} MB`;
    else if (size >= 1000)
      return `${round(size/1000, 0)} KB`;
    else
      return `${size} bytes`;
  }

  function AcceptedList() {
    return (
      <>
      <Typography variant='h6' style={{ display: 'flex', alignSelf: 'center' }} >{'Selected Files'}</Typography>
      <ul className={classes.listStyle} >
        {state.accepted.map((file) => {
          return(
            <li key={file.path} >
              <Typography variant='body2' style={{ display: 'inline', margin: theme.spacing(0, 1, 0, 0) }} >{`${file.path} - ${formatSize(file.size)}`}</Typography>
              {/* <button key={file.path} onClick={() => handleRemove(file)} >Remove</button> */}
            </li>
          );
        })}
      </ul>
      <Typography variant='body2' style={{ display: 'flex', alignSelf: 'center' }} >{`${formatSize(acceptedSize)}`}</Typography>
      </>
    );
  }

  if (fileRejections.length > 0 && !fileRejectionsHandled) {
    // console.log('fileRejections', fileRejections);
    let rejected = [ ...state.rejected ];
    for (let rejectionIndex in fileRejections) {
      let rejection = fileRejections[rejectionIndex];
      let { file } = rejection;

      let index = rejected.map(elem => {
        // console.log('onDrop', elem);
        return elem.file.path;
      }).indexOf(file.path);

      // console.log(index);

      if (index === -1) {
        // console.log('New rejection found!', rejection);
        rejected.push(rejection);
      }
    }

    fileRejectionsHandled = true;

    // console.log(rejected.length, state.rejected.length, rejected.length > state.rejected.length);
    if (isMounted && rejected.length > state.rejected.length)
      setState({ ...state, rejected });
  }

  function RejectedList() {
    return (
      <>
      <Typography variant='h6'style={{ display: 'flex', alignSelf: 'center' }} >{'Rejected Files'}</Typography>
      <ul className={classes.listStyle} >
        {/* {fileRejections.map(({ file, errors }) => { */}
        {state.rejected.map(({ file, errors }) => {
          console.log('RejectedList', file, errors);
          return (
            <li key={file.path} >
              {file.path} - {formatSize(file.size)} bytes
              <ul>
                {errors.map(e => <li key={e.code} >{e.message}</li>)}
              </ul>
            </li>
          );
        })}
      </ul>
      </>
    );
  }

  // const rejectedList = fileRejections.map(({ file, errors  }) => {
  //   console.log('rejectedList', file, errors);
  //   return (
  //     <li key={file.path} >
  //       {file.path} - {formatSize(file.size)} bytes
  //       <ul>
  //         {errors.map(e => <li key={e.code} >{e.message}</li>)}
  //       </ul>
  //     </li>
  //   );
  // });

  let uploadable = (state.accepted.length > 0) ? true : false;
  let resetable = (uploadable || state.rejected.length > 0) ? true : false;

  return(
    <Page id='user-file-center' >

      <Heading>{'File Center'}</Heading>

      {/* <BasicDropZone onDrop={onDrop} /> */}

      <FlexRow style={{ justifyContent: 'center' }} >
        <div style={{ width: '100%' }} >
          <div { ...getRootProps({ style }) } >
            <input { ...getInputProps() } />

            {/* {!state.accepted.length &&
            <p className={classes.listStyle} >{'Drag files here or click to select'}</p>} */}
            <Typography variant='body1' >{'Drag files here or click to select'}</Typography>
            <Typography variant='body2' >{'Currently for testing purposes only'}</Typography>
            <Typography variant='body2' >{'Limit 3 files, 1 MB per file, 3 MB total'}</Typography>

            {/* {state.accepted.length > 0 &&
            <FlexRow style={{ justifyContent: 'center' }} >
              <FlexColumn style={{ width: 'auto', minWidth: 'fit-content', alignItems: 'start' }} >
                <AcceptedList />
              </FlexColumn>
            </FlexRow>} */}

          </div>
        </div>
      </FlexRow>

      <FormSpacer />

      <ButtonBar>
        <PrimaryButton title={'Upload'} onClick={handleUpload} disabled={!uploadable} />
        <Button title={'Reset'} onClick={handleReset} disabled={!resetable} />
        {/* <SecondaryButton title={'Remove'} onClick={handleRemove} disabled={disabled} /> */}
      </ButtonBar>

      {state.accepted.length > 0 &&
      <FlexRow style={{ justifyContent: 'center', margin: theme.spacing(0, 0, 3, 0) }} >
        <FlexColumn style={{ width: 'auto', minWidth: 'fit-content', alignItems: 'start' }} >
          <AcceptedList />
        </FlexColumn>
      </FlexRow>}

      {state.rejected.length > 0 &&
      <FlexRow style={{ justifyContent: 'center', margin: theme.spacing(0, 0, 3, 0) }} >
        <FlexColumn style={{ width: 'auto', minWidth: 'fit-content', alignItems: 'start' }} >
          <RejectedList />
        </FlexColumn>
      </FlexRow>}

      <DisplayState title='state' state={{ isMounted, acceptedSize, fileRejectionsHandled, state }} open={true} />

    </Page>
  );
}

export default FileCenter;

FileCenter.propTypes = {
  location: PropTypes.shape(),
  history: PropTypes.shape(),
  authenticatedHandler: PropTypes.shape(),
};
