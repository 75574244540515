import React from 'react';
import { NumberField } from '@org/client-components-core/inputs/NumberField';

export function AmountField(props) {
  // console.log('AmountField', props);

  let inputProps = {
    thousandSeparator: true,
    fixedDecimalScale: true,
    decimalScale: 2,
    prefix:'$',
  };

  return(<NumberField inputProps={inputProps} {...props} />);
}
