import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  buttonBar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    // padding: theme.spacing(2, 0, 1, 0),
    // padding: theme.spacing(1, 0, 1, 0),
    '& > *': {
      margin: theme.spacing(1),
    },
  },
}));

export function ButtonBar(props) {
  // console.log('ButtonBar', props);
  let classes = useStyles();

  return(
    <span className={classes.buttonBar} {...props} >
      {props.children}
    </span>
  );
}

export default ButtonBar;

ButtonBar.propTypes = {
  children: PropTypes.any,
};
