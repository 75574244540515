import React from 'react';
// import ListItem from '@material-ui/core/ListItem';
// import ListItemIcon from '@material-ui/core/ListItemIcon';
// import ListItemText from '@material-ui/core/ListItemText';
// import Link from '@material-ui/core/Link';
// import { Link as RouterLink } from 'react-router-dom';
import HomeIcon from '@material-ui/icons/Home';
import ContactsIcon from '@material-ui/icons/Contacts';
import SecurityIcon from '@material-ui/icons/Security';
import SubjectIcon from '@material-ui/icons/Subject';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
// import EmojiPeopleRoundedIcon from '@material-ui/icons/EmojiPeopleRounded';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
// import FolderOpenIcon from '@material-ui/icons/FolderOpen';
// import WeekendRoundedIcon from '@material-ui/icons/WeekendRounded';
// import DashboardIcon from '@material-ui/icons/Dashboard';
// import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
// import PeopleIcon from '@material-ui/icons/People';
// import BarChartIcon from '@material-ui/icons/BarChart';
// import LayersIcon from '@material-ui/icons/Layers';

export const userListItems = [
  {
    to: '/user',
    text: 'Home',
    icon: <HomeIcon /* fontSize='large' */ />,
  },
  // {
  //   to: '/user/welcome',
  //   text: 'Welcome',
  //   icon: <EmojiPeopleRoundedIcon /* fontSize='large' */ />,
  // },
  // {
  //   to: '/user/account',
  //   text: 'Account',
  //   icon: <ContactsIcon /* fontSize='large' */ />,
  // },
  {
    to: '/user/personal',
    text: 'Personal',
    icon: <ContactsIcon /* fontSize='large' */ />,
  },
  {
    to: '/user/security',
    text: 'Security',
    icon: <SecurityIcon /* fontSize='large' */ />,
  },
  {
    to: '/user/activity',
    text: 'Activity',
    icon: <SubjectIcon /* fontSize='large' */ />,
  },
  {
    to: '/user/file-center',
    text: 'File Center',
    icon: <BusinessCenterIcon /* fontSize='large' */ />,
  },
  {
    to: '/sign-out',
    text: 'Sign Out',
    icon: <ExitToAppIcon />,
  },
];

// export const userListItems = (
//   <div>
//     <Link component={RouterLink} to="/user" >
//       <ListItem button>
//         <ListItemIcon>
//           <HomeIcon />
//         </ListItemIcon>
//         <ListItemText primary="Home" />
//       </ListItem>
//     </Link>
//     <ListItem button>
//       <ListItemIcon>
//         <DashboardIcon />
//       </ListItemIcon>
//       <ListItemText primary="Dashboard" />
//     </ListItem>
//     <ListItem button>
//       <ListItemIcon>
//         <ShoppingCartIcon />
//       </ListItemIcon>
//       <ListItemText primary="Orders" />
//     </ListItem>
//     <ListItem button>
//       <ListItemIcon>
//         <PeopleIcon />
//       </ListItemIcon>
//       <ListItemText primary="Customers" />
//     </ListItem>
//     <ListItem button>
//       <ListItemIcon>
//         <BarChartIcon />
//       </ListItemIcon>
//       <ListItemText primary="Reports" />
//     </ListItem>
//     <ListItem button>
//       <ListItemIcon>
//         <LayersIcon />
//       </ListItemIcon>
//       <ListItemText primary="Integrations" />
//     </ListItem>
//   </div>
// );
