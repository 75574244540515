import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { styles } from '@org/client-libs-styles';
import theme from '@org/client-libs-themes';

let localStyles = {
  ...styles,
  pageRoot: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: '100%',
    minHeight: '80vh',
    width: '100%',
    maxWidth: '120rem',
    // padding: theme.spacing(4, 8, 4, 8),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2),
    },
    [theme.breakpoints.only('sm')]: {
      padding: theme.spacing(2, 4, 2, 4),
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(4, 8, 4, 8),
    },
  },
  formTitleVariant: 'h4',
  pageHeadingWrapper: {
    display: 'flex',
    flexDirection: 'row',
    margin: theme.spacing(0, 0, 4, 0),
    width: '100%',
  },
  pageTitle: {
    fontFamily: `"Roboto Slab", "Times New Roman", serif`,
    textAlign: 'center',
  },
  pageTitleVariant: 'h3',
  pageHeadingSpan: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  pageHeadingSpanCenter: {
    alignItems: 'center',
    flexGrow: 0,
  },
  pageHeadingSpanLeft: {
    alignItems: 'flex-start',
    flexGrow: 1,
  },
  pageHeadingSpanRight: {
    alignItems: 'flex-end',
    flexGrow: 1,
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    minWidth: 'fit-content',
    marginBottom: theme.spacing(2),
  },
  sectionHeading: {
    // alignItems: 'flex-start',
    display: 'flex',
    width: '100%',
    marginBottom: theme.spacing(1),
    textTransform: 'uppercase',
  },
  sectionBody: {
    width: '100%',
    marginBottom: theme.spacing(2),
    lineHeight: 1.4,
  },
  docs: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    minWidth: 'fit-content',
    marginTop: theme.spacing(2),
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    // alignItems: 'center',
    alignItems: 'baseline',
    width: '100%',
    margin: theme.spacing(0, 0, 1, 0),
    [theme.breakpoints.only('xs')]: { // sm, small: 600px
      flexDirection: 'column',
    },
  },
  rowLabel: {
    [theme.breakpoints.only('xs')]: { // sm, small: 600px
      textDecoration: 'underline'
    },
    [theme.breakpoints.up('sm')]: { // sm, small: 600px
      minWidth: '20rem',
    },
  },
  fieldVariant: 'h6',
  // variants: {
  //   [theme.breakpoints.only('xs')]: { // sm, small: 600px
  //     title: 'h5',
  //     field: 'body1',
  //   },
  //   [theme.breakpoints.up('sm')]: { // sm, small: 600px
  //     title: 'h3',
  //     field: 'h6',
  //   },
  // },
// }));
};

const useStyles = makeStyles(localStyles);

export function FormTitle(props) {
  let classes = useStyles();
  return(
    <Typography variant={localStyles.formTitleVariant} component='h1' className={classes.headingTitle} >
      {props.children}
    </Typography>
  );
}

FormTitle.propTypes = {
  children: PropTypes.node,
};


export function Page(props) {
  let classes = useStyles();
  return (
    <div className={classes.pageRoot} {...props} >
      {props.children}
    </div>
  );
}

Page.propTypes = {
  children: PropTypes.node,
};

export function PageHeading(props) {
  let classes = useStyles();

  return(
    <Typography variant={styles.pageTitleVariant} component='h1' className={clsx(classes.pageTitle, classes.fullWidth)} >
      {props.children}
    </Typography>
  );
}

PageHeading.propTypes = {
  children: PropTypes.node,
};

export function PageHeadingWrapper(props) {
  let classes = useStyles();
  return (
    <div className={clsx(classes.pageHeadingWrapper, classes.fullWidth)} {...props} >
      {props.children}
    </div>
  );
}

PageHeadingWrapper.propTypes = {
  children: PropTypes.node,
};

export function PageHeadingLeft(props) {
  let classes = useStyles();
  return (
    <span className={clsx(classes.pageHeadingSpan, classes.pageHeadingSpanLeft)} >
      {props.children}
    </span>
  );
}

PageHeadingLeft.propTypes = {
  children: PropTypes.node,
};

export function PageHeadingCenter(props) {
  let classes = useStyles();
  return (
    <span className={clsx(classes.pageHeadingSpan, classes.pageHeadingSpanCenter)} >
      <Typography variant={localStyles.pageTitleVariant} component='h1' className={classes.pageTitle} >
        {props.children}
      </Typography>
    </span>
  );
}

PageHeadingCenter.propTypes = {
  children: PropTypes.node,
};

export function PageHeadingRight(props) {
  let classes = useStyles();
  return (
    <span className={clsx(classes.pageHeadingSpan, classes.pageHeadingSpanRight)} >
      {props.children}
    </span>
  );
}

PageHeadingRight.propTypes = {
  children: PropTypes.node,
};

export function Docs(props) {
  let classes = useStyles();

  return(
    <section className={classes.docs} >
      {props.children}
    </section>
  );
}

Docs.propTypes = {
  children: PropTypes.node.isRequired,
};
