// import gql from 'graphql-tag';
import { gql } from '@apollo/client';

//// Local

const IS_LOADING = gql`{ isLoading @client }`;
const IS_ONLINE = gql`{ isOnline @client }`;
const IS_AUTHENTICATED = gql`{ isAuthenticated @client }`;
const IS_ADMINISTRATOR = gql`{ isAdministrator @client }`;
const IS_DEVELOPER = gql`{ isDeveloper @client }`;
const IS_SUPER = gql`{ isSuper @client }`;
const GET_GROUPS = gql`{ groups @client }`;


export const SET_LOADING = gql`
  query setLoading {
    setLoading @client
  }
`;

// export const SET_AUTHENTICATED = gql`
//   mutation setAuthenticated($input: boolean!) {
//     setAuthenticated @client (input: $input)
//   }
// `;

export const SET_AUTHENTICATED = gql`
  query setAuthenticated {
    isAuthenticated @client
  }
`;

// export const SET_GROUPS = gql`
//   query setGroups($input: [ string ]) {
//     setGroups @client (input: $input)
//   }
// `;

export const SET_GROUPS = gql`
  query setGroups {
    groups @client
  }
`;

//// Queries

const PING_PUBLIC = gql`
  query pingPublic {
    pingPublic
  }
`;

export const GET_NOTE = gql`
  query getNote($input: GetItemInput!) {
    getNote(input: $input) {
      # success
      # data {
      id
      itemType
      # createdAt
      # updatedAt
      createdBy
      launchDate
      itemName
      description
      coupon
      managementRate
      originalBalance
      minimumAmount
      # referenceItemId
      # referenceItemType
      # referenceItemAssetType
      # referenceItemAssetSubType
      referenceId
      referenceType
      # }
      # error
    }
  }
`;

export const GET_VERSION = gql`
  query getVersion {
    getVersion
  }
`;

export const LIST_NOTES = gql`
  query listNotes($input: ListItemsInput) {
    listNotes(input: $input) {
      # success
      # data {
      #   count
      #   scannedCount
      #   lastKey
      #   items {
      # itemType
      id
      launchDate
      itemName
      description
      coupon
      managementRate
      originalBalance
      minimumAmount
      referenceType
      referenceId
      # referenceAssetType
      # referenceAssetSubType
      #   }
      # }
      # error
    }
  }
`;

//// Mutations

export const CONFIRM_INVITATION = gql`
  mutation confirmInvitation($input: AuthInput!) {
    confirmInvitation(input: $input) {
      firstName
      lastName
      email
      groups
    }
  }
`;

export const CONFIRM_RESET_PASSWORD = gql`
  mutation confirmResetPassword($input: ConfirmResetPasswordInput!) {
    confirmResetPassword(input: $input) {
      firstName
      lastName
      email
      groups
    }
  }
`;

export const CONFIRM_SIGN_UP = gql`
  mutation confirmSignUp($input: AuthInput!) {
    confirmSignUp(input: $input) {
      # success
      # data {
      firstName
      lastName
      email
      groups
      # }
      # error
    }
  }
`;

export const INITIATE_RESET_PASSWORD = gql`
  mutation initiateResetPassword($input: AuthInput!) {
    # initiateResetPassword(input: $input)
    initiateResetPassword(input: $input) {
      success
      confirmCode
    }
  }
`;

export const INITIATE_SIGN_UP = gql`
  mutation initiateSignUp($input: AuthInput!) {
    initiateSignUp(input: $input)
    # initiateSignUp(input: $input) {
    #   success
    #   data
    #   error
    # }
  }
`;

// export const INVITATION_SIGN_IN = gql`
  // mutation invitationSignIn($input: InvitationInput!) {
    // invitationSignIn(input: $input) {
      // success
      // data {
        // session {
          // id
          // createdAt
          // expiresAt
          // fetchCount
          // userId
          // groups
          // ipAddress
          // viewerAgent
        // }
      // }
      // error
    // }
  // }
// `;

// export const RESEND_CONFIRM_CODE = gql`
//   mutation resendConfirmCode($input: GetMessageInput!) {
//     resendConfirmCode(input: $input) {
//       success
//       data {
//         confirmCode
//       }
//       error
//     }
//   }
// `;

export const RESEND_RESET_PASSWORD = gql`
mutation resendResetPassword($input: AuthInput!) {
  resendResetPassword(input: $input) {
    success
    confirmCode
    # data
    # error
  }
}
`;

export const RESEND_SIGN_UP = gql`
  mutation resendSignUp($input: AuthInput!) {
    resendSignUp(input: $input)
    # resendSignUp(input: $input) {
    #   success
    #   data
    #   error
    # }
  }
`;

// export const RESPOND_TO_AUTH_CHALLENGE = gql`
//   mutation respondToAuthChallenge($input: RespondToAuthChallengeInput!) {
//     respondToAuthChallenge(input: $input) {
//       success
//       data {
//         authentication {
//           accessToken
//           expiresIn
//           tokenType
//           idToken
//           refreshToken
//         }
//         challenge {
//           challengeName
//           session
//           challengeParameters {
//             USER_ID_FOR_SRP
//             requiredAttributes
//             userAttributes
//           }
//         }
//       }
//       error
//     }
//   }
// `;

export const SIGN_IN = gql`
  mutation signIn($input: AuthInput!) {
    signIn(input: $input) {
      # success
      # data {
      firstName
      lastName
      email
      groups
      # }
      # error
    }
  }
`;

// export const SIGN_OUT = gql`
//   mutation signOut {
//     signOut {
//       success
//       error
//     }
//   }
// `;

export const VERIFY_INVITATION = gql`
  mutation verifyInvitation($input: AuthInput!) {
    verifyInvitation(input: $input)
    # verifyInvitation(input: $input) {
    #   success
    #   data {
    #     email
    #   }
    #   error
    # }
  }
`;

export const publicSchema = {
  //// Local
  IS_LOADING,
  IS_ONLINE,
  IS_AUTHENTICATED,
  IS_ADMINISTRATOR,
  IS_DEVELOPER,
  IS_SUPER,
  GET_GROUPS,
  SET_LOADING,
  SET_AUTHENTICATED,
  SET_GROUPS,
  //// Queries
  PING_PUBLIC,
  GET_NOTE,
  GET_VERSION,
  LIST_NOTES,
  //// Mutations
  // CREATE_INVITATION,
  CONFIRM_INVITATION,
  CONFIRM_RESET_PASSWORD,
  CONFIRM_SIGN_UP,
  INITIATE_RESET_PASSWORD,
  INITIATE_SIGN_UP,
  // INVITATION_SIGN_IN,
  // RESEND_CONFIRM_CODE,
  RESEND_RESET_PASSWORD,
  RESEND_SIGN_UP,
  // RESPOND_TO_AUTH_CHALLENGE,
  SIGN_IN,
  // SIGN_OUT,
  VERIFY_INVITATION,
};
