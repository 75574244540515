import { decodeTime } from 'ulid';
import dayjs from 'dayjs';
import cloneDeep from 'lodash-es/cloneDeep';
// import { AuthorizedHandler } from '@org/client-graphql';

export let fields = [
  {
    type: 'text',
    name: 'referenceId',
    label: 'Reference ID',
    initial: "",
  },
  {
    type: 'text',
    name: 'referenceId',
    label: 'User ID',
    initial: "",
  },
  {
    type: 'text',
    name: 'eventDate',
    label: 'Event Date',
    initial: "",
  },
  {
    type: 'text',
    name: 'referenceType',
    label: 'Reference Type',
    initial: "",
  },
  {
    type: 'text',
    name: 'referenceId',
    label: 'Reference ID',
    initial: "",
  },
  {
    type: 'amount',
    name: 'paymentAmount',
    label: 'Payment Amount',
    initial: 0.0,
  },
];

export const columns = [
  { field: 'id', headerName: 'ID', width: 260 },
  { field: 'eventDate', headerName: 'Date', width: 110, type: 'date' },
  { field: 'referenceType', headerName: 'Reference Type', width: 180 },
  { field: 'referenceId', headerName: 'Reference ID', width: 260 },
];

export async function getRows(props) {
  // let authorizedHandler = new AuthorizedHandler({ location: props.location, history: props.history });
  let { authorizedHandler } = props;

  let payments = await authorizedHandler.listPayments();

  payments = cloneDeep(payments);

  payments.forEach(payment => {
    payment.creationDate = dayjs(decodeTime(payment.id)).format('YY-MM-DD hh:mm a');
  });

  return payments;
}

export async function refreshRows(props) {
  let { authorizedHandler } = props;

  let payments = await authorizedHandler.refreshPayments();

  payments = cloneDeep(payments);

  payments.forEach(payment => {
    payment.creationDate = dayjs(decodeTime(payment.id)).format('YY-MM-DD hh:mm a');
  });

  return payments;
}

export async function createItem(props) {
  // console.log('Users', 'createItem', props);
  let { authorizedHandler, input } = props;

  return authorizedHandler.createPayment(input);
}

export async function updateItem(props) {
  // console.log('Users', 'updateItem', props);
  let { authorizedHandler, input } = props;

  return authorizedHandler.updatePayment(input);
}

export async function deleteRows(props) {
  let { authorizedHandler, selections } = props;

  if (selections.length > 1) {
    // batchDelete
    selections.forEach((selection) => {
      // let input = { id: selection.id };
      // authorizedHandler.deletePayment(input);
      authorizedHandler.deletePayment(selection.id);
    });
  } else { // just 1 selection
    let selection = selections[0];
    // let input = { id: selection.id };
    // return authorizedHandler.deletePayment(input);
    authorizedHandler.deletePayment(selection.id);
  }
}

export async function handleRowClick(event) {
  alert(JSON.stringify(event.row, null, ' '));
}
