import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import useMediaQuery from '@material-ui/core/useMediaQuery';
// import IconButton from '@material-ui/core/IconButton';
import AccountCircle from '@material-ui/icons/AccountCircle';
import clsx from 'clsx';
import { publicSchema } from '@org/client-graphql';
// import { InLink, IconButton } from '@org/client-components-core';
import { IconButton } from '@org/client-components-core/buttons/IconButton';
import { InLink } from '@org/client-components-core/links/InLink';
import { makeStyles } from '@material-ui/core/styles';
import { appBarStyles } from './appBarStyles';
import theme from '@org/client-libs-themes';

const useStyles = makeStyles(appBarStyles);

export function UserIconButton(props) {
  const isXs = useMediaQuery(theme.breakpoints.only("xs"));
  // const isSm = useMediaQuery(theme.breakpoints.only("sm"));
  // const isMd = useMediaQuery(theme.breakpoints.up("md"));
  // const { loading, data, error } = useQuery(publicSchema.IS_AUTHENTICATED);
  const { data } = useQuery(publicSchema.IS_AUTHENTICATED);
  // console.log('UserIconButton', loading, data, error);
  let isAuthenticated = false;
  // if (loading) isAuthenticated = false;
  if (data) isAuthenticated = data.isAuthenticated;
  // let isAuthenticated = await publicResolvers.isAuthenticated();
  // console.log('UserIconButton', 'isAuthenticated', isAuthenticated);
  const classes = useStyles();
  let { open, handleOpenDrawer } = props;

  let size = 'medium';
  let fontSize = 'large';
  if (isXs)
    size = 'small';
  //   fontSize = 'small';
  // } else if (isSm) {
  //   size = 'small';
  //   fontSize = 'default';
  // }

  if (isAuthenticated)
    return (
      // <span className={classes.userIconButtonWrapper} >
      <IconButton
        name='user-icon-button'
        // edge="end"
        // color="inherit"
        aria-label="user icon button"
        size={size}
        onClick={handleOpenDrawer}
        // className={clsx(classes.userButton, open && classes.menuButtonHidden)}
        // className={open ? classes.menuButtonHidden : null} //
      // >
      //   <AccountCircle fontSize='large' />
      // </IconButton>
        icon={<AccountCircle fontSize={fontSize} />}
        // style={{ margin: 0 }}
      />
      // </span>
    );
  // else if (props.pathname !== '/sign-in' && props.pathname !== '/sign-up')
  else if (props.pathname !== '/sign-in')
    return <InLink variant="h5" color="inherit" noWrap className={clsx(classes.appBarLink && classes.signInLink)} to='/sign-in' title='Sign In' />;
  else if (props.pathname !== '/sign-up')
    return <InLink variant="h5" color="inherit" noWrap className={clsx(classes.appBarLink && classes.signInLink)} to='/sign-up' title='Sign Up' />;
  else
    return null;
}

export default UserIconButton;

UserIconButton.propTypes = {
  open: PropTypes.bool,
  handleOpenDrawer: PropTypes.func,
};
