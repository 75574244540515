import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { default as MuiLink } from '@material-ui/core/Link';
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  link: theme.custom.link,
}));

/**
 * A React-Router Link to an internal address.
 */
export function Link(props) {
  // console.log('Link', props);
  let classes = useStyles();
  let { to, title, ...rest } = props;

  if (!title && !props.children)
    throw new Error(`Link Error: 'title' or 'children' must be passed in props`);

  title = title ? title : props.children;

  return(
    <MuiLink
      className={classes.link}
      variant={props.variant ? props.variant : 'body1'}
      component={RouterLink}
      to={to}
      {...rest}
    >{title}</MuiLink>
  );
}

Link.propTypes = {
  to: PropTypes.string.isRequired,
  title: PropTypes.string,
  variant: PropTypes.string,
  children: PropTypes.node,
};
