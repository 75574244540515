import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Stack, Item } from '@org/client-components-core/layout/Stack';
import { Spacer } from '@org/client-components-core/layout/Spacer';

const useStyles = makeStyles(theme => ({
  root: {
    ...theme.custom.form.root,
    [theme.breakpoints.up('sm')]: {
      // minHeight: '80vh',
      // minHeight: `calc(100vh - ${theme.custom.APP_BAR_HEIGHT + 120}px)`,
      minHeight: `calc(100vh - ${theme.custom.FORM_HEIGHT_OFFSET}px)`,
      padding: theme.spacing(4, 0, 4, 0),
    },
    [theme.breakpoints.only('xs')]: {
      minHeight: '60vh',
      padding: theme.spacing(2, 0, 2, 0),
    },
  },
  container: theme.custom.form.container,
}));

export function FormLayout(props) {
  let classes = useStyles();
  return(
    <div className={classes.root} >
      <div className={classes.container} >
        {props.children}
      </div>
    </div>
  );
}

FormLayout.propTypes = {
  children: PropTypes.any,
};

export function FormStack(props) {
  let theme = useTheme();
  let { children, ...rest } = props;
  return (
    <Stack
      direction='column'
      spacing={2}
      margin={0}
      style={{ width: '100%', maxWidth: theme.spacing(theme.custom.MAX_INPUT_WIDTH) }}
      {...rest}
    >
      {children}
    </Stack>
  );
}

FormStack.propTypes = {
  children: PropTypes.any,
};

export function FormItem(props) {
  let { direction, children, ...rest } = props;
  // console.log('FormItem', props);
  return (
    <Item style={{ width: '100%' }} {...rest} >{children}</Item>
  );
}

FormItem.propTypes = {
  children: PropTypes.any,
};

export function FormSpacer() {
  return(<Spacer spacing={3} />);
}
