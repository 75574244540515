import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import * as yup from 'yup/es';
import Grid from '@material-ui/core/Grid';
import { useForm, HooksForm } from '@org/client-components-core/form/useForm';
import { FormLayout, FormSpacer } from '@org/client-components-custom/layout/Form';
import { ButtonBar } from '@org/client-components-core/buttons/ButtonBar';
import { EmailField } from '@org/client-components-core/inputs/EmailField';
import { BackdropLoader } from '@org/client-components-core/feedback/Loaders';
import { PrimaryButton, SecondaryButton } from '@org/client-components-core/buttons/Buttons';
import { Heading, FormMessage, FormError } from '@org/client-components-custom';
import { useValidate, emailShape } from '@org/common-yup';
// import { styles } from '@org/client-libs-styles';
import { DisplayState } from '@org/client-components-utils/DisplayState';

// const useStyles = makeStyles(styles);

let initialValues = {
  email: "",
};

let requiredValues = {
  email: true,
};

let validationShape = {
  email: emailShape,
};
const validationSchema = yup.object().shape(validationShape);

export function AddEmail(props) {
  const [ state, setState ] = useState({ isLoading: true, isSubmitting: false, formMessage: null, formError: null });
  // let classes = useStyles();
  const isMounted = useRef(false);
  const [ validate ] = useValidate(validationSchema);
  const { formState, fieldProps } = useForm({ initialValues, requiredValues, validate });
  let { authenticatedHandler } = props;

  useEffect(() => {
    isMounted.current = true;
    window.scrollTo(0, 0);

    if (isMounted.current)
      setState({ ...state, isLoading: false});
    
    return () => { isMounted.current = false; };
  }, []);

  async function handleSubmit(formProps) {
    if (isMounted.current)
      setState({ ...state, isSubmitting: true });

    try {
      let { email } = formProps.values;
      await authenticatedHandler.initiateAddEmail({ email });

      props.history.push({
        pathname: '/user/confirm-add-email',
        state: { email },
      });

    } catch (error) {
      if (isMounted.current)
        setState({ ...state, isSubmitting: false, formError: error });
    }
  }

  function handleCancel() {
    props.history.goBack();
  }

  return(
    <>
    {state.isLoading &&
    <BackdropLoader />}

    <HooksForm
      id='user-personal-form'
      formState={formState}
    >
      {formProps => {
      let fieldEnabled = !state.isLoading && !state.isSubmitting;
      let submitEnabled = fieldEnabled && formProps.isValidated;
      return (
        <>
        <FormLayout>

          <Heading>{'Add Email'}</Heading>

          <Grid style={{width: '100%'}} >

            {state.formMessage &&
            <Grid item >
              <FormMessage message={state.formMessage} />
            </Grid>}

            {state.formError &&
            <Grid item >
              <FormError message={state.formError.message} />
            </Grid>}

            {!state.isCompleted &&
            <Grid item >
              <EmailField {...fieldProps} name='email' required={true} />
            </Grid>}

          </Grid>

          <FormSpacer />

          <ButtonBar>
            <PrimaryButton
              aria-label='submit button'
              title='Submit'
              disabled={!submitEnabled}
              onClick={() => handleSubmit(formProps)}
            />
            <SecondaryButton
              aria-label='cancel button'
              title='Cancel'
              onClick={handleCancel}
            />
          </ButtonBar>

        </FormLayout>
        </>
      );}}
    </HooksForm>

    <DisplayState title='state' state={{ state, formState }} open={true} />
    </>
  );
}

export default AddEmail;

AddEmail.propTypes = {
  history: PropTypes.shape(),
  authenticatedHandler: PropTypes.shape(),
};
