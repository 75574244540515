import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker as MuiKeyboardDatePicker,
} from '@material-ui/pickers';
import DayjsUtils from '@date-io/dayjs';

// https://material-ui.com/components/pickers/#material-ui-pickers
// https://material-ui-pickers.dev/demo/datepicker
// https://material-ui-pickers.dev/api/KeyboardDatePicker

const useStyles = makeStyles((theme) => ({
  datePicker: {
    '& .MuiInputBase-input': {
      width: theme.spacing(12),
      // maxWidth: 'fit-content',
    },
    '& .MuiOutlinedInput-adornedEnd': {
      paddingRight: theme.spacing(0),
    },
    '& .MuiInputAdornment-positionEnd': {
      marginLeft: theme.spacing(0),
    },
    ...theme.custom.input,
  },
}));

export function DatePicker(props) {
  // console.log('DatePicker', props);
  const classes = useStyles();
  // let { name, type, label, values, errors, validated, handleChange, handleBlur, required, ...rest } = props;
  let { name, label, state, handleChange, handleBlur, ...rest } = props;
  // let { values, validated, required, errors } = state;
  let { values, required, errors } = state;

  // let value = (values && name && values[name]) != null ? values[name] : "";
  // value = props.value ? props.value : value;
  // let error = (errors && name && errors[name]) ? true : false;

  if (!name)
    console.error(`DatePicker must receive \`name\` prop`);

  if (!label && (!props.children && typeof props.children !== 'string'))
    console.warn(`DatePicker must receive 'label' prop or child of type 'string'`);

  if (!props.handleChange)
    console.warn('DatePicker must receive `handleChange` prop', name);

  if (!props.handleBlur)
    console.warn('DatePicker must receive `handleBlur` prop', name);

  if (!state)
    console.warn(`DatePicker must receive \`state\` props from form`, name);


  return(
    <MuiPickersUtilsProvider utils={DayjsUtils} >
      <MuiKeyboardDatePicker
        id={props.id ? props.id : name}
        name={name}
        label={label}
        value={values[name]}
        // fullWidth
        disableToolbar
        variant='inline'
        inputVariant='outlined'
        format='MM/DD/YYYY'
        error={errors[name] ? true : false}
        helperText={errors[name]}
        onChange={(date) => handleChange(date, name)}
        onBlur={(event) => handleBlur(event, name)}
        required={required[name] ? true : false}
        autoOk={true} // close picker on date select
        // minDate={props.minDate}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
        size={'small'}
        className={classes.datePicker}
        {...rest} // overrides
      />
    </MuiPickersUtilsProvider>
  );
}

DatePicker.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  // type: PropTypes.string,
  label: PropTypes.string,
  state: PropTypes.shape(),
  // values: PropTypes.shape(),
  // errors: PropTypes.shape(),
  // validated: PropTypes.shape(),
  handleChange: PropTypes.func,
  // handleChangeEvent: PropTypes.func,
  handleBlur: PropTypes.func,
  // disabled: PropTypes.bool,
  // required: PropTypes.bool,
  // endAdornment: PropTypes.node,
  // autoFocus: PropTypes.bool,
  children: PropTypes.oneOfType([ PropTypes.string, PropTypes.array ]),
};
