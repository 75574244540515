import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { default as MuiLink } from '@material-ui/core/Link';

const useStyles = makeStyles(theme => ({
  link: theme.custom.link,
}));

/**
 * A Material-UI Link wrapper to an internal address.
 * Use this to wrap around other components.
 */

export function ExLinkWrapper(props) {
  let classes = useStyles();

  let { to, children, ...rest } = props;

  return(<MuiLink className={classes.link} href={to} target='_blank' rel='noopener noreferrer' {...rest} >{children}</MuiLink>);
}

ExLinkWrapper.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
};
