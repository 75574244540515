import React from 'react';
import PropTypes from 'prop-types';
import { InLink } from '@org/client-components-core/links/InLink';
import { Checkbox } from '@org/client-components-core/inputs/Checkbox';
import { makeStyles } from '@material-ui/core/styles';

const styles = {
  agree: {
    display: 'inline',
  },
};

const useStyles = makeStyles(styles);

export function AgreeCheckbox(props) {
  // console.log('AgreeCheckbox', props);
  const classes = useStyles();

  return(
    <>
    <Checkbox
      {...props}
      name='agree'
      // checked={props?.values?.agree}
      // onChange={props.handleChange}
      label={<p className={classes.agree} >I agree to the {process.env.REACT_APP_COMPANY_NAME} <InLink to='/legal/terms' title='Terms of Use' className={classes.agree} /> and <InLink to='/legal/privacy' title='Privacy Policy' className={classes.agree} /></p>}
      // errors={props.errors}
    />
    </>
  );
}

AgreeCheckbox.propTypes = {
  values: PropTypes.shape(),
  // values: PropTypes.shape({
  //   agree: PropTypes.bool,
  // }),
  // errors: PropTypes.shape(),
  // handleChange: PropTypes.func,
};
