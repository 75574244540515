import React from 'react';
import { useQuery } from '@apollo/client';
import { publicSchema } from '@org/client-graphql';
import { CustomRoute } from '@org/client-components-routes/CustomRoutes';

export function NotAuthenticatedRoute(props) {
  // console.log('NotAuthenticatedRoute', props);

  const { data } = useQuery(publicSchema.IS_AUTHENTICATED);
  // console.log(data);

  return (<CustomRoute {...props} test={!data.isAuthenticated} to='/user-not-authorized' />);
}
