import React, { lazy } from 'react';
import PropTypes from 'prop-types';
import base64url from 'base64url';
import { Switch, Route, Redirect } from 'react-router-dom';
import { NotAuthenticatedRoute } from '@org/client-components-routes/NotAuthenticatedRoute';
import { AuthenticatedRoute } from '@org/client-components-routes/AuthenticatedRoute';
import { AuthorizedRoute } from '@org/client-components-routes/AuthorizedRoute';
import { Home } from '@org/client-pages-home';
import { Learn } from '@org/client-pages-learn';
import { Invest } from '@org/client-pages-invest/Invest';
import { InvestmentDetail } from '@org/client-pages-invest/InvestmentDetail';
// import { EnterTrade } from '@org/client-pages-invest/EnterTrade';
// import { EnterTrade2 } from '@org/client-pages-invest/EnterTrade2';
import { EnterTrade3 } from '@org/client-pages-invest/EnterTrade3';
import { Partner } from '@org/client-pages-partner';
import { TheFund } from '@org/client-pages-fund';
import {
  Personal, Security, User, // Transactions, 
  FileCenter,
} from '@org/client-pages-user';
import { About, Blog, Careers, News, Contact } from '@org/client-pages-company';
import { Terms, Privacy, Cookies, Brochure } from '@org/client-pages-legal';
import {
  // ManageLoans, LoanDetail,
  // ManageLoans,
  // LoanDetail,
  // CreateNote,
  // ManageNotes,
  // NoteDetail,
  // ManageSessions, ManageUsers,
  UserDetail,
  // SetUserPassword,
} from '@org/client-pages-admin';
// import {
//   // ExamplePage, Fonts, Headings,
//   // Test, Transitions,
//   InputsForm, // UpdateForm, // UpdateForm2,
// } from '@org/client-pages-dev';
// import { InputsForm, UpdateForm } from '@org/client-pages-dev/form';

// URGENT Routes: Lazy load as many of these pages as possible...
// !!! lazy imports MUST have a default export !!!
const PageNotFound = lazy(() => import('@org/client-components-custom/PageNotFound'));
const UserNotAuthorized = lazy(() => import('@org/client-components-custom/UserNotAuthorized'));

const SignIn = lazy(() => import('@org/client-pages-auth/SignIn'));
const SignOut = lazy(() => import('@org/client-pages-auth/SignOut'));
const SignUp = lazy(() => import('@org/client-pages-auth/SignUp'));
const ConfirmSignUp = lazy(() => import('@org/client-pages-auth/ConfirmSignUp'));
const ResetPassword = lazy(() => import('@org/client-pages-auth/ResetPassword'));
const ConfirmResetPassword = lazy(() => import('@org/client-pages-auth/ConfirmResetPassword'));
const Success = lazy(() => import('@org/client-pages-auth/Success'));
const Unsubscribe = lazy(() => import('@org/client-pages-auth/Unsubscribe'));
const Invitation = lazy(() => import('@org/client-pages-auth/invitation/Invitation'));
// import {
//   // SignIn,
//   Unsubscribe, Success,
//   // Invitation, // VerifyInvitation,
// } from '@org/client-pages-auth';

const AddEmail = lazy(() => import('@org/client-pages-user/AddEmail'));
const ConfirmAddEmail = lazy(() => import('@org/client-pages-user/ConfirmAddEmail'));
const ChangePassword = lazy(() => import('@org/client-pages-user/ChangePassword'));
const Transactions = lazy(() => import('@org/client-pages-user/Transactions'));

const Welcome = lazy(() => import('@org/client-pages-demo/Welcome'));
const Demo = lazy(() => import('@org/client-pages-demo/Demo'));
const SecurityTest = lazy(() => import('@org/client-pages-demo/SecurityTest'));

const CreateApplication = lazy(() => import('@org/client-pages-borrow/CreateApplication'));

const Admin = lazy(() => import('@org/client-pages-admin/Admin'));
const CreateCashflow = lazy(() => import('@org/client-pages-admin/CreateCashflow'));
const CreateInvitation = lazy(() => import('@org/client-pages-admin/CreateInvitation'));
const CreateLoan = lazy(() => import('@org/client-pages-admin/CreateLoan'));
const CreateNote = lazy(() => import('@org/client-pages-admin/CreateNote'));
const CreatePayment = lazy(() => import('@org/client-pages-admin/CreatePayment'));
const Manage = lazy(() => import('@org/client-pages-admin/Manage'));
// const ManageItems = lazy(() => import('@org/client-pages-admin/ManageItems'));
const CreateItem = lazy(() => import('@org/client-pages-admin/CreateItem'));
const Item = lazy(() => import('@org/client-pages-admin/Item'));

const Dev = lazy(() => import('@org/client-pages-dev/Dev'));
const Cache = lazy(() => import('@org/client-pages-dev/Cache'));
const Components = lazy(() => import('@org/client-pages-dev/Components'));
const Fonts = lazy(() => import('@org/client-pages-dev/Fonts'));
const StateLinkButtonTestPage = lazy(() => import('@org/client-components-core/buttons/StateLinkButtonTestPage'));
const Rds = lazy(() => import('@org/client-pages-dev/Rds'));
const DynamoDb = lazy(() => import('@org/client-pages-dev/DynamoDb'));
const SVG = lazy(() => import('@org/client-pages-dev/SVG'));
const Ulid = lazy(() => import('@org/client-pages-dev/Ulid'));
const Dates = lazy(() => import('@org/client-pages-dev/Dates'));
const FormHooks = lazy(() => import('@org/client-pages-dev/forms/FormHooks'));
const FormBuild = lazy(() => import('@org/client-pages-dev/forms/FormBuild'));
const JsonItemForm = lazy(() => import('@org/client-pages-dev/forms/JsonItemForm'));
const JsonItemsForm = lazy(() => import('@org/client-pages-dev/forms/JsonItemsForm'));
const Questionnaire = lazy(() => import('@org/client-pages-dev/forms/Questionnaire'));

/**
 * URGENT Add security logging to routes for:
 * 1) /index.html attempts which to not have a querystring
 * 2) attempts to access protected routes
 * 3) Invitation & SignIn access attempts
 */

// https://developer.mozilla.org/en-US/docs/Web/API/URL_API

function RouteWrapper(props) {
  // console.log('RouteWrapper', 'props', props);

  // we don't care about the domain here, just the querystring
  const addr = new URL('http://anydomain.com' + props.location.pathname + props.location.search);

  let messageType = addr.searchParams.get('type');
  let messageId = addr.searchParams.get('id');
  let confirmCode = addr.searchParams.get('cc');
  let email = base64url.decode(addr.searchParams.get('email'));

  if (messageType === 'invitation') { // link
    let data = { messageId, confirmCode, email };
    return <Redirect to={{ pathname: '/invitation', state: { data } }} />;
  // } else if (messageType === 'sign-up') {
  // } else if (messageType === 'add-email') {
  // } else if (props.location.pathname === '/index.html' && Object.keys(parsed).length === 0) // why do I need this
  } else if (messageType) {
    throw new Error(`Routes querystring error: Unknown messageType: '${messageType}'`);
  } else if (props.location.pathname === '/index.html') // why do I need this?
    return <Redirect to='/' />;
  else
    return <PageNotFound />;
}

export function Routes(props) {
  // console.log('Routes', props);
  let routeProps = props;

  // if (props.isLoading)
  //   return (<Page />);

  return(
    <Switch>
      {/* /index.html is only for passing a querystring when loading the model. */}
      <Route path='/index.html' component={RouteWrapper} {...props} />
      <Route path='/user-not-authorized' exact component={UserNotAuthorized} {...props} />
      <Route path='/success' exact component={Success} {...props} />
      {/* URGENT Shouldn't /sign-out be Authenticated??? */}
      {/* <Route path='/sign-out' exact component={SignOut} {...props} /> */}
      <Route path='/sign-out' exact render={(props) => (<SignOut {...props} {...routeProps} />)} />

      {/* Public Routes */}
      <Route path='/' exact component={Home} />
      <NotAuthenticatedRoute path='/sign-in' exact component={SignIn} {...props} />
      <NotAuthenticatedRoute path='/sign-up' exact component={SignUp} {...props} />
      <NotAuthenticatedRoute path='/confirm-reset-password' exact component={ConfirmResetPassword} {...props} />
      <NotAuthenticatedRoute path='/confirm-sign-up' exact component={ConfirmSignUp} {...props} />
      <NotAuthenticatedRoute path='/reset-password' exact component={ResetPassword} {...props} />
      <NotAuthenticatedRoute path='/unsubscribe' exact component={Unsubscribe} {...props} />

      <Route path='/invitation' exact render={(props) => (<Invitation {...props} {...routeProps} />)} />
      {/* <NotAuthenticatedRoute path='/invitation/verify' exact render={(props) => (<VerifyInvitation {...props} {...routeProps} />)} /> */}
      {/* <Route path='/invitation/verify' exact render={(props) => (<VerifyInvitation {...props} {...routeProps} />)} /> */}

      <Route path='/learn' exact component={Learn} {...props} />
      <Route path='/partner' exact component={Partner} {...props} />
      <AuthenticatedRoute path='/the-fund' exact component={TheFund} {...props} />

      <Route path='/company/about' exact component={About} {...props} />
      <Route path='/company/contact' exact component={Contact} {...props} />
      <Route path='/company/blog' exact component={Blog} {...props} />
      <Route path='/company/news' exact component={News} {...props} />
      <Route path='/company/careers' exact component={Careers} {...props} />

      <Route path='/legal/terms' exact component={Terms} {...props} />
      <Route path='/legal/privacy' exact component={Privacy} {...props} />
      <Route path='/legal/cookies' exact component={Cookies} {...props} />
      <Route path='/legal/brochure' exact component={Brochure} {...props} />

      <Route path='/invest' exact render={(props) => (<Invest {...props} {...routeProps} />)} />
      <AuthenticatedRoute path='/invest/detail' exact component={InvestmentDetail} {...props} />
      {/* <AuthenticatedRoute path='/invest/enter-trade' exact component={EnterTrade} {...props} /> */}
      {/* <AuthenticatedRoute path='/invest/enter-trade2' exact component={EnterTrade2} {...props} /> */}
      <AuthenticatedRoute path='/invest/enter-trade3' exact component={EnterTrade3} {...props} />

      <AuthenticatedRoute path='/user' exact component={User} {...props} />
      <AuthenticatedRoute path='/user/personal' exact component={Personal} {...props} />
      <AuthenticatedRoute path='/user/security' exact component={Security} {...props} />
      <AuthenticatedRoute path='/user/activity' exact component={Transactions} {...props} />
      <AuthenticatedRoute path='/user/file-center' exact component={FileCenter} {...props} />

      <AuthenticatedRoute path='/user/add-email' exact component={AddEmail} {...props} />
      <AuthenticatedRoute path='/user/change-password' exact component={ChangePassword} {...props} />
      <AuthenticatedRoute path='/user/confirm-add-email' exact component={ConfirmAddEmail} {...props} />

      <AuthenticatedRoute path='/demo' exact component={Demo} {...props} />
      <AuthenticatedRoute path='/demo/welcome' exact component={Welcome} {...props} />
      <AuthenticatedRoute path='/demo/test-security' exact component={SecurityTest} {...props} />
      <AuthenticatedRoute path='/demo/forms/inputs' exact component={FormHooks} {...props} />
      <AuthenticatedRoute path='/demo/forms/json-item' exact component={JsonItemForm} {...props} />
      <AuthenticatedRoute path='/demo/forms/questionnaire' exact component={Questionnaire} {...props} />

      <AuthenticatedRoute path='/borrow/application' exact component={CreateApplication} {...props} />
      {/* <Route path='/borrow/application' exact render={(props) => (<CreateApplication {...props} {...routeProps} />)} /> */}

      <AuthorizedRoute path='/admin' exact component={Admin} {...props} allowed={['Admin', 'Dev']} />
      <AuthorizedRoute path='/admin/create-cashflow' exact component={CreateCashflow} {...props} allowed={['Admin', 'Dev']} />
      <AuthorizedRoute path='/admin/create-invitation' exact component={CreateInvitation} {...props} allowed={['Admin', 'Dev']} />
      <AuthorizedRoute path='/admin/create-loan' exact component={CreateLoan} {...props} allowed={['Admin', 'Dev']} />
      <AuthorizedRoute path='/admin/create-note' exact component={CreateNote} {...props} allowed={['Admin', 'Dev']} />
      <AuthorizedRoute path='/admin/create-payment' exact component={CreatePayment} {...props} allowed={['Admin', 'Dev']} />
      <AuthorizedRoute path='/admin/manage' exact component={Manage} {...props} allowed={['Admin', 'Dev']} />
      <AuthorizedRoute path='/admin/create-item' exact component={CreateItem} {...props} allowed={['Admin', 'Dev']} />
      <AuthorizedRoute path='/admin/item' exact component={Item} {...props} allowed={['Admin', 'Dev']} />
      {/* <AuthorizedRoute path='/admin/manage-loans' exact component={ManageLoans} {...props} allowed={['Admin', 'Dev']} /> */}
      {/* <AuthorizedRoute path='/admin/manage-notes' exact component={ManageNotes} {...props} allowed={['Admin', 'Dev']} /> */}
      {/* <AuthorizedRoute path='/admin/loan-detail' exact component={LoanDetail} {...props} allowed={['Admin', 'Dev']} /> */}
      {/* <AuthorizedRoute path='/admin/note-detail' exact component={NoteDetail} {...props} allowed={['Admin', 'Dev']} /> */}
      {/* <AuthorizedRoute path='/admin/manage-users' exact component={ManageUsers} {...props}  allowed={['Admin', 'Dev']} /> */}
      <AuthorizedRoute path='/admin/user-detail' exact component={UserDetail} {...props} allowed={['Admin', 'Dev']} />
      {/* <AuthorizedRoute path='/admin/manage-sessions' exact component={ManageSessions} {...props} allowed={['Admin', 'Dev']} /> */}
      {/* <AuthorizedRoute path='/admin/set-user-password' exact component={SetUserPassword} {...props} allowed={['Admin', 'Dev']} /> */}

      <AuthorizedRoute path='/dev' exact component={Dev} {...props} allowed={['Dev']} />
      <AuthorizedRoute path='/dev/components' exact component={Components} {...props} allowed={['Dev']} />
      <AuthorizedRoute path='/dev/components/state-link-button-test-page' exact component={StateLinkButtonTestPage} {...props} allowed={['Dev']} />
      
      <AuthorizedRoute path='/dev/cache' exact component={Cache} {...props} allowed={['Dev']} />
      <AuthorizedRoute path='/dev/fonts' exact component={Fonts} {...props} allowed={['Dev']} />
      {/* <AuthorizedRoute path='/dev/form/input' exact component={InputsForm} {...props} allowed={['Dev']} /> */}
      {/* <AuthorizedRoute path='/dev/form/update' exact component={UpdateForm} {...props} allowed={['Dev']} /> */}
      {/* <AuthorizedRoute path='/dev/form/update2' exact component={UpdateForm2} {...props} allowed={['Dev']} /> */}
      <AuthorizedRoute path='/dev/forms/hooks' exact component={FormHooks} {...props} allowed={['Dev']} />
      <AuthorizedRoute path='/dev/forms/build' exact component={FormBuild} {...props} allowed={['Dev']} />
      <AuthorizedRoute path='/dev/forms/json-item' exact component={JsonItemForm} {...props} allowed={['Dev']} />
      <AuthorizedRoute path='/dev/forms/json-items' exact component={JsonItemsForm} {...props} allowed={['Dev']} />
      <AuthorizedRoute path='/dev/forms/questionnaire' exact component={Questionnaire} {...props} allowed={['Dev']} />
      <AuthorizedRoute path='/dev/rds' exact component={Rds} {...props} allowed={['Dev']} />
      <AuthorizedRoute path='/dev/dynamodb' exact component={DynamoDb} {...props} allowed={['Dev']} />
      <AuthorizedRoute path='/dev/svg' exact component={SVG} {...props} allowed={['Dev']} />
      <AuthorizedRoute path='/dev/ulid' exact component={Ulid} {...props} allowed={['Dev']} />
      <AuthorizedRoute path='/dev/dates' exact component={Dates} {...props} allowed={['Dev']} />
      {/* <AuthorizedRoute path='/dev/transitions' exact component={Transitions} {...props} allowed={['Dev']} /> */}
      {/* <AuthorizedRoute path='/dev/test' exact component={Test} {...props} allowed={['Dev']} /> */}
      {/* <AuthorizedRoute path='/dev/example-page' exact component={ExamplePage} {...props} allowed={['Dev']} /> */}
      {/* <AuthorizedRoute path='/dev/headings' exact component={Headings} {...props} allowed={['Dev']} /> */}

      <Route component={PageNotFound} {...props}/>
    </Switch>
  );
}

export default Routes;

Routes.propTypes = {
  isLoading: PropTypes.bool,
};
