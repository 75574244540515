import React from 'react';
import PropTypes from 'prop-types';
import { Stack, Item } from '@org/client-components-core/layout/Stack';
import { StandardTextField } from '@org/client-components-core/inputs/StandardTextField';

export function TradeItem(props) {
  // console.log('AmountRow', fieldProps);

  let { fieldProps } = props;
  return(
    <>
    <Stack>

      <Item style={{ width: '100% '}} >
        <StandardTextField
          {...fieldProps}
          name='itemName'
          label='Item Name'
          disabled={true}
        />
      </Item>

    </Stack>

    <Stack spacing={2} >

      <Item style={{ width: '30%' }} >
        <StandardTextField
          {...fieldProps}
          name='itemType'
          label='Item Type'
        />
      </Item>

      <Item style={{ width: '70%' }} >
        <StandardTextField
          {...fieldProps}
          name='itemId'
          label='Item Id'
        />
      </Item>

    </Stack>
    </>
  );
}

TradeItem.propTypes = {
  fieldProps: PropTypes.shape(),
};
