import React from 'react';
import { useQuery } from '@apollo/client';
import { authenticatedSchema, handleResponse } from '@org/client-graphql';
import theme from '@org/client-libs-themes';

export function GetSession() {
  const { loading, data, error } = useQuery(authenticatedSchema.GET_AUTH);
  console.log('GetSession', loading, error, data);

  if (loading)
    return <div>{'Loading...'}</div>;
  if (error)
    return <div>{error.message}</div>;
  if (data) {
    let response = handleResponse({ data });
    if (response.success) {
      return (
        <>
        <div style={{ alignItems: 'flex-start', margin: theme.spacing(0, 0, 0.5, 0), padding: theme.spacing(0.5) }} >
          {/* <div>{'Session'}</div> */}
          {/* <div>User ID: {response.data.id}</div> */}
          <div>{`Session Started: ${response.data.createdAt}`}</div>
          <div>{`# of requests: ${response.data.fetchCount}`}</div>
          <div>{`IP Address: ${response.data.ipAddress}`}</div>
        </div>
        </>
      );
    } else if (response.error)
      return <div>{response.error.message}</div>;
  }
  return null;
}

export default GetSession;
