import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
// import clsx from 'clsx';
import { ApolloProvider } from '@apollo/client';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
// import { makeStyles } from '@material-ui/core/styles';
// import Typography from '@material-ui/core/Typography';
// import theme from '@org/client-libs-themes';
// import { InLink } from '@org/client-components-core';
import { FadeOutSection } from '@org/client-components-core/transitions/FadeOutSection';
import { styles } from '@org/client-libs-styles';
import theme from '@org/client-libs-themes';
import { publicClient } from '@org/client-graphql';

let localStyles = {
  ...styles,
  homeTitle: {
    fontFamily: `"Roboto Slab", "Times New Roman", serif`,
    padding: theme.spacing(2),
  },
};

// const useStyles = makeStyles(localStyles);

// function FadeOutSection(props) {
//   const [isVisible, setVisible] = React.useState(true);
//   const domRef = React.useRef();
//   React.useEffect(() => {
//     const observer = new IntersectionObserver(entries => {
//       entries.forEach(entry => setVisible(entry.isIntersecting));
//     });
//     observer.observe(domRef.current);
//   }, []);
//   return (
//     <div
//       className={`${isVisible ? 'fadeOutSectionVisible' : 'fadeOutSectionHidden'}`}
//       ref={domRef}
//     >
//       {props.children}
//     </div>
//   );
// }

// FadeOutSection.propTypes = {
//   children: PropTypes.any,
// };

export class HomeComponent extends PureComponent {
  render() {
    const { classes } = this.props;

    return(
      <ApolloProvider client={publicClient} >
        <div className={classes.pageRoot} >
          {/* <Typography variant={localStyles.pageTitleVariant} component='h1' className={classes.pageTitle} >
            {'Home'}
          </Typography> */}
          <div style={{ display: 'grid', gridTemplateColumns: 'repeat(8, 1fr)', gridGap: '1rem', padding: '0 0 0 0', width: '100%' }}>
            <div style={{ gridColumn: '3 / 8', paddingTop: theme.spacing(3) }} >
              <FadeOutSection key={1} >
                <Typography component='h1' variant={localStyles.pageTitleVariant} className={classes.homeTitle} style={{textAlign: 'left'}} >
                  {'Access'}
                </Typography>
              </FadeOutSection>
              <Typography component='h1' variant={localStyles.pageTitleVariant} className={classes.pageTitle} style={{textAlign: 'left'}} >
                {'Earn'}
              </Typography>
              <Typography component='h1' variant={localStyles.pageTitleVariant} className={classes.pageTitle} style={{textAlign: 'left'}} >
                {'Diversify'}
              </Typography>
            </div>
            <div style={{height: theme.spacing(100)}} >
            </div>
            {/* <div style={{gridColumn: '6 / 8', margin: '2rem 0 2rem 0', padding: '2rem'}} >
              <span style={{padding: '0.5rem', border: '1px solid white', boxShadow: '1px 1px 1px darkblue'}} >
                <InLink title='Learn More' to='/learn' style={{fontSize: '2rem', color: 'white', textShadow: '1px 1px 1px darkblue'}} />
              </span>
            </div> */}
          </div>
        </div>
      </ApolloProvider>
    );
  }
}

export default withStyles(localStyles)(HomeComponent);

HomeComponent.propTypes = {
  classes: PropTypes.any,
};
