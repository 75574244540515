import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core/styles';

export function Spacer(props) {
  let theme = useTheme();
  return(<div style={{ height: theme.spacing(props.spacing), width: '100%', margin: theme.spacing(0) }} />);
}

Spacer.propTypes = {
  spacing: PropTypes.number.isRequired,
};
