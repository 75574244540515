import React from 'react';
// import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
// import ImportantDevicesIcon from '@material-ui/icons/ImportantDevices';
import LocalActivityOutlinedIcon from '@material-ui/icons/LocalActivityOutlined';
import { IconButton } from '@org/client-components-core/buttons/IconButton';
import { publicSchema } from '@org/client-graphql';

export function DemoIconButton(props) {
  // const { loading, data, error } = useQuery(publicSchema.IS_ADMINISTRATOR);
  const { data } = useQuery(publicSchema.IS_AUTHENTICATED);
  const theme = useTheme();
  // const isSm = useMediaQuery(theme.breakpoints.down("sm")); // not only!!!
  const isXs = useMediaQuery(theme.breakpoints.only("xs"));
  // console.log('AdminIconButton', loading, data, error);

  // IconButton
  let size = 'medium';
  if (isXs)
    size = 'small';

  // Icon
  // let fontSize = 'medium';
  let fontSize = 'large';

  if (!!data?.isAuthenticated && ['dev', 'demo'].includes(process.env.REACT_APP_STAGE))
    // return <IconButton onClick={() => alert('click')} icon={<SupervisorAccountSharpIcon fontSize='large' />} />;
    // <IconButton onClick={() => alert('click')} className={ classes.greyIconButton } icon={<CodeRoundedIcon fontSize='large' />} />
    return (
      // <Fade in={isAdmin} style={{ transitionDelay: isAdmin ? '3000ms' : '0ms' }} >
      // <Fade in={isAdmin} style={{ transitionDelay: isAdmin ? '3000ms' : '0ms' }} >
      <IconButton
        // ref={props.forwardRef}
        name='demo-icon-button'
        aria-label='demo icon button'
        size={size}
        onClick={() => props.history.push('/demo')}
        icon={<LocalActivityOutlinedIcon fontSize={fontSize} style={{ margin: theme.spacing(0) }} />}
      />
      // </Fade>
    );
  else
    return null;
}

// export default AdminIconButton;
// export default React.forwardRef((props, ref) => <AdminIconButton {...props} forwardedRef={ref} />);

// DevIconButton.propTypes = {
//   open: PropTypes.bool,
//   handleOpenDrawer: PropTypes.func,
// };
