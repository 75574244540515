import React from 'react';
import PropTypes from 'prop-types';
import { Stack, Item } from '@org/client-components-core/layout/Stack';
import { StandardAmountField } from '@org/client-components-core/inputs/StandardAmountField';
// import { StandardTextField } from '@org/client-components-core/inputs/StandardTextField';

export function AmountRow(props) {
  // console.log('AmountRow', fieldProps);
  let { fieldProps } = props;
  return(
    <Stack spacing={2} >

      <Item style={{ width: '50%' }} >
        <StandardAmountField
          {...fieldProps}
          name='availableBalance'
          label='Available Balance'
          disabled={true}
        />
      </Item>

      <Item style={{ width: '50%' }} >
        <StandardAmountField
          {...fieldProps}
          name='minimumAmount'
          label='Minimum Investment'
          disabled={true}
        />
      </Item>

    </Stack>
  );
}

AmountRow.propTypes = {
  fieldProps: PropTypes.shape(),
};
