import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { SmallLinkButton } from '@org/client-components-core/buttons/LinkButtons';
import { BackdropLoader } from '@org/client-components-core/feedback/Loaders';
import { ButtonBar } from '@org/client-components-core/buttons/ButtonBar';
import { PrimaryButton } from '@org/client-components-core/buttons/Buttons';
import {
  Page, FlexRow,
  Heading, SubHeading, Section,
  LabelledFieldSpan,
  FlexColumn,
} from '@org/client-components-custom';
import { DisplayState } from '@org/client-components-utils/DisplayState';
import { percentage, dollarAmount } from '@org/common-formatters';
import { CashflowTable } from './CashflowTable';
import theme from '@org/client-libs-themes';
import { gql, useQuery } from '@apollo/client';
import { authenticatedSchema } from '@org/client-graphql';
import { LIST_CASHFLOWS, LIST_NOTES } from '@org/client-graphql/authenticated/schema';

// export async function loadInvestmentDetailData(props) {
//   // console.log('loadInvestmentDetailData', props);
//   // URGENT Create loader functions like this to allow the page being navigated away from
//   // to pass all the data we need.
//   let { authenticatedHandler, note } = props;

//   let loan = note.referenceItem;

//   let [ noteCashflows, loanCashflows ] = await Promise.all([
//     authenticatedHandler.listCashflows({ itemType: note.itemType, id: note.id }),
//     authenticatedHandler.listCashflows({ itemType: referenceItem.itemType, id: referenceItem.id }),
//   ]);

//   // console.log('loadInvetmentDetailData', noteCashflows, loanCashflows);

//   return { noteCashflows, loanCashflows };
// }

let note;
let referenceItem;
let noteCashflows = [];
let loanCashflows = [];

// async function getDetails() {
//   note = authenticatedHandler.readNoteFragment(noteId);
//   referenceItem = authenticatedHandler.readLoanFragment(note.referenceId);
// }

export function InvestmentDetail(props) {
  console.log('InvestmentDetail', props);
  let [ state, setState ] = useState({ isLoading: true });
  const isMounted = useRef(false);
  const isXs = useMediaQuery(theme.breakpoints.down("xs"));
  let { authenticatedHandler } = props;
  // const { loading, error, data, client } = useQuery(LIST_NOTES);
  // const { loading, error, data, client } = useQuery(LIST_CASHFLOWS, variables: { input: { id } });
  // console.log('InvestmentDetail', loading, error, data, client);
  // client.query({ query: LIST_CASHFLOWS, variables:  { input: { id: data.id } } });

  // async function getDetails(noteId) {
  //   note = await authenticatedHandler.readNoteFragment(noteId);
  //   referenceItem = await authenticatedHandler.readLoanFragment(note.referenceId);
  // }

  if (!note) {
    if (props.location?.state?.note) {
      note = props.location.state.note;
      referenceItem = props.location.state.referenceItem;
    } else {
      // let noteId = localStorage.getItem('noteId'); // you shouldn't be here if this is not set
      // note = authenticatedHandler.readNoteFragment(noteId);
      // referenceItem = authenticatedHandler.readLoanFragment(note.referenceId);
      // getDetails(noteId);
      // props.history.goBack();
      props.history.push('/invest');
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    isMounted.current = true;

    (async () => {
      try {
        // Preloaded in DetailsButton but we still need it here...
        let response = await Promise.all([
          authenticatedHandler.listCashflows({ itemType: note.itemType, id: note.id }),
          authenticatedHandler.listCashflows({ itemType: referenceItem.itemType, id: referenceItem.id }),
        ]);
        [ noteCashflows, loanCashflows ] = response;
        // console.log(noteCashflows, loanCashflows);

        let params = {};

        // readQuery example // this works!
        // const LIST_NOTES = gql`
        //   query listNotes($input: ListItemsInput) {
        //     listNotes(input: $input) {
        //       itemType
        //       id
        //       itemName
        //     }
        //   }
        // `;
        // const notes = await authenticatedHandler.readQuery({ query: LIST_NOTES });

        // listNotesCache example // this works!
        // let listNotesCache = await authenticatedHandler.listNotesCache();
        // console.log('listNotesCache', listNotesCache);

        // readFragment example // this works!
        // let params = {
        //   id: 'Note:01F0VC0TG8YAQZH532PCQDNOTE', // The value of the item's cache ID
        //   fragment: gql`
        //     fragment MyNote on Note {
        //       id
        //       itemType
        //       itemName
        //     }
        //   `,
        //   };
        // let readFragment = await authenticatedHandler.readFragment(params);

        // readNoteFragment example // this works!
        // let readNoteFragment = await authenticatedHandler.noteFragment('01F0VC0TG8YAQZH532PCQDNOTE');

        // let updatedNote = { ...listNotesCache[0], availableBalance: 0 };

        // writeQuery example // this works!
        // authenticatedHandler.writeQuery({
        //   query: authenticatedSchema.LIST_NOTES,
        //   data: {
        //     listNotes: [ updatedNote ],
        //   },
        // });
        // this works!
        // let params = {query: authenticatedSchema.LIST_NOTES,
        //   data: {
        //     listNotes: [ updatedNote ],
        //   },
        // };
        // authenticatedHandler.writeQuery(params);

        // updateNotesCache example // works!!!
        // let updateNotesInput = [ updatedNote ];
        // await authenticatedHandler.updateNotesCache(updateNotesInput);
        // let listNotesCache = await authenticatedHandler.listNotesCache(); // check
        // console.log('listNotesCache', listNotesCache);

        // writeQuery example // this works!
        // const WRITE_FRAGMENT = gql`
        //   fragment MyNote on Note {
        //     # id
        //     availableBalance
        //   }
        // `;
        // params = {
        //   id: 'Note:01F0VC0TG8YAQZH532PCQDNOTE',
        //   fragment: WRITE_FRAGMENT,
        //   data: {
        //     availableBalance: 1,
        //   },
        // };
        // const writeFragment = await authenticatedHandler.writeFragment(params);
        // console.log('writeFragment', writeFragment);

        // writeNoteFragment example // this works!
        // let writeNoteFragment = await authenticatedHandler.writeNoteFragment(params);
        // console.log('writeNoteFragment', writeNoteFragment);

        // console.log('readNote', note);
        if (isMounted.current)
          setState({ ...state, isLoading: false });
      } catch (error) {
        console.error('InvestmentDetail', error);
        if (isMounted.current)
          setState({ ...state, isLoading: false, formError: error });
      }

      // console.log('useEffect', isMounted.current, state.isLoading);
      // console.log('useEffect', isMounted.current, state.isLoading);
    })([]);

    return () => { isMounted.current = false; };
  }, []);

  async function handleSubmit() {
    // console.log('InvestmentDetail', 'handleSubmit', isMounted.current);
    if (isMounted.current)
        setState({ ...state, isLoading: true });

    try {
      // let transactions = await authenticatedHandler.listTransactions();
      // console.log(transactions);
      let accountBalance = await authenticatedHandler.getBalance();
      console.log(accountBalance);
      // authenticatedHandler.getBalance();
      // let accountBalance = transactions.length ? transactions[transactions.length-1]?.balance : 0;
      // console.log('InvestmentDetail', accountBalance, note);

      props.history.push({
        pathname: '/invest/enter-trade3',
        state: {
          // accountBalance,
          values: {
            accountBalance,
            // ...note,
            itemId: note.id,
            itemType: note.itemType,
            itemName: note.itemName,
            tradeType: "BUY",
            eventDate: dayjs().format('YYYY-MM-DD'),
            originalBalance: note.originalBalance,
            availableBalance: note.availableBalance,
            // currentFactor: 1.0,
            factor: 1.0,
            currentBalance: note.originalBalance * note.factor,
            minimumAmount: note.minimumAmount,
            // amount: 0.0,
            // amount: note.minimumAmount,
            // amount: 0.0,
            amount: note.minimumAmount,
            price: note.price,
            // investedAmount: 0.0,
          },
          note,
        },
      });
    } catch (error) {
      console.error('InvestmentDetail', 'handleSubmit', error);
      if (isMounted.current)
          setState({ ...state, isLoading: false, formError: error });
    }
  }

  let coupon =  percentage({ value: (note?.coupon ? note.coupon : 0) / 100, places: 2 });
  let managementRate =  percentage({ value: (note?.managementRate ? note.managementRate : 0) / 100, places: 2 });
  let originalBalance = dollarAmount.format(note?.originalBalance ? note.originalBalance : 0);
  let availableBalance = dollarAmount.format(note?.availableBalance ? note.availableBalance : 0);
  let minimumAmount = dollarAmount.format(note?.minimumAmount ? note.minimumAmount : 0);

  let assetType = "Loading";
  if (referenceItem?.assetType === 'REAL_ESTATE') {
    if (referenceItem.assetSubType === 'RESIDENTIAL')
      assetType = 'Residential Real Estate';
    else if (referenceItem.assetSubType === 'COMMERCIAL')
      assetType = 'Commercial Real Estate';
    else if (referenceItem.assetSubType === 'MULTIFAMILY')
      assetType = 'Multifamily Real Estate';
  }
  if (referenceItem?.itemType === 'LOAN')
    assetType = assetType + ' Loan';

  let enabled = note?.availableBalance > 0 && !state.isLoading;

  // console.log('pre-render', isMounted.current, state.isLoading, noteCashflows, loanCashflows);

  // console.log(state.isLoading, note, referenceItem);

  if (state.isLoading)
    return(
      <>
      <BackdropLoader />
      <Page />
      </>
    );

  return(
    <>
    {/* {state.isLoading &&
    <BackdropLoader />} */}

    <Page id='investment-detail' >
      <>

      <FlexRow>
        {/* <InLink to='/invest' title='Back' /> */}
        {/* <SmallLinkButton {...props} title='Back' to='/invest' /> */}
        <SmallLinkButton title='Back' to='/invest' />
      </FlexRow>
      
      <Heading>{'Note Details'}</Heading>

      {!note?.availableBalance &&
      <FlexRow>
        <SubHeading>{'No Longer Available'}</SubHeading>
      </FlexRow>}

      <Section>
        <LabelledFieldSpan title='Name' value={note.itemName} />
        <LabelledFieldSpan title='Description' value={note.description} />
        <FlexRow>
          <FlexColumn>
            <LabelledFieldSpan title='Original Balance' value={originalBalance} />
            <LabelledFieldSpan title='Amount Available' value={availableBalance} />
            <LabelledFieldSpan title='Launch Date' value={dayjs(note.launchDate).format('M/D/YYYY')} />
            {/* <LabelledFieldSpan title='Next Payment Date' value={dayjs(note.nextPaymentDate).format('M/D/YYYY')} /> */}
          </FlexColumn>
          <FlexColumn>
            <LabelledFieldSpan title='Coupon' value={coupon} />
            <LabelledFieldSpan title='Management Fee' value={managementRate} />
            <LabelledFieldSpan title='Minimum Investment' value={minimumAmount} />
          </FlexColumn>
        </FlexRow>
      </Section>

      {!isXs &&
      <Section>
        <SubHeading>{'Note Cashflows'}</SubHeading>
        <CashflowTable rows={noteCashflows ? noteCashflows : []} />
      </Section>}

      <Section>
        <SubHeading>{'Reference Asset Details'}</SubHeading>
        <FlexRow>
          <FlexColumn>
            <LabelledFieldSpan title='Asset Type' value={assetType} />
            <LabelledFieldSpan title='Original Balance' value={dollarAmount.format(referenceItem.originalBalance)} />
            <LabelledFieldSpan title='Current Balance' value={dollarAmount.format(referenceItem.currentBalance)} />
            <LabelledFieldSpan title='Origination Date' value={dayjs(referenceItem.originationDate).format('M/D/YYYY')} />
            <LabelledFieldSpan title='First Payment Date' value={dayjs(referenceItem.firstPaymentDate).format('M/D/YYYY')} />
            <LabelledFieldSpan title='Next Payment Date' value={dayjs(referenceItem.nextPaymentDate).format('M/D/YYYY')} />
          </FlexColumn>
          <FlexColumn>
            <LabelledFieldSpan title='LTV Ratio' value={percentage({ value: referenceItem.appraisalAmount/referenceItem.originalBalance, places: 2 })} />
            <LabelledFieldSpan title='Interest Rate' value={percentage({ value: referenceItem.interestRate/100, places: 2 })} />
            <LabelledFieldSpan title='Servicing Fee' value={percentage({ value: referenceItem.servicingRate/100, places: 2 })} />
            <LabelledFieldSpan title='Net Rate' value={percentage({ value: referenceItem.netRate/100, places: 2 })} />
            <LabelledFieldSpan title='Original Term' value={referenceItem.originalTerm + ' months'} />
            <LabelledFieldSpan title='Current Term' value={referenceItem.currentTerm + ' months'} />
          </FlexColumn>
        </FlexRow>
      </Section>

      {!isXs &&
      <Section>
        <SubHeading>{'Reference Asset Cashflows'}</SubHeading>
        <CashflowTable rows={loanCashflows ? loanCashflows : []} />
      </Section>}

      {/* <Section>
        <SubHeading>{'Projected Cashflows'}</SubHeading>
        <div>{'Coming soon...'}</div>
      </Section> */}

        {/* <FlexRow>
          <FlexColumn style={{flexGrow: 1}} >a</FlexColumn>
          <FlexColumn style={{flexGrow: 1}} >b</FlexColumn>
          <FlexColumn style={{flexGrow: 1}} >c</FlexColumn>
        </FlexRow> */}

        {/* {values && values.loan &&
        <>
        <FieldSpan title='Ref Asset' value={'LOAN'} />
        <FieldSpan title='Asset Type' value={values.loan.assetType} />
        <FieldSpan title='Asset Sub Type' value={values.loan.assetSubType} />
        <FieldSpan title='Interest Rate' value={percentage({ value: values.loan.interestRate / 100, places: 2 })} />
        <FieldSpan title='Ori Term' value={values.loan.originalTerm} />
        <FieldSpan title='First Payment Date' value={values.loan.firstPaymentOn} />
        </>} */}
      <ButtonBar>
        <PrimaryButton title='Buy Now' onClick={handleSubmit} disabled={!enabled} />
      </ButtonBar>
      </>
    </Page>

    <DisplayState title='state' state={{ state, note, noteCashflows, loanCashflows, location: props.location, history: props.history }} open={true} />
    </>
  );
}

InvestmentDetail.propTypes = {
  location: PropTypes.shape(),
  history: PropTypes.shape(),
  isValidated: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  // stateProps: PropTypes.shape(),
  fieldProps: PropTypes.shape(),
  buttonProps: PropTypes.shape(),
  authenticatedHandler: PropTypes.shape(),
};
