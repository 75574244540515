import React, { useEffect } from 'react';
import { ExLink } from '@org/client-components-core/links/ExLink';
import { Page, PageHeadingWrapper } from '@org/client-components-custom/page/Page';
import { Heading } from '@org/client-components-custom/page/Heading';
import { LearnTabs } from '@org/client-components-custom/app-bar/LearnTabs';
import { Section, SectionHeading, SectionBody } from '@org/client-components-custom/page/Section';

export function Learn() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return(
    <>
    <LearnTabs>

      <Page id='learn-page' >

        <Section>
          <PageHeadingWrapper id='what' >
            <Heading>{'A Financial Services Platform Offering\nNontraditional Credit Investments'}</Heading>
          </PageHeadingWrapper>

          <Section>
            <SectionHeading title='Our Mission' />
            <SectionBody>
              {`${process.env.REACT_APP_LEGAL_NAME} was created to provide access for eligible individuals to credit investment return profiles which have historically been available only to large, institutional investors.`}
            </SectionBody>
          </Section>

          <Section>
            <SectionHeading title='Our Solution' />
            <SectionBody>
              {'We create fractionalized credit investments in the form of Payment Dependent Notes through our web app and investment platform.'}
            </SectionBody>

            <SectionBody>
              {'Payment Dependent Notes reference direct investments owned by Cubit, and payments made on the reference assets are passed through to the Notes investors (less fees and expenses).'}
            </SectionBody>

            <SectionBody>
              {'Reference investments are typically loans purchased from, or originated in partnership with, experienced lenders across a variety of asset classes. Loans typically have maturities between 6 and 36 months, pay a monthly coupon, and are secured by real estate or other collateral and/or payment assurances.'}
            </SectionBody>
          </Section>

        </Section>

        <Section>
          <PageHeadingWrapper id='who' >
            {/* <PageHeadingLeft />
            <PageHeadingCenter>{'Decades of Experience in Alternative Credit\nCombined with Cutting Edge Technology'}</PageHeadingCenter>
            <PageHeadingRight /> */}
            {/* <Heading>{'Decades of Experience in Alternative Credit\nCombined with Cutting Edge Technology'}</Heading> */}
            <Heading>{'Experienced Alternative Credit Specialists\nUtilizing Cutting Edge Technology'}</Heading>
          </PageHeadingWrapper>

          <Section>
            <SectionHeading title='The Platform' />
            <SectionBody>
              {'In order to achieve our mission, we have developed a Progressive Web App with a Severless API (more on both below) to facilitate and/or automate virtuatlly every part of the investment management, distribution, payment processes.'}
            </SectionBody>
          </Section>

          <Section>
            <SectionHeading title='Investment Experience' />
            <SectionBody>
              {'Through our extensive alternative lending relationship network we have access to a variety of different asset types and credit profiles. In addition, due to the breadth of our asset class experience and depth of our credit markets experience, we can introduce new asset types and limit acquisitions of existing asset types based on economic, market, and product-specific factors.'}
            </SectionBody>
          </Section>

          <Section>
            <SectionHeading title='Due Diligence' />
            <SectionBody>
              {'Once identified, potential investment assets and/or new originators undergo extensive research and analysis, commonly referred to as due diligence, before an investment is made.'}
            </SectionBody>
          </Section>

          <Section>
            <SectionHeading title='Asset Acquisition' />
            <SectionBody>
              {'If the investment passes our due diligence process, we then work with the seller and/or lender partner to create the best asset acquisition process. This could be individual or bulk loan purchases, a forward flow origination program, or other custom-tailored solutions.'}
            </SectionBody>
          </Section>

          <Section>
            <SectionHeading title='Asset Management' />
            <SectionBody>
              {'Cubit Investments monitors realtime investment performance, provides detailed performance reporting, and processes automated electronic payments. In some cases we may also act as the servicer or master servicer. In the event of an asset performance issue, we would work with the lender and/or borrower in order to cure the loan and, if necessary, rigorously pursue legal action on behalf of our note investors.'}
            </SectionBody>
          </Section>

          <Section>
            <SectionHeading title='Loss Mitigation' />
            <SectionBody>
              {'Unlike the majority of Payment Dependent Notes issued on small ticket, unsecured consumer loans, we focus on larger loan sizes typically secured by real assets. Our target assets have numerous advantages for Payment Dependent Note investors, such as deterring potential strategic default, giving better negotiating leverage in a workout situation, and making collections on non-performing assets economically viable due to our ability to recover and sell the underlying asset.'}
            </SectionBody>
          </Section>

        </Section>

        <Section>
          <PageHeadingWrapper id='how' >
            {/* <PageHeadingLeft />
            <PageHeadingCenter>{'Understanding Payment Dependent Notes \n and How We Use Them'}</PageHeadingCenter>
            <PageHeadingRight /> */}
            <Heading>{'Understanding Payment Dependent Notes\nand How We Use Them'}</Heading>
          </PageHeadingWrapper>

          <Section>
            <SectionHeading title='The Notes' />
            <SectionBody>
              {'Payment Dependent Notes are a novel credit instrument which pay investors based on the performance of a reference asset, yet are solely the obligation of the issuer, not the asset. While relatively new, well over $50 billion of these notes have been issued in the past 10 years. Payment Dependent Notes are categorized by the SEC as securities. '}{<ExLink variant='body1' title='Learn more about Payment Dependent Notes.' to='https://www.investopedia.com/terms/m/member-payment-dependent-note.asp' />}
            </SectionBody>
          </Section>

          <Section>
            <SectionHeading title='Note Advantages' />
            <SectionBody>
              {'Credit investments and whole loan transactions are complex and expensive due the physical nature and legal costs of the transactions. In addition, institutional investors are reluctant to share direct ownership of loans due to potential legal complications. As a result, individual investors are virtually excluded from participation in the asset class.'}
            </SectionBody>
            <SectionBody>
              {'In contrast, Payment Dependent Notes are straightforward, passthrough participations and can be issued quickly and easily. And, because they do not share direct ownership of the loan, the Cubit Investments platform can manage and defend all investor interests equally.'}
            </SectionBody>
            <SectionBody>
              {'Our Payment Dependent Notes are registered securities and investors receive a 1099 rather than a K-1.'}
            </SectionBody>
          </Section>

          <Section>
            <SectionHeading title='Note Considerations' />
            <SectionBody>
              {'While our Notes are not secured, they are issued from special purpose entities which are bankruptcy remote and issued pursuant to a trust agreement. Each physical Note is pledged and delivered to the indenture trustee for the benefit of the respective Note owners in order to perfect the investors’ security interest in the Note. In the event of a corporate event of default, the trustee will ensure payments continue to be made to the Notes as agreed upon. Finally, as an additional layer of protection and to complete the perfection of the security interest, a '}{<ExLink variant='body1' title='UCC financing statement' to='https://en.wikipedia.org/wiki/Uniform_Commercial_Code' />}{' is filed whenever possible.'}
            </SectionBody>
          </Section>

        </Section>

        <Section>
          <PageHeadingWrapper id='tech' >
            {/* <PageHeadingLeft />
            <PageHeadingCenter>{'Cutting Edge Technology'}</PageHeadingCenter>
            <PageHeadingRight /> */}
            <Heading>{'Cutting Edge Technology'}</Heading>
          </PageHeadingWrapper>

          <Section>
            <SectionHeading title='Progressive Web Apps' />
            <SectionBody>
              {'Platform independent, browser-rendered applications ('}{<ExLink variant='body1' title='SaaS' to='https://en.wikipedia.org/wiki/Software_as_a_service' />}{') offer the best of all worlds. Users have all the functionality and performance of a native application, the security provided by a modern web browser, real-time software updates, and all the infinite possibilities of an internet-connected, cloud-based platform. Our App is mobile-first by design and works in all modern browsers and operating systems. '}{<ExLink variant='body1' title='Learn more about Progressive Web Apps.' to='https://en.wikipedia.org/wiki/Progressive_web_application' />}
            </SectionBody>
          </Section>

          <Section>
            <SectionHeading title='Serverless Computing' />
            <SectionBody>
              {'In the next evolution of cloud-based managed serivices, serverless web apps interact with provisioned functions accessed through an API gateway ('}{<ExLink variant='body1' title='FaaS' to='https://en.wikipedia.org/wiki/Function_as_a_service' />}{'). Unlike traditional websites which deliver pages via a web server exposed directly to the Internet, our servers sit safely behind the Gateway API effectively eliminating DDOS and . None of our functions allow dynamic access or functionality. This means that there is no identifiable server to be hacked and other attack vectors are either eliminated or dramatically limited. '}{<ExLink variant='body1' title='Learn more about Serverless Computing.' to='https://en.wikipedia.org/wiki/Serverless_computing' />}
            </SectionBody>
          </Section>

          <Section>

            <SectionHeading title='Provisioned Services' />

            <SectionBody>
              {'Not only do we leverage serverless computing for the efficiency and security advantages, we have built an entirely provisioned services model ('}{<ExLink variant='body1' title='IaaS' to='https://en.wikipedia.org/wiki/Infrastructure_as_a_service' />}{').'}
            </SectionBody>

            <SectionBody>
              {'Among the many advantages of this model:'}
            </SectionBody>

            <SectionBody>
              <ul style={{ margin: 0 }} >
                <li>{'Dramatically lower startup and operating costs'}</li>
                <li>{'No infrastructure hardware needs to be purchased, housed, or maintained'}</li>
                <li>{'No IT staff are needed'}</li>
                <li>{'Infinitely scalable (effectively)'}</li>
              </ul>
            </SectionBody>

            <SectionBody>
              {/* {'Without adding any infrastructure or hiring any personnel, our services are scalable to millions of users executing billions of transactions out of the box.'} */}
              {'Without any additional infrastructure or personnel, our technology is scalable to millions of users executing billions of transactions out of the box.'}
            </SectionBody>

          </Section>

          <Section>

            <SectionHeading title='Serverless Security' />

            <SectionBody>
              {'As you might have guessed, "Serverless" is not really serverless. However, rather than directly exposing web servers to the Internet (even if behind a firewall) Serverless infrastructures only expose one or more Gateway APIs which each only listen on a single open port which is not dynamically accessible (i.e. no SSH access, etc). And even then, they only accept encrypted (https) connections. In addition, the API typically can only access limited cloud functions which in turn can only access limited additional private resources (databases, etc), with identity and resource access permission checks at each stage in process. Finally, updates to Serverless infrastructure are performed via the provisioned services provider (e.g. AWS) which means, again, no direct server or other resource access. Even cloud-based web servers, while provisioned and deployed via the services provider, are typically maintained and updated directly which means they must have a potentially breachable dynamic access point. Due to this lack of direct access, ceteris paribus, we believe Serverless is inhernetly more secure than most other web server infrastrutures deployed today.'}
            </SectionBody>

            <SectionBody>
              {'Internet security is an enormous topic and the information provided here is meant to be general and based on typical deployments. That said, we are passionate about security and we will have much more detailed and specific information posted on this subject in the near future.'}
            </SectionBody>

            {/* <SectionBody>
              {'While Serverless has recently recieved an enormous amount of praise regarding its many advantages over more traditional website intfrastructure designs, there is also a lot of misinformation regarding security for Serverless APIs. Most of negative commentary is either simply outdated or not based on an obective comparison of security risks which affect ALL web site designs. In fact, we believe that the current default Serverless deployment settings '}
            </SectionBody> */}

          </Section>

         </Section>

      </Page>

    </LearnTabs>
    </>
  );
}

export default Learn;
